import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Collapse from '@material-ui/core/Collapse';
import clsx from 'clsx';
import {
  actionMapMapviewChangeRequested,
  selectorMapZoom,
} from '../Map/MapRedux';
import Tooltip from '@material-ui/core/Tooltip';
import ZoomSlider from './ZoomSlider';
import { trackEvent, MAP_TOOLS } from '../../tracking';

const isLocalhost = window.location.hostname === 'localhost'; 

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 4,
  },
  widget: {
    width: 42,
    minWidth: 'unset',
    backgroundColor: '#ffffff',
    border: '1px solid #f0f0f0',
    [theme.breakpoints.down('xs')]: {
      width: 34,
      '& .MuiSvgIcon-root': {
        fontSize: '1.25rem',
      },
    },
  },
  zoomBtns: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    '&:hover': {
      '& button': {
        borderRadius: 0,
      },
    },
  },
  zoomIn: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    borderBottom: 'none',
  },
  zoomOut: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderTop: 'none',
  },
  expand: {
    paddingTop: 0,
    paddingBottom: 0,
    backgroundColor: 'rgba(213, 213, 213, 0.5)',
    borderRadius: 0,
    border: 'none',
    '&:hover': {
      backgroundColor: 'rgba(213, 213, 213, 0.95)',
    },
    '& .MuiSvgIcon-root': {
      fontSize: '1.25rem',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none !important',
    },
  },
  slider: {
    height: '30vh',
    boxShadow: 'none',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'whitesmoke',
    [theme.breakpoints.down('xs')]: {
      display: 'none !important',
    },
  }
}));

export default function ZoomButton({ factor }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const zoom = useSelector(selectorMapZoom);

  const [expandVisible, setExpandVisible] = useState(false);
  const [expand, setExpand] = useState(false);

  const expandClick = () => { setExpand(!expand) }
  const handlePopoverOpen = () => { setExpandVisible(true) }
  const handlePopoverClose = () => { setExpandVisible(false) }

  const zoomHandler = (value) => () => {
    !isLocalhost && trackEvent({
      category: MAP_TOOLS,
      action: 'Zoom Button',
      label: value > 0 ? 'In' : 'Out'
    })
    dispatch(actionMapMapviewChangeRequested({
      zoom: zoom + value
    }));
  }

  const handleChange = (_, newValue) => {
    !isLocalhost && trackEvent({
      category: MAP_TOOLS,
      action: 'Zoom Slider',
      value: newValue
    })
    dispatch(actionMapMapviewChangeRequested({
      zoom: newValue
    }));
  }

  return (
    <div className={classes.root}>
      <Paper
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        className={classes.zoomBtns}
        data-testid="zoom-button"
        title={zoom}
      >
        <Tooltip title="Zoom In">
          <Button
            onClick={zoomHandler(factor)}
            variant="contained"
            className={clsx(classes.widget, classes.zoomIn)}
            data-testid="zoom-in"
          >
            <AddIcon />
          </Button>
        </Tooltip>
        <Collapse in={expand}>
          <ZoomSlider zoom={zoom} handleChange={handleChange} />
        </Collapse>
        <Tooltip title="Zoom Out">
          <Button
            onClick={zoomHandler(-1 * factor)}
            variant="contained"
            className={clsx(classes.widget, classes.zoomOut)}
            data-testid="zoom-out"
          >
            <RemoveIcon />
          </Button>
        </Tooltip>
        <Tooltip title="Toggle Zoom Slider">
          <Button
            style={{visibility: expandVisible ? 'visible' : 'hidden'}}
            variant="contained"
            className={clsx(classes.widget, classes.expand)}
            onClick={expandClick}>
            {expand ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </Button>
        </Tooltip>
      </Paper>
    </div>
  );
}