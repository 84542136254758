import XLSX from 'xlsx';
import { iconArray } from './IconSelector';
import _ from 'lodash';

export const wbFromFile = (file) => {
  return new Promise( resolve => {
    const reader = new FileReader();
    reader.onload = e => {
      const data = new Uint8Array(e.target.result);
      const wb = XLSX.read(data, {
        type: 'array',
        cellDates: true,
      });
      resolve(wb)
    };
    reader.readAsArrayBuffer(file);
  });
};

export const jsonFromWb = (wb) => {
  const sheet1 = wb.SheetNames[0];
  const json = XLSX.utils.sheet_to_json(wb.Sheets[sheet1], {
    header: 1, 
    defval: "", 
    // cellDates: true
    // raw: false
  });
  const colsMax = Math.max(...json.map(row => row.length));
  const returnJson = json.map(row => {
    if (row.length === colsMax) return row;
    if (row.length < colsMax) {
      const returnRow = new Array(colsMax).fill("");
      row.forEach((e, i) => {
        returnRow[i] = e;
      });
      return returnRow;
    }
  });
  return returnJson;
};

export const fileFromJson = (json, filename) => {
  let ws = XLSX.utils.aoa_to_sheet(json);
  let wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  XLSX.writeFile(wb, filename + '.xlsx');
};

export const getNextSheetId = (sheetsArray) => {
  const ids = sheetsArray.map(({id}) => id);
  return ids.length > 0 ? Math.max(...ids) + 1 : 0;
};

/**
 * 
 * @param {Object} sheet imported redux sheetfile
 * @returns {array} formatted array for use in mapbox expressions
 */
export const rmrecnbrFilterFromJson = (sheet) => {
  const { json, rmrecnbr: rrn, lnglat } = sheet;
  const validLngLat = !!(lnglat !== null && Array.isArray(lnglat) && lnglat[0] && lnglat[1]);
  const rmrecnbrArr = json
    .filter(arr => !validLngLat || !(arr[lnglat[0]] && arr[lnglat[0]]))
    .map(arr => arr[rrn])
    .map(num => parseInt(num))
    .filter(num => !isNaN(num))
    .map(num => num.toString());
  const newrmrecnbrFilter = ["in", "rmrecnbr"].concat(rmrecnbrArr);
  return newrmrecnbrFilter;
};

/** 
 * Combine values from
 * * Spreadsheet
 * * Settings
 * * Conditional Settings
 * * Default values
 * 
 * 
 * */ 
export const conditionalFilterFromJson = (conditions, key, reduxValue, reduxVisible = true, sheet) => {
  const { json, rmrecnbr, lnglat, id = 0 } = sheet;
  if (!reduxVisible) return '';
  const { [key]: defaultValue } = defaultSheetFile(id);
  // get value from imported spreadsheet
  const sheetObj = returnImportObj(json, rmrecnbr, lnglat, id);
  // get values from in-app conditional forrmatting
  const condObj = returnCondObj(conditions, key);
  // pick between imported / conditionals values
  // * values assigned to rmrecnbrs
  const mapObj = returnMapObj(sheetObj, condObj, reduxValue);
  // pick between redux / default value 
  // * values not assigned to rmrecnbrs
  const fallbackVal = returnFallbackVal(reduxValue, defaultValue, conditions);
  // creates mapbox filter
  const filter = returnMapExpression(mapObj, fallbackVal, key, sheet);
  return filter;
};


export const returnImportObj = (json, rmrecnbr, lnglat, sheetId) => {
  const MGIS_COLOR = 'MGIS-Color';
  const MGIS_COLOR_CONFIG = 'MGIS-Color-Config';
  const MGIS_TEXT = 'MGIS-Text';
  const MGIS_TEXT_CONFIG  = 'MGIS-Text-Config';
  const MGIS_ICON = 'MGIS-Icon';
  const MGIS_ICON_CONFIG = 'MGIS-Icon-Config';
  const colorIndex = json[0].indexOf(MGIS_COLOR);
  const colorConfigIndex = json[0].indexOf(MGIS_COLOR_CONFIG);
  const textIndex = json[0].indexOf(MGIS_TEXT);
  const textConfigIndex = json[0].indexOf(MGIS_TEXT_CONFIG);
  const iconIndex = json[0].indexOf(MGIS_ICON);
  const iconConfigIndex = json[0].indexOf(MGIS_ICON_CONFIG);

  const returnObj = {}
  json.forEach((row, i) => {
    // if lng lat, create object with pointid id
    if (lnglat && Array.isArray(lnglat) && lnglat[0] && lnglat[1] && row[lnglat[0]] && row[lnglat[1]]) {
      if (i === 0) return;
      const pointId = `point-${sheetId}-${i}`;
      // CREATE EMPTY OBJECT
      returnObj[pointId] = {};
      // PARSE MGIS-COLOR COLUMN
      const colorVal = colorIndex >= 0 ? row[colorIndex] : null;
      const pointColor = getFillColor(colorVal);
      if (pointColor !== null) { returnObj[pointId].pointColor = pointColor }
      const pointOpacity = getFillOpacity(colorVal);
      if (pointOpacity !== null) { returnObj[pointId].pointOpacity = pointOpacity }
      // PARSE MGIS-ICON COLUMN
      let iconVal = iconIndex >= 0 ? row[iconIndex] : null;
      if (iconVal !== null) {
        returnObj[pointId].pointIcon = String(iconVal);
      }
      // PARSE MGIS-ICON-CONFIG
      const iconConfigVal = iconConfigIndex >= 0 ? row[iconConfigIndex] : null;
      const iconSizeRegex = /size@.*?(?=;)/;
      const iconSizeMatch = iconConfigVal ? iconConfigVal.match(iconSizeRegex) : false;
      if (Array.isArray(iconSizeMatch)) {
        const iconSize = iconSizeMatch[0].replace('size@', '');
        returnObj[pointId].pointSize = Number(iconSize);
      }
    } 
    // else create object with rrn id
    else if (parseInt(row[rmrecnbr])) {
      // CREATE EMPTY OBJECT
      returnObj[row[rmrecnbr]] = {};
      // PARSE MGIS-COLOR COLUMN
      // fillColor & fillOpacity
      const colorVal = colorIndex >= 0 ? row[colorIndex] : null;
      const fillColor = getFillColor(colorVal);
      if (fillColor !== null) { returnObj[row[rmrecnbr]].fillColor = fillColor }
      const fillOpacity = getFillOpacity(colorVal);
      if (fillOpacity !== null) { returnObj[row[rmrecnbr]].fillOpacity = fillOpacity }
  
      // PARSE MGIS-COLOR CONFIG
      // lineWidth
      let colorConfigVal = colorConfigIndex >= 0 ? row[colorConfigIndex] : null;
      const lineWidthRegex = /outlineWidth@.*?(?=;)/;
      const lineWidthMatch = colorConfigVal ? colorConfigVal.match(lineWidthRegex) : false;
      if (Array.isArray(lineWidthMatch)) {
        const lineWidth = lineWidthMatch[0].replace('outlineWidth@', '');
        returnObj[row[rmrecnbr]].lineWidth = Number(lineWidth);
      }
      // lineColor
      const lineColorRegex = /outlineColor@.*?(?=;)/;
      const lineColorMatch = colorConfigVal ? colorConfigVal.match(lineColorRegex) : false
      if (Array.isArray(lineColorMatch)) {
        const lineColor = lineColorMatch[0].replace('outlineColor@', '');
        returnObj[row[rmrecnbr]].lineColor = String(lineColor);
      }
      // PARSE MGIS-TEXT
      let textVal = textIndex >= 0 ? row[textIndex] : null;
      if (textVal !== null && textVal !== undefined) {
        returnObj[row[rmrecnbr]].text = String(textVal)
      }
      // PARSE MGIS-TEXT CONFIG
      // textColor
      const textConfigVal = textConfigIndex >= 0 ? row[textConfigIndex] : null;
      const textColorRegex = /color@.*?(?=;)/;
      const textColorMatch = textConfigVal ? textConfigVal.match(textColorRegex) : false;
      if (Array.isArray(textColorMatch)) {
        const textColor = textColorMatch[0].replace('color@', '');
        returnObj[row[rmrecnbr]].textColor = String(textColor)
      }
      // textSize
      const textSizeRegex = /fontSize@.*?(?=;)/;
      const textSizeMatch = textConfigVal ? textConfigVal.match(textSizeRegex) : false;
      if (Array.isArray(textSizeMatch)) {
        const textSize = textSizeMatch[0].replace('fontSize@', '');
        returnObj[row[rmrecnbr]].textSize = Number(textSize);
      }
      // textOffset
      const xTextPosRegex = /xoffset@[-0-9.]*/;
      const xTextPosMatch = textConfigVal ? textConfigVal.match(xTextPosRegex) : false;
      const yTextPosRegex = /yoffset@[-0-9.]*/;
      const yTextPosMatch = textConfigVal ? textConfigVal.match(yTextPosRegex) : false;
      if (Array.isArray(xTextPosMatch) && Array.isArray(yTextPosMatch)) {
        const xText = xTextPosMatch[0].replace('xoffset@', '');
        const yText = yTextPosMatch[0].replace('yoffset@', '');
        returnObj[row[rmrecnbr]].textX = Number(xText);
        returnObj[row[rmrecnbr]].textY = Number(yText);
      }
      // PARSE MGIS-ICON
      let iconVal = iconIndex >= 0 ? row[iconIndex] : null;
      if (iconVal !== null) {
        returnObj[row[rmrecnbr]].icon = String(iconVal);
      }
      // PARSE MGIS-ICON-CONFIG
      // iconSize
      const iconConfigVal = iconConfigIndex >= 0 ? row[iconConfigIndex] : null;
      const iconSizeRegex = /size@.*?(?=;)/;
      const iconSizeMatch = iconConfigVal ? iconConfigVal.match(iconSizeRegex) : false;
      if (Array.isArray(iconSizeMatch)) {
        const iconSize = iconSizeMatch[0].replace('size@', '');
        returnObj[row[rmrecnbr]].iconSize = Number(iconSize);
      }
      // iconOffset
      const xIconPosRegex = /xoffset@[-0-9.]*/;
      const xIconPosMatch = iconConfigVal ? iconConfigVal.match(xIconPosRegex) : false;
      const yIconPosRegex = /yoffset@[-0-9.]*/;
      const yIconPosMatch = iconConfigVal ? iconConfigVal.match(yIconPosRegex) : false;
      if (Array.isArray(xIconPosMatch) && Array.isArray(yIconPosMatch)) {
        const xIcon = xIconPosMatch[0].replace('xoffset@', '');
        const yIcon = yIconPosMatch[0].replace('yoffset@', '');
        returnObj[row[rmrecnbr]].iconX = Number(xIcon);
        returnObj[row[rmrecnbr]].iconY = Number(yIcon);
      }
    }
  });
  return returnObj;
};

export const getFillColor = colorVal => {
  const isValidText = colorVal && colorVal.trim && colorVal.toLowerCase;
  const colorText = isValidText ? colorVal.trim().toLowerCase() : '';

  if (!colorText) {
    return null;
  } else if (colorText.startsWith('rgb')) { // rgb
    return rgbTextToRGB(colorText);
  } else if (colorText.startsWith('#')) { // hex
    return hexTextToRGB(colorText);
  } else if (colorText.startsWith('hsl')){ // hsl
    return hslToRGB(colorText);
  } else { // color name
    return colorNameToRGB(colorText);
  }
};

const getFillOpacity = colorVal => {
  const isValidText = colorVal && colorVal.trim && colorVal.toLowerCase;
  const colorText = isValidText ? colorVal.trim().toLowerCase() : '';

  if (!colorText) {
    return null;
  } else if (colorText.startsWith('rgb')) { // rgb
    const [r, g, b, a] = rgbTextToRGBArray(colorText);
    return a === undefined ? null : Number(a);
  } else { // hex & color name
    return 1;
  }
};

const rgbTextToRGBArray = text => {
  if (!text) { return [] }
  const fillColorRegex = /[\d+|.|\s]+/g 
  const match = text.match(fillColorRegex);
  return Array.isArray(match) ? match : [];
};

const rgbTextToRGB = text => {
  if (!text) { return null }
  const rgbArray = rgbTextToRGBArray(text);
  const [r, g, b] = rgbArray.length ? rgbArray : [];
  return rgbArray.length ? `rgb(${r}, ${g}, ${b})` : null;
};

// @ref: https://stackoverflow.com/questions/5623838/rgb-to-hex-and-hex-to-rgb
const hexTextToRGB = text => {
  // @ref: https://stackoverflow.com/questions/8027423/how-to-check-if-a-string-is-a-valid-hex-color-representation
  var reg=/^#([0-9a-f]{3}){1,2}$/i;
  var isValidHex = text && reg.test(text);
  if (!isValidHex) { return null }
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  var hex = text.replace(shorthandRegex, function(m, r, g, b) {
    return r + r + g + g + b + b;
  });

  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  var colorDict = result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : '';
  return colorDict ? `rgb(${colorDict.r}, ${colorDict.g}, ${colorDict.b})` : null;
};

// @ref: https://stackoverflow.com/a/47355187
const colorNameToRGB = text => {
  if (!text || !text.toLowerCase) { return null }
  var textColor = text.toLowerCase();
  var ctx = document.createElement('canvas').getContext('2d');
  ctx.fillStyle = textColor;
  var nonValidColor = textColor !== 'black' && ctx.fillStyle === '#000000';
  return nonValidColor ? null : hexTextToRGB(ctx.fillStyle);
};

// @ref: https://css-tricks.com/converting-color-spaces-in-javascript/
const hslToRGB = text => {
  let sep = text.indexOf(",") > -1 ? "," : " ";
  let hsl = text.substr(4).split(")")[0].split(sep);

  let h = hsl[0],
    s = hsl[1].substr(0, hsl[1].length - 1) / 100,
    l = hsl[2].substr(0, hsl[2].length - 1) / 100;


  let c = (1 - Math.abs(2 * l - 1)) * s,
    x = c * (1 - Math.abs((h / 60) % 2 - 1)),
    m = l - c / 2,
    r = 0,
    g = 0,
    b = 0;
  if (0 <= h && h < 60) {
    r = c; g = x; b = 0;
  } else if (60 <= h && h < 120) {
    r = x; g = c; b = 0;
  } else if (120 <= h && h < 180) {
    r = 0; g = c; b = x;
  } else if (180 <= h && h < 240) {
    r = 0; g = x; b = c;
  } else if (240 <= h && h < 300) {
    r = x; g = 0; b = c;
  } else if (300 <= h && h < 360) {
    r = c; g = 0; b = x;
  }
  r = Math.round((r + m) * 255);
  g = Math.round((g + m) * 255);
  b = Math.round((b + m) * 255);

  return "rgb(" + r + "," + g + "," + b + ")";
}

export const returnCondObj = (conditions, key) => {
  const condObj = {};
  conditions.filter(condition => condition.hasOwnProperty(key) && condition.type.indexOf('heatmap') !== 0).forEach(condition => {
    let { rmrecnbr, [key]: value } = condition;
    if (!rmrecnbr || !value) return;
    if (Array.isArray(rmrecnbr)) {
      rmrecnbr.forEach(nbr => {
        if (value) {
          condObj[nbr] = { [key] : value };
        }
      });
    } else {
      if (value) {
        condObj[rmrecnbr] = { [key] : value };
      }
    }
  });
  return condObj;
};

export const returnMapObj = (importObj, conditionObj, reduxVal) => {
  if (Array.isArray(reduxVal)) {
    if (reduxVal[0] === null && reduxVal[1] === null) {
      return {...importObj, ...conditionObj};
    } else {
      return conditionObj;
    }
  }
  if (reduxVal === null) {
    return {...importObj, ...conditionObj};
  } else {
    return conditionObj;
  }
};

export const returnFallbackVal = (reduxVal, defaultVal, conditions) => {
  if (Array.isArray(conditions) && conditions.map(cond => cond.type).some(x => x.indexOf('heatmap') === 0)) {
    return '#FFF';
  }
  if (Array.isArray(reduxVal)) {
    if (reduxVal[0] === null && reduxVal[1] === null) return defaultVal;
    return reduxVal;
  }
  return reduxVal === null ? defaultVal : reduxVal;
};

export const returnMapExpression = (mapObj, fallbackVal, key, sheet) => {
  const {json, rmrecnbr } = sheet;
  if (key === 'fillOpacity') return returnOpacityMapExpression(mapObj, fallbackVal, key);
  if (key === 'lineColor') return returnLineColorMapExpression(mapObj, fallbackVal, key);
  if (key === 'text') return returnTextMapExpression(mapObj, fallbackVal, key, json, rmrecnbr);
  if (key === 'textOffset' || key === 'iconOffset') return returnOffsetExpression(mapObj, fallbackVal, key);
  if (key === 'icon') return returnIconExpression(mapObj, fallbackVal, key);
  const NUMBER_TYPES = ['iconSize', 'textSize', 'lineWidth', 'pointOpacity', 'pointSize'];

  const format = (val) => {
    if (NUMBER_TYPES.includes(key)) return Number(val);
    return String(val);
  }
  
  let conditionalFilter = ['case',];
  
  for (const ref in mapObj) {
    const referenceExpression = parseInt(ref) ? ["get", "rmrecnbr"] : ['get', 'id'];
    if (mapObj[ref][key]) {
      // let conditionFilter =  [["==", ["get", "rmrecnbr"], String(rmrecnbr)], format(mapObj[rmrecnbr][key])];
      let conditionFilter =  [["==", referenceExpression, String(ref)], format(mapObj[ref][key])];
      conditionalFilter = conditionalFilter.concat(conditionFilter)
    }
  }

  conditionalFilter = conditionalFilter.concat(fallbackVal);
  if (conditionalFilter.length < 3) conditionalFilter = fallbackVal;
  return conditionalFilter;
};

const returnOpacityMapExpression = (mapObj, fallbackVal, key) => {
  let conditionalFilter = ['case', ];
  for (const rmrecnbr in mapObj) {
    if (mapObj[rmrecnbr][key]) {
      let val = mapObj[rmrecnbr][key]
      // Feature-state AND conditional/upload
      let selectedFilter = [
        [ 
          "all", 
          // has condition / upload value AND
          ["==", ["get", "rmrecnbr"], String(rmrecnbr)],
          // has selected feature state
          ["boolean", ["feature-state", "selected" ], false]
        ],
        selectedOpacity(val)
      ];
      conditionalFilter = conditionalFilter.concat(selectedFilter);
      // conditional / upload value NOT selected
      let conditionFilter = [["==", ["get", "rmrecnbr"], String(rmrecnbr)], Number(val)];
      conditionalFilter = conditionalFilter.concat(conditionFilter);
    } 
  }
  // NOT conditional / upload AND selected
  let selectedFilter = [['boolean', ['feature-state', 'selected'], false], selectedOpacity(fallbackVal)];
  conditionalFilter = conditionalFilter.concat(selectedFilter);
  // NOT conditional / upload NOT selected
  conditionalFilter = conditionalFilter.concat(fallbackVal);
  return conditionalFilter;
}

const returnLineColorMapExpression = (mapObj, fallbackVal, key) => {
  let conditionalFilter = ['case', ];
  for (const rmrecnbr in mapObj) {
    if (mapObj[rmrecnbr][key]) {
      let val = mapObj[rmrecnbr][key]
      // Feature-state AND conditional/upload
      let selectedFilter = [
        [ 
          "all", 
          // has condition / upload value AND
          ["==", ["get", "rmrecnbr"], String(rmrecnbr)],
          // has selected feature state
          ["boolean", ["feature-state", "selected" ], false]
        ],
        '#39FF14'
      ];
      conditionalFilter = conditionalFilter.concat(selectedFilter);
      // conditional / upload value NOT selected
      let conditionFilter = [["==", ["get", "rmrecnbr"], String(rmrecnbr)], val];
      conditionalFilter = conditionalFilter.concat(conditionFilter);
    }
  }
  // NOT conditional / upload AND selected
  let selectedFilter = [
    ['boolean', ['feature-state', 'selected'], false],
    '#39FF14'
  ];
  conditionalFilter = conditionalFilter.concat(selectedFilter);
  // NOT conditional / upload NOT selected
  conditionalFilter = conditionalFilter.concat(fallbackVal);
  return conditionalFilter;
};

const returnTextMapExpression = (mapObj, fallbackVal, key, json, rmrecnbr) => {
  let textObj = {};
  for (const rmrecnbr in mapObj) {
    if ("text" in mapObj[rmrecnbr]) {
      textObj[rmrecnbr] = mapObj[rmrecnbr].text;
    }
  }
  let fallbackObj = returnDefaultTextObj(json, fallbackVal, rmrecnbr);
  const combinedObj = {...fallbackObj, ...textObj};
  let conditionalFilter = ['case',];

  for (const rmrecnbr in combinedObj) {
    let val = combinedObj[rmrecnbr] ? combinedObj[rmrecnbr] : '';
    let conditionFilter =  [["==", ["get", "rmrecnbr"], String(rmrecnbr)], String(val)];
    conditionalFilter = conditionalFilter.concat(conditionFilter);
  }

  conditionalFilter = conditionalFilter.concat("")
  return conditionalFilter;
};

const returnOffsetExpression = (obj, fallback, key) => {
  let fallbackVal = [fallback[0] || 0, fallback[1] || 0]
  let mapObj = {};
  let x, y;
  if (key === 'iconOffset') {
    x = 'iconX';
    y = 'iconY';
  }

  if (key === 'textOffset') {
    x = 'textX';
    y = 'textY';
  }

  for (const rmrecnbr in obj) {
    if (x in obj[rmrecnbr] && y in obj[rmrecnbr]) {
      const xval = typeof obj[rmrecnbr][x] === 'number' && !isNaN(obj[rmrecnbr][x]) ? obj[rmrecnbr][x] : 0;
      const yval = typeof obj[rmrecnbr][y] === 'number' && !isNaN(obj[rmrecnbr][y]) ? obj[rmrecnbr][y] : 0;
      mapObj[rmrecnbr] = [ xval, yval ];
    }
  }
  let conditionalFilter = ['case', ];
  for (const rmrecnbr in mapObj) {
    let conditionFilter =  [["==", ["get", "rmrecnbr"], String(rmrecnbr)], ['literal', mapObj[rmrecnbr]]];
    conditionalFilter = conditionalFilter.concat(conditionFilter);
  }

  conditionalFilter = conditionalFilter.concat([['literal', fallbackVal]])
  if (conditionalFilter.length < 3) return ['literal', fallbackVal];
  return conditionalFilter;
};

const returnIconExpression = (mapObj, fallbackVal, key) => {
  const icons = iconArray.map(icon => icon.value);
  let conditionalFilter = ['case',];
  for (const rmrecnbr in mapObj) {
    if (mapObj[rmrecnbr][key] && icons.includes(mapObj[rmrecnbr][key])) {
      let conditionFilter =  [["==", ["get", "rmrecnbr"], String(rmrecnbr)], mapObj[rmrecnbr][key]];
      conditionalFilter = conditionalFilter.concat(conditionFilter);
    }
  }

  conditionalFilter = conditionalFilter.concat(fallbackVal);
  if (conditionalFilter.length < 3) conditionalFilter = fallbackVal;
  return conditionalFilter;
};

const returnDefaultTextObj = (json, fallbackVal, rmrecnbr) => {
  let returnObj = {};
  let lookup = fallbackVal === null ? rmrecnbr : fallbackVal;
  json.forEach((row, i) => {
    if (i === 0) return;
    if (!row[rmrecnbr]) return;
    returnObj[row[rmrecnbr]] = row[lookup]
  });
  return returnObj;
};

const selectedOpacity = (num) => {
  return num >= .5 ? num - .35 : num + .5;
};

export const heatMapObjfromJson = (dataObj, hslObj) => {
  if (_.isEmpty(dataObj) || _.isEmpty(hslObj) || !dataObj || !hslObj) return null;

  const { h, s, l} = hslObj;
  const max = Math.max(...Object.keys(dataObj).map(num => parseFloat(num)).filter(num => !isNaN(num)));
  const filterObj = {};
  for (const key in dataObj) {
    if (!isNaN(parseFloat(key))) {
      const lVal = (1 - (parseFloat(key) / max) * (1 - l));
      const hslStr = `hsl(${h}, ${s * 100}%, ${lVal * 100}%)`;
      filterObj[hslStr] = dataObj[key];
    }
  }
  return filterObj;
};

export const guessRrnHeaderFromJson = (json) => {
  const RRN_HEADER_ARRAY = [
    'mgis-rrn',
    'roomrecordnumber',
    'rmrecnbr',
    'rmrecnum',
    'rrn'
  ];
  const headerRow = json[0];

  const rrnIndex = findMatchingIndex(headerRow, RRN_HEADER_ARRAY);
  return rrnIndex > -1 ? rrnIndex : null;
};

export const guessLngLatHeaderFromJson = (json) => {
  const LNG_HEADER_ARRAY = ['lng', 'longitude', 'long'];
  const LAT_HEADER_ARRAY = ['lat', 'latitude'];
  const headerRow = json[0];

  const lngIndex = findMatchingIndex(headerRow, LNG_HEADER_ARRAY);
  const latIndex = findMatchingIndex(headerRow, LAT_HEADER_ARRAY);

  if (lngIndex !== -1 && latIndex !== -1) return [lngIndex, latIndex];
  return null;
};

export const guessSheetDataType = (json, rmrecnbr, lnglat) => {
  const room = rmrecnbr !== null && rmrecnbr > -1;
  const point = lnglat && Array.isArray(lnglat) && lnglat[0] > -1 && lnglat[1] > -1;
  const dual = room & point;
  if (dual) {
    let dualRoomRow = false;
    let dualPointRow = false;
    const data = json.filter((row, index) => (index !== 0))
    for (let row of data) {
      if (dualRoomRow && dualPointRow) return 'dual';
      if (row[lnglat[0]] && row[lnglat[1]]) dualPointRow = true;
      else if (row[rmrecnbr]) dualRoomRow = true;
    }
    if (dualRoomRow && !dualPointRow) return 'room';
    if (dualPointRow && !dualRoomRow) return 'point';
  }
  if (point) return 'point';
  if (room) return 'room';
  return undefined;
};

const findMatchingIndex = (searchArray, matchArray) => {
  const sArray = Array.isArray(searchArray) ? searchArray : [searchArray];
  const mArray = Array.isArray(matchArray) ? matchArray : [matchArray];

  return sArray.findIndex(cell => {
    const cellText = cell && String(cell).toLowerCase().trim();
    return mArray.includes(cellText);
  });
};

const spreadsheetDefaultColors = [
  'rgb(30,144,255)',
  'rgb(255,215,0)',
  'rgb(173,216,230)',
  'rgb(218,112,214)',
  'rgb(144,238,144)',
  'rgb(255,0,255)',
  'rgb(205,133,63)',
  'rgb(238,130,238)'
];

const spreadsheetDefaultBorders = [
  '#fff21e',
  '#00e2ff',
  '#e9baaa',
  '#66e497',
  '#ff7fff',
  '#00ff00',
  '#0db7ff',
  '#92dea9',
];

export const reduxSheetFile = () => {
  return {
    lineWidth: null,
    lineColor: null,
    fillColor: null,
    fillOpacity: null, 
    textColor: null,
    textSize: null,
    textX: null,
    textY: null,
    icon: null,
    iconSize: null,
    iconX: null,
    iconY: null,
    pointColor: null,
    pointIcon: null,
    pointSize: null,
    pointOpacity: null,
  }
};

export const defaultSheetFile = (sheetId = 0) => {
  const colorIndex = sheetId % 7;
  return {
    fileName: null,
    id: null,
    rmrecnbr: null,
    lnglat: null,
    json: [[],[],[]],
    export: false,

    layersAllVisible: true,
    selectedRmrecnbr: null,
    selectedRmnbr: null,
    selectedPoint: null,
    
    // location data
    bbox: [],

    // fill data
    fillVisible: true, 
    fillColor: spreadsheetDefaultColors[colorIndex],
    fillOpacity: 1,

    // line data
    lineColor: spreadsheetDefaultBorders[colorIndex],
    lineWidth: 2,

    // label data
    textVisible: true,
    text: null,
    defaultText: '',
    textSize: 16,
    textX: 0,
    textY: 0,
    textColor: 'black',

    // icon data
    iconVisible: true,
    icon: '',
    iconSize: 1.5,
    iconX: 0,
    iconY: 0,
    iconColor: 'black',
    textOffset: [0, 0],
    iconOffset: [0, 0],

    // point data
    pointColor: spreadsheetDefaultColors[colorIndex],
    pointVisible: true,
    pointIcon: 'room',
    pointSize: 1.5,
    pointOpacity: 1,
    
    // conditions and conditional data
    conditions: [],
    colorConditionalAttr: '',
    colorConditionalDatatype: '',
    textConditionalAttr: '',
    textConditionalDatatype: '',
    iconConditionalAttr: '',
    iconConditionalDatatype: '',
    pointConditionalAttr: '',
    pointConditionalDatatype: '',

    // settings & content expanded
    contentExpanded: false,
    contentRmrecnbrExpanded: false,
    contentSheetDataExpanded: false,
    contentBuildingDataExpanded: false,
    contentSelectedRoomExpanded: false,

    settingsExpanded: false,
    // color, text, icon
    settingsTab: 'color',
    colorDefaultExpanded: true,
    colorAdvancedExpanded: false,
    colorConditionalExpanded: false,
    textDefaultExpanded: true,
    textAdvancedExpanded: false,
    textConditionalExpanded: false,
    iconDefaultExpanded: true,
    iconAdvancedExpanded: false,
    iconConditionalExpanded: false,
    pointDefaultExpanded: true,
    pointAdvancedExpanded: false,
    pointConditionalExpanded: false,
  }
};

export const returnSheetDefaults = (sheetId, ...keys) => {
  const resetObj = {};
  const defaultObj = {...defaultSheetFile(sheetId), ...reduxSheetFile()};
  keys.forEach(key => {
    resetObj[key] = defaultObj[key]
  });
  return resetObj; 
};

export const createSheetPointGeoJsonSource = (json, lnglat, sheetId) => {
  if (!lnglat) return null;
  const [lngIndex, latIndex] = lnglat;
  const returnGeoJson = {
    type: 'FeatureCollection',
    features: []
  };

  const headerRow = json[0];
  json.forEach((row, i) => {
    if (i !== 0) {

      const properties = {};
      const coordinates = []
      row.forEach((cell, cellIndex) => {
        if (cellIndex === lngIndex) coordinates[0] = cell;
        else if (cellIndex === latIndex) coordinates[1] = cell;
        else if (cell) properties[headerRow[cellIndex]] = cell;        
      });

      properties.id = `point-${sheetId}-${i}`;
      properties.sheetRow = i;
      
      const feature = {
        type: 'Feature',
        properties,
        geometry: {
          type: 'Point',
          coordinates
        }
      };
    
      returnGeoJson.features.push(feature);
    }
  });

  return returnGeoJson;
}

// export const isValidBbox = (bbox) => {
//   return (
//     Array.isArray(bbox) &&
//     bbox.length === 2 &&
//     Array.isArray(bbox[0]) &&
//     Array.isArray(bbox[1]) &&
//     _.isFinite(bbox[0][0]) &&
//     _.isFinite(bbox[0][1]) &&
//     _.isFinite(bbox[1][0]) &&
//     _.isFinite(bbox[1][1])
//   );
// }
