import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { peopleDataFormatter } from './SearchRedux';
import { searchQuery } from './searchApi';
import MenuDialog from '../Shared/MenuDialog';
import AutocompleteMenu from '../Shared/AutocompleteMenu';
import { SEARCH, trackEvent } from '../../tracking';

async function queryPeople(queryStr, idToken) {
  const response = await searchQuery('/supersearch/' + queryStr, idToken);
  const isValidResponse = !response.error && response.data && (
                            (response.data.person_name && response.data.person_name.length) || 
                            (response.data.person_uniqname && response.data.person_uniqname.length)
                          );

  if (!isValidResponse) { return [] }

  const names = response.data.person_name ? response.data.person_name : [];
  const uniqnames = response.data.person_uniqname ? response.data.person_uniqname : [];
  const uniqnameSet = new Set();
  const people = [...names, ...uniqnames].reduce((accumulator, person) => {
    if (!uniqnameSet.has(person.person_uniqname)) {
      accumulator.push(person);
      uniqnameSet.add(person.person_uniqname);
    }
    return accumulator;
  }, []);
  return peopleDataFormatter(people);
}

function PeopleAutocomplete({ open, selectHandler }) {
  const optionDisplay = {
    selected: ['person_name', 'person_uniqname'],
    label: 'person_name',
    labelId: option => option['person_uniqname'].toLowerCase(),
    labelContext: 'rmrole',
  };

  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const defaultData = useSelector(state => state.searchReducer.defaultData.people);
  const idToken = useSelector(state => state.authReducer.idToken);
  const formattedDefaultData = defaultData && Array.isArray(defaultData) ? defaultData.map(p => {
    const person_name = p.person_name.replace(',', ', ');
    return {
      ...p,
      person_name
    };
  }) : [];

  useEffect(() => {
    setOptions(formattedDefaultData);
  }, [defaultData]);

  const onInputChange = async (input) => {
    if (input === '') {
      setOptions(formattedDefaultData || []);
    } else if (input.length >= 2) {
      setLoading(true);
      const people = await queryPeople(input, idToken);
      const formattedNames = people.map(p => {
        const person_name = p.person_name.replace(',', ', ');
        return {
          ...p,
          person_name,
        };
      });
      setLoading(false);
      setOptions(formattedNames);
      trackEvent({
        category: SEARCH,
        action: 'People',
        label: input,
      });
    }
  }

  return (
    <AutocompleteMenu
      open={open}
      loading={loading}
      selectHandler={selectHandler}
      inputLabel='People'
      optionDisplay={optionDisplay}
      options={options}
      onInputChange={onInputChange}
    />
  );
}

export default function PeopleMenu({ visible, selectHandler, onCloseHandler, offset }) {
  return (
    <MenuDialog
      offset={offset}
      visible={visible}
      selectHandler={selectHandler}
      onCloseHandler={onCloseHandler}
      menuType='person'
    >
      <PeopleAutocomplete />
    </MenuDialog>
  );
}