import { createAction, handleActions } from 'redux-actions';
import { getPhotosByBuilding, getPhotosByRoom, getRoomData } from './api';
import { xlsx } from '../Shared/downloader';
import { actionDataSelectFeatureRequested } from '../Data';

export const initialState = {
  isVisible: false, // photo pane visibility
  targetRoom: null, // room obj from room click/info card photo click
  targetPhoto: [], // actual link to photos
  photosByRoom: {}, // { rrn: [photo_links]}
  photosByBuilding: {}, // { brn: {rrn: [photo_links]} }
  isLoading: false, // loading when querying photo by rrn
  targetBuilding: null, // target room building, needed to make sure when user clicks a different building
                        // highligh room with photo checkbox would get reset
  targetBuildingPhotos: null, // list of rooms with photo
  highlightRoomsWithPhoto: false, // highlight rooms with photo within target room building
};

export const actionPhotoPaneToggled = createAction('PHOTO_PANE_TOGGLED');
export const actionPhotoTargetRoomUpdated = createAction('PHOTO_TARGET_ROOM_UPDATED');
export const actionPhotoTargetPhotoUpdated = createAction('PHOTO_TARGET_PHOTO_UPDATED');
export const actionPhotoTargetBuildingUpdated = createAction('PHOTO_TARGET_BUILDING_UPDATED');
export const actionPhotoTargetBuildingPhotosUpdated = createAction('PHOTO_TARGET_BUILDING_PHOTOS_UPDATED');
export const actionPhotoLoadingUpdated = createAction('PHOTO_LOADING_UPDATED');
export const actionPhotoByRoomUpdated = createAction('PHOTO_BY_ROOM_UPDATED');
export const actionPhotoByBuildingUpdated = createAction('PHOTO_BY_BUILDING_UPDATED');
export const actionPhotoHighlightRoomsWithPhotoUpdated = createAction('PHOTO_HIGHLIGHT_ROOMS_WITH_PHOTO_UPDATED');

export const actionPhotoGetTargetRoom = (roomData) => {
  return async (dispatch, getState) => {
    const { isVisible, photosByRoom, targetBuilding } = getState().photoReducer;
    const { idToken } = getState().authReducer;

    const targetRoom = roomData && roomData.length ? roomData[0] : roomData || {};
    const { rmrecnbr, bldrecnbr } = targetRoom || {};
    dispatch(actionPhotoTargetRoomUpdated(targetRoom));

    // reset mark room checkbox when user clicks a different building
    if (targetBuilding !== null && targetBuilding !== bldrecnbr) {
      dispatch(actionPhotoTargetBuildingUpdated(null));
      dispatch(actionPhotoHighlightRoomsWithPhotoUpdated(false));
      dispatch(actionPhotoQueryRoomsWithPhoto(false));
    }

    // photo pane not visible
    if (!isVisible) { return }

    //todo: refresh page and redirect
    if (!idToken) { return }

    // room hasn't been queried
    if (!photosByRoom[rmrecnbr]) {
      dispatch(actionPhotoLoadingUpdated(true));
      const photos = await getPhotosByRoom(rmrecnbr, bldrecnbr, idToken);
      if (photos instanceof Error) {
        photosByRoom[rmrecnbr] = {};
        alert(`Error querying photo for room ${rmrecnbr}`);
      } else {
        photosByRoom[rmrecnbr] = photos;
      }
      dispatch(actionPhotoByRoomUpdated(photosByRoom));
      dispatch(actionPhotoLoadingUpdated(false));
    }
      
    dispatch(actionPhotoTargetPhotoUpdated(photosByRoom[rmrecnbr] || null));
    return;
  }
}

export const actionPhotoQueryRoomsWithPhoto = checked => {
  return async (dispatch, getState) => {
    
    if (!checked) {
      // remove highlighted rooms
      dispatch(actionPhotoMarkRooms([]));
      actionPhotoTargetBuildingPhotosUpdated(null);
      return;
    }

    const { targetRoom, photosByBuilding } = getState().photoReducer;
    const { bldrecnbr } = targetRoom || {};
    const { idToken } = getState().authReducer;
    let rooms = photosByBuilding[bldrecnbr];

    if (!photosByBuilding[bldrecnbr]) {
      dispatch(actionPhotoTargetBuildingPhotosUpdated('fetching'));
      const response = await getPhotosByBuilding(bldrecnbr, idToken);
      if (response instanceof Error) {
        photosByBuilding[bldrecnbr] = {};
        rooms = {};
        alert(`Error querying photo for building ${bldrecnbr}`);
      } else {
        photosByBuilding[bldrecnbr] = response;
        rooms = response;
      }
    }

    let rrns = rooms ? Object.keys(rooms) : [];
    dispatch(actionPhotoMarkRooms(rrns)); // highlighted rooms with photo
    dispatch(actionPhotoByBuildingUpdated(photosByBuilding));
    dispatch(actionPhotoTargetBuildingUpdated(bldrecnbr));
    dispatch(actionPhotoTargetBuildingPhotosUpdated(rrns));
    return;
  }
}

export const actionPhotoDownloadRoomsWithPhotos = (rrns, buildingRecordNumber) => {
  return async (_, getState) => {
    const { idToken } = getState().authReducer;
    const parsed = await getRoomData(buildingRecordNumber, idToken);

    if (parsed instanceof Error) {
      alert('Download Failed');
      return;
    } 

    const filename = `rooms-with-photos-building-${buildingRecordNumber}`;
    let targetRrns = new Set(rrns);
    let rrnIndex =  parsed.data[0].findIndex(attr => attr === 'rmrecnbr');
    let filteredData = parsed.data.slice(1).filter(row => targetRrns.has(row[rrnIndex]));
    xlsx({ name: filename, data: [parsed.data[0], ...filteredData] });
    return;
  }
}

export const actionPhotoMarkRooms = rrns => {
  return dispatch => {
    dispatch(actionDataSelectFeatureRequested({
      type: 'room',
      data: rrns,
      layerId: ['umich-room-select-1', 'umich-room-select-symbol-1']
    }));
  }
}

export const photoReducer = handleActions({
  [actionPhotoPaneToggled]: (state, action) => {
    return { ...state, isVisible: action.payload };
  },
  [actionPhotoTargetRoomUpdated]: (state, action) => {
    return { ...state, targetRoom: action.payload };
  },
  [actionPhotoTargetBuildingUpdated]: (state, action) => {
    return { ...state, targetBuilding: action.payload };
  },
  [actionPhotoTargetPhotoUpdated]: (state, action) => {
    return { ...state, targetPhoto: action.payload };
  },
  [actionPhotoTargetBuildingPhotosUpdated]: (state, action) => {
    return { ...state, targetBuildingPhotos: action.payload };
  },
  [actionPhotoLoadingUpdated]: (state, action) => {
    return { ...state, isLoading: action.payload };
  },
  [actionPhotoByBuildingUpdated]: (state, action) => {
    return { ...state, photosByBuilding: action.payload };
  },
  [actionPhotoByRoomUpdated]: (state, action) => {
    return { ...state, photosByRoom: action.payload };
  },
  [actionPhotoHighlightRoomsWithPhotoUpdated]: (state, action) => {
    return { ...state, highlightRoomsWithPhoto: action.payload };
  }
}, initialState);