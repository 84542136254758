import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import MenuDialog from '../Shared/MenuDialog';
import AutocompleteMenu from '../Shared/AutocompleteMenu';
import { SEARCH, trackEvent } from '../../tracking';

function BuildingAutocomplete({ open, selectHandler }) {
  const optionDisplay = {
    selected: ['bld_descrshort', 'bldrecnbr', 'bld_descr'],
    label: 'bld_descrshort',
    labelId: 'bldrecnbr',
    labelContext: 'bld_descr',
  };
  const defaultData = useSelector(state=>state.searchReducer.defaultData.buildings);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    // console.log('_______')
    // console.log('debug in buildingautocomplete, defaultData:', defaultData)
    if (defaultData !== undefined && defaultData !== null && defaultData) {
      setOptions(defaultData);
    }
    // setOptions(null);
  }, [defaultData]);

  const onInputChange = input => {
    trackEvent({
      category: SEARCH,
      action: 'Building',
      label: input
    });
  }

  return (
    <AutocompleteMenu
      inputLabel="Building"
      open={open}
      selectHandler={selectHandler}
      options={options}
      optionDisplay={optionDisplay}
      frontEndFilter={true}
      onInputChange={onInputChange}
    />
  );
}

export default function BuildingMenu({ visible, selectHandler, onCloseHandler, offset }) {
  return (
    <MenuDialog
      offset={offset}
      visible={visible}
      selectHandler={selectHandler}
      onCloseHandler={onCloseHandler}
      menuType='building'
    >
      <BuildingAutocomplete />
    </MenuDialog>
  );
}