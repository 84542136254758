import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
  card: {
    // width: 350,
    width: 446,
    marginBottom: 2,
    border: '1px solid rgba(0, 0, 0, 0.12)',
    zIndex: 999,
    opacity: 0.975,
    '&:hover .MuiIconButton-root': { // show close btn
      visibility: 'visible',
    },
    [theme.breakpoints.down('xs')]: {
      width: 330,
      marginBottom: 0,
      opacity: 0.95,
      '& .MuiIconButton-root': {
        visibility: 'visible',
      },
    },
  },
  closeBtn: {
    visibility: 'hidden',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  cardAction: {
    padding: 0,
    '&:hover': {
      cursor: 'pointer',
    },
    [theme.breakpoints.down('xs')]: {
      '& > .MuiIconButton-root': {
        padding: 8,
      }
    },
    transition: theme.transitions.create('backgroundColor', {
      duration: theme.transitions.duration.short,
      delay: 0
    }),
  },
  cardActionFlash: {
    backgroundColor: 'rgba(121, 134, 203, 0.5)',
  },
  subtitle: {
    marginLeft: 'auto',
    letterSpacing: '0.01em',
    maxWidth: 170,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  cardContent: {
    padding: '0px 8px',
    // maxHeight: 300,
    maxHeight: 600,
    overflow: 'auto',
    '&:last-child': {
      paddingBottom: 8,
    },
    [theme.breakpoints.down('xs')]: {
      padding: '2px !important',
    },
  }
}));

export default function Accordion(props) {
  const {
    children,
    title,
    subtitle,
    visible,
    expand,
    visibleHandler,
    expandHandler,
    getContainerRef,
    action = false,
  } = props;
  const classes = useStyles();
  const containerRef = useRef(null);

  const [isVisible, setIsVisible] = useState(visible);
  useEffect(() => {
    setIsVisible(visible);
  }, [visible]);

  const [isExpanded, setIsExpanded] = useState(expand);
  useEffect(() => {
    setIsExpanded(expand);
  }, [expand]);

  const [isActive, setIsActive] = useState(action);
  useEffect(() => {
    setIsActive(action)
  }, [action])

  const handleCardClick = (e) => {
    if (e.target.classList.contains('MuiCardActions-root') ||
        e.target.classList.contains('MuiTypography-root')) {
      setIsExpanded(!isExpanded);
      if (expandHandler) {
        expandHandler(!isExpanded);
      }
    }
  }

  const handleExpandClick = () => {
    setIsExpanded(!isExpanded);
    if (expandHandler) {
      expandHandler(!isExpanded);
    }
  }

  const handleCloseClick = () => {
    setIsVisible(!isVisible);
    if (visibleHandler) {
      visibleHandler(!isVisible);
    }
  }

  useEffect(() => {
    if (getContainerRef) {
      getContainerRef(containerRef);
    }
  }, [containerRef, getContainerRef]);
  
  return (
    <Card className={classes.card} style={isVisible? {} : { display: 'none'}}>
      <CardActions
        disableSpacing
        className={classes.cardAction} 
        className={clsx(classes.cardAction, {
            [classes.cardActionFlash]: isActive,
          })}
        onClick={handleCardClick}
      >
        <IconButton 
          className={classes.closeBtn}
          onClick={handleCloseClick}
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="button" data-testid="accordion-title">
          {title || ''}
        </Typography>
        {
          subtitle && 
          <Typography variant="overline" component="div" className={classes.subtitle} data-testid="accordion-subtitle">
            {subtitle}
          </Typography> 
        }
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: isExpanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={isExpanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </IconButton>
      </CardActions>
      <Collapse in={isExpanded} timeout="auto">
        <CardContent className={classes.cardContent} ref={containerRef}>
          {React.cloneElement(children, {})}
        </CardContent>
      </Collapse>
    </Card>
  );
}