import { combineReducers } from 'redux'
import { authReducer } from '../components/Auth';
import { appReducer } from '../components/App';
import { miscReducer } from '../components/App';
import { mapReducer } from '../components/Map';
import { appBarReducer } from '../components/AppBar';
import { layerReducer } from '../components/Layer';
import { customLayerReducer } from '../components/CustomLayer';
import { infoReducer } from '../components/Info';
import { dataCardReducer } from '../components/Data';
import { drawReducer } from '../components/Draw';
import { legendReducer } from '../components/Legend';
import { searchReducer } from '../components/Search'; 
import { spreadsheetReducer } from '../components/Spreadsheet';
import { printReducer } from '../components/Print';
import { photoReducer } from '../components/Photo';
import { alertReducer } from '../components/Alert';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

const appPersistConfig = {
  key: 'appReducer',
  storage: storage,
  stateReconciler: autoMergeLevel2,
  whitelist: ['layersVisible', 'printVisible', 'drawVisible', 'spreadsheetVisible']
};

const infoPersistConfig = {
  key: 'infoReducer',
  storage: storage,
  stateReconciler: autoMergeLevel2,
  whitelist: ['buildingActionBtnExpanded', 'roomActionBtnExpanded', 'fileDownloadFormat']
};

const layerPersistConfig = {
  key: 'layerReducer',
  storage: storage,
  stateReconciler: autoMergeLevel2,
  whitelist: ['tabIndex', 'labelSettings', 'label']
};

const rootReducer = combineReducers({
  authReducer,
  appReducer: persistReducer(appPersistConfig, appReducer),
  mapReducer,
  appBarReducer,
  dataCardReducer,
  infoReducer: persistReducer(infoPersistConfig, infoReducer),
  layerReducer: persistReducer(layerPersistConfig, layerReducer),
  customLayerReducer,
  drawReducer,
  legendReducer,
  searchReducer,
  spreadsheetReducer,
  printReducer,
  photoReducer,
  alertReducer,
  miscReducer,
});

export default rootReducer;