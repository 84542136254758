import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import GetAppIcon from '@material-ui/icons/GetApp';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from '@material-ui/core/Dialog';
import { SwatchesPicker } from "react-color";
import Feature from './Feature';
import TotalPolygonArea from './TotalPolygonArea';
import { floorSorter } from '../AppBar/Floor';
import { actionAppBarFloorUpdated } from '../AppBar';
import _ from 'lodash';
import {
  actionMapDrawModeUpdated,
  actionMapZoomFeaturesUpdated,
} from '../Map';
import { actionDrawUpdateGroupFeature } from './drawRedux';
import { xlsx } from '../Shared/downloader';
import { trackEvent, DRAW } from '../../tracking';

const useStyles = makeStyles((theme) => ({
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  header: {
    padding: 0,
    '& .MuiIconButton-root': {
      color: 'rgba(0, 0, 0, 0.54)',
      padding: 8,
      fontSize: '1.125rem',
    }
  },
  colorPicker: {
    right: 'unset !important',
    '& .MuiBackdrop-root': {
      backgroundColor: 'transparent',
    }
  }
}));

export function FeatureGroups({ children }) {
  const zeroPadding = {
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
  };

  return (
    <CardContent style={zeroPadding}>
      {children}
    </CardContent>
  )
}

export default function FeatureGroup({ featureGroup={} }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const display = { display: _.isEmpty(featureGroup) ? 'none' : 'block' };

  const [layerVisible, setLayerVisible] = useState(true);
  const [cardExpanded, setCardExpanded] = useState(true);
  
  const handleExpandClick = () => { setCardExpanded(!cardExpanded) }
  const handleDownloadClick = () => {
    trackEvent({
      category: DRAW,
      action: 'Download',
    });
    xlsx({features: featureGroup.features, directPropPath: false});
  }
  const onVisibleClick = () => {
    setLayerVisible(!layerVisible);
    const visible = !layerVisible;
    if (visible) {
      dispatch(actionMapDrawModeUpdated({ mode: 'show_feature_group', data: featureGroup}))
    } else {
      dispatch(actionMapDrawModeUpdated({ mode: 'hide_feature_group', data: featureGroup}))
    }
  }

  const [colorPickerVisible, setColorPickerVisible] = useState(false);
  const [targetFeature, setTargetFeature] = useState();
  const onColorPickerClick = (feature) => { 
    setColorPickerVisible(true);
    setTargetFeature(feature);
  }
  const handleClose = () => { setColorPickerVisible(false) }
  const handleChange = color => {
    trackEvent({
      category: DRAW,
      action: 'Change Drawn Color',
      label: color.hex
    });
    const properties = {...targetFeature.properties, color: color.hex };
    const updatedFeature = {...targetFeature, properties };
    dispatch(actionDrawUpdateGroupFeature('update', [updatedFeature]));
  };

  const [buildings, nonBuilding] = sortByBuilding(featureGroup.features);

  return (
    <Card variant="outlined" style={display}>
      <CardActions disableSpacing className={classes.header}>
        <IconButton onClick={onVisibleClick}>
          {layerVisible ? <CheckBoxIcon fontSize="small" /> : <CheckBoxOutlineBlankIcon fontSize="small" />}
        </IconButton>
        <Typography variant="button">Measurement</Typography>
        <IconButton
          style={{marginLeft: 4, display: cardExpanded ? 'inherit': 'none'}}
          onClick={handleDownloadClick}
        >
            <GetAppIcon fontSize="small" />
          </IconButton>
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: cardExpanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={cardExpanded}
          aria-label="show more"
        >
          <ExpandMoreIcon fontSize="small" />
        </IconButton>
      </CardActions>
      <Collapse in={cardExpanded} timeout="auto" unmountOnExit>
        <Buildings>
          {buildings.map(building => <Building key={building.name} building={building} onColorPickerClick={onColorPickerClick}/>)}
        </Buildings>
        <NonBuilding >
          {nonBuilding.map(feature => <Feature key={feature.id} feature={feature} onColorPickerClick={onColorPickerClick} />)}
        </NonBuilding>
      </Collapse>
      <Dialog onClose={handleClose} open={colorPickerVisible} className={classes.colorPicker}>
        <SwatchesPicker color={targetFeature ? targetFeature.properties.color : '#FFFFFF'} onChangeComplete={handleChange} />
      </Dialog>
    </Card>
  );
}

function Buildings({ children }) {
  const style = { padding: 0 }
  return (
    <CardContent style={style}>{children}</CardContent>
  );
}

function NonBuilding({ children }) {
  const style = {
    padding: 0,
    borderTop: '1px solid rgba(224, 222, 220, 0.4)',
  }

  return (
    <CardContent style={style}>{children}</CardContent>
  );
}

const useBuildingStyles = makeStyles((theme) => ({
  header: {
    padding: 0,
    borderTop: '1px solid rgba(224, 222, 220, 0.4)',
    color: 'rgba(0, 0, 0, 0.54)',
    '& .MuiButton-root': {
      fontSize: '0.7rem',
    },
  },
  building: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  content: {
    padding: 0,
    '&.MuiCardContent-root:last-child': {
      paddingBottom: 8,
    }
  },
}));

function Building({ building={}, onColorPickerClick }) {
  let { name, features } = {name: '', features: [], ...building};
  const floors = toFloors(features);

  const classes = useBuildingStyles();
  const dispatch = useDispatch();

  const [expandBuilding, setExpandBuilding] = useState(true);
  const [expandIconVisible, setExpandIconVisible] = useState(false);
  const onExpandClick = () => { setExpandBuilding(!expandBuilding) }
  const onMouseEnter = () => { setExpandIconVisible(true) }
  const onMouseLeave = () => { setExpandIconVisible(false) }
  const onBuildingClick = (features) => { dispatch(actionMapZoomFeaturesUpdated({ data: features })) }
  const onFloorClick = (floor) => {
    dispatch(actionMapZoomFeaturesUpdated({ data: filterByFloor(features, floor) }));
    dispatch(actionAppBarFloorUpdated(floor === 'BLDG' ? '' : floor));
  }

  return (
    <React.Fragment>
      <CardActions disableSpacing className={classes.header} >
        <div className={classes.building} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
          <IconButton
            size="small"
            onClick={onExpandClick}
            style={{visibility: expandIconVisible ? 'visible' : 'hidden' }}
          >
            {expandBuilding ? <ExpandLessIcon fontSize="small" /> : <ExpandMoreIcon fontSize="small" />} 
          </IconButton>
          <BuildingName name={name} features={features} onBuildingClick={onBuildingClick} />
          {floors.map(floor => <Floor key={floor} floor={floor} onFloorClick={onFloorClick} />)}
        </div>
      </CardActions>
      <Collapse in={expandBuilding} timeout="auto" unmountOnExit>
        <CardContent className={classes.content}>
          {features.map(feature => <Feature key={feature.id} feature={feature} onColorPickerClick={onColorPickerClick} />)}
          {features.length > 1 && <TotalPolygonArea features={features} />}
        </CardContent>
      </Collapse>
    </React.Fragment>
  );
}

function BuildingName({ name, features, onBuildingClick }) {
  const onClick = () => { onBuildingClick(features) }
  return (
    <Tooltip title="Building">
      <Button size="small" onClick={onClick} color="primary">
        {name}
      </Button>
    </Tooltip>
  )
}

function Floor({ floor, onFloorClick }) {
  const style = { minWidth: 0 };
  const onClick = () => { onFloorClick(floor) }
  const title = floor === 'BLDG' ? 'Building View' : `Floor ${floor}`;
  return (
    <Tooltip title={title}>
      <Button size="small" onClick={onClick} color="primary" style={style}>
        {floor}
      </Button>
    </Tooltip>
  )
}

// utils
function sortByBuilding(features=[]) {
  if (!features.length) { return [[], []]; }

  const byBuilding = _.groupBy(features, feature => feature.properties.bldname);
  const nonBuilding = byBuilding['undefined']; 
  const sortedBuildings = Object.keys(byBuilding).filter(building => building !== 'undefined').sort();
  const buildings =  sortedBuildings.map(building => ({name: building, features: byBuilding[building]}));

  return [buildings || [], nonBuilding || []];
}

function toFloors(features=[]) {
  const floors = features
                  .filter(feature => feature.properties.floor)
                  .map(feature => feature.properties.floor.trim());
  const uniqFloors = [...new Set(floors)];
  uniqFloors.sort(floorSorter);
  return uniqFloors.reverse();
}

function filterByFloor(features=[], floor) {
  return features.filter(feature => {
    return !!feature.properties.floor && feature.properties.floor === floor;
  });
}