import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Container from '@material-ui/core/Container';
import { actionSearchVisibleToggled } from './SearchRedux';
import IconButton from '@material-ui/core/IconButton';
// import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchInput from './Input';
import Filter from './Filters';
import Table from './Table';
import { initialAPIPing, actionSearchPopulateDefaultData } from './SearchRedux';
import UrlSearch from './UrlSearch';

const useStyles = makeStyles(theme => ({
  drawer: {
    width: '45vw',
    minWidth: 335,
    maxWidth: 600,
    height: '100vh',
    [theme.breakpoints.down('md')]: {
      width: '80vw',
    },
    [theme.breakpoints.down('sm')]: {
      width: '90vw',
    },
    [theme.breakpoints.down('xs')]: {
      width: '95vw',
    }
  },
  container: {
    height: '100vh',
    padding: '5px 20px',
    [theme.breakpoints.down('xs')]: {
      padding: '0 5px 5px 5px',
    }
  },
  closeBtn: {
    position: 'absolute',
    right: 0,
    top: 0,
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  hideBtn: {
    display: 'none',
    padding: 5,
    width: '100%',
    borderRadius: 0,
    backgroundColor: 'rgba(211, 211, 211, 0.5)',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  }
}));

export default function Search() {
  const classes = useStyles();
  const isVisible = useSelector(state => state.searchReducer.isVisible);
  const idToken = useSelector(state => state.authReducer.idToken);
  const dispatch = useDispatch();

  useEffect(() => {
    !!idToken && initialAPIPing(idToken);
  }, [idToken]);

  useEffect(() => {
    !!idToken && dispatch(actionSearchPopulateDefaultData());
  }, [dispatch, idToken]);

  const toggleDrawer = event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    dispatch(actionSearchVisibleToggled());
  };

  return (
    <Drawer 
      anchor="bottom"
      // variant="persistent"
      open={isVisible}
      onClose={toggleDrawer}
      // onOpen={toggleDrawer}
      className={classes.drawer}
      classes={{paperAnchorBottom: classes.drawer}}
      ModalProps={{keepMounted: true}}
    >
      <Container className={classes.container}>
        {/* <IconButton className={classes.closeBtn} onClick={toggleDrawer}>
          <CloseIcon />
        </IconButton> */}
        <IconButton className={classes.hideBtn} onClick={toggleDrawer}>
          <ExpandMoreIcon />
        </IconButton>
        <SearchInput />
        <Filter />
        <Table />
        <UrlSearch />
      </Container>
    </Drawer>
  );

}