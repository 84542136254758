import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import MenuDialog from '../Shared/MenuDialog';
import AutocompleteMenu from '../Shared/AutocompleteMenu';
import { SEARCH, trackEvent } from '../../tracking';

const SUBTYPE_OFFSET = 15;

function RoomtypeAutocomplete({ open, selectHandler }) {
  const optionDisplay = {
    // label, labelId, and labelContext different for roomtypes and subtypes
    selected: ['label', 'labelId', 'labelContext'],
    label: option => ({
      text: option['label'],
      style: {marginLeft: option['isSubtype'] ? SUBTYPE_OFFSET : 0},
    }),
    labelId: 'labelId',
    labelContext: option => ({
      text: option['labelContext'],
      style: {
        marginLeft: option['isSubtype'] ? SUBTYPE_OFFSET : 0,
        fontSize: '0.65rem',
      },
    }),
  };
  const defaultData = useSelector(state=>state.searchReducer.defaultData.roomtypes);
  const [options, setOptions] = useState([]);
  useEffect(() => {
    setOptions(defaultData);
  }, [defaultData]);

  const onInputChange = input => {
    trackEvent({
      category: SEARCH,
      action: 'RoomType',
      label: input
    });
  }

  return (
    <AutocompleteMenu
      inputLabel='Room Type'
      open={open}
      selectHandler={selectHandler}
      optionDisplay={optionDisplay}
      options={options}
      frontEndFilter={true}
      onInputChange={onInputChange}
    />
  );
}

export default function RoomtypeMenu({ visible, selectHandler, onCloseHandler, offset }) {
  return (
    <MenuDialog
      offset={offset}
      visible={visible}
      selectHandler={selectHandler}
      onCloseHandler={onCloseHandler}
      menuType='roomtype'
    >
      <RoomtypeAutocomplete />
    </MenuDialog>
  );
}
