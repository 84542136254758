import React, { useEffect, useState } from 'react';
import { useDispatch } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import GetAppIcon from '@material-ui/icons/GetApp';
import Grid from '@material-ui/core/Grid';
// import AddIcon from '@material-ui/icons/Add';
import { actionDataSelectFeatureRequested, actionDataGroupUpdated, roomsToRrns } from '../../Data';
import BuildingFloorsDisplay from './DisplayBuildingFloors';
import BuildingDisplay from './DisplayBuilding' ;
import RoomDisplay from './DisplayRoom';
// import TableTools from './TableTools';
import { actionAppBarFloorsInSearchUpdated } from '../../AppBar';
import { Tooltip } from '@material-ui/core';
// import { actionSearchVisibleToggled } from '../../Search';
import clsx from 'clsx';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Filters from './Filters';
import RestroomFilter from '../RestroomFilter';
import { xlsx } from '../../Shared/downloader';

const useDataCardStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexFlow: 'column-reverse',
  },
  addSearch: {
    textAlign: 'center',
    '& .MuiSvgIcon-root': {
      fontSize: '1.25rem'
    }
  },
  overlap: {
    display: 'inline-block',
    padding: 2,
    marginLeft: 4,
    border: '2px solid red',
    lineHeight: '1.25',
    fontSize: '0.6rem',
    letterSpacing: '0.01em',
    borderRadius: 4,
  },
  card: {
    width: '100%',
    pointerEvents: 'auto',
    marginBottom: 4,
    '& > .MuiCardActions-root': {
      paddingRight: 0,
    },
  },
  expand: {
    padding: 0,
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    padding: 0,
    transform: 'rotate(180deg)',
  },
  verticalDots: {
    padding: 0,
    borderRadius: 0,
    width: 20,
    color: 'rgba(0, 0, 0, 0.25)',
    '&:hover': {
      color: 'rgba(0, 0, 0, 0.54)',
    }
  },
  cardActionRoot: {
    padding: 2,
    borderTop: '1px solid rgba(0,0,0,0.1)',
  },
  checkbox: {
    marginRight: 6,
  },
  lastGrid: {
    textAlign: 'end',
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

export default function DataCard({ data, headerType }) {
    const classes = useDataCardStyles();
    const [expanded, setExpanded] = useState(true);
    const handleExpandClick = () => { setExpanded(!expanded) }
    const [toolsExpanded, setToolsExpanded] = useState(false);
    const handleToolToggle = () => { setToolsExpanded(!toolsExpanded) }

    const [filters, setFilters] = useState([]);

    const addFilter = (attribute, value) => {
        const newFilters = [...filters, {attribute, value}];
        setFilters(newFilters);
    }

    const removeFilter = (attribute, value) => {
        const newFilters = filters.filter(filter => filter.attribute !== attribute && filter.value !== value);
        setFilters(newFilters);
    }

    const filterRooms = (data=[], filters=[]) => {
        return data.filter(item => {
            // [{'attribute': 'urinal', 'value': '5'}]
            return filters.every(filter => item[filter.attribute] === filter.value);
        });
    }

    const downloadData = () => {
      xlsx({name: 'Restroom Data', features: filteredData.rooms, raw:true});
    }

    const [filteredData, setFilteredData] = useState(data);

    useEffect(() => {
        const rooms = filterRooms(data.rooms, filters);
        setFilteredData({...filteredData, rooms, filters});
    }, [filters, data.rooms])


    let header;
    if (headerType === 'filter') {
        header = <DataCardHeader
            data={filteredData}
            expanded={expanded}
            handleExpandClick={handleExpandClick}
            handleToolToggle={handleToolToggle}
            checkboxVisible={false}
            expandVisible={false}
            optionsVisible={false}
            filterType="filterdown"
            addFilter={addFilter}
            removeFilter={removeFilter}
            downloadData={downloadData}
        />
    } else {
        header = <DataCardHeader
            data={filteredData}
            expanded={expanded}
            handleExpandClick={handleExpandClick}
            handleToolToggle={handleToolToggle}
        />
    }

    return (
        <Card className={classes.card} variant="outlined">
            {header}
            <DataCardBody
                data={filteredData}
                expanded={expanded}
                toolsExpanded={toolsExpanded}
            />
        </Card>
    );
}


function DataCardHeader(props) {
    const {
      data,
      expanded, handleExpandClick,
      handleToolToggle,
      checkboxVisible=true,
      expandVisible=true,
      optionsVisible=true,
      filterType='default',
      addFilter,
      removeFilter,
      downloadData,
    } = props;
  
    const { id, filters, loading, layerVisible, rooms, color } = data;
  
    const classes = useDataCardStyles();
    const dispatch = useDispatch();
    const onClick = () => { handleExpandClick() }
    const toolsOnClick = () => { handleToolToggle() }
  
    const checkboxClick = () => {
      if (layerVisible) {
        dispatch(actionDataGroupUpdated({ id, layerVisible: false }));
        dispatch(actionDataSelectFeatureRequested({ type: 'room', data: [], layerId: data.colorLayerId }));
      } else {
        dispatch(actionDataGroupUpdated({ id, layerVisible: true }));
        dispatch(actionDataSelectFeatureRequested({ type: 'room', data: roomsToRrns(rooms), layerId: data.colorLayerId }));
      }
    }
  
    let loadingIcon = <IconButton size="small"><CircularProgress size={24}/></IconButton>
    let checkboxIcon = checkboxVisible ? (
                          <Tooltip title="Toggle Highlight">
                            <IconButton size="small" onClick={checkboxClick} className={classes.checkbox} data-testid="search-result-highlight-btn">
                              {layerVisible ?  <CheckBoxIcon data-testid="search-result-highlight-checked"/> : <CheckBoxOutlineBlankIcon data-testid="search-result-highlight-not-checked"/>}
                            </IconButton>
                          </Tooltip>
                        ) : null;
  
    let filterComponent = null;
  
    if (filterType === 'default') {
      filterComponent = <Filters filters={filters} id={id}/>
    } else if (filterType === 'filterdown') {
      filterComponent = <RestroomFilter filters={filters} rooms={rooms} id={id} addFilter={addFilter} removeFilter={removeFilter}/>
    }
  
    return (
      <React.Fragment>
        <CardActions disableSpacing>
          
          {/* checkbox */}
          <Grid container >
              {
                  checkboxVisible && (
                      <Grid item xs={1} className={classes.center}>
                          { loading ? loadingIcon : checkboxIcon }
                      </Grid>
                  )
              }
  
            {/* filters */}
            <Grid item xs={checkboxVisible ? 9 : 10}>
              {filterComponent}
              <Tooltip title="Download Data">
                <IconButton
                    onClick={downloadData}
                    aria-label="Download Data"
                    size="small"
                >
                    <GetAppIcon />
                </IconButton>
              </Tooltip>
            </Grid>
  
            {/* expand/collapse, more option */}
            <Grid item xs={2} className={clsx(classes.lastGrid, classes.center)}>


              {
                  expandVisible && (
                      <Tooltip title="Expand/Collapse">
                          <IconButton
                              className={clsx(classes.expand, {
                              [classes.expandOpen]: expanded,
                              })}
                              onClick={onClick}
                              aria-expanded={expanded}
                              aria-label="show more"
                              size="small"
                              style={{
                              border: `2px solid ${color}`,
                              backgroundColor: `${color}`,
                              }}
                          >
                              <ExpandMoreIcon/>
                          </IconButton>
                      </Tooltip>
  
                  )
              }
              {
                  optionsVisible && (
                      <Tooltip title="Options">
                          <IconButton
                              className={classes.verticalDots}
                              style={{ visibility: expanded ? 'visible' : 'hidden' }}
                              onClick={toolsOnClick}
                              aria-label="show more tools"
                              size="small"
                          >
                              <MoreVertIcon />
                          </IconButton>
                      </Tooltip>
                  )
              }
            </Grid>
          </Grid>
        </CardActions>
      </React.Fragment>
    );
  }


function DataCardBody({ data, expanded, toolsExpanded }) {
    const { id, rooms, loading, groupBy, sortBy, sortDirection } = data;

    const dispatch = useDispatch();
    dispatch(actionDataSelectFeatureRequested({ type: 'room', data: roomsToRrns(rooms), layerId: data.colorLayerId }));

    let dataTable;
    if (groupBy === 'building-floor') {
        dataTable = <BuildingFloorsDisplay id={id} rooms={rooms} sortBy={sortBy} sortDirection={sortDirection}/>;
    } else if (groupBy === 'building-floor-sqft') {
        dataTable = <BuildingDisplay id={id} rooms={rooms} sortBy={sortBy} sortDirection={sortDirection}/>;
    } else if (groupBy === 'room') {
        dataTable = <RoomDisplay id={id} rooms={rooms} sortBy={sortBy} sortDirection={sortDirection}/>;
    } else {
        dataTable = <BuildingFloorsDisplay id={id} rooms={rooms} sortBy={sortBy} sortDirection={sortDirection}/>;
    }

  return (
    <React.Fragment>
      <Collapse in={expanded} timeout="auto">
        <Collapse in={toolsExpanded} timeout="auto">
          {/* <TableTools data={data}/> */}
        </Collapse>
        { !!rooms.length && dataTable }
        { !loading && !rooms.length && <NoResult data={data}/> }
      </Collapse>
    </React.Fragment>
  );
}

function NoResult({ data }) {
    const classes = useDataCardStyles();
    const dispatch = useDispatch()
  
    dispatch(actionAppBarFloorsInSearchUpdated([]));
    dispatch(actionDataSelectFeatureRequested({ type: 'room', data: [], layerId: data.colorLayerId  }));
  
    return (
      <CardActions disableSpacing classes={{root: classes.cardActionRoot}}>
        <Grid container align="center" justifyContent="center">
          No Result
        </Grid>
      </CardActions>
    );
  }
