import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { selectorAppIsSmallScreen, selectorAppIsTouchScreen } from '../App';
import ZoomBox from './ZoomBox';
import ZoomButton from './ZoomButton';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 4,
  },
}));

export default function Zoom() {
  const classes = useStyles();
  const isSmallScreen = useSelector(selectorAppIsSmallScreen);
  const isTouchScreen = useSelector(selectorAppIsTouchScreen);
  const isVisible = !isSmallScreen || !isTouchScreen;

  return (
    <React.Fragment>
      { 
        isVisible &&
        <div className={classes.root}>
          <ZoomBox />
          <ZoomButton factor={0.5} />
        </div>
      }
    </React.Fragment>
  );
}