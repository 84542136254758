import React, { useState, useEffect } from 'react';
import { useDispatch } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import CardActions from '@material-ui/core/CardActions';
import _ from 'lodash';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { actionMapZoomFeaturesUpdated } from '../../Map';
import { actionAppBarFloorUpdated } from '../../AppBar';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  root: {
    maxHeight: 160,
    padding: '8px 0',
    borderTop: '1px solid rgba(0,0,0,0.1)',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      webkitAppearance: 'none',
      width: 7,
      backgroundColor: 'rgba(0, 0, 0, 0.1)',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 2,
      backgroundColor: 'rgba(0, 0, 0, .5)',
      webkitBoxShadow: '0 0 1px rgba(255, 255, 255, .5)',
    }
  },
  gridContent: {
    alignSelf: 'center',
    lineHeight: '25px',
    height: 25,
    '& .MuiTypography-overline': {
      lineHeight: '25px',
      height: 25,
      fontSize: '0.65rem',
    }
  },
  accordionBtn: {
    padding: '2px 3px',
    minWidth: 0,
  },
  compact: {
    padding: 8,
  },
  cardActionRoot: {
    padding: 0,
  },
  row: {
    lineHeight: 1,
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
      color: 'black',
    }
  },
  rowActive: {
    backgroundColor: '#4a53b1',
    color: 'white',
    '& .MuiTypography-overline': {
      fontWeight: 'bold',
    }
  }
}));

export default function RoomDisplay({ id, rooms, sortBy, sortDirection }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const defaultRows = _.sortBy(rooms, ['bld_descrshort', 'floor', 'rmnbr']);
  const rows = sortRows(defaultRows, sortBy, sortDirection);
  const [firstRow] = rows;

  const zoom = (floor, rmrecnbr) => {
    dispatch(actionMapZoomFeaturesUpdated({ type: 'rrn', data: [rmrecnbr] }));
    dispatch(actionAppBarFloorUpdated(floor));
  }

  const [selectedIndex, setSelectedIndex] = useState(0);
  const onSelect = (index, floor, rmrecnbr) => {
    setSelectedIndex(index);
    zoom(floor, rmrecnbr);
  }

  useEffect(() => {
    firstRow && zoom(firstRow.floor, firstRow.rmrecnbr);
  }, []);

  return (
    <div className={classes.root}>
      {
        rows.map((row, index) => {
          return <Room
                    key={row.rmrecnbr}
                    data={row}
                    index={index}
                    isSelected={index === selectedIndex}
                    onSelect={onSelect}
                  />
        })
      }
    </div>
  );
}

function Room({ data, isSelected, onSelect, index }) {
  const classes = useStyles();
  const onClick = () => { onSelect(index, data.floor, data.rmrecnbr) }

  return (
    <CardActions disableSpacing classes={{root: classes.cardActionRoot}}>
      <Grid
        container
        align="center"
        justifyContent="center"
        className={clsx({[classes.rowActive]: isSelected}, classes.row)}
        onClick={onClick}
      >
        <Grid item xs={4} className={classes.gridContent} >
          <Typography variant="overline">
            {data.bld_descrshort}
          </Typography>
        </Grid>
        <Grid item xs={2}  className={classes.gridContent} >
          <Typography variant="overline">
            {data.floor}
          </Typography>
        </Grid>
        <Grid item xs={3}  className={classes.gridContent} >
          <Typography variant="overline">
            {data.rmnbr}
          </Typography>
        </Grid>
        <Grid item xs={3}  className={classes.gridContent} >
          <Typography variant="overline">
            {`${numberWithCommas(data.rmsqrft)} sqft`}
          </Typography>
        </Grid>
      </Grid>
    </CardActions>
  ); 
}

function sortRows(rows, sortBy, sortDirection) {
  if (sortBy === 'building' && sortDirection === 'ascending') {
    return _.orderBy(rows, ['bld_descrshort', 'floor', 'rmnbr'], ['asc', 'asc', 'asc']);
  } else if (sortBy === 'building' && sortDirection === 'descending') {
    return _.orderBy(rows, ['bld_descrshort', 'floor', 'rmnbr'], ['desc', 'asc', 'asc']);
  } else if (sortBy === 'sqft' && sortDirection === 'ascending') {
    return _.orderBy(rows, ['rmsqrft'], ['asc']);
  } else if (sortBy === 'sqft' && sortDirection === 'descending') {
    return _.orderBy(rows, ['rmsqrft'], ['desc']);
  } else if (sortBy === 'room' && sortDirection === 'ascending') {
    return _.orderBy(rows, ['rmnbr'], ['asc']);
  } else if (sortBy === 'room' && sortDirection === 'descending') {
    return _.orderBy(rows, ['rmnbr'], ['desc']);
  }

  return rows;
}

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
