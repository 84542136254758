import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import LayersIcon from '@material-ui/icons/Layers';
import { blue } from '@material-ui/core/colors';

const layers = [
  {
    layer: 'Restroom',
    description: 'Information on restrooms such as gender designation, number of fixtures, and more.'
  }
];
const useStyles = makeStyles({
    title: {
        textAlign: 'center'
    },
    list: {
        minWidth: 400,
    },
    avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
  note: {
    fontSize: '0.8rem',
    marginBottom: 0,
    maxWidth: 250,
  }
});

export default function SimpleDialog({ onClose, open }) {
  const classes = useStyles();

  const handleListItemClick = (value) => {
    onClose(value);
  };

  return (
    <Dialog onClose={() => handleListItemClick()} open={open} maxWidth={"lg"}>
      <DialogTitle className={classes.title}>Select Layer</DialogTitle>
      <List className={classes.list}>
        {layers.map(({layer, description}) => (
          <ListItem button onClick={() => handleListItemClick(layer)} key={layer}>
            <ListItemAvatar>
              <Avatar className={classes.avatar}>
                <LayersIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={layer} secondary={description}/>
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
}
