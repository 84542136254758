import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '../Shared/Accordion';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { actionAppToggleComponent } from '../App';
import { actionMapPrintUpdated } from '../Map';
import { actionPrintExpandToggled, actionPrintSizeUpdated } from './PrintRedux';
import { trackEvent, PRINT } from '../../tracking';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 10,
  },
  formControl: {
    minWidth: 120,
    marginRight: 6,
    '& .MuiSelect-root.MuiSelect-select': {
      padding: '14.5px 14px',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -12px) scale(0.75)',
    }
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  resetBtn: {
    padding: '11px 6px',
    position: 'absolute',
    right: 0,
    marginRight: 16,
  },
  btnContainer: {
    marginTop: 30,
    display: 'flex',
    justifyContent: 'space-around', 
  }
}));


export default function Print() {
  const component = 'printVisible';
  const classes = useStyles();
  const didMountRef = useRef(false);
  const dispatch = useDispatch();

  const visible = useSelector(state => state.appReducer[component]);
  const visibleHandler = () => {
    dispatch(actionAppToggleComponent({ component }));
  }

  const expand = useSelector(state => state.printReducer.isExpanded)
  const expandHandler = () => {
    dispatch(actionPrintExpandToggled());
  }

  const size = useSelector(state => state.printReducer.printSize);
  const sizeLabel = useRef(null);
  const [sizeLabelWidth, setSizeLabelWidth] = useState(0);

  const [orientation, setOrientation] = useState('landscape');
  const orientationLabel = useRef(null);
  const [orientationLabelWidth, setOrientationLabelWidth] = useState(0);

  useEffect(() => {
    setSizeLabelWidth(sizeLabel.current.offsetWidth);
    setOrientationLabelWidth(orientationLabel.current.offsetWidth);
  }, []);

  const handleSizeChange = (event) => {
    trackEvent({
      category: PRINT,
      action: 'Change Paper Size',
      label: event.target.value,
    });
    dispatch(actionPrintSizeUpdated(event.target.value));
  }

  const handleOrientationChange = (event) => {
    trackEvent({
      category: PRINT,
      action: 'Change Paper Orientation',
      label: event.target.value
    });
    setOrientation(event.target.value);
  }

  const resetHandler = () => {
    trackEvent({
      category: PRINT,
      action: 'Reset Orientation & Size',
    });
    dispatch(actionPrintSizeUpdated(''));
  }

  const printHandler = () => {
    if (!size || !orientation) {
      return;
    }
    trackEvent({
      category: PRINT,
      action: 'Print'
    });
    dispatch(actionMapPrintUpdated({status: 'print', size, orientation}));
  }

  useEffect(() => {
    if (didMountRef.current) {
      const status = size && orientation ? 'preview' : 'cancel';
      dispatch(actionMapPrintUpdated({status, size, orientation}));      
    } else {
      didMountRef.current = true;
    }
  }, [dispatch, size, orientation]);

  return (
    <Accordion
      title="Print"
      visible={visible}
      visibleHandler={visibleHandler}
      expand={expand}
      expandHandler={expandHandler}
    >
      <Grid container className={classes.root} >
        <Grid item xs={12} >
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel ref={sizeLabel} id="print-size" data-testid="print-size-label">
              Size
            </InputLabel>
            <Select
              labelId="print-size"
              value={size}
              onChange={handleSizeChange}
              labelWidth={sizeLabelWidth}
              data-testid="print-size-input"
            >
              <MenuItem value="" data-testid="print-size-input-none">None</MenuItem>
              <MenuItem value='letter' data-testid="print-size-input-letter">Letter</MenuItem>
              <MenuItem value='legal' disabled="true" data-testid="print-size-input-legal">Legal</MenuItem>
              {/* <MenuItem value='tabloid'>Tabloid</MenuItem> */}
            </Select>
          </FormControl>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel ref={orientationLabel} id="print-orientation" data-testid="print-orientation-label">
              Orientation
            </InputLabel>
            <Select
              labelId="print-orientation"
              value={orientation}
              onChange={handleOrientationChange}
              labelWidth={orientationLabelWidth}
            >
              <MenuItem value='landscape'>Landscape</MenuItem>
              <MenuItem value='portrait'>Portrait</MenuItem>
            </Select>
          </FormControl>
          <Button
            variant='outlined'
            color='secondary'
            className={classes.resetBtn}
            onClick={resetHandler}
            disabled={!size}
            data-testid="print-reset"
          >
            Reset
          </Button>
        </Grid>
        <Grid item xs={12} className={classes.btnContainer} >
            <Button
              variant='contained'
              color='primary'
              onClick={printHandler}
              disabled={!size}>
              Print
            </Button>
        </Grid>
      </Grid>
    </Accordion>
  );
}
