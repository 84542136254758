import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ListSubheader from '@material-ui/core/ListSubheader';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { VariableSizeList } from 'react-window';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import debounce from 'lodash/debounce';
import isFunction from 'lodash/isFunction';

const useAutocompleteStyle = makeStyles(theme => ({
  autocomplete: {
    width: 400,
    [theme.breakpoints.down('xs')]: {
      width: '70vw',
      marginRight: 30,
    }
  },
}));

const useStyles = makeStyles(theme => ({
  paper: {
    height: '65vh',
    maxHeight: '65vh',
    boxShadow: 'none',
    [theme.breakpoints.down('xs')]: {
      height: '60vh',
      maxHeight: '60vh',
    }
  },
  listbox: {
    maxHeight: '65vh !important',
    [theme.breakpoints.down('xs')]: {
      maxHeight: '60vh !important',
    }
  },
  option: {
    ...theme.typography.body2,
    borderBottom: '1px solid #e9e9e9',
    padding: '8px 16px',
    [theme.breakpoints.down('xs')]: {
      padding: 4,
      minHeight: 'unset',
    }
  },
  inputRoot: {
    padding: '6px !important',
    [theme.breakpoints.down('xs')]: {
      padding: '0 !important',
      marginTop: '10px !important',
    }
  },
}));

// wrapper for autocomplete where suggestions are kept open and acts like a dropdown/filter
export default function AutocompleteMenu(params) {
  // @params: input display, defaultdata, input label
  // @advanceParams: input change handler, render option fn
  // @inheritedParams: open, selecthandler

  const {
    open, // open autocomplete result
    selectHandler, // user clicked an option
    optionDisplay, // {selected, label, labelId, labelContext} 
    inputLabel, // label in inputfield
    options = [], // options to be shown
    onInputChange, // inputfield value
    alwaysShowContext,
    loading = false,
    frontEndFilter = false // true for searches that do not use API
  } = params;

  const { selected } = optionDisplay;

  const classes = useStyles();
  const autocompleteClass = useAutocompleteStyle();
  const theme = useTheme();
  const xsDown = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true });
  const isValid = !!options && Array.isArray(options) && options.length > 0;
  const autocompleteOptions = isValid ? options : [];

  // useEffect(() => {
  //   const isValid = !!options && Array.isArray(options) && options.length > 0;
  //   console.log('_______')
  //   console.log('debug in AutocompleteMenu')
  //   console.log('options:', options)
  //   console.log('options isValid:', isValid)
  //   console.log('setAutocompleteOptions input:', isValid ? options : [])
  // }, [options]);

  const setInputChangeHandler = debounce((value) => {
    if (onInputChange) {
      onInputChange(value);
    }
  }, 500);
  
  const inputChangeHandler = (event) => {
    setInputChangeHandler(event.target.value)
  }

  const filterHandler = (options, state) => {
    const {inputValue} = state
    // if front end filtered, filter by selected values
    if (frontEndFilter) {
      const filteredOptions = options.filter(o => {
        let returnValue = false;
        if (Array.isArray(selected)) {
          returnValue = selected.some(selection => {
            return (o[selection].toLowerCase().indexOf(inputValue.toLowerCase()) !== -1);
          });
        } else {
          returnValue = o[selected].toLowerCase().indexOf(inputValue.toLowerCase()) !== -1;
        }
        return returnValue;
      })
      // else return all API response
      return filteredOptions;
    }
    return options;
  }

  return (
    <Autocomplete
      open={open}
      loading={loading}
      className={autocompleteClass.autocomplete}
      onChange={(event, newValue) => {
        selectHandler(newValue);
      }}
      freeSolo
      disableListWrap
      classes={classes}
      ListboxComponent={ListboxComponent}
      renderGroup={renderGroup}
      // getOptionSelected={(option, value) => option[selected] === value[selected]}
      getOptionLabel={option => {
        if (Array.isArray(selected)) {
          let arrString = "";
          selected.forEach(s => {
            arrString = arrString.concat(option[s] + ' ')
          });
          return arrString;
        }
        return option[selected];
      }}
      options={autocompleteOptions}
      filterOptions={filterHandler}
      renderInput={params => (
        <TextField
          {...params}
          autoFocus={true}
          variant={xsDown ? "standard" : "outlined"}
          label={inputLabel}
          onChange={inputChangeHandler}
          fullWidth
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}        
        />
      )}
      renderOption={(option, {inputValue}) => {
        return (
          <Option
            option={option}
            inputValue={inputValue}
            optionDisplay={optionDisplay}
            xsDown={xsDown}
            alwaysShowContext={alwaysShowContext}
          />
        )
      }}
      />
  );
}

export function Option({ option, inputValue, optionDisplay, xsDown, alwaysShowContext }) {
  const {
    label,
    labelId,
    labelContext,
  } = optionDisplay;

  const labelStr =  !isFunction(label) ?  option[label] : label(option);
  const labelIdStr = !isFunction(labelId) ? option[labelId] : labelId(option);
  const contextStr = !isFunction(labelContext) ? option[labelContext] : labelContext(option);

  return (
    <React.Fragment>
        <Grid container>
          <Grid item xs={1}></Grid>
          <Grid container item xs={11}>
            <Grid item xs={12}>
                <OptionLabel label={labelStr} inputValue={inputValue} />
                <OptionLabelId labelId={labelIdStr} inputValue={inputValue} />
                {
                  xsDown && alwaysShowContext &&
                  <OptionContext context={contextStr} inputValue={inputValue} />
                }
            </Grid>
            {
              !xsDown && 
              <Grid item xs={12}>
                  <OptionContext context={contextStr} inputValue={inputValue} />
              </Grid>
            }
          </Grid>
        </Grid>
    </React.Fragment>
  );

}


function OptionLabel({ label, inputValue }) {
  const { text, style } = !isObj(label) ?
                          {text: label, style: {}} : label;
  const labelMatches = match(text, inputValue);
  const labelParts = parse(text, labelMatches);

  return (
      <span data-testid="search-option-label">
        {labelParts.map((part, index) => (
          <span
            key={index}
            style={{
              fontWeight: part.highlight ? 700 : 400,
              ...style,
              marginLeft: index ? 0 : style.marginLeft || 0,
            }}
          >
            {part.text}
          </span>                      
        ))}
      </span>
  );
}

function OptionLabelId({ labelId, inputValue }) {
  const { text, style } = !isObj(labelId) ?
                          {text: labelId, style: {}} : labelId;
  const optionIdMatches = match(text, inputValue);
  const optionIdParts = parse(text, optionIdMatches);

  return (
      <span data-testid="search-option-id">
        <span style={{marginLeft: 4}}>
          (
          {optionIdParts.map((part, index) => (
            <span
              key={index}
              style={{
                fontWeight: part.highlight ? 700 : 400,
                ...style,
                marginLeft: index ? 0 : style.marginLeft || 0,
              }}
            >
              {part.text}
            </span>                      
          ))}
          )
        </span>
      </span>
  );
}

function OptionContext({ context, inputValue }) {
  const { text, style } = !isObj(context) ?
                          {text: context, style: {}} : context;
  const optionContextMatches = match(text, inputValue);
  const optionContextParts = parse(text, optionContextMatches);

  return (
    <span data-testid="search-option-context">
        {
          optionContextParts.map((part, i) => (
            <span
              key={i}
              style={{
                color: 'rgba(0, 0, 0, 0.54)',
                fontSize: '0.7rem',
                fontWeight: part.highlight ? 700 : 400,
                ...style,
                marginLeft: i ? 0 : style.marginLeft || 0,
              }}
            >
              {part.text}
            </span>
          ))
        }
    </span>
  );
}


function isObj(target) {
  return typeof target === 'object' && target !== null;
}

function renderRow(props) {
  const { data, index, style } = props;
  return React.cloneElement(data[index], {
    style: {
      ...style,
      top: style.top,
    },
  });
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

// Adapter for react-window
const ListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {
  const { children, ...other } = props;
  const itemData = React.Children.toArray(children);
  const theme = useTheme();
  const xsDown = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true });
  const itemCount = itemData.length;
  const itemSize = xsDown ? 32 : 57;

  const getChildSize = child => {
    if (React.isValidElement(child) && child.type === ListSubheader) {
      return itemSize;
    }

    return itemSize;
  };

  const getHeight = () => {
    return document.documentElement.clientHeight * 0.65;
  };

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight()}
          width="100%"
          key={itemCount}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={index => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

ListboxComponent.propTypes = {
  children: PropTypes.node,
};

const renderGroup = params => [
  <ListSubheader key={params.key} component="div">
    {params.key}
  </ListSubheader>,
  params.children,
];
