import { actionSheetFileUpdated } from '../Spreadsheet';
import {
  rmrecnbrFilterFromJson,
  conditionalFilterFromJson,
  defaultSheetFile,
  returnImportObj,
  returnCondObj,
  returnMapObj,
  returnFallbackVal,
  createSheetPointGeoJsonSource
} from '../Spreadsheet/spreadsheetutils';
import { defaultState } from '../Layer/LayerData';
import { setMapFilter } from './maputils';
import { queryRoom, rrnToBbox, queryBuilding } from '../Search/searchApi';
import _ from 'lodash';

const LAYER = "umich-reference";

export const handleCustomLayers = (map, customSheets, floor, idToken, dispatch, singleBuildingMode) => {
  if (!map) return;

  const sheetIds = customSheets.map(sheet => sheet.id);
  const customLayers = map.getStyle().layers.map(layer => layer.id).filter(id => id.indexOf('sheet') === 0);
  const customSources = Object.keys(map.getStyle().sources).filter(id => id.indexOf('sheet') === 0);

  // add or update
  customSheets.forEach(sheet => {
    handleData(map, sheet, floor, idToken, dispatch, singleBuildingMode);  
  });

  // remove layer if more layers than sheets
  customLayers.forEach(layer => {
    const id = parseInt(layer.split('-')[2]);
    
    if (!sheetIds.includes(id)) {
      map.removeLayer(layer);
    }
  });

  // remove points gjson source if more sources
  customSources.forEach(source => {
    const id = parseInt(source.split('-')[3]);
    if (!sheetIds.includes(id)) {
      map.removeSource(source);
    }
  });
};

const handleData = (map, sheet, floor, idToken, dispatch, singleBuildingMode) => {
  const { 
    id, 
    rmrecnbr, 
    json,
    lnglat,
    layersAllVisible: reduxAllVisible, 
    fillColor: reduxFillColor, 
    fillOpacity: reduxFillOpacity,
    lineColor: reduxLineColor, 
    lineWidth: reduxLineWidth,
    text: reduxTextColumn,
    // defaultText: reduxDefaultTextColumn,
    textSize: reduxTextSize,
    iconVisible: reduxIconVisible,
    icon: reduxIcon,
    iconSize: reduxIconSize,
    iconY: reduxIconY,
    iconX: reduxIconX,
    iconColor: reduxIconColor,
    fillVisible: reduxFillVisible,
    textVisible: reduxTextVisible,
    textX: reduxTextX,
    textY: reduxTextY,
    textColor: reduxTextColor,
    selectedRmrecnbr: reduxSelectedRmrecnbr,
    conditions: reduxConditions,
    // * Point
    pointVisible: reduxPointVisible,
    pointColor: reduxPointColor,
    pointIcon: reduxPointIcon,
    pointSize: reduxPointSize,
    pointOpacity: reduxPointOpacity
  } = sheet;

  const importObj = returnImportObj(json, rmrecnbr, lnglat, id);
  const fillLayerId = `sheet-fill-${id}`;
  const lineLayerId = `sheet-line-${id}`;
  const symbolLayerId = `sheet-symbol-${id}`;
  const pointLayerId = `sheet-point-${id}`;
  // get mapbox layer values
  const fillLayer = map.getLayer(fillLayerId);
  const lineLayer = map.getLayer(lineLayerId);
  const symbolLayer = map.getLayer(symbolLayerId);
  const pointLayer = map.getLayer(pointLayerId);

  const layersAllVisible = map.getLayoutProperty(fillLayerId, 'visibility') === 'visible';
  const layerFillVisible = map.getLayoutProperty(fillLayerId, 'visibility') === 'visible';
  const layerPointVisible = map.getLayoutProperty(pointLayerId, 'visibility') === 'visible';
  
  const layerIconOffsetProperty = getLayerProperty(map, symbolLayer, symbolLayerId, 'icon-offset', 'layout');
  const layerTextOffsetProperty = getLayerProperty(map, symbolLayer, symbolLayerId, 'text-offset', 'layout');
  const layerTextColorProperty = getLayerProperty(map, symbolLayer, symbolLayerId, 'text-color', 'paint');
  const layerFillColorProperty = getLayerProperty(map, fillLayer, fillLayerId, 'fill-color', 'paint');
  const layerFillOpacityProperty = getLayerProperty(map, fillLayer, fillLayerId, 'fill-opacity', 'paint');
  const layerLineColorProperty = getLayerProperty(map, lineLayer, lineLayerId, 'line-color', 'paint');
  const layerTextSizeProperty = getLayerProperty(map, symbolLayer, symbolLayerId, 'text-size', 'layout');
  const layerIconProperty = getLayerProperty(map, symbolLayer, symbolLayerId, 'icon-image', 'layout');
  const layerIconSizeProperty = getLayerProperty(map, symbolLayer, symbolLayerId, 'icon-size', 'layout');
  const layerIconColorProperty = getLayerProperty(map, symbolLayer, symbolLayerId, 'icon-color', 'paint');
  const layerLineWidthProperty = getLayerProperty(map, lineLayer, lineLayerId, 'line-width', 'paint');
  const layerTextFieldProperty = getLayerProperty(map, symbolLayer, symbolLayerId, 'text-field', 'layout');
  // * Point Values 
  const layerPointColorProperty = getLayerProperty(map, pointLayer, pointLayerId, 'icon-color', 'paint');
  const layerPointIconProperty = getLayerProperty(map, pointLayer, pointLayerId, 'icon-image', 'layout');
  const layerPointSizeProperty = getLayerProperty(map, pointLayer, pointLayerId, 'icon-size', 'layout');
  const layerPointOpacityProperty = getLayerProperty(map, pointLayer, pointLayerId, 'icon-opacity', 'paint')

  const layerSelectedRmrecnbr = fillLayer ? fillLayer.metadata.selected : null;    

  // reconcile layers to redux values
  if (
    reduxAllVisible && (
      (rmrecnbr !== null && !fillLayer) || 
      (lnglat !== null && !pointLayer)
    )) {
    map.addLayer(createFillLayer(sheet, floor, singleBuildingMode), LAYER);
    map.addLayer(createLineLayer(sheet, floor, singleBuildingMode), LAYER);
    map.addLayer(createSymbolLayer(sheet, floor, singleBuildingMode), LAYER);
    createPointLayer(sheet, floor, singleBuildingMode, map);
    getSheetLayerBoundingBox(map, sheet, idToken, dispatch);
  }
  /** Map visibility */
  if (fillLayer && layersAllVisible !== reduxAllVisible) {
    map.setLayoutProperty(fillLayerId, 'visibility', reduxAllVisible ? 'visible' : 'none');
    map.setLayoutProperty(lineLayerId, 'visibility', reduxAllVisible ? 'visible' : 'none');
    map.setLayoutProperty(symbolLayerId, 'visibility', reduxAllVisible ? 'visible' : 'none');
    map.setLayoutProperty(pointLayerId, 'visibility', reduxAllVisible ? 'visible': 'none')
  }
  /** Color Visibility */
  if (fillLayer && (layersAllVisible !== reduxAllVisible || layerFillVisible !== reduxFillVisible)) {
    map.setLayoutProperty(fillLayerId, 'visibility', reduxAllVisible && reduxFillVisible ? 'visible' : 'none');
    map.setLayoutProperty(lineLayerId, 'visibility', reduxAllVisible && reduxFillVisible ? 'visible' : 'none');
  }

  /** Point Visibility */
  if (pointLayer && (layersAllVisible !== reduxAllVisible || layerPointVisible !== reduxPointVisible)) {
    map.setLayoutProperty(pointLayerId, 'visibility', reduxAllVisible && reduxPointVisible ? 'visible' : 'none');
  }

  // CREAT NEW FILTERS IF NECESSARY
  if (fillLayer && rmrecnbr !== fillLayer.metadata.rrn) {
    const newFilter = rmrecnbrFilterFromJson(sheet);
    getSheetLayerBoundingBox(map, sheet, idToken, dispatch);

    // update layer metadata
    fillLayer.metadata.rrn = rmrecnbr;
    lineLayer.metadata.rrn = rmrecnbr;
    symbolLayer.metadata.rrn = rmrecnbr;

    // update layer filter
    setMapFilter(map, fillLayerId, 'rmrecnbr', newFilter);
    setMapFilter(map, lineLayerId, 'rmrecnbr', newFilter);
    setMapFilter(map, symbolLayerId, 'rmrecnbr', newFilter);
  }

  // Check and handle layer rerenders
  /** fillColor */
  if (fillLayer && rerenderCheck('fillColor', reduxConditions, layerFillColorProperty, importObj, reduxFillColor, id)) {
    map.setPaintProperty(fillLayerId, 'fill-color', conditionalFilterFromJson(reduxConditions, 'fillColor', reduxFillColor, reduxFillVisible, sheet));
  }
  /** fillOpacity */
  if (fillLayer && rerenderCheck('fillOpacity', reduxConditions, layerFillOpacityProperty, importObj, reduxFillOpacity, id)) {
    map.setPaintProperty(fillLayerId, 'fill-opacity', conditionalFilterFromJson(reduxConditions, 'fillOpacity', reduxFillOpacity, reduxFillVisible, sheet));
  }
  /** lineColor */
  if (lineLayer && rerenderCheck('lineColor', reduxConditions, layerLineColorProperty, importObj, reduxLineColor, id)) {
    map.setPaintProperty( lineLayerId, 'line-color', conditionalFilterFromJson(reduxConditions, 'lineColor', reduxLineColor, reduxFillVisible, sheet));
  }
  /** lineWidth */
  if (lineLayer && rerenderCheck('lineWidth', reduxConditions, layerLineWidthProperty, importObj, reduxLineWidth, id)) {
    map.setPaintProperty(lineLayerId, 'line-width', conditionalFilterFromJson(reduxConditions, 'lineWidth', reduxLineWidth, reduxFillVisible, sheet));
  }
  /** text */
  if (symbolLayer && 
      (rerenderCheckText('text', reduxConditions, layerTextFieldProperty, importObj, reduxTextColumn, json, rmrecnbr) ||
      symbolLayer.metadata.textVisible !== reduxTextVisible)
    ) {
    symbolLayer.metadata.textVisible = reduxTextVisible;
    if (reduxTextVisible) {
      // ! Text Visibility
      // const newFilter = conditionalFilterFromJson(reduxConditions, 'text', reduxTextColumn, json, rmrecnbr, reduxTextVisible, id);
      const newFilter = conditionalFilterFromJson(reduxConditions, 'text', reduxTextColumn, reduxTextVisible, sheet);
      map.setLayoutProperty(symbolLayerId, 'text-field', newFilter);
    } else {
      map.setLayoutProperty(symbolLayerId, 'text-field', '');
    }
    
  }
  /** textColor */
  if (symbolLayer && rerenderCheck('textColor', reduxConditions, layerTextColorProperty, importObj, reduxTextColor, id)) {
    map.setPaintProperty(symbolLayerId, 'text-color', conditionalFilterFromJson(reduxConditions, 'textColor', reduxTextColor, reduxTextVisible, sheet));
  }
  /** textSize */
  if (symbolLayer && rerenderCheck('textSize', reduxConditions, layerTextSizeProperty, importObj, reduxTextSize, id)) {
    map.setLayoutProperty(symbolLayerId, 'text-size', conditionalFilterFromJson(reduxConditions, 'textSize', reduxTextSize, reduxTextVisible, sheet));
  }
  /** textOffset */
  if (symbolLayer && rerenderCheck('textOffset', reduxConditions, layerTextOffsetProperty, importObj, [reduxTextX, reduxTextY], id)) {
    map.setLayoutProperty(symbolLayerId, 'text-offset', conditionalFilterFromJson(reduxConditions, 'textOffset', [reduxTextX, reduxTextY], reduxTextVisible, sheet));
  }
  /** icon */
  // TODO @point-2 refactor blank icon-image
  if (symbolLayer && (rerenderCheck('icon', reduxConditions, layerIconProperty, importObj, reduxIcon, id) || symbolLayer.metadata.iconVisible !== reduxIconVisible)) {
    // ! Icon Visibility
    symbolLayer.metadata.iconVisible = reduxIconVisible;
    if (reduxIconVisible) {
      map.setLayoutProperty(symbolLayerId, 'icon-image', conditionalFilterFromJson(reduxConditions, "icon", reduxIcon, reduxIconVisible, sheet));
    } else {
      map.setLayoutProperty(symbolLayerId, 'icon-image', '');
    }
  }
  /** iconSize */
  if (symbolLayer && rerenderCheck('iconSize', reduxConditions, layerIconSizeProperty, importObj, reduxIcon, id)) {
    map.setLayoutProperty(symbolLayerId, 'icon-size', conditionalFilterFromJson(reduxConditions, 'iconSize', reduxIconSize, reduxIconVisible, sheet));
  }
  /** iconOffset */
  if (symbolLayer && rerenderCheck('iconOffset', reduxConditions, layerIconOffsetProperty, importObj, [reduxIconX, reduxIconY], id)) {
    map.setLayoutProperty(symbolLayerId, 'icon-offset', conditionalFilterFromJson(reduxConditions, 'iconOffset', [reduxIconX, reduxIconY], reduxIconVisible, sheet));
  }
  /** iconColor */
  if (symbolLayer && rerenderCheck('iconColor', reduxConditions, layerIconColorProperty, importObj, reduxIconColor, id)) {
    map.setPaintProperty(symbolLayerId, 'icon-color', conditionalFilterFromJson(reduxConditions, 'iconColor', reduxIconColor, reduxIconVisible, sheet));
  }
  /** point COLOR */
  if (pointLayer && rerenderCheck('pointColor', reduxConditions, layerPointColorProperty, importObj, reduxPointColor, id)) {
    map.setPaintProperty(pointLayerId, 'icon-color', conditionalFilterFromJson(reduxConditions, 'pointColor', reduxPointColor, reduxPointVisible, sheet));
  }
  /** point ICON */
  if (pointLayer && rerenderCheck('pointIcon', reduxConditions, layerPointIconProperty, importObj, reduxPointIcon, id)) {
    map.setLayoutProperty(pointLayerId, 'icon-image', conditionalFilterFromJson(reduxConditions, 'pointIcon', reduxPointIcon, reduxPointVisible, sheet));
  }
  /** point SIZE */
  if (pointLayer && rerenderCheck('pointSize', reduxConditions, layerPointSizeProperty, importObj, reduxPointSize, id)) {
    map.setLayoutProperty(pointLayerId, 'icon-size', conditionalFilterFromJson(reduxConditions, 'pointSize', reduxPointSize, reduxPointVisible, sheet));
  }
  /** point Opacity */
  if (pointLayer && rerenderCheck('pointOpacity', reduxConditions, layerPointOpacityProperty, importObj, reduxPointOpacity, id)) {
    map.setPaintProperty(pointLayerId, 'icon-opacity', conditionalFilterFromJson(reduxConditions, 'pointOpacity', reduxPointOpacity, reduxPointVisible, sheet));
  }

  
  /** featureState */
  if (fillLayer && reduxSelectedRmrecnbr !== layerSelectedRmrecnbr) {      
    // Add featureState to feature
    if (reduxSelectedRmrecnbr) {
      map.setFeatureState({
        source: 'campus-room',
        sourceLayer: 'room',
        id: reduxSelectedRmrecnbr,
      }, { selected: true });
    }
    // remove featureState from prev feature
    if (layerSelectedRmrecnbr) {
      map.setFeatureState({
        source: 'campus-room',
        sourceLayer: 'room',
        id: layerSelectedRmrecnbr,
      }, { selected: false });
    }
    // update metadata
    fillLayer.metadata.selected = reduxSelectedRmrecnbr;
  }

}

const createFillLayer = (sheet, floor, singleBuildingMode) => {
  const { rmrecnbr, id } = sheet;
  const newrmrecnbrFilter = rmrecnbrFilterFromJson(sheet);
  const newfloorFilter = ['==', 'floor', floor || defaultState.floor ];
  const newFullFilter = ['all', newrmrecnbrFilter, newfloorFilter];
  
  if (!_.isEmpty(singleBuildingMode) && singleBuildingMode.bldrecnbr) {
    newFullFilter.push(['==', 'bldrecnbr', singleBuildingMode.bldrecnbr]);
  }

  return {
    'filter': newFullFilter,
    'source': 'campus-room',
    'source-layer': 'room',
    'type': 'fill',
    'id': `sheet-fill-${id}`,
    'minzoom': 17,
    'maxzoom': 24,
    'metadata': {
      'rrn': rmrecnbr,
      'selected': null,
    },
  };
};

const createLineLayer = (sheet, floor, singleBuildingMode) => {
  const { rmrecnbr, id } = sheet;
  const newrmrecnbrFilter = rmrecnbrFilterFromJson(sheet);
  const newfloorFilter = ['==', 'floor', floor || defaultState.floor ];
  const newFullFilter = ['all', newrmrecnbrFilter, newfloorFilter];
  
  if (!_.isEmpty(singleBuildingMode) && singleBuildingMode.bldrecnbr) {
    newFullFilter.push(['==', 'bldrecnbr', singleBuildingMode.bldrecnbr]);
  }

  return {
    'filter': newFullFilter,
    'source': 'campus-room',
    'source-layer': 'room',
    'type': 'line',
    'id': `sheet-line-${id}`,
    'minzoom': 17,
    'maxzoom': 24,
    'metadata': {
      'rrn': rmrecnbr
    },
  };
};

const createSymbolLayer = (sheet, floor, singleBuildingMode) => {
  const { rmrecnbr, id } = sheet;
  const newrmrecnbrFilter = rmrecnbrFilterFromJson(sheet);
  const newfloorFilter = ['==', 'floor', floor || defaultState.floor];
  const newFullFilter = ['all', newrmrecnbrFilter, newfloorFilter];
  
  if (!_.isEmpty(singleBuildingMode) && singleBuildingMode.bldrecnbr) {
    newFullFilter.push(['==', 'bldrecnbr', singleBuildingMode.bldrecnbr]);
  }

  return {
    'filter': newFullFilter,
    'source': 'campus-label',
    'source-layer': 'label',
    'type': 'symbol',
    'id': `sheet-symbol-${id}`,
    "minzoom": 17,
    "maxzoom": 24,
    'metadata': {
      'rrn': rmrecnbr,
      'iconVisible': true,
      'textVisible': true,
    },
  };
};

const createPointLayer = (sheet, floor, singleBuildingMode, map) => {
  const { json, lnglat, id } = sheet;
  const geoJson = createSheetPointGeoJsonSource(json, lnglat, id);
  const source_id = `sheet-point-source-${id}`;
  const layer_id = `sheet-point-${id}`;

  if (!map.getSource(source_id) && geoJson) {
    map.addSource(source_id, {
      promoteId: 'id',
      type: 'geojson',
      data: geoJson
    })
  }
  
  const newfloorFilter = [
    'any',
      ['boolean', 
        ['all',
          ['boolean', ['has', 'floor']],
          ['boolean', [ '==', ['get', 'floor'], floor || defaultState.floor ]],
        ]],
      ['boolean', ['!',[ 'has', 'floor' ]]]
  ];

  const combinedFilter = [
    'all',
    newfloorFilter
  ];

  if (singleBuildingMode && singleBuildingMode.bldrecnbr) {
    const singleBuildingFilter = [
      'all',
      ['has', 'bldrecnbr'],
      ['==', ['get', 'bldrecnbr'], singleBuildingMode.bldrecnbr]
    ];
    combinedFilter.push(singleBuildingFilter);
  }

  map.addLayer({
    'id': layer_id,
    'type': 'symbol',
    'source': source_id,
    'filter': combinedFilter,
    'paint': {
      'icon-color': 'black',
    },
    'layout': {
      'icon-size': 3,
      'icon-image': 'room',
      'icon-allow-overlap': true,
    }
  }, LAYER);
}

const getSheetLayerBoundingBox = (map, sheet, idToken, dispatch) => {
  const { json, rmrecnbr, id, status, lnglat } = sheet;
  const rmrecnbrArray = json.map(row => row[rmrecnbr]).filter(rrn => !isNaN(parseInt(rrn)));
  const pointArray = lnglat !== null ? json.map(row => [row[lnglat[0]], row[lnglat[1]]]).filter(pair => (!isNaN(parseFloat(pair[0])) || !isNaN(parseFloat(pair[1])))) : [];

  // get bbox for layer and dispatch it
  const getLayerBbox = async (rrn, points, token, status) => {
    if (status === 'preupload') return;
    const roombbox = await rrnToBbox(rrn, token);
    const pointbbox = createbbox(...points);
    const bbox = createbbox(...roombbox, ...pointbbox);

    dispatch(actionSheetFileUpdated({
      id,
      bbox
    }));
  }

  getLayerBbox(rmrecnbrArray, pointArray, idToken, status);

  // get bbox for all floor building combos, and building data, and dispatch it
  const getRoom = async (rrn, token) => {
    const data = await queryRoom(rrn, token);
    return data;
  };

  const getRooms = async (token) => {
    return Promise.all(rmrecnbrArray.map(rmrecnbr => getRoom(rmrecnbr, token)));
  };

  const getBuildingData = async (rooms) => {
    const bldrecnbrArray = [...new Set(rooms.filter(room => room && room.bldrecnbr).map(room => room && room.bldrecnbr))];
    const buildObj = {};
    bldrecnbrArray.forEach(bldrecnbr => {
      const floorArray = [...new Set(rooms.filter(room => room && room.bldrecnbr === bldrecnbr).map(room => room.floor))];
      buildObj[bldrecnbr] = floorArray;
    });

    const buildingData = [];  
    for (const building in buildObj) {
      for (const floor of buildObj[building]) {
        const buildingRoomsArray = rooms.filter(room => room && room.bldrecnbr === building && room.floor === floor);
        const totalSqFeet = buildingRoomsArray
          .map(room => room.rmsqrft)
          .reduce((a, b) => a + b);
        const { bld_descrshort } = rooms.find(room => room && String(room.bldrecnbr) === String(building));
        const roomsRrn = buildingRoomsArray.map(room => room && room.rmrecnbr)
        const bbox = await getBldingBBox(roomsRrn, idToken);
        buildingData.push({
          data: {
            building: bld_descrshort,
            floor,
            totalSqFeet
          },
          bbox
        });
      }
    }
    return buildingData;
  };
 
  const getPointBuildingData = async (json) => {
    // TODO add try catch
    const NO_BUILDING = 'No Building';
    const buildingObj = {};
    if (!lnglat) return [];
    const [ lngIndex, latIndex ] = lnglat;
    const bldrecnbrIndex = json[0].findIndex(x => x === 'bldrecnbr');
    const floorIndex = json[0].findIndex(x => x === 'floor');

    for (const row of json.slice(1) ) {
      const bldrecnbr = row[bldrecnbrIndex];
      const floor = row[floorIndex];
      const lngVal = row[lngIndex];
      const latVal = row[latIndex];
      if (bldrecnbr && lngVal && latVal) {
        if (buildingObj[bldrecnbr] && buildingObj[bldrecnbr][floor]) {
          buildingObj[bldrecnbr][floor].lng.push(lngVal);
          buildingObj[bldrecnbr][floor].lat.push(latVal);
        } else if (buildingObj[bldrecnbr]) {
          buildingObj[bldrecnbr][floor] = {
            lng: [lngVal],
            lat: [latVal]
          };
        } else {
          buildingObj[bldrecnbr] = {
            [floor]: {
              lng: [lngVal],
              lat: [latVal]
            }
          };
        }
      } else if (lngVal && latVal) {
        if (buildingObj[NO_BUILDING]) {
          buildingObj[NO_BUILDING].lng.push(lngVal);
          buildingObj[NO_BUILDING].lat.push(latVal);
        } else {
          buildingObj[NO_BUILDING] = {
            lng: [lngVal],
            lat: [latVal]
          };
        }
      }
    }

    const buildingData = [];
    for (const building in buildingObj) {
      if (building !== NO_BUILDING) {

        const { bld_descrshort } = await queryBuilding(building, idToken);
        for (let floor in buildingObj[building]) {
          const lngMin = Math.min(...buildingObj[building][floor].lng);
          const lngMax = Math.max(...buildingObj[building][floor].lng);
          const latMin = Math.min(...buildingObj[building][floor].lat);
          const latMax = Math.max(...buildingObj[building][floor].lat);
          
          const obj = {
            data: {
              building: bld_descrshort,
              floor: floor,
            },
            bbox: [[lngMin, latMin], [lngMax, latMax]]
          }
          buildingData.push(obj)
        }
      } else {
        const lngMin = Math.min(...buildingObj[building].lng);
        const lngMax = Math.max(...buildingObj[building].lng);
        const latMin = Math.min(...buildingObj[building].lat);
        const latMax = Math.max(...buildingObj[building].lat);
        const obj = {
          data: {
            building: 'No Building',
          },
          bbox: [[lngMin, latMin], [lngMax, latMax]]
        };
        buildingData.push(obj);
      }
    }
    return buildingData;
  }

  const getBldingBBox = async (rrn, token) => {
    let bbox = await rrnToBbox(rrn, token);
    return bbox;
  };

  getRooms(idToken).then(async rooms => {
    const roomBuildingData = await getBuildingData(rooms);
    const pointBuildingData = await getPointBuildingData(json);
    const buildingData = combineBuildingData(...roomBuildingData, ...pointBuildingData);
    dispatch(actionSheetFileUpdated({
      id,
      buildingData
    }));
  });
};

const combineBuildingData = (...data) => {
  const returnArray = [];
  data.forEach(x => {
    const { building, floor } = x.data;
    const i = returnArray.findIndex(b => b.data.building === building && b.data.floor === floor);
    if (i >= 0) {
      // combine for existing building
      const newBbox = x.bbox;
      const existingBbox = returnArray[i].bbox;
      const combinedBbox = createbbox(...newBbox, ...existingBbox);
      returnArray[i].bbox = combinedBbox;
    } else {
      // add building
      returnArray.push(x);
    }
  });
  return returnArray;
}

// TODO @point-2 
// * walk through rerender check
// * annotate default rerender check
// * update point rerender check
// * 1 Work with redux changes
const rerenderCheckPoint = (reduxKey, reduxConditions, layer, importObj, reduxVal, id) => {
  console.log(reduxKey);

  const { [reduxKey]: defaultVal } = defaultSheetFile(id);
  // console.log(defaultVal);

  const reduxObj = {};
  reduxConditions.filter(cond => cond[reduxKey] && cond.type.indexOf('heatmap') !== 0).forEach(cond => {
    if(Array.isArray(cond.rmrecnbr)) {
      cond.rmrecnbr.forEach(rmrecnbr => {
        reduxObj[rmrecnbr] = cond[reduxKey]
      });
    } else {
      reduxObj[cond.rmrecnbr] = cond[reduxKey]
    }
  });
  // console.log(reduxObj);
  
  const condObj = returnCondObj(reduxConditions, reduxKey);
  // console.log(condObj)
  

  const mapObj = returnMapObj(importObj, condObj, reduxVal);
  // console.log(mapObj);
  
  const reduxFallback = returnFallbackVal(
    reduxVal,
    defaultVal,
    // reduxConditions
  );
  
  const [layerObj, layerFallback] = returnLayerObj(layer, reduxKey);

  const parsedMapObj = {}
  for (let rm in mapObj) {
    if (mapObj[rm][reduxKey]) parsedMapObj[rm] = mapObj[rm][reduxKey];
  }

  console.log("===COMPARE FALLBACKS===");
  console.log(reduxFallback);
  console.log(layerFallback);

  console.log("---COMPARE VALUES---");
  console.log(parsedMapObj);
  console.log(layerObj);
  const rerenderBool = (!_.isEqual(reduxFallback, layerFallback) || !_.isEqual(parsedMapObj, layerObj));
  // console.log(rerenderBool)
  return rerenderBool;
}

const rerenderCheck = (reduxKey, reduxConditions, layer, importObj, reduxVal, id) => {

  const { [reduxKey]: defaultVal } = defaultSheetFile(id);

  const reduxObj = {};
  reduxConditions.filter(cond => cond[reduxKey] && cond.type.indexOf('heatmap') !== 0).forEach(cond => {
    if(Array.isArray(cond.rmrecnbr)) {
      cond.rmrecnbr.forEach(rmrecnbr => {
        reduxObj[rmrecnbr] = cond[reduxKey]
      });
    } else {
      reduxObj[cond.rmrecnbr] = cond[reduxKey]
    }
  });

  const condObj = returnCondObj(reduxConditions, reduxKey);
  const mapObj = returnMapObj(importObj, condObj, reduxVal);
  const reduxFallback = returnFallbackVal(
    reduxVal,
    defaultVal,
    // reduxConditions
  );
  const [layerObj, layerFallback] = returnLayerObj(layer, reduxKey);

  const parsedMapObj = {}
  for (let rm in mapObj) {
    if (mapObj[rm][reduxKey]) parsedMapObj[rm] = mapObj[rm][reduxKey];
  }

  return (!_.isEqual(reduxFallback, layerFallback) || !_.isEqual(parsedMapObj, layerObj));
}

const rerenderCheckText = (reduxKey, reduxConditions, layer, importObj, reduxVal, json, rmrecnbr) => {
  const reduxObj = {};
  reduxConditions.filter(cond => cond[reduxKey] && cond.type.indexOf('heatmap') !== 0).forEach(cond => {
    if(Array.isArray(cond.rmrecnbr)) {
      cond.rmrecnbr.forEach(rmrecnbr => {
        reduxObj[rmrecnbr] = cond[reduxKey]
      });
    } else {
      reduxObj[cond.rmrecnbr] = cond[reduxKey]
    }
  });

  const condObj = returnCondObj(reduxConditions, reduxKey);
  const textObj = returnTextObj(json, rmrecnbr, reduxVal)
  const parsedImports = {}
  for (let rrn in importObj) {
    if (importObj[rrn] && importObj[rrn].text) parsedImports[rrn] = importObj[rrn].text;
  }
  let mapObj; 
  if (reduxVal === null) {
    mapObj = { ...textObj, ...parsedImports, ...condObj };
  } else {
    mapObj = { ...textObj, ...condObj}
  }

  const reduxFallback = '';
  const [layerObj, layerFallback] = returnLayerObj(layer, reduxKey);

  return reduxFallback !== layerFallback || !_.isEqual(mapObj, layerObj);
}

const returnLayerObj = (layer, key) => {
  if (key === 'fillOpacity') return fillOpacityLayerObj(layer);
  let layerObj = {}
  let layerFallback = '';
  if (Array.isArray(layer)) {
    let last = layer.length -1;
    let first = 1;
    let slicedLayer = layer.slice(first, last);
    for (let i = 0; i < slicedLayer.length; i += 2) {
      // SKIP CHECK OF FEATURE-STATE LOGIC
      if (!slicedLayer[i] || !slicedLayer[i][1] || !slicedLayer[i][1][1] || slicedLayer[i][1][0] !== 'feature-state') {
        let key = slicedLayer[i][2];
        let value = slicedLayer[i + 1];
        if (key) {
          layerObj[key] = value;  
        }
      }
    }
    layerFallback = layer[layer.length - 1];
  } else {
    layerFallback = layer;
  }
  return [layerObj, layerFallback];
}

const returnTextObj = (json, rmrecnbr, textcol) => {
  let lookup = textcol === null ? rmrecnbr : textcol;
  const textObj = {};
  json.forEach((row, i) => {
    if (i === 0) return;
    if (!row[rmrecnbr]) return;
    textObj[row[rmrecnbr]] = row[lookup];
  });
  return textObj;
}

const fillOpacityLayerObj = (layer) => {
  let layerObj = {};
  let layerFallback = '';
  if (Array.isArray(layer)) {
    layerFallback = layer[layer.length - 1];
    for (let i = 1; i < layer.length - 1; i += 2) {
      if (layer[i][0] === '==') {
        layerObj[layer[i][2]] = layer[i + 1]
      }
    }
  }
  return [layerObj, layerFallback]
}

const getLayerProperty = (map, layer, layerId, mapProperty, propertyType) => {
  if (propertyType === 'paint') return layer ? map.getPaintProperty(layerId, mapProperty) : null;
  if (propertyType === 'layout') return layer ? map.getLayoutProperty(layerId, mapProperty) : null;
  throw Error('Need property type of paint or layout')
};

function createbbox(...coordPairs) {
  if (coordPairs.length === 0) return [];
  const swCoordPair = coordPairs.reduce((prev, curr) => {
    let lngMin;
    let latMin;
    if (!prev || prev[0] === -Infinity) {
      lngMin = curr[0];
    } else {
      lngMin = Math.min(prev[0], curr[0]);
    }
    if (!prev || prev[1] === -Infinity) {
      latMin = curr[1];
    } else {
      latMin = Math.min(prev[1], curr[1]);
    }
    return [lngMin, latMin];
  });
  const neCoordPair = coordPairs.reduce((prev, curr) => {
    let lngMax;
    let latMax;
    if (prev[0] === Infinity) {
      lngMax = curr[0];
    } else {
      lngMax = Math.max(prev[0], curr[0]);
    }
    if (prev[1] === Infinity) {
      latMax = curr[1];
    } else {
      latMax = Math.max(prev[1], curr[1]);
    }
    return [lngMax, latMax];
  });
  return [swCoordPair, neCoordPair];
}

