import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';

import { actionSheetFileUpdated } from '.';
import { defaultSheetFile, fileFromJson, returnImportObj, getFillColor } from './spreadsheetutils';
import { makeStyles } from '@material-ui/core';
import { SPREADSHEET, trackEvent } from '../../tracking';

const useStyles = makeStyles(theme => ({
  control: {
    padding: 10,
  },
  text: {
    marginBottom: 10
  }
}));

const formatExportJson = (file) => {
  const {
    id, 
    json,
    rmrecnbr,
    lnglat,
    fillColor,
    fillOpacity,
    text,
    icon,
    lineColor,
    lineWidth,
    textSize,
    textColor,
    textX,
    textY,
    iconX,
    iconY,
    iconSize,
    conditions,

    pointIcon,
    pointColor,
    pointOpacity,
    pointSize
  } = file;
  // find existing headers
  // update if existing
  // TODO @point-future add MGIS-type column?
  const indices = {
    'MGIS-Color': null,
    'MGIS-Text': null,
    'MGIS-Icon': null,
    'MGIS-Color-Config': null,
    'MGIS-Text-Config': null,
    'MGIS-Icon-Config': null
  };

  let i = 0;
  for (const key in indices) {
    let headers = json[0];
    if (headers.indexOf(key) > -1) indices[key] = headers.indexOf(key)
    else {
      indices[key] = headers.length + i;
      i++;
    }
  }

  const addMGIS_RRN = json[0][0] !== 'MGIS-RRN';
  const importObj = returnImportObj(json, rmrecnbr, lnglat, id);
  const defaultObj =  defaultSheetFile(id);
  // ! @point-future RRN array also includs "pointId" for conditions
  // ! reference is confusing and should be refactored
  const conditionRRNs = conditions.map(cond => cond.rmrecnbr).reduce((a, b) => a.concat(b), []);

  const exportJson = json.map((row, index) => {
    
    // detect type
    const rowRRN = row[rmrecnbr];
    const pointId = `point-${id}-${index}`;
    const rowLng = lnglat && lnglat[0] && row[lnglat[0]];
    const rowLat = lnglat && lnglat[1] && row[lnglat[1]];
    if (!rowRRN && (!rowLng || !rowLat)) return [];
    const type = rowLng && rowLat ? 'Point' : 'Room';

    const exportCondition = {};
    const importedVal = (type === 'Point' ? importObj[pointId] : importObj[rowRRN]) || {};
    // if room has a condition
    if (conditionRRNs.includes(rowRRN)) {
      // remove heatmap conditions
      conditions.filter(cond => cond.type.indexOf('heatmap') !== 0).forEach(condition => {
        // find conditions matching the room
        if (condition.rmrecnbr.includes(rowRRN)) {
          // update the condition object
          if ('fillOpacity' in condition) exportCondition['fillOpacity'] = condition['fillOpacity'];
          if ('fillColor' in condition) exportCondition['fillColor'] = condition['fillColor'];
          if ('text' in condition) exportCondition['text'] = condition['text'];
          if ('icon' in condition) exportCondition['icon'] = condition['icon'];
          if ('lineColor' in condition) exportCondition['lineColor'] = condition['lineColor'];
          if ('textSize' in condition) exportCondition['textSize'] = condition['textSize'];
          if ('textColor' in condition) exportCondition['textColor'] = condition['textColor'];
          if ('iconSize' in condition) exportCondition['iconSize'] = condition['iconSize'];
        }
      });
    }
    if (conditionRRNs.includes(pointId)) {
      // remove heatmap reference conditions
      conditions.filter(cond => cond.type.indexOf('heatmap') !== 0).forEach(condition => {
        console.log(condition);
        if (condition.rmrecnbr.includes(pointId)) {
          // update the condition object
          if ('pointIcon' in condition) exportCondition['pointIcon'] = condition['pointIcon'];
          if ('pointColor' in condition) exportCondition['pointColor'] = condition['pointColor'];
          if ('pointSize' in condition) exportCondition['pointSize'] = condition['pointSize'];
        }
      });
    }
    const newRow = row.slice();

    if (index === 0) {
      newRow.splice(indices['MGIS-Color'], 1, 'MGIS-Color');
      newRow.splice(indices['MGIS-Text'], 1, 'MGIS-Text');
      newRow.splice(indices['MGIS-Icon'], 1, 'MGIS-Icon');
      newRow.splice(indices['MGIS-Color-Config'], 1, 'MGIS-Color-Config');
      newRow.splice(indices['MGIS-Text-Config'], 1, 'MGIS-Text-Config');
      newRow.splice(indices['MGIS-Icon-Config'], 1, 'MGIS-Icon-Config');
      if (addMGIS_RRN) newRow.splice(0, 0, 'MGIS-RRN');
    } else {
      const exportPointIcon = returnExportValue(exportCondition.pointIcon, pointIcon, importedVal.icon, defaultObj.pointIcon);
      const exportPointColor = returnExportPointColor(exportCondition, importedVal, defaultObj, pointColor, pointOpacity);
      const exportPointIconSize = returnExportValue(exportCondition.pointSize, pointSize, importedVal.pointSize, defaultObj.pointSize)
      
      const exportLineColor = returnExportValue(exportCondition.lineColor, lineColor, importedVal.lineColor, defaultObj.lineColor);
      const exportLineWidth = returnExportValue(exportCondition.lineWidth, lineWidth, importedVal.lineWidth, defaultObj.lineWidth);
      
      const exportTextSize = returnExportValue(exportCondition.textSize, textSize, importedVal.textSize, defaultObj.textSize);
      const exportTextColor = returnExportValue(exportCondition.textColor, textColor, importedVal.textColor, defaultObj.textColor);
    
      const exportIcon = returnExportValue(exportCondition.icon, icon, importedVal.icon, defaultObj.icon);
      const exportIconSize = returnExportValue(exportCondition.iconSize, iconSize, importedVal.iconSize, defaultObj.iconSize);
      
      // no conditional offsets, param 1 false
      const exportTextX = returnExportValue(false, textX, importedVal.textX, 0);
      const exportTextY = returnExportValue(false, textY, importedVal.textY, 0);
      const exportIconX = returnExportValue(false, iconX, importedVal.iconX, 0);
      const exportIconY = returnExportValue(false, iconY, importedVal.iconY, 0);

      const exportRGBA = returnExportFillColor(exportCondition, importedVal, defaultObj, fillColor, fillOpacity);
      // MGIS-Color can be used for point and fill
      newRow.splice(indices['MGIS-Color'], 1, 
        (type === 'Point' ? exportPointColor : exportRGBA)
      );
      newRow.splice(indices['MGIS-Text'], 1, exportCondition.text || (text !== null ? row[text] : false ) || importedVal.text || '' );
      // MGIS-Icon can be used for point and icon
      newRow.splice(indices['MGIS-Icon'], 1, 
        (type === 'Point' ? exportPointIcon : exportIcon)
      );
      newRow.splice(indices['MGIS-Color-Config'], 1, `outlineColor@${exportLineColor};outlineStyle@solid;outlineWidth@${exportLineWidth};style@solid;`);
      newRow.splice(indices['MGIS-Text-Config'], 1, `fontSize@${exportTextSize};color@${exportTextColor};xoffset@${exportTextX};yoffset@${exportTextY};`);
      // MGIS-Icon-Config can be used for point and Icon
      newRow.splice(indices['MGIS-Icon-Config'], 1, 
        (
          type === 'Point' ?
          `xoffset@${0};yoffset@${0};size@${exportPointIconSize};` :
          `xoffset@${exportIconX};yoffset@${exportIconY};size@${exportIconSize};`
        ));
      if (addMGIS_RRN) newRow.splice(0, 0, rowRRN);
    }
    return newRow;
  }).filter(array => array.length > 0);
  return exportJson;
};

const mergeJson = (jsonArray) => {
  if (jsonArray.length === 1) return jsonArray[0];
  const CONFIG_HEADERS = ['MGIS-Color', 'MGIS-Text', 'MGIS-Icon', 'MGIS-Color-Config', 'MGIS-Text-Config', 'MGIS-Icon-Config'];
  const combinedHeader = [];
  const indexRef = [];

  // build header and ref (without config headers)
  jsonArray.forEach((json, jsonIndex) => {
    indexRef.push([]);
    json[0].forEach(head => {
      // skip config
      if (CONFIG_HEADERS.includes(head)) return;
      // note already added
      if (combinedHeader.includes(head)) {
        indexRef[jsonIndex].push(combinedHeader.indexOf(head))
        return;
      };
      // add otherwise
      indexRef[jsonIndex].push(combinedHeader.length)
      combinedHeader.push(head);
    });
  });
  // add config headers
  const configIndexStart = combinedHeader.length;
  combinedHeader.push(...CONFIG_HEADERS)
  const configIndexArray = [configIndexStart, configIndexStart + 1, configIndexStart + 2, configIndexStart + 3, configIndexStart + 4, configIndexStart + 5]
  indexRef.forEach(indexArray => {
    indexArray.push(...configIndexArray)
  });

  // fill json
  const returnJson = [combinedHeader]
  jsonArray.forEach((json, jsonIndex) => {
    const indexArray = indexRef[jsonIndex];
    json.forEach((row, rowIndex) => {
      // Skip headers
      if (rowIndex === 0) return;
      const returnRow = []
      combinedHeader.forEach((_,i) => {
        if (indexArray.includes(i)) {
          returnRow.push(row[indexArray.indexOf(i)])
        }
        // use empty string in no data
        else returnRow.push('')
      });
      returnJson.push(returnRow);
    })
  })
  return returnJson;
};

const returnExportFillColor = (exportCondition, importedVal, defaultObj, fillColor, fillOpacity) => {
  const { fillColor: condFillColor, fillOpacity: condFillOpacity } = exportCondition;
  const { fillColor: importFillColor, fillOpacity: importFillOpacity } = importedVal;
  const { fillColor: defaultFillColor, fillOpacity: defaultFillOpacity } = defaultObj;
  const colorVal = returnExportValue(condFillColor, fillColor, importFillColor, defaultFillColor);
  const rgb = getFillColor(colorVal);
  const rgbRegex = /[\d+|\.|\s]+(?=,|\))/g;
  const match = rgb.match(rgbRegex);
  if (!match) return rgb;
  const [r, g, b] = rgb.match(rgbRegex); 
  const a = returnExportValue(condFillOpacity, fillOpacity, importFillOpacity, defaultFillOpacity);
  return `rgba(${Number(r)}, ${Number(g)}, ${Number(b)}, ${Number(a)})`;
}

const returnExportPointColor = (exportCondition, importedVal, defaultObj, pointColor, pointOpacity) => {
  const { pointColor : condPointColor } = exportCondition;
  const {
    pointColor: importPointColor,
    pointOpacity: importPointOpacity
  } = importedVal;
  const {
    pointColor: defaultPointColor,
    fillOpacity: defaultPointOpacity
  } = defaultObj;
  const colorVal = returnExportValue(condPointColor, pointColor, importPointColor, defaultPointColor);
  const rgb = getFillColor(colorVal);
  const rgbRegex = /[\d+|\.|\s]+(?=,|\))/g;
  const match = rgb.match(rgbRegex);
  if (!match) return rgb;
  const [r, g, b] = rgb.match(rgbRegex); 
  const a = returnExportValue(null, pointOpacity, importPointOpacity, defaultPointOpacity);
  return `rgba(${Number(r)}, ${Number(g)}, ${Number(b)}, ${Number(a)})`;
}

const returnExportValue = (cond, redux, imp, def) => {
  if (cond) return cond;
  if (redux !== null) return redux;
  if (imp) return imp;
  return def;
}

const ExportDialog = ({setDialogOpen, dialogOpen}) => {
  const { files } = useSelector(state => state.spreadsheetReducer);
  const classes = useStyles();
  const [exportFilename, setExportFilename] = useState('MGIS-Data')
  const dispatch = useDispatch();
  const canExport = files.filter(file => file.export).length > 0;

  const handleClose = () => {
    setDialogOpen(false);
  }

  const handleCheckChange = event => {
    dispatch(actionSheetFileUpdated({
      id: Number(event.target.name),
      export: event.target.checked
    }));
  }

  const handleExportSubmit = () => {
    setDialogOpen(false);
    const jsonArray = [];
    files.filter(file => file.export).forEach(file => {
      const exportJson = formatExportJson(file);
      jsonArray.push(exportJson);
      dispatch(actionSheetFileUpdated({
        id: file.id,
        export: false,
      }))
    });
    const mergedJson = mergeJson(jsonArray);
    fileFromJson(mergedJson, exportFilename);
    trackEvent({
      category: SPREADSHEET,
      action: 'Export'
    });
  }

  const handleExportCancel = () => {
    setDialogOpen(false);
    files.filter(file => file.export).forEach(file => {
      dispatch(actionSheetFileUpdated({
        id: file.id,
        export: false,
      }));
    })
  }

  return (
    <Dialog
      onClose={handleClose}
      open={dialogOpen}
    >
      <DialogTitle>Export Spreadsheet Files</DialogTitle>
      <FormControl classes={{root: classes.control}}>
        <TextField
          classes={{root: classes.text}}
          id="filename"
          label="Filename"
          value={exportFilename}
          onChange={e => setExportFilename(e.target.value)}
        />
        <FormLabel>Layer Data</FormLabel>
        <FormGroup>
          {files.map(file => {
            return (
              <FormControlLabel 
                key={file.id}
                checked={file.export}
                name={String(file.id)}
                onChange={handleCheckChange}
                control={<Checkbox />}
                label={file.fileName}
                />
            );
          })}
        </FormGroup>
      </FormControl>
      <Button
        disabled={!canExport}
        onClick={handleExportSubmit}
      >Submit</Button>
      <Button
        onClick={handleExportCancel}
      >Cancel</Button>
    </Dialog>
  );
}

export default ExportDialog;