import { createAction, handleActions } from 'redux-actions';

export const initialState = {
  // MapviewHistory component
  historyMax: 20,
  mapViewHistory: [],
  mapViewCurrentIndex: 0,
  mapViewIgnore: false,
}

export const actionMapviewHistoryUpdated = createAction('MISC_MAPVIEW_HISTORY_UPDATED');
export const actionMapviewCurrentIndexUpdated = createAction('MISC_MAPVIEW_CURRENT_INDEX_UPDATED');
export const actionMapviewIgnoreUpdated = createAction('MISC_MAPVIEW_IGNORE_UPDATED');

export const actionUpdateMapviewHistory = (mapview) => {
  return (dispatch, getState) => {
    const { mapViewHistory, historyMax, mapViewIgnore } = getState().miscReducer;
    if (mapViewIgnore) {
      dispatch(actionMapviewIgnoreUpdated(false));
      return;
    }

    let newHistory = [...mapViewHistory, mapview];
    newHistory = newHistory.length > historyMax ? newHistory.slice(newHistory.length - historyMax) : newHistory;
    dispatch(actionMapviewHistoryUpdated(newHistory));
    dispatch(actionMapviewCurrentIndexUpdated(newHistory.length - 1));
  }
} 

export const miscReducer = handleActions({
  [actionMapviewHistoryUpdated]: (state, action) => {
    return { ...state, mapViewHistory: action.payload };
  },
  [actionMapviewCurrentIndexUpdated]: (state, action) => {
    return { ...state, mapViewCurrentIndex: action.payload };
  },
  [actionMapviewIgnoreUpdated]: (state, action) => {
    return { ...state, mapViewIgnore: action.payload };
  },
}, initialState);