import React, { useState } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import CircularProgress from '@material-ui/core/CircularProgress';
import Radio from '@material-ui/core/Radio';
import { useDispatch, useSelector } from "react-redux";
import { actionInfoBulkDownload, actionInfoDownloadFormatUpdated } from './InfoRedux';
import { INFO, trackEvent } from '../../tracking';

export default function DownloadDialog({ open, onClose, bldrecnbr, floor, name }) {
  const titleStyle = { backgroundColor: 'rgba(0, 0, 0, 0.05)'}
  const progressStyle = { position: 'absolute', top: '50%', left: '45%' };
  const itemStyle = { textAlign: 'center' };

  const fileDownloadFormat = useSelector(state => state.infoReducer.fileDownloadFormat);
  const dispatch = useDispatch();
  const floorStr = floor ? `(Floor ${floor})` : '';
  const [isLoading, setIsLoading] = useState(false);  

  const handleClose = () => {
    setIsLoading(false);
    onClose();
  };

  const handleDownloadClick = (downloadType, buildFloor) => () => {
    setIsLoading(true);
    dispatch(actionInfoBulkDownload(downloadType, bldrecnbr, buildFloor, handleClose));
    trackEvent({
      category: INFO,
      action: 'Download',
      label: downloadType,
    });
  }

  const handleRadioChange = event => {
    dispatch(actionInfoDownloadFormatUpdated(event.target.value));
  }

  return (
    <Dialog onClose={handleClose} aria-labelledby="data-download" open={open}>
      <DialogTitle style={titleStyle}>Data Download - {name} {floorStr}</DialogTitle>
      <DownloadFormatInput fileDownloadFormat={fileDownloadFormat} handleRadioChange={handleRadioChange} />
      <List>
        <ListItem button onClick={handleDownloadClick('room', floor)} disabled={isLoading}>
          <ListItemText primary={'Room Data'} style={itemStyle} />
        </ListItem>
        <ListItem button onClick={handleDownloadClick('perrol', floor)} disabled={isLoading} >
          <ListItemText primary={'Occupant Data'} style={itemStyle} />
        </ListItem>
        <ListItem button onClick={handleDownloadClick('occdept', floor)} disabled={isLoading} >
          <ListItemText primary={'Department Data'} style={itemStyle} />
        </ListItem>
      </List>
      {isLoading && <CircularProgress style={progressStyle} />}
    </Dialog>
  );
}

function DownloadFormatInput({ fileDownloadFormat, handleRadioChange }) {
  const style = { 'textAlign': 'center' };

  return (
    <div style={style}>
      <label htmlFor="file-format-csv">CSV</label>
      <Radio
        checked={fileDownloadFormat === 'csv'}
        onChange={handleRadioChange}
        value="csv"
        name="radio-button-demo"
        inputProps={{ 'aria-label': 'CSV format', 'id': 'file-format-csv' }}
        color="primary"
      />
      <label htmlFor="file-format-xlsx">Excel</label>
      <Radio
        checked={fileDownloadFormat === 'xlsx'}
        onChange={handleRadioChange}
        value="xlsx"
        name="radio-button-demo"
        inputProps={{ 'aria-label': 'Excel format', 'id': 'file-format-xlsx'}}
        color="primary"
      />
    </div>
  );
}