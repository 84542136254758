import React, { useState, useEffect } from 'react';
import { useDispatch } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import CardActions from '@material-ui/core/CardActions';
import _ from 'lodash';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { actionMapZoomFeaturesUpdated } from '../../Map';
import { actionAppBarFloorUpdated } from '../../AppBar';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  root: {
    maxHeight: 130,
    padding: '8px 0',
    borderTop: '1px solid rgba(0,0,0,0.1)',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      webkitAppearance: 'none',
      width: 7,
      backgroundColor: 'rgba(0, 0, 0, 0.1)',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 2,
      backgroundColor: 'rgba(0, 0, 0, .5)',
      webkitBoxShadow: '0 0 1px rgba(255, 255, 255, .5)',
    }
  },
  gridContent: {
    alignSelf: 'center',
    lineHeight: '25px',
    height: 25,
    '& .MuiTypography-overline': {
      lineHeight: '25px',
      height: 25,
      fontSize: '0.65rem',
    }
  },
  accordionBtn: {
    padding: '2px 3px',
    minWidth: 0,
  },
  compact: {
    padding: 8,
  },
  cardActionRoot: {
    padding: 0,
  },
  row: {
    lineHeight: 1,
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
      color: 'black',
    }
  },
  rowActive: {
    backgroundColor: '#4a53b1',
    color: 'white',
    '& .MuiTypography-overline': {
      fontWeight: 'bold',
    }
  }
}));

export default function BuildingDisplay({ id, rooms, sortBy, sortDirection }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const rows = roomsToRows(id, rooms);
  const buildings = sortRows(rows, sortBy, sortDirection);
  const [firstRow] = buildings;

  const zoom = (floor, rooms) => {
    dispatch(actionMapZoomFeaturesUpdated({ type: 'rrn', data: rooms.map(room=>room.rmrecnbr) }));
    dispatch(actionAppBarFloorUpdated(floor));
  }

  const [selectedIndex, setSelectedIndex] = useState(0);
  const onSelect = (index, floor, rooms) => {
    setSelectedIndex(index);
    zoom(floor, rooms);
  }

  useEffect(() => {
    firstRow && zoom(firstRow.floor, firstRow.rooms);
  }, []);

  return (
    <div className={classes.root}>
      {
        buildings.map((building, index) => {
          return <Building
                    key={building.id}
                    data={building}
                    index={index}
                    isSelected={index === selectedIndex}
                    onSelect={onSelect}
                  />
        })
      }
    </div>
  );
}

function Building({ data, isSelected, onSelect, index }) {
  const classes = useStyles();
  const onClick = () => { onSelect(index, data.floor, data.rooms) }

  return (
    <CardActions disableSpacing classes={{root: classes.cardActionRoot}}>
      <Grid
        container
        align="center"
        justifyContent="center"
        className={clsx({[classes.rowActive]: isSelected}, classes.row)}
        onClick={onClick}
      >
        <Grid item xs={4} className={classes.gridContent} >
          <Typography variant="overline">
            {data.building}
          </Typography>
        </Grid>
        <Grid item xs={4}  className={classes.gridContent} >
          <Typography variant="overline">
            {data.floor}
          </Typography>
        </Grid>
        <Grid item xs={4}  className={classes.gridContent} >
          <Typography variant="overline">
            {`${numberWithCommas(data.floorSqft)} sqft`}
          </Typography>
        </Grid>
      </Grid>
    </CardActions>
  ); 
}

function roomsToRows(groupId, rooms) {
  const byBuildings = _.groupBy(rooms, 'bld_descrshort');
  let data = [];

  for (let building in byBuildings) {
    const brn = byBuildings[building].length ? byBuildings[building][0]['bldrecnbr'] : '';
    const byFloor = _.groupBy(byBuildings[building], 'floor');

    for (let floor in byFloor) {
      const id = `${groupId}-${building}-${floor}`;
      const floorSqft = byFloor[floor].reduce((acc, room) => {
        return acc + room.rmsqrft
      }, 0);
      const rooms = _.sortBy(byFloor[floor], ['rmnbr']);
      data.push({ id, building, brn, floor, floorSqft, rooms });
    }
  }
  return _.sortBy(data, ['building', 'floor']);
}

function sortRows(rows, sortBy, sortDirection) {
  if (sortBy === 'building' && sortDirection === 'ascending') {
    return _.orderBy(rows, ['building','floor'], ['asc', 'asc']);
  } else if (sortBy === 'building' && sortDirection === 'descending') {
    return _.orderBy(rows, ['building','floor'], ['desc', 'asc']);
  } else if (sortBy === 'sqft' && sortDirection === 'ascending') {
    return _.orderBy(rows, ['floorSqft'], ['asc']);
  } else if (sortBy === 'sqft' && sortDirection === 'descending') {
    return _.orderBy(rows, ['floorSqft'], ['desc']);
  }

  return rows;
}

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}