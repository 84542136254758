import {
  actionMapDrawModeUpdated,
  actionMapCursorUpdated,
} from './MapRedux';
import { actionDrawUpdateGroupFeature } from '../Draw';
import {
  addPropertiesToDrawFeatures,
  addIntersectBuilding,
  addMarkersForPointFeatures,
  updateMarkersForPointFeatures,
} from './maputils';

export const mapDrawCreateHandler = (map, dispatch) => (event) => {
  if (!event || !event.features) { return }
  const newFeatures = addPropertiesToDrawFeatures(map, event.features);
  addMarkersForPointFeatures(map, newFeatures);
  dispatch(actionDrawUpdateGroupFeature('add', newFeatures));
}

export const mapDrawUpdateHandler = (map, dispatch) => (event) => {
  if (!event || !event.features) { return }
  const updatedFeatures = addIntersectBuilding(map, event.features);
  updateMarkersForPointFeatures(map, updatedFeatures);
  dispatch(actionDrawUpdateGroupFeature('update', updatedFeatures));
}

export const mapDrawModeChangeHandler = (map, dispatch) => (event) => {
  if (!map) { return; }
  const { mode } = event;
  const cursor = !mode || mode === 'simple_select' ? false : true;

  dispatch(actionMapDrawModeUpdated({mode}));
  dispatch(actionMapCursorUpdated({drawMode: cursor}))
}