import axios from 'axios';
import { createAction, handleActions } from 'redux-actions';
import { roomsToRrns, actionDataSelectFeatureRequested } from '../Data';

const CUSTOM_LAYER_URL = process.env.REACT_APP_CUSTOM_LAYER_URL ? process.env.REACT_APP_CUSTOM_LAYER_URL : "https://vj1fegsup4.execute-api.us-east-1.amazonaws.com/dev";

export const initialState = {
    restroomLayerExpanded: false,
    listVisible: false,
    selectedRoom: null,
    restroomData: null,
};

export const actionCustomLayerRestroomExpandToggled = createAction('CUSTOM_LAYER_EXPAND_TOGGLED');
export const actionCustomLayerListToggled = createAction('CUSTOM_LAYER_LIST_TOGGLED');
export const actionCustomLayerSelectedRoomUpdated = createAction('CUSTOM_LAYER_SELECTED_ROOM_UPDATED');
export const actionCustomLayerRestroomDataDownloaded = createAction('CUSTOM_LAYER_RESTROOM_DATA_DOWNLOADED');

export const actionCustomLayerDownloadRestroomData = (idToken) => {
  return async (dispatch, getState) => {
    const url = `${CUSTOM_LAYER_URL}/restrooms/all`;
    const headers = { "Authorization": `Bearer ${idToken}` };
    const opt = { headers };
    const response = await axios.get(url, opt);

    if (response.status === 200 && response.data?.results?.length) {
        const formattedData = formatRestroomData(response.data.results);
        const restroomData = populateRestroomLayerAttributes(formattedData);
        dispatch(actionCustomLayerRestroomDataDownloaded(restroomData));
        // highlight rooms on map
        dispatch(actionDataSelectFeatureRequested({
            type: 'room',
            data: roomsToRrns(restroomData.rooms),
            layerId: restroomData.colorLayerId 
        })); 

    } else {
        console.log('failed to download restroom data');
    }
  }
}

export const customLayerReducer = handleActions({
    [actionCustomLayerRestroomExpandToggled]: (state, action) => {
        const restroomLayerExpanded = action.payload === null || action.payload === undefined ? !state.isExpanded : action.payload;
        console.log('restroomLayerExpanded', restroomLayerExpanded)
        return {...state, restroomLayerExpanded};
    },
    [actionCustomLayerListToggled]: (state, action) => {
        const listVisible = action.payload === null || action.payload === undefined ? !state.listVisible : action.payload;
        return {...state, listVisible};
    },
    [actionCustomLayerSelectedRoomUpdated]: (state, action) => {
        const selectedRoom = action.payload && action.payload.length ? action.payload[0] : null;
        return {...state, selectedRoom};
    },
    [actionCustomLayerRestroomDataDownloaded]: (state, action) => {
        return {...state, restroomData: action.payload};
    }
}, initialState);

const formatRestroomData = rawData => {
    // group by rmrecnmbrs
    const rooms = {};
    // get all fields and values for filtering
    const filters = {};

    rawData.forEach(room => {
        const {roomAttributes, filterAttributes} = seperateCustomField(room)

        // group by rrn
        if (rooms[room.rmrecnbr]) {
            // todo: check if restroom attribute already exists, if so get the latest entry
            rooms[room.rmrecnbr] = {...rooms[room.rmrecnbr], ...roomAttributes}
        } else {
            rooms[room.rmrecnbr] = roomAttributes;
        }

        // populate filter field
        filterAttributes.forEach(filter => {
            const {field, value} = filter;
            if (filters[field]) {
                filters[field].indexOf(value) === -1 && filters[field].push(value)
            } else {
                filters[field] = [value];
            }
        })
    });

    return {
        rooms: Object.values(rooms),
        filterList: filters
    }
}

const seperateCustomField = customFieldRoom => {
    const { rmattr_fname, rmattr_ftype, rmattr_value, last_updated_by, last_update_date, ...room } = customFieldRoom;
    
    const roomAttributes = {
        ...room,
        // [`restroom_${rmattr_fname.toLowerCase()}`]: rmattr_value,
        // [`restroom_${rmattr_fname.toLowerCase()}_field_type`]: rmattr_ftype,
        // [`restroom_${rmattr_fname.toLowerCase()}_update_date`]: last_update_date,
        // [`restroom_${rmattr_fname.toLowerCase()}_update_by`]: last_updated_by,
        [`${rmattr_fname.toLowerCase()}`]: rmattr_value,
        [`${rmattr_fname.toLowerCase()}_field_type`]: rmattr_ftype,
        [`${rmattr_fname.toLowerCase()}_update_date`]: last_update_date,
        [`${rmattr_fname.toLowerCase()}_update_by`]: last_updated_by,
    };
    

    const filterAttributes = [
        {field: rmattr_fname.toLowerCase(), value: rmattr_value},
        {field: 'rmnbr', value: room.rmnbr},
        {field: 'rmrecnbr', value: room.rmrecnbr},
        {field: 'bld_descrshort', value: room.bld_descrshort},
        {field: 'bldrecnbr', value: room.bldrecnbr}
    ]

    return {roomAttributes, filterAttributes}
}

const populateRestroomLayerAttributes = (data) => {

    return {
        id: guidGenerator(),
        name: 'Restroom Layer',
        rooms: data?.rooms || [],
        filters: [],
        filterList: data?.filterList || [],
        layerVisible: true,
        loading: false,
        color: '#e1bee7',
        colorLayerId: 'umich-room-select-5',
        groupBy: "building-floor",
        sortBy: "building",
        sortDirection: "ascending"
    }
}

// https://stackoverflow.com/questions/6860853/generate-random-string-for-div-id
const guidGenerator = () => {
    var S4 = function() {
       return (((1+Math.random())*0x10000)|0).toString(16).substring(1);
    };
    return (S4()+S4()+"-"+S4()+"-"+S4()+"-"+S4()+"-"+S4()+S4()+S4());
}