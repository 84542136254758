import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Slider from '@material-ui/core/Slider';

const useStyles = makeStyles(theme => ({
  slider: {
    height: '30vh',
    boxShadow: 'none',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'whitesmoke',
    [theme.breakpoints.down('xs')]: {
      display: 'none !important',
    },
  }
}));

export default function ZoomSlider({ zoom, handleChange }) {
  const classes = useStyles();

  return (
    <Paper className={classes.slider} >
      <Slider
        orientation="vertical"
        value={zoom}
        onChange={handleChange}
        min={0}
        max={24}
      />            
    </Paper>
  );
}