import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import GetAppIcon from '@material-ui/icons/GetApp';
import DownloadDialog from './DownloadDialog';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Tooltip from '@material-ui/core/Tooltip';
import { actionInfoBuildingActionBtnToggled } from './InfoRedux';
import SingleBuildingMode from './SingleBuildingMode';
import Typography from '@material-ui/core/Typography';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import PDFDownloadDialog from './PDFDownloadDialog';
import { actionLayerSingleBuildingModeUpdated } from '../Layer';
import { actionAddAlert, actionDeleteAlert } from '../Alert';

const useStyles = makeStyles(theme => ({
  btn: {
    margin: 2,
  },
  moreBtn: {
    position: 'absolute',
    top: 8,
    right: 0
  }
}));

export default function BuildingActions({ building }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    bld_descrshort,
    bldrecnbr,
  } = building;

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => { setAnchorEl(event.currentTarget) }
  const handleClose = () => { setAnchorEl(null) }

  const [downloadVisible, setDownloadVisible] = useState(false);
  const handleDownloadClick = () => {
    setDownloadVisible(true);
    handleClose();
  };
  const handleDownloadClose = () => { setDownloadVisible(false) };

  const quitMode = () => {
    dispatch(actionLayerSingleBuildingModeUpdated(null));
    dispatch(actionDeleteAlert('sbv'));  
  }
  const singleBuildingMode = useSelector(state => state.layerReducer.singleBuildingMode);
  const checked = !!singleBuildingMode && !!singleBuildingMode.bldrecnbr;

  const singleBuildingModeClickHandler = () => {
    if (checked) {
      dispatch(actionLayerSingleBuildingModeUpdated(null));
      dispatch(actionDeleteAlert('sbv'));
    } else {
      dispatch(actionLayerSingleBuildingModeUpdated(bldrecnbr));
      dispatch(actionAddAlert({
        id: 'sbv',
        title: 'Single Building View',
        buttonLabel: 'QUIT',
        onClick: quitMode,
        onClose: false
      }));
    }
  }

  const [pdfDownloadVisible, setPdfDownloadVisible] = useState(false);
  const handlePdfDownloadClick = () => {
    setPdfDownloadVisible(true);
    handleClose();
  };
  const handlePdfDownloadClose = () => { setPdfDownloadVisible(false) };

  return (
    <React.Fragment>
      <Tooltip title="More Options">
        <IconButton size="small" className={classes.moreBtn} onClick={handleClick}>
          <MoreVertIcon />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleDownloadClick}>
          <ListItemIcon><GetAppIcon fontSize="small" /></ListItemIcon>
          <Typography variant="overline">Data</Typography>    
        </MenuItem>
        <MenuItem onClick={handlePdfDownloadClick}>
          <ListItemIcon><PictureAsPdfIcon fontSize="small" /></ListItemIcon>
          <Typography variant="overline">Floor Plan</Typography>    
        </MenuItem>
        <MenuItem onClick={singleBuildingModeClickHandler}>
          <ListItemIcon>
            {
              checked ?
                <CheckBoxIcon fontSize="small" /> :
                <CheckBoxOutlineBlankIcon fontSize="small" />
            }
          </ListItemIcon>
          <Typography variant="overline">Single Building</Typography>   
        </MenuItem>
      </Menu>
      <DownloadDialog
        open={downloadVisible}
        onClose={handleDownloadClose}
        bldrecnbr={bldrecnbr}
        name={bld_descrshort}
      />
      <PDFDownloadDialog
        open={pdfDownloadVisible}
        onClose={handlePdfDownloadClose}
        bldrecnbr={bldrecnbr}
        name={bld_descrshort}
      />
    </React.Fragment>
  );
}

export function BuildingActionsLegacy({ building }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    bld_descrshort,
    bldrecnbr,
  } = building;

  const buildingActionBtnExpanded = useSelector(state => state.infoReducer.buildingActionBtnExpanded);
  const handleExpand = () => { dispatch(actionInfoBuildingActionBtnToggled()) }

  const [downloadVisible, setDownloadVisible] = useState(false);
  const handleDownloadClick = () => { setDownloadVisible(true) };
  const handleDownloadClose = () => { setDownloadVisible(false) };

  return (
    <div>
      <Button
        variant="outlined"
        color="default"
        size="small"
        className={classes.btn}
        onClick={handleDownloadClick}
        startIcon={<GetAppIcon />}
      >
        Download
      </Button>
      <DownloadDialog
        open={downloadVisible}
        onClose={handleDownloadClose}
        bldrecnbr={bldrecnbr}
        name={bld_descrshort}
      />
      <span style={{ display: buildingActionBtnExpanded ? 'inline' : 'none'}}>
        <SingleBuildingMode bldrecnbr={bldrecnbr} />
      </span>
      <IconButton size="small" onClick={handleExpand}>
        { buildingActionBtnExpanded ? <ChevronLeftIcon /> : <ChevronRightIcon /> }
      </IconButton>
    </div>  
  );
}
