import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { formatDefaultDepartmentData } from './SearchRedux';
import { searchQuery } from './searchApi';
import MenuDialog from '../Shared/MenuDialog';
import AutocompleteMenu from '../Shared/AutocompleteMenu';
import { SEARCH, trackEvent } from '../../tracking';

async function queryDept(queryStr, idToken) {
  const queries = queryStr.split(' ');
  const responseArrays = await Promise.all(queries.map(async (q) => {
    if (!q) return [];
    const response = await searchQuery('/supersearch/' + q, idToken);
    const isValidResponse = !response.error && response.data && 
      ((response.data.deptid && response.data.deptid.length) ||
      (response.data.dept_descr && response.data.dept_descr.length));

    if (!isValidResponse) { return [] }
    const deptids = response.data.deptid ? response.data.deptid : [];
    const deptdescrs = response.data.dept_descr ? response.data.dept_descr : [];
    return formatDefaultDepartmentData([...deptids, ...deptdescrs]);
  }));

  const flatResponseArray = responseArrays.flat();
  // remove duplicates (by department record number)
  const uniqueResponseArray = flatResponseArray.reduce((prev, curr) => {
    const existingIndex = prev.findIndex(x => x.deptid === curr.deptid)
    // if exists update matches deptid
    if (existingIndex !== -1) {
      prev[existingIndex].responses = prev[existingIndex].responses + 1;
    }
    // add if not in list
    else {
      prev.push({
        responses: 1,
        ...curr
      });
    }

    return prev;
  }, []);

  function departmentSort(a,b) {
    // check multiple matches
    if (a.responses !== b.responses) return b.responses - a.responses;
    
    // check is exact
    if (b.isExact && !a.isExact) return 1;
    if (a.isExact && !b.isExact) return -1;
    
    // check if queries start string
    const bStart = queries.some(q => (
      b.deptid.indexOf(q) === 0 ||
      b.dept_descr.indexOf(q) === 0
    ));
  
    const aStart = queries.some(q => (
      a.deptid.indexOf(q) === 0 ||
      a.dept_descr.indexOf(q) === 0
    ));
  
    if (bStart && !aStart) return 1;
    if (aStart && !bStart) return -1;
  
    return 0;
  }

  return uniqueResponseArray.sort(departmentSort);
}

function DepartmentAutocomplete({ open, selectHandler }) {
  const optionDisplay = {
    selected: ['dept_descr', 'deptid'],
    label: 'dept_descr',
    labelId: 'deptid',
    labelContext: 'dept_grp_descr',
  };

  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const defaultData = useSelector(state => state.searchReducer.defaultData.departments);
  const idToken = useSelector(state => state.authReducer.idToken);

  useEffect(() => {
    setOptions(defaultData);
  }, [defaultData]);

  const onInputChange = async (input) => {
    if (input === '') {
      setOptions(defaultData || []);
    } else if (input.length >= 2) {
      setLoading(true);
      const rooms = await queryDept(input, idToken);
      setLoading(false);
      setOptions(rooms);
      trackEvent({
        category: SEARCH,
        action: 'Department',
        label: input,
      })
    } 
  }

  return (
    <AutocompleteMenu
      open={open}
      loading={loading}
      selectHandler={selectHandler}
      inputLabel='Department'
      optionDisplay={optionDisplay}
      options={options}
      onInputChange={onInputChange}
    />
  );
}

export default function DepartmentMenu({ visible, selectHandler, onCloseHandler, offset }) {
  return (
    <MenuDialog
      offset={offset}
      visible={visible}
      selectHandler={selectHandler}
      onCloseHandler={onCloseHandler}
      menuType='department'
    >
      <DepartmentAutocomplete />
    </MenuDialog>
  );
}