import React from 'react';
import RoomInfo from './RoomInfo';
import BuildingInfo from './BuildingInfo';

export default function InfoCards({ dataItems }) {

  return (
    <React.Fragment>
      {dataItems.map(data=>renderCard(data))}
    </React.Fragment>
  );
}

function renderCard(data) {
  if (data.type === 'building') {
    return <BuildingInfo building={data} key={data.id || data.bldrecnbr}></BuildingInfo>;
  } else if (data.type === 'room') {  
    return <RoomInfo room={data} key={data.id || data.rmrecnbr}></RoomInfo>;
  }
}