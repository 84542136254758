import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { roomDataFormatter } from './SearchRedux';
import { searchQuery } from './searchApi';
import MenuDialog from '../Shared/MenuDialog';
import AutocompleteMenu from '../Shared/AutocompleteMenu';
import { trackEvent, SEARCH } from '../../tracking';

async function queryRoom(queryStr, idToken) {
  const queries = queryStr.split(' ');
  const responseArrays = await Promise.all(queries.map(async (q) => {
    if (!q) return [];
    const response = await searchQuery('/supersearch/' + q, idToken);
    const isValidResponse = !response.error && response.data && 
                            ((response.data.rmnbr && response.data.rmnbr.length) ||
                            (response.data.rmrecnbr && response.data.rmrecnbr.length));
    if (!isValidResponse) { return [] }
    const rmnbrs = response.data.rmnbr ? response.data.rmnbr : [];
    const rmrecnbrs = response.data.rmrecnbr ? response.data.rmrecnbr : [];
    const rooms = [...rmnbrs, ...rmrecnbrs];
    return roomDataFormatter(rooms);
  }));  
  return responseArrays.flat();
}

function RoomAutocomplete({ open, selectHandler }) {
  const optionDisplay = {
    selected: ['rmnbr', 'rmrecnbr', 'bld_descrshort'],
    label: 'rmnbr',
    labelId: 'rmrecnbr',
    labelContext: 'bld_descrshort',
  };
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const defaultData = useSelector(state => state.searchReducer.defaultData.rooms);
  const idToken = useSelector(state => state.authReducer.idToken);
  useEffect(() => {
    setOptions(defaultData);
  }, [defaultData]);

  const onInputChange = async (input) => {
    if (input === '') {
      setOptions(defaultData || []);
    } else if (input.length >= 2) {
      setLoading(true);
      const rooms = await queryRoom(input, idToken);
      setLoading(false);
      setOptions(rooms);
      trackEvent({
        category: SEARCH,
        action: 'Room',
        label: input,
      });
    } 
  }

  return (
    <AutocompleteMenu
      open={open}
      loading={loading}
      selectHandler={selectHandler}
      inputLabel='Room'
      optionDisplay={optionDisplay}
      alwaysShowContext={true}
      options={options}
      onInputChange={onInputChange}
    />
  );
}

export default function RoomMenu({ visible, selectHandler, onCloseHandler, offset }) {
  return (
    <MenuDialog
      offset={offset} 
      visible={visible}
      selectHandler={selectHandler}
      onCloseHandler={onCloseHandler}
      menuType='room'
    >
      <RoomAutocomplete />
    </MenuDialog>
  );
}
