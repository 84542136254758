import { createAction, handleActions } from 'redux-actions';

export const initialState = {
  isExpanded: false,
  printSize: '',
};

export const actionPrintExpandToggled = createAction('PRINT_EXPAND_TOGGLED');
export const actionPrintSizeUpdated = createAction('PRINT_SIZE_UPDATED');

export const printReducer = handleActions({
  [actionPrintExpandToggled]: (state, action) => {
    const isExpanded = action.payload === null || action.payload === undefined ? !state.isExpanded : action.payload;
    return {...state, isExpanded };
  },
  [actionPrintSizeUpdated]: (state, action) => {
    return {...state, printSize: action.payload };
  },
}, initialState);