import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'fixed',
    visibility: 'hidden',
    pointerEvents: 'none',
    zIndex: -1 * theme.zIndex.modal,
    margin: 0,
    right: 0,
    bottom: 0,
    top: 0,
    left: 0,
  },
  visible: {
    position: 'fixed',
    visibility: 'visible',
    pointerEvents: 'auto',
    zIndex: theme.zIndex.modal,
    borderRight: '1px solid rgba(128, 128, 128, 0.5)',
  },
  defaultSize: {
    maxHeight: '100vh',
    width: '50vw',
    minWidth: 335,
    maxWidth: 600,
    [theme.breakpoints.down('md')]: {
      width: '80vw',
    },
    [theme.breakpoints.down('sm')]: {
      width: '90vw',
    },
    [theme.breakpoints.down('xs')]: {
      width: '95vw',
    },
  },
  fullscreenSize: {
    width: '100vw',
    height: '100vh'
  },
  floatRight: {
    marginLeft: 'auto',
  },
  closeIcon: {
    fontSize: 28
  }
}));

export default function Pane({ children, visible, fullscreen, onClose }) {
  const classes = useStyles();
  const rootClassNames = clsx(classes.root, classes.defaultSize, {
    [classes.visible]: visible,
    [classes.fullscreenSize]: fullscreen
  });
  const handleClose = () => {
    onClose && onClose();
  }

  return (
    <Card className={rootClassNames}>
      <CardActions disableSpacing>
        <IconButton size="small" className={classes.floatRight} onClick={handleClose}>
          <CloseIcon className={classes.closeIcon}/>
        </IconButton>
      </CardActions>
      <CardContent>
        {children}
      </CardContent>
    </Card>
  );
}