import React, { useRef, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";

import { makeStyles } from '@material-ui/core/styles';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Drawer from '@material-ui/core/Drawer';
import Input from '@material-ui/core/Input';
import List from '@material-ui/core/List';

import UploadListItem from './UploadListItem';
import { 
  actionSheetUploadToggled,
  selectorSheets,
  actionSheetFileUploaded,
  actionSheetFileUpdated,
} from '.';
import { 
  getNextSheetId,
  wbFromFile,
  defaultSheetFile,
  reduxSheetFile,
  jsonFromWb,
  guessRrnHeaderFromJson,
  guessLngLatHeaderFromJson,
  guessSheetDataType,
} from './spreadsheetutils';
import { SPREADSHEET, trackEvent } from '../../tracking';

const useStyles = makeStyles(theme => ({
  drawer: {
    width: '100vw',
    minWidth: 335,
    // maxWidth: 600,
    height: '100vh',
    [theme.breakpoints.up('md')]: {
      width: '80vw',
    },
    [theme.breakpoints.down('sm')]: {
      width: '90vw',
    },
    [theme.breakpoints.down('xs')]: {
      width: '95vw',
    }
  },
  topBar: {
    width: '100%',
    display: 'flex',
    zIndex: 99,
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  bottomBar: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  mBox: {
    backgroundColor: '#00274C',
    color: '#FFCB05',
    '&:hover': { 
      color: '#00274C',
      backgroundColor: '#FFCB05'
    },
  },
  appBar: {
    top: 'auto',
    bottom: 0,
  },
  grow: {
    flexGrow: 1,
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  button: {
    margin: '10px'
  }

}));

export default function Upload() {
  const isVisible = useSelector(state => state.spreadsheetReducer.uploadOpen);
  const dispatch = useDispatch();
  const classes = useStyles();
  const inputRef = useRef(null);
  const files = useSelector(selectorSheets);
  const nextId = getNextSheetId(files);

  const toggleUpload = () => {
    dispatch(actionSheetUploadToggled())
    files.filter(file => file.status !== 'complete').forEach(file => {
      dispatch(actionSheetFileUpdated({
        id: file.id,
        status: "centerMap",
      }))
    })
  };

  useEffect(() => {
    if (
      files.length > 0 && 
      files.filter(file => file.status !== 'complete').length > 0 &&
      files.filter(file => file.status !== 'complete').every(file => file.status === 'dismissUpload')
      )
    {
      toggleUpload();
    }
  }, [files])

  const handleFile = (e) => {
    const files = [...e.target.files];
    files.forEach((file, index) => {
      const id = nextId + index;
      const fileName = file.name;
      // dispatch upload with empty file
      dispatch(actionSheetFileUploaded({
        ...defaultSheetFile(id),
        ...{id, fileName, status: 'preupload'}
      }));
      (async (file) => {
        const workbook = await wbFromFile(file);
        const json = jsonFromWb(workbook);
        const rmrecnbr = guessRrnHeaderFromJson(json);
        const lnglat = guessLngLatHeaderFromJson(json);
        const type = guessSheetDataType(json, rmrecnbr, lnglat);
        const status = type !== undefined ? 'dismissUpload' : 'pickRRN';
        
        dispatch(actionSheetFileUpdated({
          ...{
            id,
            fileName,
            rmrecnbr,
            json,
            lnglat,
            defaultText: rmrecnbr,
            type,
            status
          },
          ...reduxSheetFile(id),
        }));
        trackEvent({
          category: SPREADSHEET,
          action: 'Import',
          label: 'Dialog',
        });
      })(file);
    });
  };

  return (
    <Drawer
      open={isVisible}
      onClose={toggleUpload}
      anchor="bottom"
      classes={{paperAnchorBottom: classes.drawer}}
    >
      <Container style={{height: '100%'}}>
        <Box className={classes.flexColumn}>
          <Box className={classes.topBar}>
            <Box>
              <Button 
                variant="contained"
                color="primary"
                component="label"
                className={classes.button}
                startIcon={<AttachFileIcon />}
                >
                  Add Local File
                  <Input
                      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      color="primary"
                      type="file"
                      onChange={handleFile}
                      inputRef={inputRef}
                      style={{ display: 'none' }}
                      inputProps={{ multiple: true }}
                      />
              </Button>
            </Box>
            <Box>
              <Button
                variant="contained"
                onClick={toggleUpload}
              >
                Done
              </Button>
            </Box>
          </Box>
          <Box className={classes.grow}>
            <List className={classes.grow}>
              {files.map((file) => {
                return (
                  <UploadListItem
                    key={file.id} 
                    sheetId={file.id}
                  />  
                );
              })}
            </List>
          </Box>
        </Box>
      </Container>
    </Drawer>
  );
};