import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { actionDataSearchRoomsWithUrl } from '../Data';


export default function UrlSearch() {
  const CLEAN_HASH_DELAY = 15000;
  const dispatch = useDispatch();

  // handle query by url
  const idToken = useSelector(state => state.authReducer.idToken);
  const sourceDataLoaded = useSelector(state => state.mapReducer.sourceDataLoaded);
  const [urlParam, setUrlParam] = useState(null);
  const [timeoutId, setSetTimeoutId] = useState(null);
  useEffect(() => {
    function extractHashQueryParam(hashUrl) {
      if (!hashUrl || hashUrl === '#') { return null }
      hashUrl = hashUrl.toLowerCase ? hashUrl.toLowerCase() : hashUrl;

      const re = /&rmtyp=\d{3}&rmsubtyp=\d{2}/gmi;
      let hasRmsubtyp = hashUrl.indexOf('rmsubtyp') !== -1;
      let rmsubtyp;
      if (hasRmsubtyp) {
        rmsubtyp = hashUrl.match(re);
        hashUrl = hashUrl.replaceAll(re, '');
      }

      const parts = hashUrl.replace('#', '').split('&');
      const params = parts.reduce((data, part) => {
        const [key, value] = part.split('=');
        if (data[key]) {
          data[key] = Array.isArray(data[key]) ? [...data[key], value] : [data[key], value]
        } else {
          data[key] = value;
        }
        return data;
      }, {});

      if (hasRmsubtyp) { params.rmsubtyp = rmsubtyp }

      return params;
    }
    
    const hashchangeHandler = () => {
      const params = extractHashQueryParam(window.location.hash);
      if (params && params.feature === 'search') {
        setUrlParam(params);
      }
      window.clearTimeout(timeoutId);
    }

    window.addEventListener('hashchange', hashchangeHandler);
    hashchangeHandler();
    return () => {
      window.removeEventListener('hashchange', hashchangeHandler);
    }
  }, []);

  useEffect(() => {
    if (urlParam && idToken && sourceDataLoaded) {
      console.log(urlParam);
      dispatch(actionDataSearchRoomsWithUrl(urlParam, idToken));
      setUrlParam(null);
      const timeoutId = window.setTimeout(() => {
        const cleanUrl = window.location.href.replace(window.location.hash, '');
        window.history.replaceState(null, null, cleanUrl);
      }, CLEAN_HASH_DELAY);
      setSetTimeoutId(timeoutId);
    }
  }, [urlParam, idToken, sourceDataLoaded, dispatch]);

  return (<></>)
}