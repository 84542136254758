import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import { actionInfoRoomIndexUpdated } from './InfoRedux';
import BasicInfo from './RoomInfoBasic';
import AdditionalInfo from './RoomInfoAdditional';
import { INFO, trackEvent } from '../../tracking';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
  },
  tabs: {
    '& button.MuiTab-root': {
      minWidth: 'auto !important'
    }
  },
}));

export default function RoomInfo({ room }) {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const tabIndex = useSelector(state => state.infoReducer.roomInfoIndex);
  const handleChange = (event, newValue) => { 
    dispatch(actionInfoRoomIndexUpdated(newValue));
    trackEvent({
      category: INFO,
      action: 'Change Tab',
      label: newValue ? 'Additional' : 'Basic'
    });
  };
  const handleChangeIndex = index => { dispatch(actionInfoRoomIndexUpdated(index)) };

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={tabIndex}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="map layer tabs"
          className={classes.tabs}
        >
          <Tab label="BASIC" {...a11yProps(0)} data-testid="info-room-basic-tab"/>
          <Tab label="ADDITIONAL" {...a11yProps(1)} data-testid="info-room-additional-tab"/>
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={tabIndex}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={tabIndex} index={0} dir={theme.direction}>
          <BasicInfo room={room} />
        </TabPanel>
        <TabPanel value={tabIndex} index={1} dir={theme.direction}>
          <AdditionalInfo room={room} />
        </TabPanel>
      </SwipeableViews>
    </div>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <div style={{padding: '16px'}}>{children}</div>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}