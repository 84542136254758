import React from 'react';
import { useDispatch } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import Tooltip from '@material-ui/core/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { actionDataSearchRooms } from '../Data';
import { trackEvent, INFO } from '../../tracking';

// const DELIMITER = '\\n';
const DELIMITER = ';';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTypography-overline': {
      lineHeight: 1.25,
      letterSpacing: '0.03em'
    }
  },
  table: {
    marginBottom: 16,
    lineHeight: 1.1,
  },
  actionIcon: {
    padding: 2,
    marginRight: 2,
    marginTop: -3,
    fontSize: '1rem',
    '& .MuiSvgIcon-root': {
      fontSize: '1rem',
    }
  },
}));

export default function AdditionalInfo({ room }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    bldrecnbr, bld_descrshort,
    falist,
    occlist,
    grantdtlist,
    deptid, dept_descr,
    occdeptlist,
    rmfnclist
  } = room;

  const Occdeptlist = ListSplitter(occdeptlist, DELIMITER);
  const Rmfnclist = ListSplitter(rmfnclist, DELIMITER);
  const Occlist = OccList(occlist, DELIMITER)
  const Falist = FaList(falist, bldrecnbr, bld_descrshort, DELIMITER);
  const Grantdtlist = ListSplitter(grantdtlist, DELIMITER);

  const searchDept = () => {
    const filters = [
      {type: 'building', bldrecnbr, label: bld_descrshort},
      {type: 'department', deptid, label: dept_descr}
    ];
    dispatch(actionDataSearchRooms(filters));
    trackEvent({
      category: INFO,
      action: 'Search',
      label: 'Department'
    });
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={0} className={classes.table}>
        <Grid item xs={12}>
          <Typography variant="overline" style={{fontWeight: 'bold'}}>Survey Dept</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="overline">{dept_descr} ({deptid})</Typography> 
          <Tooltip title={`Search ${dept_descr.toUpperCase()} in ${bld_descrshort}`}>
            <IconButton color="primary" onClick={searchDept} className={classes.actionIcon}><SearchIcon /></IconButton>
          </Tooltip>
        </Grid>
      </Grid>

      <Grid container spacing={0} className={classes.table}>
        <Grid item xs={12}>
          <Typography variant="overline" style={{fontWeight: 'bold'}}>Occupying Dept</Typography>
        </Grid>
        <Grid item xs={12}>
          {Occdeptlist}
        </Grid>
      </Grid>

      <Grid container spacing={0} className={classes.table}>
        <Grid item xs={12}>
          <Typography variant="overline" style={{fontWeight: 'bold'}}>Room Function</Typography>
        </Grid>
        <Grid item xs={12}>
          {Rmfnclist}
        </Grid>
      </Grid>      

      <Grid container spacing={0} className={classes.table}>
        <Grid item xs={12}>
          <Typography variant="overline" style={{fontWeight: 'bold'}}>Occupants</Typography>
        </Grid>
        <Grid item xs={12}>
          {Occlist}
        </Grid>
      </Grid>
       <Grid container spacing={0} className={classes.table}>
        <Grid item xs={12}>
          <Typography variant="overline" style={{fontWeight: 'bold'}}>Faculty Allocation</Typography>
        </Grid>
        <Grid item xs={12}>
          {Falist}
        </Grid>
      </Grid>
      <Grid container spacing={0} className={classes.table}>
        <Grid item xs={12}>
          <Typography variant="overline" style={{fontWeight: 'bold'}}>Grant</Typography>
        </Grid>
        <Grid item xs={12}>
          {Grantdtlist}
        </Grid>
      </Grid>  
    </div>
  );
}

function OccList(list = '', seperator) {
  const btnStyle = {
    padding: 0,
    fontSize: '0.6rem',
    marginLeft: 4,
  };

  const occupants = !list || !list.split ? [] : list
    .split(seperator)
    .map(occ => {
      let tempOcc = occ ? occ.trim() : '';
      let temp = tempOcc ? tempOcc.split('-') : [''];
      let uniqname = temp[0].toLowerCase();
      return {
        label: tempOcc,
        link: `https://mcommunity.umich.edu/person/${uniqname}`
      }
    });

  return (
    <div>
      {
        list && occupants && occupants.length &&
        occupants.map(occ => {
          return (
            <div key={occ.label}>
              <Typography variant="overline">{occ.label}</Typography>
              <IconButton color="primary" style={btnStyle}>
                <a href={occ.link} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faExternalLinkAlt} /></a>
              </IconButton>
            </div>
          )
        })
      }
    </div>
  );
}

function FaList(list = '', bldrecnbr, bld_descrshort, seperator) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const btnStyle = {
    padding: 0,
    fontSize: '0.6rem',
    marginLeft: 4,
    marginRight: 2,
  };

  let tempUniqname;

  const handleTracking = () => {
    trackEvent({
      category: INFO,
      action: 'Click M-Community Link',
    })
  }

  const occupants = !list || !list.split ? []: list
    .split(seperator)
    .map(occ => {
      let tempOcc = occ ? occ.trim() : '';
      let temp = tempOcc ? tempOcc.split('(') : [''];
      let uniqname = temp[0].toLowerCase();
      tempUniqname = uniqname;

      return {
        uniqname,
        label: tempOcc,
        link: `https://mcommunity.umich.edu/person/${uniqname}`,
        search: () => {
          const filters = [
            {type: 'building', bldrecnbr, label: bld_descrshort},
            {type: 'person', person_uniqname: uniqname, label: uniqname}
          ];
          dispatch(actionDataSearchRooms(filters));
          trackEvent({
            category: INFO,
            action: 'Search',
            label: 'Faculty Allocation'
          });
        }
      }
    });

  const isNA = tempUniqname === '*na';

  return (
    <div>
      {
        list && occupants && occupants.length &&
        occupants.map(occ => {
          return (
            <div key={occ.label}>
              <Typography variant="overline">{occ.label}</Typography>
              <IconButton color="primary" style={btnStyle}>
                {!isNA && <a onClick={handleTracking} href={occ.link} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faExternalLinkAlt} /></a>}
              </IconButton>
              <Tooltip title={`Search ${occ.uniqname.toUpperCase()} in ${bld_descrshort}`}>
                  <IconButton color="primary" onClick={occ.search} className={classes.actionIcon}><SearchIcon /></IconButton>
              </Tooltip>
            </div>
          )
        })
      }
    </div>
  );
}

function ListSplitter(list, seperator) {
  return (
    list && list.length && list.split ?
      <div>{list.split(seperator).map(item => <div key={item}><Typography variant="overline">{item}</Typography></div>)}</div> : ''
  );
}