import React from 'react';
import { useDispatch } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import Tooltip from '@material-ui/core/Tooltip';
import { actionAuthSignOutUser } from '../Auth';
import { clearCache } from '../Shared/util';

const useStyles = makeStyles(theme => ({
  root: {
    width: 200,
    padding:10,
    border: '1px rgba(0, 0, 0, 0.2) solid',
  },
  btn: {
    marginLeft: 'auto',
  }
}));

export default function UserMenu() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const anchorRef = React.useRef(null);
  const [expandUser, setExpandUser] = React.useState(false);

  const handleExpandUserClick = () => {
    setExpandUser(!expandUser);
  }

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setExpandUser(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setExpandUser(false);
    }
  }

  const signoutHandler = (event) => {
    dispatch(actionAuthSignOutUser());
    handleClose(event);
  }

  const clearCacheHandler = () => {
    clearCache();
    dispatch(actionAuthSignOutUser());
  }

  return (
      <React.Fragment>
        <Tooltip title="More Options">
          <IconButton ref={anchorRef} onClick={handleExpandUserClick} className={classes.btn}>
            <MoreVertIcon />
          </IconButton>
        </Tooltip>
        <Popper 
          open={expandUser}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
          className={classes.popper}
          style={ expandUser ? { zIndex: 1} : {}}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <Paper className={classes.root}>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList autoFocusItem={expandUser} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                    <MenuItem>
                      <Tooltip title="About MGIS">
                        <a href="/home/index.html#about-mgis" target="_blank" rel="noopener noreferrer">
                          About
                        </a>
                      </Tooltip>
                    </MenuItem>
                    <MenuItem>
                      <Tooltip title="MGIS Documentation">
                        <a href="https://docs.google.com/document/d/1e9JAh4edWLZx4Mko7GQVxnRwQ0q2a3a6xXsS5J7SDHY" target="_blank" rel="noopener noreferrer">
                          Help
                        </a>
                      </Tooltip>
                    </MenuItem>
                    <MenuItem>
                      <Tooltip title="Email MGIS Help">
                        <a href="mailto:mgis-help@umich.edu" target="_blank" rel="noopener noreferrer">
                          Email MGIS
                        </a>
                      </Tooltip>
                    </MenuItem>
                    <Divider/>
                    <MenuItem>
                      <Tooltip title="Space Attribute Reporting Tool">
                        <a href='https://www.umms.med.umich.edu/mspace/' target="_blank" rel="noopener noreferrer">
                          M-Space
                        </a>
                      </Tooltip>
                    </MenuItem>
                    <MenuItem>
                      <Tooltip title="U-M Tools and Resources">
                        <a href='https://wolverineaccess.umich.edu' target="_blank" rel="noopener noreferrer">
                          Wolverine Access
                        </a>
                      </Tooltip>
                    </MenuItem>
                    <Divider/>
                    <MenuItem onClick={clearCacheHandler}>
                      Clear Cache
                    </MenuItem>
                    <Divider/>
                    <MenuItem onClick={signoutHandler}>
                      Logout
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </React.Fragment>
  );
}




