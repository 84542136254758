import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Button } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { actionPhotoDownloadRoomsWithPhotos } from './PhotoRedux';

const useStyles = makeStyles(theme => ({
  checkboxLabelRoot: {
    marginLeft: -7,
  },
  checkboxLabel: {
    fontSize: '0.9rem',
  },
  checkbox: {
    padding: 5,
    '& svg': {
      fontSize: '1.25rem',
    }
  }
}));

export default function RoomInfo({ targetRoom, checked, onCheckboxChange, busy }) {
  const { rmnbr, bldrecnbr, bld_descrshort } = targetRoom || {};

  return (
    <div>
      <div>Building: {bld_descrshort}</div>
      <div>Room: {rmnbr} </div>
      <div>
        <HighlightRoomCheckbox
          checked={checked}
          onChange={onCheckboxChange}
          busy={busy}
          bldrecnbr={bldrecnbr}
          bld_descrshort={bld_descrshort}
        />
      </div>
    </div>
  )
}

function HighlightRoomCheckbox({ checked, onChange, busy, bldrecnbr, bld_descrshort }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const rooms = useSelector(state => state.photoReducer.targetBuildingPhotos);
  const validRoom = checked && rooms !== 'fetching' && rooms !== null;
  const roomCount = validRoom ? formatRoomCount(rooms) : '';

  const downloadRooms = () => {
    dispatch(actionPhotoDownloadRoomsWithPhotos(rooms, bldrecnbr));   
  }

  return (
    <span>
      <FormControlLabel
        control={
          rooms !== 'fetching' ?
          <Checkbox
            checked={checked}
            onChange={onChange}
            name="highlight-rooms-with-photo"
            color="primary"
            classes={{root: classes.checkbox}}
          />
          : <CircularProgress style={{width: 26, height: 26, margin: 2}} />
        }
        disabled={busy || rooms === 'fetching'}
        label={
          <span>
            Mark all rooms in {bld_descrshort} with available photos
            <DownloadButton
              isVisible={rooms && rooms !== 'fetching'}
              onClick={downloadRooms}
              text={roomCount}
              isDisabled={!rooms || (rooms && rooms.length < 1)}
              noPhotoAvailable={checked && rooms !== null && rooms !== 'fetching' && rooms.length === 0}
            />
          </span>
        }
        classes={{root: classes.checkboxLabelRoot, label: classes.checkboxLabel}}
      />
    </span>
  );
}

function DownloadButton({isVisible, onClick, text, isDisabled, noPhotoAvailable}) {
  return (
    <span>
    {
      isVisible && !noPhotoAvailable &&
      <Button
        style={{color: 'rgb(0, 100, 177)', padding: '2px 8px'}}
        onClick={onClick}
        disabled={isDisabled}
        title="Download">
        {text}
      </Button>
    }
    {
      noPhotoAvailable &&
      <span> (<span style={{color: 'red'}}>No Photo Available</span>)</span>
    }
    </span>
  )
}

function formatRoomCount(rooms) {
  return rooms.length === 1 ? '(1 Room)' : `(${rooms.length} Rooms)`;
}
