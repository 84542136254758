import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import CardContent from '@material-ui/core/CardContent';
import Collapse from '@material-ui/core/Collapse';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';

import { actionSheetFileUpdated, selectorSheet } from './spreadsheetRedux';
import { CollapseHeading } from './SpreadsheetSettingSharedComponents';
import { actionMapZoomFeaturesUpdated } from '../Map';
import { actionAppBarFloorUpdated } from '../AppBar';
import { trackEvent, SPREADSHEET } from '../../tracking';

const useStyles = makeStyles(theme => ({
  sectionTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 10,
    fontWeight: 500,
    '&:hover': {
      cursor: 'pointer',
    },
    '& :hover': {
      cursor: 'pointer',
    },
    '& button': {
      padding: 0,
      marginLeft: 4,
    },
  },
  settingExpandHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 10,
    paddingLeft: 10,
    fontStyle: 'italic',
    '&:hover': {
      cursor: 'pointer',
    },
    '& :hover': {
      cursor: 'pointer',
    },
    '& button': {
      padding: 0,
      marginLeft: 4,
    },
  },
  componentContainer: {
    paddingTop: 0,
    paddingLeft: 15,
    paddingRight: 15,
    paddingBottom: 0,
  } ,
  expand: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  hr: {
    backgroundColor: 'lightgrey',
    height: 1,
    border: 0,
  },
  tabs: {
    maxWidth: '100%'
  },
  buildingRow: {
    cursor: 'pointer',
  },
  hoverRow: {
    backgroundColor: 'rgba(0, 0, 0, 0.04)'
  },
  sheetRow: {
    '&:nth-of-type(2n)': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)'
    }
  },
  dataHeader: {
    marginTop: 10,
    marginBottom: 5
  },
  formControl: {
    margin: 0,
    minWidth: 80,
  },
  selector: {
    minWidth: 200
  }
}));

const HeaderSelector = ({ sheetId }) => {
  const { rmrecnbr, lnglat, json, contentRmrecnbrExpanded,} = useSelector(selectorSheet(sheetId));
  const headerRow = json[0] || [];
  const classes = useStyles();
  const dispatch = useDispatch();

  const lngRedux = lnglat && Array.isArray(lnglat) && lnglat[0];
  const latRedux = lnglat && Array.isArray(lnglat) && lnglat[1];


  const [rrnActive, setRrnActive] = useState(rmrecnbr !== null);
  const [lngLatActive, setLngLatActive] = useState((lngRedux !== null && latRedux !== null));
  const [rrnVal, setRrnVal] = useState("");
  const [lngVal, setLngVal] = useState("");
  const [latVal, setLatVal] = useState("");

  useEffect(() => {
    const latSelectorVal = latRedux === null ? '' : latRedux;
    const lngSelectorVal = lngRedux === null ? '' : lngRedux;
    const rrnSelectorVal = rmrecnbr === null ? '' : rmrecnbr;
    setLatVal(latSelectorVal);
    setLngVal(lngSelectorVal);
    setRrnVal(rrnSelectorVal);
    setRrnActive(rmrecnbr !== null);
    setLngLatActive(lngRedux !== null && latRedux !== null);

  }, [lngRedux, latRedux, rmrecnbr]);

  const handleRrnSwitch = () => {
    setRrnActive(!rrnActive);
  }

  const handleLngLatSwitch = () => {
    setLngLatActive(!lngLatActive);
  }

  const handleRrnSelect = (e) => {
    setRrnVal(e.target.value);
  }

  const handleLngSelect = (e) => {
    setLngVal(e.target.value);
  }
  
  const handleLatSelect = (e) => {
    setLatVal(e.target.value);
  }

  const handleSubmit = () => {
    const rrnSubmit = rrnActive ? rrnVal : null;
    const lngLatSubmit = lngLatActive ? [ lngVal, latVal ] : null;
    let type;

    if (rrnActive && lngLatActive) type = 'dual';
    else if (rrnActive) type = 'room';
    else if (lngLatActive) type = 'point';

    dispatch(actionSheetFileUpdated({
      id: sheetId,
      contentRmrecnbrExpanded: !contentRmrecnbrExpanded,
      rmrecnbr: rrnSubmit,
      lnglat: lngLatSubmit,
      type
    }));
  }

  return (
    <>
      <Box display="flex" flexDirection="row" justifyContent="space-evenly" alignItems="center" >
        <Switch checked={rrnActive} onChange={handleRrnSwitch}/>
        <Box display="flex" flexDirection="column" className={classes.selector}>
          <Box style={{opacity: rrnActive ? '100%' : '50%'}}>
            Room Record Number
          </Box>
          <FormControl
            disabled={!rrnActive}
            className={classes.formControl}
            fullWidth
          >
            <Select
              labelId='select-layer-text-label'
              id="select-layer-text"
              value={rrnVal}
              onChange={handleRrnSelect}
              displayEmpty
              required
              >
              <MenuItem value={''}>Unselected</MenuItem>
              {headerRow.map((x, i) => {
                return <MenuItem key={i} value={i}>{x}</MenuItem>
              })}
            </Select>
          </FormControl>
        </Box>
      </Box>
      <Box display="flex" flexDirection="row" justifyContent="space-evenly" alignItems="center" style={{marginTop: 20, marginBottom: 20}}>
        <Switch checked={lngLatActive} onChange={handleLngLatSwitch}/>
        <Box display="flex" flexDirection="column" className={classes.selector}>
          <Box>
            <Box style={{opacity: lngLatActive ? '100%' : '50%'}}>
              Longitude
            </Box>
            <FormControl
              disabled={!lngLatActive}
              className={classes.formControl}
              fullWidth
            >
              <Select
                labelId='select-layer-text-label'
                id="select-layer-text"
                value={lngVal}
                onChange={handleLngSelect}
                displayEmpty
                required
                >
                <MenuItem value={''}>Unselected</MenuItem>
                {headerRow.map((x, i) => {
                  return <MenuItem key={i} value={i}>{x}</MenuItem>
                })}
              </Select>
            </FormControl>
          </Box>
          <Box style={{marginTop: 10}}>
            <Box style={{opacity: lngLatActive ? '100%' : '50%'}}>
              Latitude
            </Box>
            <FormControl
              disabled={!lngLatActive}
              className={classes.formControl}
              fullWidth
              >
              <Select
                labelId='select-layer-text-label'
                id="select-layer-text"
                value={latVal}
                onChange={handleLatSelect}
                displayEmpty
                required
                >
                <MenuItem value={''}>Unselected</MenuItem>
                {headerRow.map((x, i) => {
                  return <MenuItem key={i} value={i}>{x}</MenuItem>
                })}
              </Select>
            </FormControl>
          </Box>
        </Box>
      </Box>
      <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
        <Button variant="contained" onClick={handleSubmit}>Submit</Button>
      </Box>
    </>
  );
};

const SheetData = ({ sheetId }) => {
  const { json, fileName } = useSelector(selectorSheet(sheetId));

  return (
    <Box>
      <Box>File Name: {fileName}</Box>
      <Box>Spreadsheet Rows: {json && json.length - 1}</Box>
    </Box>
  );
};

const BuildingData = ({ sheetId }) => {
  const { buildingData } = useSelector(selectorSheet(sheetId));
  const dispatch = useDispatch();
  const [hoverRow, setHoverRow] = useState(null);
  const [sortBy, setSortBy] = useState('building'); // building, floor, totalSqFeet
  const [sort, setSort] = useState('desc'); // asc, desc
  const classes = useStyles();

  if (!buildingData) return '';

  const handleSortChange = (property) => {
    if (property === sortBy) {
      setSort(sort === 'asc' ? 'desc' : 'asc');
      trackEvent({
        category: SPREADSHEET,
        action: 'Sort Building Data',
        label: property + (sort === 'asc' ? ' Descending' : ' Ascending')
      });
    }
    else {
      setSortBy(property);
      setSort('desc');
      trackEvent({
        category: SPREADSHEET,
        action: 'Sort Building Data',
        label: property + ' Descending',
      });
    }
  }

  const sorted = (data, sort, sortBy) => {
    let sortable = data.map(x => x);
    return sortable.sort((a, b) => {
      if (a.data[sortBy] > b.data[sortBy]) return sort === 'desc' ? -1 : 1;
      if (a.data[sortBy] < b.data[sortBy]) return sort === 'desc' ? 1 : -1;
      if (a.data[sortBy] === b.data[sortBy]) return 0;
      return 0;   
    });
  }

  const handleClick = (bbox, floor) => {
    dispatch(actionMapZoomFeaturesUpdated({
      type: 'bbox',
      data: bbox,
    }));
    dispatch(actionAppBarFloorUpdated(floor));
    trackEvent({
      category: SPREADSHEET,
      action: 'Zoom',
      label: 'Floor ' + floor,
    });
  };


  return (
    <TableContainer >
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>
              <TableSortLabel
                active={sortBy === 'building'}
                direction={sort}
                onClick={() => handleSortChange('building')}
              >
                Building
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={sortBy === 'floor'}
                direction={sort}
                onClick={() => handleSortChange('floor')}
              >
                Floor
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={sortBy === 'totalSqFeet'}
                direction={sort}
                onClick={() => handleSortChange('totalSqFeet')}
              >
                Square Feet
              </TableSortLabel>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sorted(buildingData, sort, sortBy).map((dataObj, index) => {
            return (
              <TableRow
                onMouseEnter={() => setHoverRow(index)}
                onMouseLeave={() => setHoverRow(null)}
                onClick={() => handleClick(dataObj.bbox, dataObj.data.floor)}
                key={index}
                className={clsx( classes.buildingRow, {
                  [classes.hoverRow]: index === hoverRow
                })}
              >
                <TableCell>{dataObj.data.building}</TableCell>
                <TableCell>{dataObj.data.floor} </TableCell>
                <TableCell>{dataObj.data.totalSqFeet}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const SelectedRoom = ({ sheetId }) => {
  const { json, selectedRmrecnbr, selectedRmnbr, rmrecnbr: rmrecnbrCol, selectedPoint } = useSelector(selectorSheet(sheetId));
  const rmrecnbr = selectedRmrecnbr || '';
  const rmnbr = selectedRmnbr || '';
  const point = selectedPoint;
  const classes = useStyles();

  if (!rmrecnbr && !point) return (<Typography>Click room on map to select.</Typography>);
  
  const headerRow = json && json[0];
  const selectedRows = json && rmrecnbr ? json.filter(row => String(row[rmrecnbrCol]) === String(rmrecnbr)) : [];
  const selectedPointRows = json && point ? [json[point]] : []; 
  let displayTable = [];

  if (headerRow && (selectedRows.length > 0 || selectedPointRows.length > 0)) {
    for (let i = 0; i < headerRow.length; i++) {
      displayTable.push([headerRow[i], ...selectedRows.map(row => row[i]), ...selectedPointRows.map(row => row[i])])
    }
  }

  return (
    <>
      {
        rmrecnbr ?
        <>
          <Typography className={classes.dataHeader} >Room Data:</Typography>
          <Box>Room Number: {rmnbr}</Box>
          <Box>Room Record Number: {rmrecnbr}</Box>
        </>
        : ''
      }
      {
        point ? 
        <Typography className={classes.dataHeader} >Point Data</Typography> : ''
      }
      <Typography className={classes.dataHeader}>Spreadsheet Data:</Typography>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Column Header</TableCell>
              {displayTable.length > 0 && new Array(displayTable[0].length - 1).fill(null).map((cell, index) => {
                  return <TableCell key={index}>Value</TableCell>
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {displayTable.map((row, index) => {
              return (
                <TableRow 
                  key={index}
                  className={classes.sheetRow}
                >
                  {row.map((cell, index) => {
                    return (
                      <TableCell key={index}>
                        {String(cell)}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default function Content({ sheetId }) {
  const {
    contentExpanded,
    contentRmrecnbrExpanded,
    contentSheetDataExpanded,
    contentBuildingDataExpanded,
    contentSelectedRoomExpanded
  } = useSelector(selectorSheet(sheetId))
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleContentExpand = () => {
    dispatch(actionSheetFileUpdated({
      id: sheetId,
      contentExpanded: !contentExpanded
    }));
  }

  const handleRmrecnbrExpand = () => {
    dispatch(actionSheetFileUpdated({
      id: sheetId,
      contentRmrecnbrExpanded: !contentRmrecnbrExpanded
    }));
  }

  const handleSheetDataExpand = () => {
    dispatch(actionSheetFileUpdated({
      id: sheetId,
      contentSheetDataExpanded: !contentSheetDataExpanded
    }));
  }

  const handleBuildingDataExpand = () => {
    dispatch(actionSheetFileUpdated({
      id: sheetId,
      contentBuildingDataExpanded: !contentBuildingDataExpanded
    }));
  }

  const handleSelectedRoomExpand = () => {
    dispatch(actionSheetFileUpdated({
      id: sheetId,
      contentSelectedRoomExpanded: !contentSelectedRoomExpanded
    }));
  }

  return (
    <>
      <CollapseHeading 
        headingClass={classes.sectionTitle}
        expanded={contentExpanded}
        setExpanded={handleContentExpand}
        headingText="Content"
      />
      <Collapse in={contentExpanded}>
        <Paper>
          <CardContent>
            <CollapseHeading 
              headingClass={classes.settingExpandHeader}
              expanded={contentRmrecnbrExpanded}
              setExpanded={handleRmrecnbrExpand}
              headingText="Header Columns"
            />
            <Collapse in={contentRmrecnbrExpanded}>
              <Box className={classes.componentContainer}>
                <HeaderSelector sheetId={sheetId} />
              </Box>
            </Collapse>
            <hr className={classes.hr} />
            <CollapseHeading 
              headingClass={classes.settingExpandHeader}
              expanded={contentSheetDataExpanded}
              setExpanded={handleSheetDataExpand}
              headingText="Spreadsheet Data"
            />
            <Collapse in={contentSheetDataExpanded}>
              <Box className={classes.componentContainer}>
                <SheetData sheetId={sheetId} />
              </Box>
            </Collapse>
            <hr className={classes.hr} />
            <CollapseHeading 
              headingClass={classes.settingExpandHeader}
              expanded={contentBuildingDataExpanded}
              setExpanded={handleBuildingDataExpand}
              headingText="Building Data"
            />
            <Collapse in={contentBuildingDataExpanded}>
              <Box className={classes.componentContainer}>
                <BuildingData sheetId={sheetId} />
              </Box>
            </Collapse>
            <hr className={classes.hr} />
            <CollapseHeading 
              headingClass={classes.settingExpandHeader}
              expanded={contentSelectedRoomExpanded}
              setExpanded={handleSelectedRoomExpand}
              headingText="Selected Data"
            />
            <Collapse in={contentSelectedRoomExpanded}>
              <Box className={classes.componentContainer}>
                <SelectedRoom sheetId={sheetId}/>
              </Box>
            </Collapse>
          </CardContent>
        </Paper>
      </Collapse>
    </>
  );
};