import { createAction, handleActions } from 'redux-actions';
import { actionAppToggleComponent } from '../App';

export const initialState = {
  isExpanded: false,
  uploadOpen: false,
  files: [],
};

export const actionSheetExpandToggled = createAction('SHEET_COMPONENT_EXPAND_TOGGLED');
export const actionSheetUploadToggled = createAction('SHEET_UPLOAD_VISIBILITY_TOGGLED');
export const actionSheetFileUploaded = createAction('SHEET_FILE_UPLOADED');
export const actionSheetFileRemoved = createAction('SHEET_FILE_REMOVED');
export const actionSheetFileUpdated = createAction('SHEET_FILE_UPDATED');

export const actionSheetHeatmapCreated = ({ conditionId, sheetId, heatMapObj, catObj, hslObj }) => {
  return (dispatch, getState) => {
    const { conditions } = getState().spreadsheetReducer.files.find(x => x.id === sheetId)
    const condition = conditions.find(x => x.id === conditionId);
    if (condition.type === 'color' || condition.type === 'point') {
      // Make the existing condition a heatmap reference condition
      dispatch(actionSheetConditionUpdated({
        id: sheetId,
        updatedCondition: {
          id: conditionId,
          type: condition.type === 'point' ? 'heatmapPoint' : 'heatmapColor',
          catObj,
          hslObj,
        }
      }));
      let nextId = Math.max(...conditions.map(condition => condition.id)) + 1;
      // Add heatmap conditions, referring back to the original heatmap condition
      if (heatMapObj) {
        Object.keys(heatMapObj).forEach((key, index) => {
          if (condition.type === 'color' ) {
            dispatch(actionSheetConditionAdded({
              id: sheetId,
              newCondition: {
                id: nextId + index,
                type: 'color',
                fillColor: key,
                // ! @point-future RRN array also includs "pointId" for conditions
                // ! reference is confusing and should be refactored
                rmrecnbr: heatMapObj[key],
                isHeatMapCondition: true,
                heatMapConditionRef: conditionId,
              }
            }));
          }
          if (condition.type === 'point') {
            dispatch(actionSheetConditionAdded({
              id: sheetId,
              newCondition: {
                id: nextId + index,
                type: 'point',
                pointColor: key,
                // ! @point-future RRN array also includs "pointId" for conditions
                // ! reference is confusing and should be refactored
                rmrecnbr: heatMapObj[key],
                isHeatMapCondition: true,
                heatMapConditionRef: conditionId,
              }
            }));
          }
        });
      }
    }
  };
};

export const actionSheetHeatmapRemoved = ({conditionId, sheetId}) => {
  return (dispatch, getState) => {
    const { conditions } = getState().spreadsheetReducer.files.find(x => x.id === sheetId)
    const condition = conditions.find(x => x.id === conditionId);
    const type = condition.type === 'heatmapPoint' ? 'point' : 'color';
    dispatch(actionSheetConditionUpdated({
      id: sheetId,
      updatedCondition: {
        id: conditionId,
        catObj: null,
        type
      }
    }));
    conditions
      .filter(condition => condition.isHeatMapCondition && condition.heatMapConditionRef === conditionId)
      .forEach(condition => {
        dispatch(actionSheetConditionRemoved({
          id: sheetId,
          conditionId: condition.id
        }));
      });
  }
}
export const actionSheetHeatmapColorUpdated = ({conditionId, sheetId, heatMapObj, newColor}) => {
  return (dispatch, getState) => {
    const { conditions } = getState().spreadsheetReducer.files.find(x => x.id === sheetId);
    const condition = conditions.find(x => x.id === conditionId);
    dispatch(actionSheetConditionUpdated({
      id: sheetId,
      updatedCondition: {
        id: conditionId,
        hslObj: newColor.hsl,
        fillColor: newColor.hex
      },
    }));
    // remove all conditions
    conditions
      .filter(condition => condition.isHeatMapCondition && condition.heatMapConditionRef === conditionId)
      .forEach(condition => {
        dispatch(actionSheetConditionRemoved({
          id: sheetId,
          conditionId: condition.id
        }));
      });
    // add updated conditions
    let nextId = Math.max(...conditions.map(condition => condition.id)) + 1;
    Object.keys(heatMapObj).forEach((key, index) => {
      if (condition.type === 'heatmapColor') {
        dispatch(actionSheetConditionAdded({
          id: sheetId,
          newCondition: {
            id: nextId + index,
            type: 'color',
            fillColor: key,
            // ! @point-future RRN array also includs "pointId" for conditions
            // ! reference is confusing and should be refactored
            rmrecnbr: heatMapObj[key],
            isHeatMapCondition: true,
            heatMapConditionRef: conditionId,
          }
        }))
      }
      if (condition.type === 'heatmapPoint') {
        dispatch(actionSheetConditionAdded({
          id: sheetId,
          newCondition: {
            id: nextId + index,
            type: 'point',
            pointColor: key,
            // ! @point-future RRN array also includs "pointId" for conditions
            // ! reference is confusing and should be refactored
            rmrecnbr: heatMapObj[key],
            isHeatMapCondition: true,
            heatMapConditionRef: conditionId,
          }
        }))
      }
    });
  }
};

export const actionSheetRoomClicked = ({id, selectedRmrecnbr, selectedRmnbr}) => {
  return (dispatch, getState) => {
    const { selectedRmrecnbr: prevSelectedRmrecnbr } = getState().spreadsheetReducer.files.find(x => x.id === id);
    if (prevSelectedRmrecnbr !== selectedRmrecnbr) {
      dispatch(actionSheetFileUpdated({
        id,
        selectedRmrecnbr,
        selectedRmnbr,
        selectedPoint: null
      }));
    } else {
      dispatch(actionSheetFileUpdated({
        id,
        selectedRmrecnbr: null,
        selectedRmnbr: null,
      }));
    }
  };
};

export const actionSheetPointClicked = ({id, selectedPoint}) => {
  return (dispatch, getState) => {
    const { selectedPoint: prevSelectedPoint } = getState().spreadsheetReducer.files.find(x => x.id === id);
    if (prevSelectedPoint !== selectedPoint) {
      dispatch(actionSheetFileUpdated({
        id,
        selectedPoint,
        selectedRmrecnbr: null,
        selectedRmnbr: null
      }));
    } else {
      dispatch(actionSheetFileUpdated({
        id,
        selectedPoint: null,
      }));
    }
  }
}

export const actionSheetFileDropped = (file) => {
  return (dispatch, getState) =>  {
    const { spreadsheetVisible } = getState().appReducer
    const { isExpanded } = getState().spreadsheetReducer
    dispatch(actionSheetFileUploaded(file));
    if (!spreadsheetVisible) {
      dispatch(actionAppToggleComponent({ component: 'spreadsheetVisible' }));
    }
    if (!isExpanded) {
      dispatch(actionSheetExpandToggled());
    }
  }
};

export const actionSheetConditionAdded = (payload) => {
  return (dispatch, getState) => {
    const { conditions } = getState().spreadsheetReducer.files.find(sheet => sheet.id === payload.id);
    const newConditions = [...conditions, payload.newCondition]
    dispatch(actionSheetFileUpdated({
      id: payload.id,
      conditions: newConditions
    }))
  };
};

export const actionSheetConditionUpdated = (payload) => {
  return (dispatch, getState) => {
    const { conditions } = getState().spreadsheetReducer.files.find(sheet => sheet.id === payload.id);
    let newConditions = conditions.map(condition => {
      if (condition.id === payload.updatedCondition.id) return {...condition, ...payload.updatedCondition}; 
      return condition; 
    });
    dispatch(actionSheetFileUpdated({
      id: payload.id,
      conditions: newConditions
    }));
  };
};

export const actionSheetConditionRemoved = (payload) => {
  return (dispatch, getState) => {
    const { conditions } = getState().spreadsheetReducer.files.find(sheet => sheet.id === payload.id);
    const newConditions = conditions.filter(condition => condition.id !== payload.conditionId);
    dispatch(actionSheetFileUpdated({
      id: payload.id,
      conditions: newConditions
    }));
  };
};

export const spreadsheetReducer = handleActions({
  [actionSheetExpandToggled]: (state, action) => {
    const isExpanded = action.payload === null || action.payload === undefined ? !state.isExpanded : action.payload;
    return { ...state, isExpanded };
  },
  [actionSheetUploadToggled]: (state) => {
    return { ...state, uploadOpen: !state.uploadOpen };
  },
  [actionSheetFileUpdated]: (state, action) => {
    const updatedFiles = state.files.map((file) => {
      if (file.id === action.payload.id) {
        return {...file, ...action.payload}
      }
      return file;
    });
    return {...state, files: updatedFiles };
  },
  [actionSheetFileRemoved]: (state, action) => {
    const removedState = state.files.filter((file) => action.payload.id !== file.id);
    return { ...state, files: removedState }
  },
  [actionSheetFileUploaded]: (state, action) => {
    return { ...state, isExpanded: true, files: [...state.files, action.payload] };
  },
}, initialState);

export const selectorSheets = (state) => state.spreadsheetReducer.files;
export const selectorSheet = (id) => state => state.spreadsheetReducer.files.find(file => file.id === id);
export const selectorSheetCondition = (sheetId, conditionId) => state => {
  return state.spreadsheetReducer.files.find(file => {
    return file.id === sheetId
  }).conditions.find(condition => {
    return condition.id === conditionId
  });
}