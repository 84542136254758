import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CognitoAuth } from 'amazon-cognito-auth-js';
import {
  actionAuthUpdated,
  actionAuthSignInUser,
  urlHasAccessToken,
  localStorageHasToken,
  getAuthDataConfig,
} from './authRedux';
import LoginDialog from './LoginDialog';
import useUrlHashRouter from '../Shared/useUrlHashRouter';
import { actionAddAlert, actionDeleteAlert } from '../Alert/AlertRedux';

export default function Auth() {
  const dispatch = useDispatch();
  const user = useSelector(state => state.authReducer.user);
  const status = useSelector(state => state.authReducer.status);

  // https://github.com/rajaraodv/react-redux-blog/blob/master/public/src/reducers/reducer_user.js
  const [auth, setAuth] = useState(null);
  useEffect(() => {
    const initCognitoSDK = () => {
      var authData = getAuthDataConfig();
      var auth = new CognitoAuth(authData);
      auth.userhandler = {
          onSuccess: function(session) {
              dispatch(actionAuthSignInUser(session));
              dispatch(actionAuthUpdated(auth));
          },
          onFailure: function(err) {
              alert("Error!" + err);
          }
      };
      return auth;
    }  

    const initCognito = () => {
      const auth = initCognitoSDK();
      setAuth(auth);
      if (urlHasAccessToken()) {
        const curUrl = window.location.href;
        auth.parseCognitoWebResponse(curUrl);        
      } else if (localStorageHasToken()) {
        auth.getSession();
      }
    }

    initCognito();
  }, [dispatch]);

  // url hash query params after auth.getSession redirect
  const signature = ["access_token", "id_token", "state", "token_type", "expires_in"];
  const [params, clearParam] = useUrlHashRouter({ signature });
  useEffect(() => {
    if (status === 'authenticated' && clearParam) {
      // check localStorage for hash query param redirection
      const hashRedirect = window.localStorage.getItem('hashredirect');
      window.localStorage.setItem('hashredirect', '');
      window.localStorage.removeItem('hashredirect');
      // clear hash param after AWS cognito redirection
      if (hashRedirect) {
        clearParam(hashRedirect);
      } else {
        clearParam()
      }
    }
  }, [params, clearParam, status]);


  useEffect(() => {
    const refreshPageHandler = () => {
      auth && auth.getSession && auth.getSession();
      window.location.reload(false);
    }

    if (user && status === 'expired') {
      dispatch(actionAddAlert({
        id: 'auth',
        title: 'Your session has expired. Please refresh page.',
        severity: 'error',
        buttonLabel: 'REFRESH',
        onClick: refreshPageHandler,
        onClose: false, 
      }))
    } else { 
      dispatch(actionDeleteAlert('auth'));
    }
  
  }, [dispatch, auth, user, status]);

  // store hash query param before auth redirection
  // only store non auth hash query params
  if (!user && window.location.hash && !urlHasAccessToken()) {
    window.localStorage.setItem('hashredirect', window.location.hash);
  }

  return (
    <React.Fragment>
      { !user && <LoginDialog auth={auth} /> }
    </React.Fragment>
  );
}