import { createAction, handleActions } from 'redux-actions';

export const initialState = {
  isExpanded: false,
};

export const actionLegendExpandToggled = createAction('LEGEND_EXPAND_TOGGLED');

export const legendReducer = handleActions({
  [actionLegendExpandToggled]: (state) => {
    return {...state, isExpanded: !state.isExpanded };
  },
}, initialState);