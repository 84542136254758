import React from 'react';
import { actionLayerUpdate } from './LayerRedux';
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import FormGroup from '@material-ui/core/FormGroup';
import Switch from '@material-ui/core/Switch';
import { trackEvent, LAYERS } from '../../tracking';

const useStyles = makeStyles(theme => ({
  radioGroup: {
    paddingLeft: 4,
    '& .MuiRadio-root': {
      padding: 1,
      marginRight: 4,
    },
    '& .MuiTypography-overline': {
      lineHeight: 1,
    }
  },
  label: {
    height: 28
  }
}));

export default function ColorTabPanel({ layers }) {
  const color = useSelector(state=>state.layerReducer.color);
  const dispatch = useDispatch();
  const classes = useStyles();
  const handleChange = event => {
    const data = { id: event.target.value, faavailable: color.faavailable, shared: color.shared };
    trackEvent({
      category: LAYERS,
      action: 'Change Color',
      label: data.id
    })
    dispatch(actionLayerUpdate({ type: 'color', data }));
  }

  const handleSwitchChange = event => {
    if (event.target.name === "shared") {
      const data = { id: color.color, faavailable: color.faavailable, shared: !color.shared }
      trackEvent({
        category: LAYERS,
        action: 'Toggle Shared',
        label: data.shared ? 'Visible' : 'Hidden'
      });
      dispatch(actionLayerUpdate({ type: 'color', data }));
    } else {
      const data = { id: color.color, faavailable: !color.faavailable, shared: color.shared }
      trackEvent({
        category: LAYERS,
        action: 'Toggle FA-Available',
        label: data.faavailable ? 'Visible' : 'Hidden'
      });
      dispatch(actionLayerUpdate({ type: 'color', data }))
    }
  }

  return (
    <Grid container direction="column">
      <RadioGroup 
        aria-label="Color Layer"
        name="layer-color"
        value={color.color}
        onChange={handleChange}
        className={classes.radioGroup}
      >
        {
          layers
            .filter(layer=>layer.type === 'color' && layer.radio)
            .map(layer => <Color layer={layer} key={layer.id} tooltip={layer.tooltip}/>)
        }
      </RadioGroup>
      <FormGroup
        className={classes.radioGroup}
      >
        {
          layers
            .filter(layer=>layer.type === 'color' && !layer.radio)
            .map(layer => (
              <ColorSwitch
                layer={layer}
                key={layer.id}
                handleSwitch={handleSwitchChange}
                checked={color[layer.id]}
              />
            ))
        }
      </FormGroup>
    </Grid>
  );
}

function TooltipWrapper({ tooltips, children }) {  
  return (
    tooltips && tooltips.length > 0 ? 
    <Tooltip title={
      tooltips.map((tooltip, i) => (
        <div key={i}>{tooltip}</div>
      ))
    } placement="bottom-start">  
      {children}
    </Tooltip> : children
  );
}

function Color({ layer }) {
  const classes = useStyles();
  const { id, label, tooltips, key } = layer;

  return (
    <TooltipWrapper tooltips={tooltips} key={key}>
      <FormControlLabel
        className={classes.label}
          value={id}
          control={<Radio color="primary"/>}
          label={
            <Typography variant="overline" data-testid={`layer-color-label-${id}`}>
              {label}
            </Typography>
          }
        />
    </TooltipWrapper>
  );
}

function ColorSwitch({ layer, handleSwitch, checked }) {
  const classes = useStyles();
  const { id, label, tooltips, key } = layer;

  return (
    <TooltipWrapper tooltips={tooltips} key={key}>
        <FormControlLabel
        className={classes.label}
        control={<Switch
          color="primary"
          checked={checked}
          onChange={event => handleSwitch(event)}
          name={id}
        />}
        label={
          <Typography variant="overline" data-testid={`layer-color-label-${id}`}>
            {label}
          </Typography>}
      />
    </TooltipWrapper>
  );
}