import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import { actionAppBarFloorUpdated } from './AppBarRedux';
import { trackEvent, MAP_TOOLS } from '../../tracking';

const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: 90,
    '& .MuiSelect-select.MuiSelect-select': {
      minHeight: 'unset !important',
    },
    '& .MuiFormLabel-root': {
      color: 'rgba(0, 0, 0, 0.87)',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      [theme.breakpoints.down('xs')]: {
        display: 'none',   
      }
    },
    '& .MuiInputBase-root': {
      lineHeight: 0.7,
      [theme.breakpoints.down('xs')]: {
        lineHeight: 0.4,
      },
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  },
  label: {
    top: -9,
    '&.MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(7px, 3px) scale(0.6)',
    },
  },
}));

export default function Floor() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const floor = useSelector(state => state.appBarReducer.floor);
  const floors = useSelector(state => state.appBarReducer.floors);
  const floorsInView = useSelector(state => state.appBarReducer.floorsInView);
  const floorsInCurrentSearch = useSelector(state => state.appBarReducer.floorsInCurrentSearch);
  const floorsInActiveBuilding = useSelector(state => state.appBarReducer.floorsInActiveBuilding);
  const highlightFloors = new Set(floorsInCurrentSearch);
  const floorOptions = createFloorOptions(floor, floors, floorsInView, floorsInCurrentSearch);

  const inputLabel = useRef(null);
  const [labelWidth, setLabelWidth] = useState(0);
  useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  const handleChange = event => {
    trackEvent({
      category: MAP_TOOLS,
      action: 'Change Floor',
      label: event.target.value
    });
    dispatch(actionAppBarFloorUpdated(event.target.value));
  };

  const getBackgroundColor = floorOption => {
    const BLUE = 'rgba(59, 178, 208, 0.7)';
    const LIGHT_BLUE = 'rgba(59, 178, 208, 0.3)';
    const SELECTED = 'rgba(0, 0, 0, 0.15)';

    if (highlightFloors.has(floorOption) && floorOption === floor) {
      return BLUE;
    } else if (highlightFloors.has(floorOption)) {
      return LIGHT_BLUE;
    } else if (floorOption === floor) {
      return SELECTED;
    } else {
      return 'default';
    }
  }

  const getFontWeight = floorOption => {
    return floorsInActiveBuilding.indexOf(floorOption) !== -1 ? 'bolder' : 'normal';
  }

  const setStyle = floorOptions => {
    return {
      backgroundColor: getBackgroundColor(floorOptions),
      fontWeight: getFontWeight(floorOptions)
    }
  }

  return (
    <Tooltip title="Building Floor" placement="right">
      <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel ref={inputLabel} id="floor-dropdown-label" className={classes.label}>
            <Typography variant="button" component="span">
              Floor
            </Typography>
          </InputLabel>
          <Select
            labelId="floor-dropdown-label"
            id="floor-dropdown"
            value={floor}
            onChange={handleChange}
            labelWidth={labelWidth}
          >
            <MenuItem value="">
              None
            </MenuItem>
            {
              floorOptions.map(floor => {
                return (
                  <MenuItem
                    style={setStyle(floor)}
                    value={floor}
                    key={floor}>
                    {floor}
                  </MenuItem>
                )
              })
            }
          </Select>
      </FormControl>
    </Tooltip>
  );
}

function createFloorOptions(floor, floors, floorsInView, floorsInCurrentSearch) {
  const floorOptions = [...new Set([floor, ...floors, ...floorsInView, ...floorsInCurrentSearch])];
  floorOptions.sort(floorSorter);
  return floorOptions.filter(floor => floor !== '');
}

export function floorSorter(a, b) {
  var scoreA = getFloorScore(a),
    scoreB = getFloorScore(b);
  return scoreB - scoreA;
}

function getFloorScore(floor) {
  var nonzero = removeLeadingZero(floor),
    leading = getFirstDigitOrChar(nonzero),
    trailing = getFirstDigitOrChar(nonzero.replace(leading, '')),
    score = getTotalFloorScore(leading, trailing);

  return score;
}

function sumAscii(targetString) {
  var asciiVal = 0, i;
  for (i = 0;  i < targetString.length; i++) {
    asciiVal += targetString.charCodeAt(i);
  }
  return asciiVal;
}

function getScoreForFloors(targetString) {
  var num = parseInt(targetString),
    score = 0;

  if (targetString && !isNaN(num)) {
    score = sumAscii(targetString);
  } else if (targetString && isNaN(num)) {
    if (targetString === 'G') {
      // ascii Z - A, make ground level score between 0 and A
      score = 90 - 65 + 1;
    } else {
      // ascii Z == 90
      score = 90 - sumAscii(targetString);				
    }
  }
  return score;
}

function getTotalFloorScore(lead, trail) {
  var score = getScoreForFloors(lead),
    decimal = getScoreForFloors(trail),
    decimalPlace = decimal.toString().length
    decimal = decimal / Math.pow(10,decimalPlace);
  return score + decimal;
}

function removeLeadingZero(targetString) {
  return targetString.startsWith('0') ? targetString.replace('0', '') : targetString;
}

function getFirstDigitOrChar(targetString) {
  var num = parseInt(targetString);
  return isNaN(num) ? targetString.charAt(0) : num.toString();
}