import React, { useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import Slider from '@material-ui/core/Slider';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import { actionMapMapviewChangeRequested } from '../Map/MapRedux';
import { MAP_TOOLS, trackEvent } from '../../tracking';

let rotateMarks = [];
for (var i = -180; i < 200; i += 45 ) {
  rotateMarks.push({ value: i, label: i + '°'});
}

let pitchMarks = [];
for (var j = 0; j < 65; j += 10) {
  pitchMarks.push({ value: j, label: j + '°'});
}

const useStyles = makeStyles(theme => ({
  root: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    pointerEvents: 'none',
    textAlign: 'center',
  },
  rotate: {
    textAlign: 'center',
    position: 'fixed',
    bottom: 6,
    minWidth: 600,
    width: '50%',
    left: '50%',
    transform: 'translate(-50%, 0)',
    margin: '0px 10px',
    padding: '0px 5px',
    pointerEvents: 'auto',
    backgroundColor: 'rgba(255, 255, 255, 1)',
    boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)',
  },
  sliderTrack: {
    width: '0 !important',
  },
  pitch: {
    position: 'fixed',
    right: 100,
    top: '50%',
    transform: 'translate(0, -50%)',
    height: Math.floor(window.innerHeight, window.innerWidth) * 0.75, // follow height grid minus padding
    width: 100,
    textAlign: 'center',
    pointerEvents: 'auto',
    backgroundColor: 'rgba(255, 255, 255, 1)',
    // padding: '5px 0',
    boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)',
  },
  sliderVertical: {
    marginRight: '0 !important',
    height: '92% !important',
  },
  grid: {
    width: Math.floor(window.innerHeight, window.innerWidth) * 0.65,
    height: Math.floor(window.innerHeight, window.innerWidth) * 0.65,
    display: 'inline-block',
    position: 'relative',
    margin: 10,
    verticalAlign: 'top',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background: 'linear-gradient(to right, #4455b5 1px, transparent 1px)',
      backgroundSize: '10%',
      // specific
      backgroundColor: 'transparent',
      boxShadow: 'inset 0px 0px 0 1px #4455b5',
    },
    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background: 'linear-gradient(to right, #4455b5 1px, transparent 1px)',
      backgroundSize: '10%',
      // specific
      transform: 'rotate(90deg)',
    },
  },
  buttons: {
    position: 'absolute',
    bottom: 27,
    right: -150,
    pointerEvents: 'auto',
  },
  buttonReset: {
    borderRadius: 0,
    padding: '6px 18px',
  },
  buttonLabel: {
    display: 'flex',
    flexDirection: 'column',
  },
  buttonDone: {
    position: 'relative',
    left: 30,
    padding: 16,
  },
}));

export default function Compass({ visible }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const bearing = useSelector(state=>state.mapReducer.bearing);
  const pitch = useSelector(state=>state.mapReducer.pitch);

  const [gridVisible, setGridVisible] = useState(false);
  const mouseEnterHandler = () => { setGridVisible(true); }
  const mouseExitHandler = () => { setGridVisible(false); }

 
  const handlePitchValue = (event, val) => {
    dispatch(actionMapMapviewChangeRequested({
      pitch: val
    }));
    trackEvent({
      category: MAP_TOOLS,
      action: 'Change Pitch',
      value: val,
    });
  }

  const resetPitch = () => {
    dispatch(actionMapMapviewChangeRequested({
      pitch: 0
    }));
    trackEvent({
      category: MAP_TOOLS,
      action: 'Reset Pitch'
    });
  }

  const handleRotateValue = (event, val) => {
    dispatch(actionMapMapviewChangeRequested({
      bearing: val
    }));
    trackEvent({
      category: MAP_TOOLS,
      action: 'Rotate',
      value: val,
    });
  }
 
  const resetBearing = () => {
    dispatch(actionMapMapviewChangeRequested({
      bearing: 0
    }));
    trackEvent({
      category: MAP_TOOLS,
      action: 'Reset Rotation'
    });
  }

  return (
    <React.Fragment>
      <div
        className={classes.root}
        style={{
          display: visible ? 'block' : 'none',
          opacity: gridVisible ? 1: 0.9
        }}
      >
        <div className={classes.grid} style={{opacity: gridVisible ? 1: 0.5 }} ></div>
      </div>
      <div
        style={{ display: visible ? 'block' : 'none' }}
        className={classes.rotate}
        onMouseEnter={mouseEnterHandler}
        onMouseLeave={mouseExitHandler}
      >
        <Tooltip title="Reset Rotation">
          <Button color="primary" onClick={resetBearing}>
          Rotation:{bearing.toFixed(1)}&#176;
          </Button>
        </Tooltip>
        <Slider 
          value={bearing}
          max={180}
          min={-180}
          marks={rotateMarks}
          onChange={handleRotateValue}
          classes={{track: classes.sliderTrack}}
          data-testid="rotate-slider"
        />
      </div>
      <div
        style={{ display: visible ? 'block' : 'none' }}
        className={classes.pitch}
        onMouseEnter={mouseEnterHandler}
        onMouseLeave={mouseExitHandler}
      >
        <Tooltip title="Reset Pitch">
          <Button color="primary" onClick={resetPitch}>
            Pitch:{pitch.toFixed()}&#176;
          </Button>
        </Tooltip>
        <Slider 
            orientation="vertical"
            value={pitch}
            min={0}
            max={60}
            marks={pitchMarks}
            onChange={handlePitchValue}
            classes={{track: classes.sliderTrack, vertical: classes.sliderVertical}}
            data-testid="pitch-slider"
          />
      </div>
    </React.Fragment>
  );
}