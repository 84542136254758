import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

const useStyles = makeStyles(theme => ({
  previousBtn: {
    position: 'absolute',
    top: 300,
    zIndex: 1,
    left: 22,
    border: '1px solid white',
    color: 'white',
  },
  nextBtn: {
    position: 'absolute',
    top: 300,
    zIndex: 1,
    right: 22,
    border: '1px solid white',
    color: 'white',
  },
}));

export function PreviousBtn({ targetPhoto, currentIndex, onClick }) {
  const classes = useStyles();
  const isVisible = !!targetPhoto && targetPhoto.length && currentIndex > 0; 

  return (
    <span>
      {
        isVisible && 
        <IconButton
          size="small"
          className={classes.previousBtn}
          onClick={onClick}
        >
          <NavigateBeforeIcon />
        </IconButton>
      }
    </span>
  )
}

export function NextBtn({ targetPhoto, currentIndex, onClick }) {
  const classes = useStyles();
  const isVisible = !!targetPhoto && targetPhoto.length && currentIndex < targetPhoto.length - 1; 

  return (
    <span>
      {
        isVisible && 
        <IconButton
          size="small"
          className={classes.nextBtn}
          onClick={onClick}
        >
          <NavigateNextIcon />
        </IconButton>
      }
    </span>
  )
}
