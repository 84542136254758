/**
 * window.mapDrawMarkerStore: {featureId1: markerObj1, ...}
 * what: storage for mapbox marker objects
 * why: while drawn polygons are store as objects in the redux store,
 *      marker can't be stored as it's too big and causes circular dependant
 *   
 */

// @param obj: {featureId: markerObj}
export function addMarkerStorage(obj) {
  window.mapDrawMarkerStore = window.mapDrawMarkerStore || {};
  window.mapDrawMarkerStore = {...window.mapDrawMarkerStore, ...obj};
}

// @id: feature id (feature of type Point)
export function removeMarkerStorage(id) {
  window.mapDrawMarkerStore = window.mapDrawMarkerStore || {};
  const {[id]: marker, ...rest} = window.mapDrawMarkerStore;
  window.mapDrawMarkerStore = rest;
  return marker;
}

export function getMarker(id) {
  const {[id]: marker} = window.mapDrawMarkerStore;
  return marker;
}

export function getAllMarkers() {
  return window.mapDrawMarkerStore || {};
}