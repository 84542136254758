import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actionAppToggleComponent } from '../App';
import Accordion from '../Shared/Accordion';
import { actionCustomLayerDownloadRestroomData, actionCustomLayerRestroomExpandToggled, actionCustomLayerListToggled } from "./customLayerRedux";
import LayerList from "./LayerList";
import RestroomLayer from './RestroomLayer';
import CircularProgress from '@material-ui/core/CircularProgress';
import { actionDataSelectFeatureRequested} from '../Data';

export default function CustomLayerRestroom() {
  const restroomComponent = 'customLayerRestroomVisible';
  const dispatch = useDispatch();

  const restroomLayerVisible = useSelector(state => state.appReducer[restroomComponent]);
  const restroomLayerVisibleHandler = () => { dispatch(actionAppToggleComponent({restroomComponent}))}

  const restroomLayerExpanded = useSelector(state => state.customLayerReducer.restroomLayerExpanded);
  const restroomLayerExpandHandler = () => { dispatch(actionCustomLayerRestroomExpandToggled()) }

  const listVisible = useSelector(state => state.customLayerReducer.listVisible);
  const handleListClose = selectedLayer => {
    console.log('selectedLayer', selectedLayer);

    if (selectedLayer) {
      dispatch(actionAppToggleComponent({ component: restroomComponent, visible: true }));
      dispatch(actionCustomLayerRestroomExpandToggled(true))
    }

    dispatch(actionCustomLayerListToggled(false));
  }

  const idToken = useSelector(state => state.authReducer.idToken);
  const restroomData = useSelector(state => state.customLayerReducer.restroomData);
  const [downloadingRestroomData, setDownloadingRestroomData] = useState(false);

  useEffect(() => {
    if (!restroomData && idToken && restroomLayerVisible) {
      setTimeout(() => {
        setDownloadingRestroomData(true);
        console.log('downloading restroom data')
        dispatch(actionCustomLayerDownloadRestroomData(idToken));
      }, 2000);
    }

    if (restroomData && !restroomLayerVisible) {
      dispatch(actionDataSelectFeatureRequested({ type: 'room', data: [], layerId: restroomData.colorLayerId }));
    }

    if (restroomData && restroomLayerVisible) { setDownloadingRestroomData(false) }

  }, [idToken, restroomData, restroomLayerVisible])

  console.log('restroomData', restroomData)

  return (
    <>
      <LayerList open={listVisible} onClose={handleListClose} />
      <Accordion
          title='Restroom Layer'
          visible={restroomLayerVisible}
          visibleHandler={restroomLayerVisibleHandler}
          expand={restroomLayerExpanded}
          expandHandler={restroomLayerExpandHandler}
          >
            <span>
              <RestroomLayer data={restroomData}/>
              {downloadingRestroomData && <CircularProgress style={{position: 'absolute', top: '50%', right: '50%'}}/>}
            </span>
      </Accordion>
    </>
    
  );
}