import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Accordion from '../Shared/Accordion';
import { actionAppToggleComponent } from '../App';
import { actionDrawExpandToggled, actionDrawResetMode } from './drawRedux';
import FeatureGroup, { FeatureGroups } from './FeatureGroup';
import DrawTools from './DrawTools';

/**
 * color
 * 
 * draw:
 * https://github.com/mapbox/mapbox-gl-draw/issues/833
 * https://stackoverflow.com/questions/51303181/how-to-color-individual-polygons-drawn-with-mapbox-gl-draw
 * 
 * feature:
 * feature state -
 * https://docs.mapbox.com/mapbox-gl-js/example/hover-styles/ 
 * https://docs.mapbox.com/help/tutorials/create-interactive-hover-effects-with-mapbox-gl-js/
 * https://blog.mapbox.com/going-live-with-electoral-maps-a-guide-to-feature-state-b520e91a22d
 * data driven style -
 * https://github.com/mapbox/mapbox-gl-js/issues/3804
 * https://stackoverflow.com/questions/42282533/change-style-of-single-feature-in-mapbox-gl
 * by prop -
 * https://docs.mapbox.com/mapbox-gl-js/example/data-driven-lines/
 * 
 * 
 */
export default function Draw() {
  const component = 'drawVisible';
  const dispatch = useDispatch();

  const visible = useSelector(state=>state.appReducer[component]);
  const featureGroups = useSelector(state=>state.drawReducer.featureGroups);
  const [featureGroup] = featureGroups; // get first group only for prototyping

  const expand = useSelector(state=>state.drawReducer.isExpanded);
  const visibleHandler = () => { dispatch(actionAppToggleComponent({ component })) }
  const expandHandler = () => { dispatch(actionDrawExpandToggled()) }
  if (!visible) { dispatch(actionDrawResetMode()) }

  return (
    <Accordion
      title='Draw'
      visible={visible}
      visibleHandler={visibleHandler}
      expand={expand}
      expandHandler={expandHandler}
    >
      <React.Fragment>
        <DrawTools featureGroups={featureGroups}/>
        <FeatureGroups>
          <FeatureGroup featureGroup={featureGroup} />
        </FeatureGroups>
      </React.Fragment>
    </Accordion>
  )
}