import React from 'react';
import { actionLayerUpdate, actionLayerExpandLabelUpdated } from './LayerRedux';
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Collapse from '@material-ui/core/Collapse';
import LabelSettings from './LabelSettings';
import Typography from '@material-ui/core/Typography';
import { trackEvent, LAYERS } from '../../tracking';
import Switch from '@material-ui/core/Switch';

const SWITCH_LABELS_ARRAY = ["facultyallocationlabs", "facultyallocationoffices", "facultyallocationothers"];

export default function LabelTabPanel({ layers }) {
  return (
    <Grid container>
      <LabelSettings />
      {
        layers
          .filter(layer => layer.type === 'label' && !layer.hasParent)
          .map(layer => {
            if (layer.isParent) {
              return <LabelParent layer={layer} key={layer.id} layers={layers}/>
            } else {
              return <Label layer={layer} key={layer.id} />
            }
          })
      }
    </Grid>
  );
}

const useStyles = makeStyles(theme => ({
  label: {
    paddingLeft: 5,
    '& .MuiCheckbox-root': {
      padding: 0,
      marginRight: 4,
    },
    '& .MuiTypography-overline': {
      lineHeight: 1,
    }
  },
  expand: {
    position: 'relative',
    right: 12,
    top: 3,
    '&:hover': {
      cursor: 'pointer',
    }
  },
  children: {
    paddingLeft: 22,
  }
}));

function Label({ layer }) {
  const { id, label } = layer;
  let checked = useSelector(state => state.layerReducer.label[id]);
  const indeterminate = checked === "indeterminate";
  checked = checked === "indeterminate" || checked;
  const dispatch = useDispatch();
  const classes = useStyles();

  const onChange = event => {
    const isVisible = event.target.checked;
    trackEvent({
      category: LAYERS,
      action: `Change Label ${id}`,
      label: isVisible ? 'Visible' : 'Hidden',
    });
    const data = {id, isVisible };
    dispatch(actionLayerUpdate({ type: 'label', data}));
  }

  const onClick = () => { dispatch(actionLayerExpandLabelUpdated(layer.id)) }

  return (
    <Grid container item xs={12} className={classes.label}>
      <FormControlLabel
          value="show"
          control={
            SWITCH_LABELS_ARRAY.includes(id) ?
            <Switch color="primary" checked={checked} onChange={onChange} size="small" /> :
            <Checkbox color="primary" checked={checked} onChange={onChange} indeterminate={indeterminate}/>
          }
          label={
            <Typography variant="overline" data-testid={`layer-label-type-${layer.id}`}>
              {label}
            </Typography>
          }
        />
      {layer.isParent && <KeyboardArrowDownIcon onClick={onClick} className={classes.expand} />}
    </Grid>
  );
}

function LabelParent({ layer, layers }) {
  const classes = useStyles();
  const expand = useSelector(state => state.layerReducer.expandLabel[layer.id]);
  const childLayers = layers.filter(l => layer.children.indexOf(l.id) !== -1);
  
  return (
    <React.Fragment>
      <Label layer={layer} key={layer.id} />
      <div className={classes.children}>
        <Collapse in={expand}>
          {
            childLayers.length &&
            childLayers.map(childLayer => <Label layer={childLayer} key={childLayer.id} />)
          }
        </Collapse>
      </div>
    </React.Fragment>
  );
}