import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from '@material-ui/core/CircularProgress';
import Pane from './Pane';
import { Pannellum } from "pannellum-react";
import { actionPhotoPaneToggled, actionPhotoHighlightRoomsWithPhotoUpdated, actionPhotoQueryRoomsWithPhoto } from './PhotoRedux';
import RoomInfo from './RoomInfo';
import { PreviousBtn, NextBtn } from './Navigation';

/**
 * todo: handle expired link in penullum
 */

export default function Photo() {
  const dispatch = useDispatch();
  const visible = useSelector(state => state.photoReducer.isVisible);
  const targetRoom = useSelector(state => state.photoReducer.targetRoom);
  const targetPhoto = useSelector(state => state.photoReducer.targetPhoto);
  const isLoading = useSelector(state => state.photoReducer.isLoading);
  const highlightRoomsWithPhoto = useSelector(state => state.photoReducer.highlightRoomsWithPhoto);
  
  const onClose = () => { dispatch(actionPhotoPaneToggled(false)) }
  const onCheckboxChange = event => {
    const { checked } = event.target;
    dispatch(actionPhotoHighlightRoomsWithPhotoUpdated(checked));
    dispatch(actionPhotoQueryRoomsWithPhoto(checked));
  }

  const [photoIndex, setPhotoIndex] = useState(0);
  const previousBtnClicked = () => { setPhotoIndex(photoIndex - 1) }
  const nextBtnClicked = () => { setPhotoIndex(photoIndex + 1) }
  useEffect(() => {
    setPhotoIndex(0);
  }, [targetPhoto]);

  return (
    <Pane visible={visible} onClose={onClose}>
      <RoomInfo
        targetRoom={targetRoom}
        checked={highlightRoomsWithPhoto}
        onCheckboxChange={onCheckboxChange}
        busy={isLoading}
      />
      <LoadingAnimation isVisible={isLoading} />
      <NoImageAvailable isVisible={!isLoading && !targetPhoto}/>
      <Image
        isVisible={!isLoading && targetPhoto && targetPhoto.length}
        targetPhoto={targetPhoto}
        photoIndex={photoIndex}
        previousBtnClicked={previousBtnClicked}
        nextBtnClicked={nextBtnClicked}
      />
    </Pane>
  )
}

function LoadingAnimation({ isVisible }) {
  return (
    <span>
      {
        isVisible &&
        <div style={{textAlign: 'center', marginTop: 100}}>
          <CircularProgress />
        </div>
      }
    </span>
  );
}

function NoImageAvailable({ isVisible }) {
  return (
    <span>
      {
        isVisible &&
        <div style={{textAlign: 'center', marginTop: 100}}>
          No Image Available
        </div>
      }
    </span>
  );
}

function Image({ isVisible, targetPhoto, photoIndex, previousBtnClicked, nextBtnClicked }) {

  return (
    <span>
      {
        isVisible && 
        <div>
          <PreviousBtn
            targetPhoto={targetPhoto}
            currentIndex={photoIndex}
            onClick={previousBtnClicked}
          />
          <Pannellum
            width="100%"
            height="400px"
            image={targetPhoto[photoIndex]}
            autoLoad
            showZoomCtrl={true}
          />
          <NextBtn
            targetPhoto={targetPhoto}
            currentIndex={photoIndex}
            onClick={nextBtnClicked}
          />
        </div>
      }
    </span>
  )
}