import React from 'react';
import { actionMenuToggled, actionMenuSearchTypeToggled, actionMenuSearchUpdateChanged } from './AppBarRedux';
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import clsx from 'clsx';
import Floor from './Floor';
import Menu from './Menu';
import { actionDataSearchRooms } from '../Data';
import { 
  actionSearchVisibleToggled,
  BuildingMenu, RoomMenu, PeopleMenu, DepartmentMenu, RoomtypeMenu,
} from '../Search';

// const umichBlue = '#00274c';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'fixed',
    left: 5,
    top: 5,
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 352,
    minHeight: 48,
    opacity: 0.975,
    [theme.breakpoints.down('xs')]: {
      top: 4,
      left: '50%',
      width: 330,
      minHeight: 40,
      padding: 1,
      transform: 'translate(-50%, 0)',
      opacity: 0.9,
    }
  },
  menuBtn: {
    borderRadius: 0,
    padding: 6,
    color: 'rgba(0, 0, 0, 0.87)',
    '& .MuiSvgIcon-root': {
      fontSize: '1.8rem'
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: 3,
    },
  },
  mgisBtn: {
    padding: 6,
    // color: umichBlue,
    width: 'auto',
    minWidth: 'unset',
    borderRadius: 0,
    '& .MuiButton-label': {
      fontSize: '1rem',
    },
    [theme.breakpoints.down('xs')]: {
      '& .MuiButton-label': {
        fontSize: '0.9rem',
      },
      // display: 'none',
    },
  },
  searchBtn: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    borderLeft: '1px solid lightgrey',
    borderRadius: 0,
    width: '100%',
    lineHeight: 2.1,
    [theme.breakpoints.down('xs')]: {
      width: '60%',    
      lineHeight: 2,
      borderRight: '1px solid lightgrey',
    }
  },
  grid: {
    display: 'flex',
    alignItems: 'center'
  },
  gridLeft: {
    // width: '30%',
    width: '13%',
    // [theme.breakpoints.down('xs')]: {
    //   width: '15%',
    // }
  },
  gridRight: {
    // width: '70%',
    width: '87%',
    // [theme.breakpoints.down('xs')]: {
    //   width: '85%',
    // }
  },
  floor: {
    left: 360,
    position: 'fixed',
    backgroundColor: 'white',
    borderRadius: 5,
    boxShadow: theme.shadows[1],
    [theme.breakpoints.down('xs')]: {
      position: 'fixed',
      left: 240,
      boxShadow: theme.shadows[0],
    }
  },
}));

export default function CustomizedInputBase() {
  const classes = useStyles();
  const appBarVisible = useSelector(state => state.appReducer.appBarVisible);
  const searchType = useSelector(state => state.appBarReducer.searchType);
  const updateSearch = useSelector(state => state.appBarReducer.updateSearch);
  const dispatch = useDispatch();

  const menuClickedHandler = () => {
    dispatch(actionMenuToggled());
  }

  const selectHandler = (data) => {
    dispatch(actionDataSearchRooms(data, updateSearch));
    dispatch(actionMenuSearchTypeToggled(data.type));
    dispatch(actionMenuSearchUpdateChanged(null));
  }

  const onCloseHandler = (menuType) => {
    dispatch(actionMenuSearchTypeToggled(menuType));
  }

  return (
    <div style={{display: appBarVisible ? 'inherit' : 'none'}}>
      <Paper className={classes.root}>
        <Grid container spacing={0}>
          <Grid item className={clsx(classes.grid, classes.gridLeft)}>
            <Tooltip title="Menu">
              <IconButton
                className={classes.menuBtn}
                aria-label="menu"
                onClick={menuClickedHandler}
              >
                <MenuIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item className={clsx(classes.grid, classes.gridRight)}>
            <Tooltip title="Search for Building, Room, Person and more">
              <Button
                onClick={()=>dispatch(actionSearchVisibleToggled())}
                className={classes.searchBtn}
                endIcon={<SearchIcon />}
              >
                Search
              </Button>
            </Tooltip>
            <div className={classes.floor}>
              <Floor />
            </div>
          </Grid>
        </Grid>
      </Paper>
      <Menu />
      <BuildingMenu
        visible={searchType === 'building'}
        selectHandler={selectHandler}
        onCloseHandler={onCloseHandler} />
      <RoomMenu
        visible={searchType === 'room'}
        selectHandler={selectHandler}
        onCloseHandler={onCloseHandler} />
      <PeopleMenu
        visible={searchType === 'person'}
        selectHandler={selectHandler}
        onCloseHandler={onCloseHandler} />
      <DepartmentMenu
        visible={searchType === 'department'}
        selectHandler={selectHandler}
        onCloseHandler={onCloseHandler} />
      <RoomtypeMenu
        visible={searchType === 'roomtype'}
        selectHandler={selectHandler}
        onCloseHandler={onCloseHandler} />
    </div>
  );
}