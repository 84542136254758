import { createAction, handleActions } from 'redux-actions';

export const initialState = {
  /**
   * [{id, title, severity, buttonLabel, onClick, onClose}, {}, {}, ...]
   * @id - alert id
   * @title - main alert text
   * @severity - error | warning | info | success 
   * @buttonLabel - action button title
   * @onClick - callback when button clicked
   * @onClose - close button clicked
   */
  alerts: [],
};

export const actionAlertUpdated = createAction('ALERT_UPDATED');

export const actionAddAlert = (newAlert) => {
  return (dispatch, getState) => {
    if (!newAlert || !newAlert.id || !newAlert.title) { return }
    const alerts = getState().alertReducer.alerts.filter(alert => alert.id !== newAlert.id);
    dispatch(actionAlertUpdated([newAlert, ...alerts]));
  }
}

export const actionUpdateAlert = (id, data) => {
  return (dispatch, getState) => {
    const alerts = getState().alertReducer.alerts.map(alert => {
      if (alert.id === id) {
        alert = {...alert, ...data};
      }
      return alert;
    });
    dispatch(actionAlertUpdated(alerts));
  }
}

export const actionDeleteAlert = (id) => {
  return (dispatch, getState) => {
    const alerts = getState().alertReducer.alerts.filter(alert => alert.id !== id);
    dispatch(actionAlertUpdated(alerts));
  }
}

export const alertReducer = handleActions({
  [actionAlertUpdated]: (state, action) => {
    return { ...state, alerts: action.payload };
  },
}, initialState);