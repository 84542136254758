import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { actionInfoExpandToggled } from './InfoRedux';
import { actionAppToggleComponent } from '../App';
import InfoCards from './InfoCards';
import Accordion from '../Shared/Accordion';

export default function InfoWrapper() {
  const component = 'infoVisible';
  const [subtitle, setSubtitle] = useState(null);
  const [anchorRef, setAnchorRef] = useState(null);
  const [action, setAction] = useState(false);
  const dispatch = useDispatch();

  const visible = useSelector(state => state.appReducer[component]);
  const visibleHandler = () => { dispatch(actionAppToggleComponent({ component })) }

  const expand = useSelector(state => state.infoReducer.isExpanded);
  const expandHandler = () => { dispatch(actionInfoExpandToggled()) }

  const getContainerRef = (ref) => { setAnchorRef(ref) }

  const dataItems = useSelector(state => state.infoReducer.infoData);

  useEffect(() => {
    // reset view by scrolling to top of the card
    if (anchorRef && anchorRef.current && anchorRef.current.scrollTo) {
      anchorRef.current.scrollTo(0, 0);
    }

    if (dataItems.length && !visible) {
      dispatch(actionAppToggleComponent({ component, visible: true }));
    }
    const newSubtitle = toSubtitle(dataItems);

    if (newSubtitle === subtitle) return;
    
    setSubtitle(toSubtitle(dataItems));
    setAction(true);

    const timer = setTimeout(() => {
      setAction(false)
    }, 500);

    return () => clearTimeout(timer);
  }, [dispatch, dataItems, anchorRef]);

  const accordion = {
    visible,
    visibleHandler,
    expand,
    expandHandler,
    getContainerRef,
    action,
  };

  return <Info accordion={accordion} data={dataItems} />
}

export function Info({accordion, data}) {
  const {
    visible,
    visibleHandler,
    expand,
    expandHandler,
    getContainerRef,
    action,
  } = accordion;

  return (
    <Accordion
      title='INFO'
      subtitle={toSubtitle(data)}
      visible={visible}
      visibleHandler={visibleHandler}
      expand={expand}
      expandHandler={expandHandler}
      getContainerRef={getContainerRef}
      action={action}
    >
      <InfoCards dataItems={data} />
    </Accordion>
  );
}

function toSubtitle(dataItems) {
  if (!dataItems || !dataItems.length) {
    return null;
  }
  const [item] = dataItems;

  if (item.type === 'building') {
    return item.bld_descrshort;
  } else if (item.type === 'room') {
    return `${item.bld_descrshort}: Room ${item.rmnbr}`;
  } else if (item.type === 'point') {
    if (item.layer === 'umich-point-occupant') {
      return item.occupant;
    }
  }
}