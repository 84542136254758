import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import CropFreeIcon from '@material-ui/icons/CropFree';
import AddIcon from '@material-ui/icons/Add';
import clsx from 'clsx';
import {
  actionMapDrawModeUpdated,
  actionMapCursorUpdated,
} from '../Map';
import { actionDrawIsDrawingUpdated } from '../Draw';
import { actionAddAlert, actionDeleteAlert } from '../Alert';
import Tooltip from '@material-ui/core/Tooltip';

const useZoomboxStyles = makeStyles(theme => ({
  widget: {
    width: 42,
    minWidth: 'unset',
    backgroundColor: '#ffffff',
    border: '1px solid #f0f0f0',
    [theme.breakpoints.down('xs')]: {
      width: 34,
      '& .MuiSvgIcon-root': {
        fontSize: '1.25rem',
      },
    },
  },
  zoomBox: {
    marginBottom: 2,
    [theme.breakpoints.down('xs')]: {
      display: 'none !important',
    },
  },
  zoomboxActive: {
    backgroundColor: '#4555b5',
    color: 'white',
  },
}));

// @bug: when user is drawing and click close mode
export default function ZoomBox() {
  const mode = 'draw_zoom_box';
  const classes = useZoomboxStyles();
  const dispatch = useDispatch();
  const drawMode = useSelector(state => state.mapReducer.drawMode);

  const zoomBoxClickHandler = () => {
    if (drawMode.mode !== mode) {
      dispatch(actionMapDrawModeUpdated({ mode }));
      dispatch(actionDrawIsDrawingUpdated(true));
      dispatch(actionMapCursorUpdated({ drawMode: true }));
    } else {
      cancelModeHandler();
    }
  }

  const cancelModeHandler = () => {
    dispatch(actionMapDrawModeUpdated({ mode: 'simple_select' }));
    dispatch(actionDrawIsDrawingUpdated(false));
    dispatch(actionMapCursorUpdated({ drawMode: false }));
  }

  useEffect(() => {
    const cancelModeHandler = () => {
      dispatch(actionMapDrawModeUpdated({ mode: 'simple_select' }));
      dispatch(actionDrawIsDrawingUpdated(false));
      dispatch(actionMapCursorUpdated({ drawMode: false }));
    }

    if (drawMode.mode === mode) {
      dispatch(actionAddAlert({
        id: mode,
        title: 'Zoom Box - Click and Drag to Draw Box on Map',
        onClose: cancelModeHandler,
      }));
    } else {
      dispatch(actionDeleteAlert(mode));
    }
  }, [dispatch, drawMode]);

  return (
    <React.Fragment>
      <Tooltip title="Zoom Box">
        <Button
          variant="contained"
          className={clsx(
            classes.widget,
            classes.zoomBox,
            {[classes.zoomboxActive]: drawMode.mode === mode} 
            )}
          onClick={zoomBoxClickHandler}
        >
          <CropFreeIcon style={{fontSize: '1.75rem'}}/>
          <AddIcon style={{fontSize: '1.2rem', position: 'absolute'}} />
        </Button>
      </Tooltip>
    </React.Fragment>
  );
}
