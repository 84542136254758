import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import * as turf from '@turf/turf';

export default function TotalPolygonArea({ features }) {
  const SQM_TO_SQFT = 10.76391042;
  const containerStyle = {
    paddingTop: 4,
    borderTop: '1px double rgba(0, 0, 0, 0.25)'
  };
  const btnStyle = {
    fontSize: '0.6rem',
    padding: '0 2px',
    borderRadius: 0,
  };

  const [unit, setUnit] = useState('sqft');
  const [sumArea, setSumArea] = useState(() => sumPolygonArea(features));

  useEffect(() => {
    setSumArea(sumPolygonArea(features));
  }, [features])

  const onClick = () => {
    if (unit === 'sqft') {
      setUnit('sqm');
      setSumArea(sumArea * SQM_TO_SQFT);
    } else {
      setUnit('sqft');
      setSumArea(sumArea / SQM_TO_SQFT);
    }
  }

  return (
    <div>
      <Grid container alignItems="center">
        <Grid item xs={1}></Grid>
        <Grid item xs={4}></Grid>
        <Grid container item xs={4} alignItems='center'>
          <div style={containerStyle}>
            <Tooltip title="Total Polygon Area">
              <Button
                size="small"
                color="default"
                style={btnStyle}
                onClick={onClick}
              >
                {`${sumArea.toFixed(2)} ${unit}`}
              </Button>
            </Tooltip>
          </div>
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={1}></Grid>
      </Grid>
    </div>
  );
}

function sumPolygonArea(features) {
  const SQM_TO_SQFT = 10.76391042;
  return features.reduce((sum, feature) => {
    const hasRoomSqft = feature && feature.properties && feature.properties.rmsqrft;
    const isPolygon = feature && feature.geometry && feature.geometry.type === 'Polygon';
    
    if (hasRoomSqft) {
      sum += feature.properties.rmsqrft;
    } else if (isPolygon) {
      sum += turf.area(feature.geometry)*SQM_TO_SQFT;
    }

    return sum;
  }, 0.0);
}