import React, { useState } from 'react';
import { actionLayerUpdate } from './LayerRedux';
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Collapse from '@material-ui/core/Collapse';
import SettingsIcon from '@material-ui/icons/Settings';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import Input from '@material-ui/core/Input';
import ReplayIcon from '@material-ui/icons/Replay';
import { defaultState } from './LayerData';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import { SwatchesPicker } from "react-color";
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { trackEvent, LAYERS } from '../../tracking';

const useSettingStyles = makeStyles(theme => ({
  container: {
    width: '100%',
  },
  button: {
    display: 'block',
    marginLeft: 'auto',
    '& .MuiButton-label': {
      fontSize: '0.7rem'
    }
  },
  labelIcon: {
    position: 'relative',
    top: 5,
  },
  root: {
    padding: 20,
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    borderRadius: 4,
  },
  input: {
    width: 42,
  },
  icon: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  colorIndicator: {
    width: 25,
    height: 25,
    '&:hover': {
      cursor: 'pointer',
    }
  },
  colorPalette: {
    position: 'relative',
  }
}));

export default function LabelSettings() {
  const classes = useSettingStyles();
  const [expand, setExpand] = useState(false);
  const onClick = () => { setExpand(!expand) }

  return (
    <div className={classes.container} style={{marginBottom: expand ? 10 : -30 }}>
      <Tooltip title="Settings">
        <Button
             color="primary"
             size="small"
             className={classes.button}
             onClick={onClick}
             startIcon={<SettingsIcon/>}
        >
          Font Settings
        </Button>
      </Tooltip>
      <Collapse in={expand}>
        <div className={classes.root}>
          <FontSizeControl />
          <FontColorControl />
          <div style={{textAlign: 'center'}}>
            <Button size="small" onClick={onClick}>Hide Settings</Button>
          </div>
        </div>
      </Collapse>
    </div>
  );
}

function FontSizeControl() {
  const classes = useSettingStyles();
  const dispatch = useDispatch();
  const fontSize = useSelector(state => state.layerReducer.labelSettings.fontSize);

  const handleSliderChange = (event, newValue) => {
    trackEvent({
      category: LAYERS,
      action: 'Change Label Size',
      label: 'Slider',
      value: newValue,
    });
    dispatch(actionLayerUpdate({ type: 'label-settings', field: 'fontSize', value: newValue}));
  };

  const handleInputChange = (event) => {
    const newValue = event.target.value === '' ? '' : Number(event.target.value)
    trackEvent({
      category: LAYERS,
      action: 'Change Label Size',
      label: 'Input',
      value: newValue,
    });
    dispatch(actionLayerUpdate({ type: 'label-settings', field: 'fontSize', value: newValue}));
  };

  const handleBlur = () => {
    if (fontSize < 1) {
      dispatch(actionLayerUpdate({ type: 'label-settings', field: 'fontSize', value: 1}));
    }
  };

  const resetHandler = () => {
    trackEvent({
      category: LAYERS,
      action: 'Change Label Size',
      label: 'Reset',
      value: defaultState.labelPrimarySize
    });
    dispatch(actionLayerUpdate({ type: 'label-settings', field: 'fontSize', value: defaultState.labelPrimarySize }));
  }

  return (
    <React.Fragment>
      <Typography id="input-label-font-size-slider" variant="overline" gutterBottom>
        Font Size 
      </Typography>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs>
          <Slider
            value={typeof fontSize === 'number' ? fontSize : 1}
            onChange={handleSliderChange}
            aria-labelledby="input-label-font-size-slider"
            marks={true}
            step={1}
            min={1}
            max={30}
          />
        </Grid>
        <Grid item>
          <Input
            className={classes.input}
            value={fontSize}
            margin="dense"
            onChange={handleInputChange}
            onBlur={handleBlur}
            inputProps={{
              step: 1,
              min: 1,
              max: 100,
              type: 'number',
              'aria-labelledby': 'input-label-font-size-slider',
            }}
          />
        </Grid>
        <Grid item>
          <Tooltip title="Reset">
            <ReplayIcon onClick={resetHandler} className={classes.icon}/>
          </Tooltip>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
function FontColorControl() {
  const classes = useSettingStyles();
  const dispatch = useDispatch();
  const fontColor = useSelector(state => state.layerReducer.labelSettings.fontColor);
  const [showPalette, setShowPalette] = useState(false);

  const togglePalette = () => { setShowPalette(!showPalette) }
  const handleClose = () => { setShowPalette(false) }

  const onChangeComplete = color => { 
    trackEvent({
      category: LAYERS,
      action: 'Change Label Color',
      label: color.hex,
    });
    dispatch(actionLayerUpdate({ type: 'label-settings', field: 'fontColor', value: color.hex}));
  }
  const resetHandler = () => {
    trackEvent({
      category: LAYERS,
      action: 'Change Label Color',
      label: 'Reset',
    });
    dispatch(actionLayerUpdate({ type: 'label-settings', field: 'fontColor', value: defaultState.labelColor}));
  }

  return (
    <React.Fragment>
      <Typography id="input-label-font-size-slider" variant="overline" gutterBottom>
         Color 
      </Typography>
      <ClickAwayListener onClickAway={handleClose}>
        <Grid container spacing={2} alignItems="center">
          <Grid item style={{paddingTop: 2}}>
            <div
              style={{backgroundColor: fontColor}}
              className={classes.colorIndicator}
              onClick={togglePalette}
            >
            </div>
          </Grid>
          <Grid item>
            <Tooltip title="Reset">
              <ReplayIcon onClick={resetHandler} className={classes.icon}/>
            </Tooltip>
          </Grid>
          <Grid item>
            <div style={{display: showPalette ? 'block' : 'none'}} className={classes.colorPalette}>
              <SwatchesPicker color={fontColor} onChangeComplete={onChangeComplete} />
            </div>
          </Grid>
        </Grid>
      </ClickAwayListener>
    </React.Fragment>
  );
}