import { createAction, handleActions } from 'redux-actions';
import axios from 'axios';
import Papaparse from 'papaparse';
import { xlsx, csv } from '../Shared/downloader';
import { actionMapUpdateZoomFeatures, actionMapUpdateActiveFeature } from '../Map/MapRedux';
import { searchQuery } from '../Search/searchApi';

const BULKDATA_URL = `${process.env.REACT_APP_API_BASE_URL}/bulkdata?`;
const PDFLIST_URL = `${process.env.REACT_APP_API_BASE_URL}/pdflist?`;

export const initialState = {
  isExpanded: false,
  infoData: [],
  // maxDataCount: 10,
  maxDataCount: 1,
  roomInfoIndex: 0,
  buildingInfoIndex: 0,
  buildingActionBtnExpanded: false,
  roomActionBtnExpanded: false,
  fileDownloadFormat: 'csv',
  floorExpanded: true,
  floorShowAll: false,
};

export const actionInfoExpandToggled = createAction('INFO_EXPAND_TOGGLED');
export const actionInfoDataAdded = createAction('INFO_DATA_ADDED');
export const actionInfoRoomIndexUpdated = createAction('INFO_ROOM_INDEX_UPDATED');
export const actionInfoBuildingIndexUpdated = createAction('INFO_BUILDING_INDEX_UPDATED');
export const actionInfoBuildingActionBtnToggled = createAction('INFO_BUILDING_ACTION_BTN_TOGGLED');
export const actionInfoRoomActionBtnToggled = createAction('INFO_ROOM_ACTION_BTN_TOGGLED');
export const actionInfoDownloadFormatUpdated = createAction('INFO_DATA_DOWNLOAD_FORMAT_UPDATED');
export const actionInfoFloorExpandedUpdated = createAction('INFO_FLOOR_EXPANDED_UPDATED');
export const actionInfoFloorShowAllUpdated = createAction('INFO_FLOOR_SHOW_ALL_UPDATED');

export const actionInfoAddData = (data) => {
  return (dispatch, getState) => {
    const { isDrawing } = getState().drawReducer;
    if (isDrawing) { return }
    
    const { maxDataCount, infoData } = getState().infoReducer;
    const newInfoData = normalizeInfoData(data, maxDataCount, infoData);
    dispatch(actionInfoDataAdded(newInfoData));
  }
}

export const actionInfoBulkDownload = (dataType, building, floor, cb) => {
  return (dispatch, getState) => {
    const fileDownloadFormat = getState().infoReducer.fileDownloadFormat;
    const cred = getState().authReducer.idToken;

    const tableParam = `table=${dataType}`;
    const bldgParam = `&bldrecnbr=${building}`;
    const floorParam = floor ? `&floor=${floor}` : '';

    const requestUrl = `${BULKDATA_URL}${tableParam}${bldgParam}${floorParam}`;
    const opt = {
      headers: {
        "Authorization": `Bearer ${cred}`
      } 
    };

    axios.get(requestUrl, opt)
      .then(result => {
        return axios.get(result.data);
      })
      .then(file => {
        const parsed = Papaparse.parse(file.data);
        const filename = makeFilename(building, floor, dataType);

        if (fileDownloadFormat === 'csv') {
          csv({ name: filename, data: file.data})
        } else if (fileDownloadFormat === 'xlsx') {
          xlsx({ name: filename, data: parsed.data });
        } else {
          xlsx({ name: filename, data: parsed.data });
        }
      })
      .catch(error => {
        alert('Error downloading data');
        console.error(error);
      })
      .finally(() => {
        cb && cb();
      });
  }
}

export const actionInfoPDFDownload = (building, cb) => {
  return (dispatch, getState) => {
    const cred = getState().authReducer.idToken;
    const requestUrl = `${PDFLIST_URL}bldrecnbr=${building}`;
    const opt = {
      headers: {
        "Authorization": `Bearer ${cred}`
      } 
    };

    axios.get(requestUrl, opt)
      .then(result => {
        cb && cb(result.data);
      })
      .catch(error => {
        alert('Error downloading data');
        console.error(error);
      });
  }
}

export const actionInfoDataAddedBackground = (payload) => {
  return (dispatch, getState) => {
    if (getState().drawReducer.isDrawing) { return }

    const { infoVisible } = getState().appReducer;
    const { isExpanded: infoExpanded } = getState().infoReducer;
    if (!infoVisible && infoExpanded) {
      dispatch(actionInfoExpandToggled());
    }
    dispatch(actionInfoDataAdded(payload.data));
  }
}

export const actionInfoDataAddedForeground = (payload) => {
  return (dispatch, getState) => {
    if (getState().drawReducer.isDrawing) { return }

    const { infoVisible } = getState().appReducer;
    const { isExpanded: infoExpanded } = getState().infoReducer;
    if (!infoVisible && !infoExpanded ) {
      dispatch(actionInfoExpandToggled());
    }
    dispatch(actionInfoDataAdded(payload.data))
  }
}

export const actionInfoZoomAndHighlight = (bldrecnbr, targetFloor) => {
  return (dispatch, getState) => {
    const floor = targetFloor || getState().appBarReducer.floor || '01';
    dispatch(actionMapUpdateZoomFeatures({ type: 'brn', data: bldrecnbr }));
    dispatch(actionMapUpdateActiveFeature({ bldrecnbr, floor }));
  }
}

export const actionInfoQueryAndSetData = (dataId, type) => {
  return (dispatch, getState) => {
    const idToken = getState().authReducer.idToken;
    let query;

    if (type === 'building') {
      query = searchQuery(`/buildings/${dataId}`, idToken);
    } else if (type === 'room') {
      query = searchQuery(`/rooms/${dataId}`, idToken);
    }

    query.then(result => {
      if (result.data && result.data.length) {
        const [data] = result.data;
        dispatch(actionInfoDataAdded([{ ...data, type }]));
      } else {
        console.error(result);
        alert('Operation Failed');
      }
    });
  }
}

export const infoReducer = handleActions({
  [actionInfoExpandToggled]: (state) => {
    return {...state, isExpanded: !state.isExpanded };
  },
  [actionInfoDataAdded]: (state, action) => {
    return { ...state, infoData: action.payload };
  },
  [actionInfoDownloadFormatUpdated]: (state, action) => {
    return { ...state, fileDownloadFormat: action.payload };
  },
  [actionInfoRoomIndexUpdated]: (state, action) => {
    return { ...state, roomInfoIndex: action.payload };
  },
  [actionInfoBuildingIndexUpdated]: (state, action) => {
    return { ...state, buildingInfoIndex: action.payload };
  },
  [actionInfoBuildingActionBtnToggled]: (state, action) => {
    return { ...state, buildingActionBtnExpanded: !state.buildingActionBtnExpanded }
  },
  [actionInfoRoomActionBtnToggled]: (state, action) => {
    return {...state, roomActionBtnExpanded: !state.roomActionBtnExpanded };
  },
  [actionInfoFloorExpandedUpdated]: (state, action) => {
    return { ...state, floorExpanded: !state.floorExpanded };
  },
  [actionInfoFloorShowAllUpdated]: (state, action) => {
    return { ...state, floorShowAll: !state.floorShowAll };
  },
}, initialState);

// util
export function normalizeInfoData(newItems, maxDataCount, currentInfoData) {
  const MAX_LENGTH = maxDataCount;
  const set = new Set();
  const infoData = [...newItems, ...currentInfoData].reduce((acc, item) => {
    if (set.has(item.id)) { // remove duplicates
      return acc;
    }
    set.add(item.id);
    acc.push(item);
    return acc;    
  }, []);

  if (infoData.length > MAX_LENGTH) {
    infoData.splice(MAX_LENGTH);
  } 

  return infoData;
}

function makeFilename(building, floor, dataType) {
  const label = {
    room: 'room',
    perrol: 'occupant',
    occdept: 'dept'
  };

  const floorStr = floor ? `-${floor}` : '';
  const dataTypeStr = `-${label[dataType]}`;

  return `${building}${floorStr}${dataTypeStr}`;
}
