import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from "react-redux";
import { actionLayerSingleBuildingModeUpdated } from '../Layer';
import { actionAddAlert, actionDeleteAlert } from '../Alert';
import { INFO, trackEvent } from '../../tracking';

const useStyles = makeStyles((theme) => ({
  btn: {
    margin: 2,
    paddingRight: 0,
    paddingTop: 0,
    paddingBottom: 0,
    '& .MuiFormControlLabel-root': {
      marginRight: 0,
    },
    '& .MuiButtonBase-root.MuiIconButton-root': {
      padding: '0px 6px 0px 4px',
    },
    '& .MuiTypography-root.MuiFormControlLabel-label': {
      fontSize: '0.8125rem',
      fontWeight: '500',
      lineHeight: '2.2',
      paddingRight: 10
    },
  },
}));

export default function SingleBuildingMode({ bldrecnbr }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const singleBuildingMode = useSelector(state => state.layerReducer.singleBuildingMode);
  const checked = !!singleBuildingMode && !!singleBuildingMode.bldrecnbr;

  const handleChange = (event) => {
    if (event.target.checked) {
      dispatch(actionLayerSingleBuildingModeUpdated(bldrecnbr))
      trackEvent({
        category: INFO,
        action: 'Single Building Mode',
        label: bldrecnbr
      });
    } else {
      dispatch(actionLayerSingleBuildingModeUpdated(null));
    }
  }

  const quitMode = () => { dispatch(actionLayerSingleBuildingModeUpdated(null)) }

  if (checked) {
    dispatch(actionAddAlert({
      id: 'sbv',
      title: 'Single Building View',
      buttonLabel: 'QUIT',
      onClick: quitMode,
      onClose: false
    }));
  } else {
    dispatch(actionDeleteAlert('sbv'));
  }

  return (
    <Tooltip title="Single Building View">
      <Button
        variant="outlined"
        color="default"
        size="small"
        className={classes.btn}
      >
        <FormControlLabel
          value="end"
          control={
            <Checkbox
              checked={checked}
              color="primary"
              onChange={handleChange}
            />
          }
          label="SINGLE"
          labelPlacement="end"
        />
      </Button>
    </Tooltip>
  );
}
