import React, { useState, useEffect } from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import CircularProgress from '@material-ui/core/CircularProgress';
import Radio from '@material-ui/core/Radio';
import { useDispatch } from "react-redux";
import { actionInfoPDFDownload } from './InfoRedux';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import Button from '@material-ui/core/Button';
import { trackEvent, INFO } from '../../tracking';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiPaper-root.MuiDialog-paper': {
      width: 400,
    }
  },
  title: {
    textAlign: 'center',
  },
  btn: {
    marginRight: 4,
  },
  radio: {
    margin: 8,
    textAlign: 'center',
    '& .MuiButtonBase-root.MuiIconButton-root': {
      padding: 2,
    }
  },
  faIconButton: {
    margin: 4,
    '& svg': {
      marginRight: 8,
    },
  },
  floors: {
    margin: 10,
    marginBottom: 30,
    textAlign: 'center'
  },
}));

export default function PDFDownloadDialog({ open, onClose, bldrecnbr, name, floor }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const titleStyle = { backgroundColor: 'rgba(0, 0, 0, 0.05)'}
  const progressStyle = { position: 'absolute', top: '50%', left: '45%' };
  const floorStr = floor ? `(Floor ${floor})` : '';

  const [isLoading, setIsLoading] = useState(true);

  const [pdfType, setPdfType] = useState('white');
  const handleRadioChange = event => { setPdfType(event.target.value) }

  const handleClose = () => {
    setIsLoading(false);
    onClose();
  };

  const [downloadData, setDownladData] = useState(null);
  useEffect(() => {
    if (open && bldrecnbr) {
      dispatch(actionInfoPDFDownload(bldrecnbr, data => {
        setDownladData(data);
        setIsLoading(false);
      }));
    }
  }, [open, bldrecnbr, dispatch]);

  return (
    <Dialog
      className={classes.root}
      onClose={handleClose}
      aria-labelledby="pdf-download"
      open={open}
      scroll="paper"
    >
      <DialogTitle className={classes.title} style={titleStyle}>PDF Download - {name} {floorStr}</DialogTitle>
      <DownloadFormatInput pdfType={pdfType} handleRadioChange={handleRadioChange} isLoading={isLoading}/>
      <FloorList pdfType={pdfType} downloadData={downloadData} floor={floor}/>
      {isLoading && <CircularProgress style={progressStyle} />}
    </Dialog>
  );
}

function DownloadFormatInput({ pdfType, handleRadioChange, isLoading }) {
  const classes = useStyles();

  return (
    <div className={classes.radio}>
      <FormControl component="fieldset">
        <RadioGroup aria-label="gender" name="pdf-type" value={pdfType} onChange={handleRadioChange} >
          <FormControlLabel value="white" control={<Radio color="primary" disabled={isLoading} />} label="Black & White" />
          <FormControlLabel value="rmtyp" control={<Radio color="primary" disabled={isLoading} />} label="Room Type" />
          <FormControlLabel value="deptid" control={<Radio color="primary" disabled={isLoading} />} label="Department" />
        </RadioGroup>
      </FormControl>
    </div>
  );
}

function FloorList({ downloadData, pdfType, floor }) {
  const classes = useStyles();
  const isValid = !(!downloadData || !downloadData[pdfType] || !downloadData[pdfType].length);
  const data = isValid ? downloadData[pdfType] : [];
  const formatted = data.map(entry => {
    const newEntry = {};
    for (let floor in entry) {
      newEntry['floor'] = floor;
      newEntry['url'] = entry[floor];
    }
    return newEntry;
  });
  const filtered = floor ? formatted.filter(entry => entry.floor === floor) : formatted;
  
  const handleEventTracking = () => {
    trackEvent({
      category: INFO,
      action: 'Download PDF',
      label: pdfType
    });
  }

  return (
    <div className={classes.floors}>
      {
        filtered.map(entry => {
          return (
            <Button
              key={entry.floor}
              variant="contained"
              color="default"
              size="small"
              className={classes.faIconButton}
            >
              <FontAwesomeIcon icon={faExternalLinkAlt} /> 
              <a onClick={handleEventTracking} href={entry.url} target="_blank" rel="noopener noreferrer">{`FlOOR ${entry.floor}`}</a>
            </Button>
          )
        })
      }
    </div>
  );
}
