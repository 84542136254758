import React from 'react';
// import { useDispatch } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
// import IconButton from '@material-ui/core/IconButton';
// import RoomIcon from '@material-ui/icons/Room';
// import { actionInfoQueryAndSetData } from './InfoRedux';
// import { actionDataSearchRooms } from '../Data/dataRedux';
// import { actionMapUpdateZoomFeatures } from '../Map/MapRedux';
// import RoomActions from './RoomActions';
// import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
// import clsx from 'clsx';
// import { CopyToClipboard } from 'react-copy-to-clipboard';

const useBasicInfoStyles = makeStyles(theme => ({
  root: {
    padding: '20px',
    '& .MuiTypography-overline': {
      lineHeight: 1.25,
      letterSpacing: '0.03em'
    },
    '& .MuiButton-label': {
      color: 'black'
    }
  },
  id: {
    color: 'grey',
  },
  temp: {
    // display: 'flex',
    // justifyContent: 'space-between',
    marginBottom: '10px',
    fontSize: '1rem'
  },
  stack: {
    flexGrow: '1',
    display: 'inline-block',
    '& .MuiButton-root': {
      lineHeight: 1,
    },
    '& .MuiTypography-overline': {
      lineHeight: 1,
    }
  },
  dep: {
    fontSize: '0.65rem',
    letterSpacing: '0.01em !important',
    fontWeight: 'bold',
    color: 'rgb(0, 100, 177)',
  },
  circleIcon: {
    color: 'white !important',
    backgroundColor: 'rgb(0, 100, 177)',
    borderRadius: '50%',
    letterSpacing: '0',
    display: 'inline-block',
    width: 18,
    height: 18,
    fontSize: '0.55rem',
    lineHeight: '18px',
    textAlign: 'center',
    marginLeft: 6,
  },
  title: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    display: 'inline-block',
    letterSpacing: '0 !important',
  },
  buildingBtn: {
    padding: 0,
    minWidth: 'auto',
    borderRadius: 0,
    fontWeight: '400',
    color: '#4455b5',
    display: 'block',
    fontSize: '0.6rem',
    marginBottom: 2,
  },
  primary: {
    fontWeight: 'bold',
    letterSpacing: 0,
    fontSize: '0.7rem'
  },
  secondary: {
    color: 'grey',
    fontSize: '0.6rem',
    letterSpacing: '0 !important',
  },
  tableRow: {
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.025)',
    }
  },
  actionIcon: {
    padding: 2,
    marginRight: 2,
    marginTop: -3,
    fontSize: '1rem',
    '& .MuiSvgIcon-root': {
      fontSize: '1rem',
    }
  },
  clickable: {
    color: 'rgb(0, 100, 177)',
    '&:hover': {
      textDecoration: 'underline',
    }
  }
}));

export default function RestroomLayerInfo({ room={} }) {
  const classes = useBasicInfoStyles();
  // const dispatch = useDispatch();

  const {
    rmnbr,
    institutional_intent,
    user_type,
    gender_designation,

    accessible_room,
    countertops,

    num_toilets,
    num_urinals,
    num_sinks,
    num_showers,
    num_lockers,
    num_breast_pump,

    changing_table,
    menstrual_products,
  } = room;

  const formatOutput = (attr) => {
    if (rmnbr && attr === undefined) {
      return 'NO DATA';
    } else if (rmnbr && attr) {
      return attr;
    } else {
      return "";
    }
  }

  const isVisible = {visibility: !!rmnbr ? 'visible' : 'hidden'};

  return (
    <div className={classes.root}>    

      <div style={isVisible}>

        <div className={classes.temp}>
          <div className={classes.stack} style={{width: '31%'}}>
            <Typography component="div" variant="overline">
              <span className={classes.secondary}>ROOM</span>
            </Typography>
            <Typography component="div" variant="overline">
                <Button
                  size="small"
                  style={{padding: 0, minWidth: 'auto', fontSize: 'inherit'}}
                  // onClick={searchRoomtype}
                  className={classes.clickable}
                >
                  <span className={classes.primary} data-testid="info-room-rmtyp-name">{rmnbr}</span>
                </Button>
            </Typography>
          </div>
          <div className={classes.stack}  style={{width: '31%'}}>
            <Typography component="div" variant="overline">
              <span className={classes.secondary}>Gender </span>
            </Typography>
            <Typography component="div" variant="overline">
              {/* <Tooltip title={`Search ${rmtyp_descrshort.toUpperCase()} in ${bld_descrshort}`}> */}
                <Button
                  size="small"
                  style={{padding: 0, minWidth: 'auto', fontSize: 'inherit'}}
                  // onClick={searchRoomtype}
                  className={classes.clickable}
                >
                  <span className={classes.primary} data-testid="info-room-rmtyp-name">{formatOutput(gender_designation)}</span>
                </Button>
              {/* </Tooltip> */}
            </Typography>
          </div>
          <div className={classes.stack} style={{width: '31%'}}>
            <Typography component="div" variant="overline">
              <span className={classes.secondary}>Intent </span>
            </Typography>
            <Typography component="div" variant="overline">
                <Button
                  size="small"
                  style={{padding: 0, minWidth: 'auto', fontSize: 'inherit'}}
                  // onClick={searchRoomtype}
                  className={classes.clickable}
                >
                  <span className={classes.primary} data-testid="info-room-rmtyp-name">
                      {formatOutput(institutional_intent)}
                  </span>
                </Button>
            </Typography>
          </div>
        </div>

        <div className={classes.temp}>
          <div className={classes.stack} style={{width: '31%'}}>
            <Typography component="div" variant="overline">
              <span className={classes.secondary}>Accessible</span>
            </Typography>
            <Typography component="div" variant="overline">
              {/* <Tooltip title={`Search ${rmtyp_descrshort.toUpperCase()} in ${bld_descrshort}`}> */}
                <Button
                  size="small"
                  style={{padding: 0, minWidth: 'auto', fontSize: 'inherit'}}
                  // onClick={searchRoomtype}
                  className={classes.clickable}
                >
                  <span className={classes.primary} data-testid="info-room-rmtyp-name">
                      {formatOutput(accessible_room)}
                  </span>
                </Button>
              {/* </Tooltip> */}
            </Typography>
          </div>
          <div className={classes.stack} style={{width: '31%'}}>
            <Typography component="div" variant="overline">
              <span className={classes.secondary}>Countertops </span>
            </Typography>
            <Typography component="div" variant="overline">
              {/* <Tooltip title={`Search ${rmtyp_descrshort.toUpperCase()} in ${bld_descrshort}`}> */}
                <Button
                  size="small"
                  style={{padding: 0, minWidth: 'auto', fontSize: 'inherit'}}
                  // onClick={searchRoomtype}
                  className={classes.clickable}
                >
                  <span className={classes.primary} data-testid="info-room-rmtyp-name">{formatOutput(countertops)}</span>
                </Button>
              {/* </Tooltip> */}
            </Typography>
          </div>
          <div className={classes.stack} style={{width: '31%'}}>
            <Typography component="div" variant="overline">
              <span className={classes.secondary}>TYPE </span>
            </Typography>
            <Typography component="div" variant="overline">
              {/* <Tooltip title={`Search ${rmtyp_descrshort.toUpperCase()} in ${bld_descrshort}`}> */}
                <Button
                  size="small"
                  style={{padding: 0, minWidth: 'auto', fontSize: 'inherit'}}
                  // onClick={searchRoomtype}
                  className={classes.clickable}
                >
                  <span className={classes.primary} data-testid="info-room-rmtyp-name">{formatOutput(user_type)}</span>
                </Button>
              {/* </Tooltip> */}
            </Typography>
          </div>
        </div>

        <div>
        <div className={classes.stack} style={{width: '31%'}}>
            <Typography component="div" variant="overline">
              <span className={classes.secondary}>Changing Table </span>
            </Typography>
            <Typography component="div" variant="overline">
              {/* <Tooltip title={`Search ${rmtyp_descrshort.toUpperCase()} in ${bld_descrshort}`}> */}
                <Button
                  size="small"
                  style={{padding: 0, minWidth: 'auto', fontSize: 'inherit'}}
                  // onClick={searchRoomtype}
                  className={classes.clickable}
                >
                  <span className={classes.primary} data-testid="info-room-rmtyp-name">{formatOutput(changing_table)}</span>
                </Button>
              {/* </Tooltip> */}
            </Typography>
          </div>
          <div className={classes.stack}>
            <Typography component="div" variant="overline">
              <span className={classes.secondary}>Menstrual Product  </span>
            </Typography>
            <Typography component="div" variant="overline">
              {/* <Tooltip title={`Search ${rmtyp_descrshort.toUpperCase()} in ${bld_descrshort}`}> */}
                <Button
                  size="small"
                  style={{padding: 0, minWidth: 'auto', fontSize: 'inherit'}}
                  // onClick={searchRoomtype}
                  className={classes.clickable}
                >
                  <span className={classes.primary} data-testid="info-room-rmtyp-name">{formatOutput(menstrual_products)}</span>
                </Button>
              {/* </Tooltip> */}
            </Typography>
          </div>
        </div>
      
        <div style={{marginTop: 15, marginBottom: 10}}>
          <div className={classes.stack} style={{width: '31%'}}>
            <Typography component="div" variant="overline" style={{marginBottom: 4}}>
              <span className={classes.primary} data-testid="info-room-sqft">{formatOutput(num_toilets)}</span>
              <span className={classes.secondary} style={{marginLeft: 2}}>
                Toilets
              </span>      
            </Typography>
            <Typography component="div" variant="overline">
              <span className={classes.primary} data-testid="info-room-rmstationcnt">{formatOutput(num_urinals)}</span>
              <span className={classes.secondary} style={{marginLeft: 2}}>
                Urinals
              </span>  
            </Typography>
          </div>
          <div className={classes.stack} style={{width: '31%'}}>
            <Typography component="div" variant="overline"  style={{marginBottom: 4}}>
              <span className={classes.primary} data-testid="info-room-sqft">{formatOutput(num_sinks)}</span>
              <span className={classes.secondary} style={{marginLeft: 2}}>
                Sinks
              </span>      
            </Typography>
            <Typography component="div" variant="overline">
              <span className={classes.primary} data-testid="info-room-rmstationcnt">{num_showers}</span>
              <span className={classes.secondary} style={{marginLeft: 2}}>
                Showers
              </span>  
            </Typography>
          </div>
          <div className={classes.stack} style={{width: '31%'}}>
            <Typography component="div" variant="overline"  style={{marginBottom: 4}}>
              <span className={classes.primary} data-testid="info-room-sqft">{formatOutput(num_lockers)}</span>
              <span className={classes.secondary} style={{marginLeft: 2}}>
                Lockers
              </span>      
            </Typography>
            <Typography component="div" variant="overline">
              <span className={classes.primary} data-testid="info-room-rmstationcnt">{formatOutput(num_breast_pump)}</span>
              <span className={classes.secondary} style={{marginLeft: 2}}>
                Breast Pumps
              </span>  
            </Typography>
          </div>
        </div>

      </div>

    </div>
  );
}
