import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ClearIcon from '@material-ui/icons/Clear';
import Grid from '@material-ui/core/Grid';
import * as turf from '@turf/turf';
import {
  actionMapZoomFeaturesUpdated,
  actionMapDrawModeUpdated,
} from '../Map';
import { actionAppBarFloorUpdated } from '../AppBar';
import { actionDrawUpdateGroupFeature } from './drawRedux';
import { Tooltip } from '@material-ui/core';


const useFeatureStyles = makeStyles((theme) => ({
  feature: {
    '&:hover': {
      backgroundColor: theme.palette.grey[50],
    },
    '&:hover .MuiGrid-item': {
      visibility: 'visible',
    }
  },
  featureDelete: {
    visibility: 'hidden',
    '&:hover': {
      cursor: 'pointer',
    }
  },
  floor: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '0.7rem',
  }
}));

export default function Feature({ feature, onColorPickerClick }) {
  const classes = useFeatureStyles();
  const dispatch = useDispatch();
  
  const { id, properties } = feature;
  const { groupId, name, floor } = properties || {};

  const onClickFeature = () => {
    dispatch(actionMapZoomFeaturesUpdated({ data: feature }));
    dispatch(actionAppBarFloorUpdated(floor === 'BLDG' ? '' : floor));
  }

  const onRemoveFeature = () => {
    dispatch(actionDrawUpdateGroupFeature('delete', {id, groupId}));
    dispatch(actionMapDrawModeUpdated({mode: 'delete', data: id}));
  }

  return (
      <div>
        <Grid container alignItems="center" className={classes.feature}>
          <Grid item xs={1} className={classes.featureDelete}>
            <ClearIcon fontSize="small" onClick={onRemoveFeature}/>
          </Grid>
          <Grid item xs={4}>
            <Button size="small" color="primary" onClick={onClickFeature}>
              {name}
            </Button>
            <RoomRecordNumber feature={feature}/>
          </Grid>
          <Grid container item xs={4} alignItems='center'>
            <Measurement feature={feature} />
          </Grid>
          <Grid item xs={2}>
            <Typography variant="button" className={classes.floor}>
              {floor}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Color feature={feature} onColorPickerClick={onColorPickerClick} />
          </Grid>
        </Grid>
      </div>
  );
}

function Measurement({ feature={} }) {
  const { geometry, properties } = {geometry:{}, properties:{}, ...feature};
  const { type } = geometry;

  const isPolygon = type === 'Polygon' || type === 'MultiPolygon';
  const isLine = type === 'LineString' || type === 'MultiLineString';
  const isPoint = type === 'Point' || type === 'MultiPoint';
  let measurement;

  if (isPolygon) {
    measurement = <PolygonMeasurement geometry={geometry} properties={properties}/>;
  } else if (isLine) {
    measurement = <LineMeasurement geometry={geometry} />
  } else if (isPoint) {
    measurement = <PointMeasurement geometry={geometry} />
  }

  return (
    <React.Fragment>
      {measurement}
    </React.Fragment>
  )
}

function  RoomRecordNumber({ feature }) {
  const { properties } = {properties:{}, ...feature};
  const style = {
    color: 'grey',
    fontSize: 10,
    letterSpacing: 'initial'
  };

  if (properties && properties.rmrecnbr) {
    return (
      <Tooltip title={`Room Record Number: ${properties.rmrecnbr}`} arrow>
        <span style={style}>
          {' '}
          {properties.rmrecnbr}
        </span>
      </Tooltip>
    )
  }

  return null;
}

const useMeasurementStyles = makeStyles((theme) => ({
  grid:{
    lineHeight: 1
  },
  btn: {
    fontSize: '0.6rem',
    padding: '0 2px',
    borderRadius: 0,
  },
}));

const useColorStyles = makeStyles((theme) => ({
  display: {
    width: 15,
    height: 15,
    borderRadius: '50%',
    '&:hover': {
      cursor: 'pointer',
    }
  }
}));

function Color({ feature, onColorPickerClick }) {
  const { color } = feature.properties || {};
  const bgColor = { backgroundColor: color || '#3bb2d0'};
  const classes = useColorStyles();
  const onClick = () => { onColorPickerClick(feature) }

  return (
    <div style={bgColor} className={classes.display} onClick={onClick}></div>
  )
}

function PolygonMeasurement({ geometry, properties={} }) {
  const classes = useMeasurementStyles();
  const SQM_TO_SQFT = 10.76391042;
  
  const [unit, setUnit] = useState('sqft');
  const [value, setValue] = useState(properties.rmsqrft || turf.area(geometry) * SQM_TO_SQFT);
  
  useEffect(() => {
    if (unit === 'sqft') {
      setValue(properties.rmsqrft || turf.area(geometry)*SQM_TO_SQFT)
    } else if (unit === 'sqm') {
      setValue(properties.rmsqrft ? properties.rmsqrft/SQM_TO_SQFT : turf.area(geometry));
    }
  }, [geometry, properties.rmsqrft, unit]);
  
  const onClick = () => { setUnit(unit === 'sqft' ? 'sqm' : 'sqft')}
  
  return (
    <Grid item xs={12} className={classes.grid}>
      <Button
        size="small"
        color="default"
        className={classes.btn}
        onClick={onClick}
      >
        {`${value.toFixed(2)} ${unit}`}
      </Button>
    </Grid> 
  );
}

function LineMeasurement({ geometry }) {
  const classes = useMeasurementStyles();
  const KM_TO_FT = 3280.84;
  const KM_TO_M = 1000;
  const lineGeometry = toLineGeometry(geometry);
  
  const [unit, setUnit] = useState('ft');
  const [value, setValue] = useState(turf.length(lineGeometry) * KM_TO_FT);
  
  useEffect(() => {
    const value = unit === 'ft' ?
          turf.length(lineGeometry) * KM_TO_FT : turf.length(lineGeometry) * KM_TO_M;
    setValue(value);
  }, [lineGeometry, unit]);
  
  const onClick = () => { setUnit(unit === 'ft' ? 'm' : 'ft')}
  
  return (
    <Grid item xs={12} className={classes.grid}>
      <Button
        size="small"
        color="default"
        className={classes.btn}
        onClick={onClick}
      >
        {`${value.toFixed(2)} ${unit}`}
      </Button>
    </Grid> 
  );
}

function PointMeasurement({ geometry }) {
  const classes = useMeasurementStyles();
  const [lng, lat] = geometry.coordinates;
  
  return (
    <React.Fragment>
      <Grid item xs={12} className={classes.grid}>
        <Button
          size="small"
          color="default"
          className={classes.btn}
        >
          {`${lng.toFixed(6)} Lng`}
        </Button>
      </Grid> 
      <Grid item xs={12} className={classes.grid}>
        <Button
          size="small"
          color="default"
          className={classes.btn}
        >
          {`${lat.toFixed(6)} Lat`}
        </Button>
      </Grid> 
    </React.Fragment>
  );
}

function toLineGeometry(geometry = {}) {
  const lineGeometry = {...geometry};
  const lastIndex = lineGeometry.coordinates.length - 1;
  // remove last coordinate since mapbox automatically close linestring coordinate and becomes a polygon
  if (lineGeometry.coordinates[0] === lineGeometry.coordinates[lastIndex]) {
    lineGeometry.coordinates.splice(lastIndex, 1);
  }
  return lineGeometry;
}