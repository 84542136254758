import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import RoomIcon from '@material-ui/icons/Room';
import InfoIcon from '@material-ui/icons/Info';
import { actionInfoQueryAndSetData } from './InfoRedux';
import { actionDataSearchRooms } from '../Data/dataRedux';
import { actionMapUpdateZoomFeatures } from '../Map/MapRedux';
import RoomActions from './RoomActions';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { INFO, trackEvent } from '../../tracking';

const useBasicInfoStyles = makeStyles(theme => ({
  root: {
    '& .MuiTypography-overline': {
      lineHeight: 1.25,
      letterSpacing: '0.03em'
    }
  },
  id: {
    color: 'grey',
  },
  stack: {
    display: 'inline-block',
    '& .MuiButton-root': {
      lineHeight: 1,
    },
    '& .MuiTypography-overline': {
      lineHeight: 1,
    }
  },
  dep: {
    fontSize: '0.65rem',
    letterSpacing: '0.01em !important',
    fontWeight: 'bold',
    color: 'rgb(0, 100, 177)',
  },
  circleIcon: {
    color: 'white !important',
    backgroundColor: 'rgb(0, 100, 177)',
    borderRadius: '50%',
    letterSpacing: '0',
    display: 'inline-block',
    width: 18,
    height: 18,
    fontSize: '0.55rem',
    lineHeight: '18px',
    textAlign: 'center',
    marginLeft: 6,
  },
  title: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    display: 'inline-block',
    letterSpacing: '0 !important',
  },
  buildingBtn: {
    padding: 0,
    minWidth: 'auto',
    borderRadius: 0,
    fontWeight: '400',
    color: '#4455b5',
    display: 'block',
    fontSize: '0.6rem',
    marginBottom: 2,
  },
  primary: {
    fontWeight: 'bold',
  },
  secondary: {
    color: 'grey',
    fontSize: '0.6rem',
    letterSpacing: '0 !important',
  },
  tableRow: {
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.025)',
    }
  },
  actionIcon: {
    padding: 2,
    marginRight: 2,
    marginTop: -3,
    fontSize: '1rem',
    '& .MuiSvgIcon-root': {
      fontSize: '1rem',
    }
  },
  clickable: {
    color: 'rgb(0, 100, 177)',
    '&:hover': {
      textDecoration: 'underline',
    }
  }
}));

export default function BasicInfo({ room }) {
  const classes = useBasicInfoStyles();
  const dispatch = useDispatch();
  const { roomtypes } = useSelector(state => state.searchReducer.defaultData)

  const {
    rmnbr,
    rmrecnbr,
    bld_descrshort, bldrecnbr,
    deptid, dept_descr,
    floor,
    rmsqrft,
    rmstationcnt,
    rmtyp, rmtyp_descrshort,
    rmsubtyp, rmsubtyp_descrshort,
  } = room;

  // compare descrshort strings for rmtyp and rmsubtyp to redux search data descr50 strings
  const abbrevData = {};
  const roomData = roomtypes && roomtypes.find(x => x.rmtyp === rmtyp);
  if (
    roomData
    && roomData.rmtyp_descr50 
    && rmtyp_descrshort !== roomData.rmtyp_descr50
  ) abbrevData.rmtyp = roomData.rmtyp_descr50;
  if (!!rmsubtyp_descrshort.trim()) {
    const rmsubtypData = roomData && roomData.subtypes && roomData.subtypes.find(x => x.rmsubtyp === rmsubtyp);
    if (
      rmsubtypData
      && rmsubtypData.rmsubtyp_descr50
      && rmsubtyp_descrshort !== rmsubtypData.rmsubtyp_descr50
    ) abbrevData.rmsubtyp = rmsubtypData.rmsubtyp_descr50;
  }

  const [rmrecnbrLabel, setRmrecnmbrLabel] = useState('Room Record Number');
  const onCopyHandler = () => {
    setRmrecnmbrLabel('Room Record Number Copied to Clipboard');
    trackEvent({
      category: INFO,
      action: 'Copy RRN'
    });
  }
  const zoomTo = () => {
    dispatch(actionMapUpdateZoomFeatures({ type: 'rrn', data: rmrecnbr }));
    trackEvent({
      category: INFO,
      action: 'Zoom'
    });
  }
  const toBuildingInfo = () => {
    dispatch(actionInfoQueryAndSetData(bldrecnbr, 'building'))
    trackEvent({
      category: INFO,
      action: 'Switch to Building'
    });
  }

  const searchDept = () => {
    const filters = [
      {type: 'building', bldrecnbr, label: bld_descrshort},
      {type: 'department', deptid, label: dept_descr}
    ];
    dispatch(actionDataSearchRooms(filters));
    trackEvent({
      category: INFO,
      action: 'Search',
      label: 'Department'
    });
  }

  const searchRoomtype = () => {
    const filters = [
      {type: 'building', bldrecnbr, label: bld_descrshort},
      {type: 'roomtype', rmtyp, label: rmtyp_descrshort}
    ];
    dispatch(actionDataSearchRooms(filters));
    trackEvent({
      category: INFO,
      action: 'Search',
      label: 'Room Type'
    });
  }

  const searchRoomsubtype = () => {
    const filters = [
      {type: 'building', bldrecnbr, label: bld_descrshort},
      {type: 'roomsubtype', rmtyp, rmsubtyp, label: `${rmtyp_descrshort} - ${rmsubtyp_descrshort}`}
    ];
    dispatch(actionDataSearchRooms(filters));
    trackEvent({
      category: INFO,
      action: 'Search',
      label: 'Room Subtype'
    });
  }

  return (
    <div className={classes.root}>
      <div>
        <Tooltip title={rmrecnbrLabel}>
          <CopyToClipboard text={rmrecnbr} onCopy={onCopyHandler}>
            <Typography component="span" variant="overline" className={classes.id} data-testid="info-room-rmrecnbr">
              {rmrecnbr}      
            </Typography>
          </CopyToClipboard>
        </Tooltip>
        <span> </span> {/* fix issue when double clicking bldrecnbr grabs DEP too */} 
        <span className={classes.circleIcon}>DEP</span>
        <Tooltip title="Survey Dept">
          <Button
            size="small"
            style={{padding: 0, minWidth: 'auto', marginLeft: 4, fontSize: 'inherit'}}
            onClick={searchDept}
          >
            <Typography component="span" variant="overline" className={clsx(classes.dep, classes.clickable)}  data-testid="info-room-dept-name">
              {dept_descr}
            </Typography>
          </Button>
        </Tooltip>
      </div>
      <div style={{margin: '8px 0'}}>
        <Typography component="div" variant="overline" className={classes.title} data-testid="info-room-rmnbr">         
          {`RM ${rmnbr}`}
          <Tooltip title="Zoom In">
            <IconButton color="primary" onClick={zoomTo} className={classes.actionIcon}><RoomIcon /></IconButton>
          </Tooltip>
        </Typography>
        <div className={classes.stack}>
          <Tooltip title="Building">
              <Button size="small" onClick={toBuildingInfo} className={clsx(classes.buildingBtn, classes.clickable)} data-testid="info-room-bld">
                {bld_descrshort}
              </Button>
          </Tooltip>
          <Typography component="div" variant="overline" className={classes.secondary} data-testid="info-room-floor">
            FLOOR {floor}
          </Typography>
        </div>
      </div>
      <div>
        <div className={classes.stack} >
          <Typography component="div" variant="overline">
            <span className={classes.primary} data-testid="info-room-sqft">{rmsqrft}</span>
            <span className={classes.secondary} style={{marginLeft: 2}}>
              sqft
            </span>      
          </Typography>
          <Typography component="div" variant="overline">
            <span className={classes.primary} data-testid="info-room-rmstationcnt">{rmstationcnt}</span>
            <span className={classes.secondary} style={{marginLeft: 2}}>
              workstation{rmstationcnt !== 1 && 's'}
            </span>  
          </Typography>
        </div>
        <div className={classes.stack} style={{marginLeft: 16}}>
          <Typography component="div" variant="overline">
            <span className={classes.secondary}>ROOM TYPE </span>
          </Typography>
          <Typography component="div" variant="overline">
            <Tooltip title={`Search ${rmtyp_descrshort.toUpperCase()} in ${bld_descrshort}`}>
              <Button
                size="small"
                style={{padding: 0, minWidth: 'auto', fontSize: 'inherit'}}
                onClick={searchRoomtype}
                className={classes.clickable}
              >
                <span className={classes.primary} data-testid="info-room-rmtyp-name">{rmtyp_descrshort}</span>
              </Button>
            </Tooltip>
            <span className={classes.secondary} style={{marginLeft: 2}} data-testid="info-room-rmtyp">({rmtyp})</span>
          </Typography>
        </div>
        { abbrevData && abbrevData.rmtyp &&
          <div className={classes.stack} >
            <div style={{display: 'flex', justifyContent: 'center'}}>
              <Tooltip title={abbrevData.rmtyp}>
                <IconButton size="small"><InfoIcon fontSize="small" /></IconButton>
              </Tooltip>
            </div>
          </div>
        }
        { 
          !!rmsubtyp.trim() &&
          <div className={classes.stack} style={{marginLeft: 16}}>
            <Typography component="div" variant="overline">
              <span className={classes.secondary}>ROOM SUBTYPE </span> 
            </Typography>
            <Typography component="div" variant="overline">
              <Tooltip title={`Search ${rmsubtyp_descrshort.toUpperCase()} in ${bld_descrshort}`}>
                <Button
                  size="small"
                  style={{padding: 0, minWidth: 'auto', fontSize: 'inherit'}}
                  onClick={searchRoomsubtype}
                  className={classes.clickable}
                >
                  <span className={classes.primary} data-testid="info-room-rmsubtyp-name">{rmsubtyp_descrshort}</span>
                </Button>
              </Tooltip>
              <span className={classes.secondary} style={{marginLeft: 2}} data-testid="info-room-rmsubtyp">({rmsubtyp})</span> 
            </Typography>
          </div>
        }
        { abbrevData && abbrevData.rmsubtyp && 
          <div className={classes.stack} >
            <div style={{display: 'flex', justifyContent: 'center'}}>
              <Tooltip title={abbrevData.rmsubtyp}>
                <IconButton size="small"><InfoIcon fontSize="small" /></IconButton>
              </Tooltip>
            </div>
          </div>
        }
      </div>
      <RoomActions room={room} />
    </div>
  );
}
