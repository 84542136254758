import { createAction, handleActions } from 'redux-actions';
import takeRight from 'lodash/takeRight';

export const initialState = {
  isSmallScreen: false,
  isTouchScreen: false,
  // high level visibility
  appBarVisible: true,
  accordionVisible: true,
  widgetVisible: true,
  // mid level
  mapVisible: true,
  resultDataVisibile: false,
  infoVisible: false,
  layersVisible: false,
  customLayerRestroomVisible: false,
  searchVisible: false,
  printVisible: false,
  legendVisible: false,
  drawVisible: false,
  spreadsheetVisible: false,
  zoomVisible: true,
  rotateVisible: true,
  accordions: [
    'resultDataVisibile',
    'infoVisible',
    'layersVisible',
    'customLayerRestroomVisible',
    'printVisible',
    'drawVisible',
    'legendVisible',
    'spreadsheetVisible'
  ],
  maxVisibleAccordions: 10,
  history: {},
  // misc
  printSize: '',
  lastUpdated: {},
};

export const actionAppIsSmallScreenUpdated = createAction('APP_IS_SMALL_SCREEN_UPDATED');
export const actionAppIsTouchScreenUpdated = createAction('APP_IS_TOUCH_SCREEN_UPDATED');
export const actionAppComponentToggled = createAction('APP_COMPONENT_TOGGLED');
export const actionAppAccordionsRearranged = createAction('APP_ACCORDIONS_REARRANGED');
export const actionAppMaxVisibleAccordionsUpdated = createAction('APP_MAX_VISIBLE_ACCORDIONS_UPDATED');
export const actionAppAllHid = createAction('APP_ALL_HID');
export const actionAppVisibilityRestored = createAction('APP_VISIBILITY_RESTORED');
export const actionAppDateUpdated = createAction('APP_DATE_UPDATED');

export const actionAppHideAll = () => {
  return (dispatch, getState) => {
    const currentState = getState().appReducer;
    const history = {};
    const newState = {};
    for (let state in currentState) {
      if (state !== 'mapVisible' && state.indexOf('Visible') !== -1 ) {
        history[state] = currentState[state];
        newState[state] = false;
      }
    }
    dispatch(actionAppAllHid({newState, history}));
  }
}

export const actionAppToggleComponent = (payload) => {
  return (dispatch, getState) => {
    const state = getState().appReducer;
    const { component, visible } = payload;
    const visibility = visible === null || visible === undefined ?
                        !state[component] : visible;

    if (state.accordions.indexOf(component) === -1) {
      dispatch(actionAppComponentToggled({component, visibility}));
      return;
    }

    const accordions = rearrangeAccordions(state.accordions, component, visibility);
    const hideAccordions = syncVisibleAccordions(state, accordions);
    dispatch(actionAppAccordionsRearranged(accordions));
    dispatch(actionAppComponentToggled({component, visibility}));
    hideAccordions.forEach(component => {
      dispatch(actionAppComponentToggled({component, visibility: false}));
    });
  }
}

export const actionAppToSmallScreen = (isSmallScreen) => {
  return (dispatch, getState) => {
    // const { accordions } = getState().appReducer;
    dispatch(actionAppIsSmallScreenUpdated(isSmallScreen));
    dispatch(actionAppIsTouchScreenUpdated(isTouchDevice()));
    // if (isSmallScreen) {
    //   accordions.forEach(component => {
    //     dispatch(actionAppComponentToggled({component, visibility: false}));
    //   });
    //   dispatch(actionAppMaxVisibleAccordionsUpdated(2));
    // } else {
    //   ['layersVisible', 'printVisible'].forEach(component => {
    //     dispatch(actionAppComponentToggled({component, visibility: true}));
    //   });
    //   dispatch(actionAppMaxVisibleAccordionsUpdated(6));
    // }
  }
}

export const appReducer = handleActions({
  [actionAppIsSmallScreenUpdated]: (state, action) => {
    return {...state, isSmallScreen: action.payload };
  },
  [actionAppIsTouchScreenUpdated]: (state, action) => {
    return {...state, isTouchScreen: action.payload };
  },
  [actionAppComponentToggled]: (state, action) => {
    const {component, visibility} = action.payload;
    return {...state, [component]: visibility };
  },
  [actionAppAccordionsRearranged]: (state, action) => {
    return {...state, accordions: action.payload };
  },
  [actionAppAllHid]: (state, action) => {
    return {...state, ...action.payload.newState, history: action.payload.history };
  },
  [actionAppVisibilityRestored]: (state) => {
    return {...state, ...state.history, history: {}}
  },
  [actionAppMaxVisibleAccordionsUpdated]: (state, action) => {
    return {...state, maxVisibleAccordions: action.payload };
  },
  [actionAppDateUpdated]: (state, action) => {
    return {...state, lastUpdated: action.payload}
  },
}, initialState);


export const selectorAppIsSmallScreen = state => state.appReducer.isSmallScreen;
export const selectorAppIsTouchScreen = state => state.appReducer.isTouchScreen;

function rearrangeAccordions(accordions, component, visibility) {
  const newAccordion = [...accordions];

  if (!visibility) {
    return accordions;
  }

  const targetIndex = newAccordion.indexOf(component);
  newAccordion.splice(targetIndex, 1);
  newAccordion.unshift(component);
  return newAccordion;
}

function syncVisibleAccordions(state, accordions) {
  const { maxVisibleAccordions } = state;
  if (accordions.length <= maxVisibleAccordions) {
    return [];
  }

  const rightCount = accordions.length - maxVisibleAccordions;
  const toHide = takeRight(accordions, rightCount);
  return toHide;
}

// https://stackoverflow.com/questions/4817029/whats-the-best-way-to-detect-a-touch-screen-device-using-javascript
function isTouchDevice() {
    var prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
    var mq = function (query) {
        return window.matchMedia(query).matches;
    }

    if (('ontouchstart' in window) || (window.DocumentTouch && document instanceof window.DocumentTouch)) {
        return true;
    }

    // include the 'heartz' as a way to have a non matching MQ to help terminate the join
    // https://git.io/vznFH
    var query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
    return mq(query);
}

