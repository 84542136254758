import React, { useState, useRef, useEffect } from 'react';
import { useDispatch } from "react-redux";
import CardActions from '@material-ui/core/CardActions';
import { makeStyles } from '@material-ui/core/styles';
import GetAppIcon from '@material-ui/icons/GetApp';
import IconButton from '@material-ui/core/IconButton';
import SortIcon from '@material-ui/icons/Sort';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import CheckIcon from '@material-ui/icons/Check';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { SwatchesPicker } from "react-color";
import Dialog from '@material-ui/core/Dialog';
import Tooltip from '@material-ui/core/Tooltip';
import { actionDataGroupUpdated, getDefaultColor, actionDataRemoveDataGroup, actionDataMaxGroupSizeUpdated, actionDataSelectFeatureRequested, filtersToQueryParam } from './dataRedux';
import { xlsx } from '../Shared/downloader';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import _ from 'lodash';
import { actionLayerUpdate } from '../Layer';
import { toText } from './Filters';

const useStyles = makeStyles(theme => ({
  display: {
    '& .MuiMenu-paper': {
      minWidth: 130,
    },
  }
}));

export default function TableTools({ data }) {
  return (
    <CardActions disableSpacing style={{paddingTop: 0, marginTop: -8}}>
      <Remove data={data} />
      <Download data={data} />
      <Sort data={data} />
      <GroupBy data={data} />
      <FiltersToLink data={data} />
      <ColorPicker data={data} />
    </CardActions>
  );
}

function Download({ data }) {
  const onClick = () => {
    const filters = data.filters.map(filter => toText(filter)).join(' + ');
    const name = `Search Result - ${filters}`;
    const features = _.sortBy(data.rooms, ['bld_descrshort', 'floor', 'rmnbr']);
    xlsx({name, features, directPropPath: true});
  }

  return (
    <Tooltip title="Download Result">
      <IconButton
        style={{marginLeft: 'auto'}}
        size="small"
        onClick={onClick}
      >
        <GetAppIcon />
      </IconButton>
    </Tooltip>
  );
}

function Remove({ data }) {
  const dispatch = useDispatch();
  const { colorLayerId } = data;

  const handleClick = () => {
    dispatch(actionDataRemoveDataGroup(data));
    dispatch(actionDataSelectFeatureRequested({ type: 'room', data: [], layerId: colorLayerId }));
    dispatch(actionDataMaxGroupSizeUpdated(-1));
  }

  return (
    <Button color="secondary" onClick={handleClick}>
      REMOVE
    </Button>
  );
}

function Sort({ data }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const iconStyle = {
    fontSize: '1.2rem',
    marginLeft: 4
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [sortBy, setSortBy] = React.useState('building');
  const [direction, setDirection] = useState('ascending');

  const prevSort = useRef();
  useEffect(() => {
    prevSort.current = sortBy;
  });

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (sortType) => () => {
    setSortBy(sortType);
    if (sortType === prevSort.current) {
      const newDirection = direction === 'ascending' ? 'descending' : 'ascending';
      setDirection(newDirection);
      dispatch(actionDataGroupUpdated({ id: data.id, sortBy: sortType, sortDirection: newDirection }));
    } else {
      dispatch(actionDataGroupUpdated({ id: data.id, sortBy: sortType }));
    }
  }

  return (
    <React.Fragment>
      <Tooltip title="Sort By">
        <IconButton
            onClick={handleClick}
            size="small"
          >
            <SortIcon />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className={classes.display}
      >
        <MenuItem onClick={handleSelect('building')}>
          <Typography variant="overline" >
            Building
          </Typography>
          { sortBy === 'building' &&  direction === 'ascending' && <ArrowUpwardIcon style={iconStyle} /> }
          { sortBy === 'building' &&  direction === 'descending' && <ArrowDownwardIcon style={iconStyle} /> }
        </MenuItem>
        <MenuItem onClick={handleSelect('room')}>
          <Typography variant="overline" >
            Room
          </Typography>            
          { sortBy === 'room' &&  direction === 'ascending' && <ArrowUpwardIcon style={iconStyle} /> }
          { sortBy === 'room' &&  direction === 'descending' && <ArrowDownwardIcon style={iconStyle} /> }
        </MenuItem>
        <MenuItem onClick={handleSelect('sqft')}>
          <Typography variant="overline" >
            Sqft 
          </Typography>            
          { sortBy === 'sqft' &&  direction === 'ascending' && <ArrowUpwardIcon style={iconStyle} /> }
          { sortBy === 'sqft' &&  direction === 'descending' && <ArrowDownwardIcon style={iconStyle} /> }
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}

function GroupBy({ data }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const iconStyle = {
    fontSize: '1.2rem',
    marginLeft: 4
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [groupBy, setGroupBy] = React.useState(data.groupBy);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (group) => () => {
    setGroupBy(group);
    dispatch(actionDataGroupUpdated({ id: data.id, groupBy: group }));
  }

  return (
    <React.Fragment>
      <Tooltip title="Content Display">
        <Button onClick={handleClick}>
          List By
        </Button>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className={classes.display}
      >
        <Tooltip title="Building-Floors">
          <MenuItem onClick={handleSelect('building-floor')}>
              <Typography variant="overline" >
                Building
              </Typography>
            { groupBy === 'building-floor' && <CheckIcon style={iconStyle} />}
          </MenuItem>
        </Tooltip>
        <Tooltip title="Building-Floor-Sqft">
          <MenuItem onClick={handleSelect('building-floor-sqft')}>
              <Typography variant="overline" >
                Floor
              </Typography>            
            { groupBy === 'building-floor-sqft' && <CheckIcon style={iconStyle} />}
          </MenuItem>
        </Tooltip>
        <Tooltip title="Building-Floor-Room-Sqft">
          <MenuItem onClick={handleSelect('room')}>
              <Typography variant="overline" >
                Room
              </Typography>
            { groupBy === 'room' && <CheckIcon style={iconStyle} />}
          </MenuItem>
        </Tooltip>
      </Menu>
    </React.Fragment>
  );
}

const useColorStyles = makeStyles(theme => ({
  color: {
    width: 20,
    height: 20,
    borderRadius: '50%',
    '&:hover': {
      cursor: 'pointer'
    }
  }
}));

function FiltersToLink({ data }) {
  const defaultTitle = 'Create URL from Search';
  const [title, setTitle] = useState(defaultTitle);
  const urlText = filtersToQueryParam(data.filters);

  const onCopyHandler = () => {
    setTitle('Copied to Clipboard!');
    setTimeout(() => { setTitle(defaultTitle) }, 1500);
  }

  return (
    <Tooltip title={title}>
      <CopyToClipboard text={urlText} onCopy={onCopyHandler}>        
        <IconButton size="small">
          <FileCopyIcon />
        </IconButton>
      </CopyToClipboard>
    </Tooltip>
  )
}

function ColorPicker({ data }) {
  const classes = useColorStyles();
  const dispatch = useDispatch();
  const { id, colorLayerId, color } = data;
  const [isVisible, setIsVisible] = useState(false);

  const onChangeComplete = color => {
    const newColor = color && color.hex ? color.hex : color;
    dispatch(actionDataGroupUpdated({ id, color: newColor }));
    dispatch(actionLayerUpdate({
      type: 'room-select',
      id: colorLayerId,
      field: 'paint',
      value: { attr: 'fill-color', val: newColor }
    }));
  }

  const handleShowColor = () => { setIsVisible(true) }
  const handleClose = () => { setIsVisible(false) }
  const resetHandler = () => {
    onChangeComplete(getDefaultColor(colorLayerId));
    handleClose();
  }
  
  return (
    <div>
      <Tooltip title="Highlight Color">
        <div
          style={{ backgroundColor: color }}
          onClick={handleShowColor}
          className={classes.color}
        ></div>
      </Tooltip>
      <Dialog onClose={handleClose} open={isVisible} >
        <div style={{textAlign: 'center'}}>
          <SwatchesPicker color={color} onChangeComplete={onChangeComplete} />
          <Button onClick={resetHandler}>Reset</Button>
        </div>
      </Dialog>
    </div>
  );
}
