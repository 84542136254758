import ReactGA from 'react-ga4';

export const MAP_INTERACTION = 'Map Interaction';
export const MAP_TOOLS = 'Map Tools';
export const LAYERS = 'Layers';
export const PRINT = 'Print';
export const DRAW = 'Draw';
export const SPREADSHEET = 'Spreadsheet';
export const SEARCH = 'Search';
export const INFO  = 'Info Box';
export const BUILDING_INFO = 'Building Info Box';
export const ROOM_INFO = 'Room Info Box';

/**
 * Calls ReactGA event with Google Analytics object syntax
 * 
 * @param {Object} event Google Analytics Event reported
 * @param {string} event.category 
 * @param {string} event.action
 * @param {string} event.label Optional
 * @param {integer} event.value Optional 
 */
export const trackEvent = ({category, action, label, value}) => {
  ReactGA.event({ category, action, label, value });
}
