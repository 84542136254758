import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import TimelineIcon from '@material-ui/icons/Timeline';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import DashboardIcon from '@material-ui/icons/Dashboard';
import TouchAppIcon from '@material-ui/icons/TouchApp';
import CropFreeIcon from '@material-ui/icons/CropFree';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import EditIcon from '@material-ui/icons/Edit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faDrawPolygon,
  faVectorSquare,
  faCircle,
} from '@fortawesome/free-solid-svg-icons';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { SwatchesPicker } from "react-color";
import { actionDrawDefaultColorUpdated, actionDrawResetDefaultColor } from './drawRedux';
import { actionAddAlert } from '../Alert';
import { trackEvent, DRAW } from '../../tracking';

const useStylesDrawButton = makeStyles(theme => ({
  button: {
    minWidth: 0,
    minHeight: 0,
    height: 34,
    width: 34,
    margin: 2,
  },
  icon: {
    fontSize: '1rem',
  }
}));

const useStylesTooltip = makeStyles(theme => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
  },
}));

export function RectangleButton({ drawMode, clickCounter, changeMode }) {
  const MODE = 'draw_rectangle';
  const classes = useStylesDrawButton();
  const dispatch = useDispatch();
  const tooltipStyle = useStylesTooltip();
  const { counter } = clickCounter;
  const { mode } = drawMode;
  const modeIsActive = mode === MODE;
  const onChangeMode = () => { changeMode(MODE) }
  const cancelMode = () => { changeMode('simple_select') }
  
  let instruction = '';
  if (modeIsActive && counter < 1) {
    instruction = 'Rectangle: Step 1 - Click on the Map';
  } else if (modeIsActive && counter >= 1) {
    instruction = 'Rectangle: Step 2 - Drag Cursor and Click to Complete Rectangle';
  }

  if (modeIsActive) {
    dispatch(actionAddAlert({
      id: 'draw',
      title: instruction,
      buttonLabel: 'CANCEL',
      onClick: cancelMode,
      onClose: cancelMode,
    }));
    trackEvent({
      category: DRAW,
      action: 'Rectangle',
    });
  }

  return (
    <Tooltip title="Rectangle" placement="top" classes={tooltipStyle}>
      <Button
        className={classes.button}
        variant={modeIsActive ? 'contained' : 'outlined'}
        color="primary"
        size="small"
        onClick={onChangeMode}>
          <FontAwesomeIcon icon={faVectorSquare} />
      </Button>
    </Tooltip>
  );
}

export function LineButton({ drawMode, clickCounter, changeMode }) {
  const MODE = 'draw_line_string';
  const classes = useStylesDrawButton();
  const dispatch = useDispatch();
  const tooltipStyle = useStylesTooltip();
  const { counter } = clickCounter;
  const { mode } = drawMode;
  const modeIsActive = mode === MODE;
  const onChangeMode = () => { changeMode(MODE) }
  const cancelMode = () => { changeMode('simple_select') }
  
  let instruction = '';
  if (modeIsActive && counter < 1) {
    instruction = 'Line: Click on the Map';
  } else if (modeIsActive && counter >= 1) {
    instruction = 'Line: Double Click to Complete Line';
  }

  if (modeIsActive) {
    dispatch(actionAddAlert({
      id: 'draw',
      title: instruction,
      buttonLabel: 'CANCEL',
      onClick: cancelMode,
      onClose: cancelMode,
    }));
    trackEvent({
      category: DRAW,
      action: 'Line',
    });
  }

  return (
    <Tooltip title="Line" placement="top" classes={tooltipStyle}>
      <Button
        className={classes.button}
        variant={modeIsActive ? 'contained' : 'outlined'}
        color="primary"
        size="small"
        onClick={onChangeMode}
      >
        <TimelineIcon />
      </Button>
    </Tooltip>
  );
}

export function MarkerButton({ drawMode, changeMode }) {
  const MODE = 'draw_point';
  const classes = useStylesDrawButton();
  const dispatch = useDispatch();
  const tooltipStyle = useStylesTooltip();
  const { mode } = drawMode;
  const modeIsActive = mode === MODE;
  const onChangeMode = () => { changeMode(MODE) }  
  const cancelMode = () => { changeMode('simple_select') }

  if (modeIsActive) {
    dispatch(actionAddAlert({
      id: 'draw',
      title: 'Marker: Click on the Map',
      buttonLabel: 'CANCEL',
      onClick: cancelMode,
      onClose: cancelMode,
    }));
    trackEvent({
      category: DRAW,
      action: 'Marker',
    });
  }

  return (
    <Tooltip title="Marker" placement="top" classes={tooltipStyle}>
      <Button
        className={classes.button}
        variant={modeIsActive ? 'contained' : 'outlined'}
        color="primary"
        size="small"
        onClick={onChangeMode}
      >
        <LocationOnIcon />
      </Button>
    </Tooltip>
  );
}

export function CircleButton({ drawMode, changeMode }) {
  const MODE = 'draw_circle';
  const classes = useStylesDrawButton();
  const dispatch = useDispatch();
  const style = { fontSize: '1rem' };
  const tooltipStyle = useStylesTooltip();
  const { mode } = drawMode;
  const modeIsActive = mode === MODE;
  const onChangeMode = () => { changeMode(MODE) }  
  const cancelMode = () => { changeMode('simple_select') }

  if (modeIsActive) {
    dispatch(actionAddAlert({
      id: 'draw',
      title: 'Circle: Click on the Map and Adjust Size',
      buttonLabel: 'CANCEL',
      onClick: cancelMode,
      onClose: cancelMode,
    }));
    trackEvent({
      category: DRAW,
      action: 'Circle',
    });
  }

  return (
    <Tooltip title="Circle" placement="top" classes={tooltipStyle}>
      <Button
        style={style}
        className={classes.button}
        variant={modeIsActive ? 'contained' : 'outlined'}
        color="primary"
        size="small"
        onClick={onChangeMode}
        disabled={true}
      >
        <FontAwesomeIcon icon={faCircle} />
      </Button>
    </Tooltip>
  );
}

export function PolygonButton({ drawMode, clickCounter, changeMode }) {
  const MODE = 'draw_polygon';
  const classes = useStylesDrawButton();
  const dispatch = useDispatch();
  const tooltipStyle = useStylesTooltip();
  const { counter } = clickCounter;
  const { mode } = drawMode;
  const modeIsActive = mode === MODE;
  const onChangeMode = () => { changeMode(MODE) }
  const cancelMode = () => { changeMode('simple_select') }

  let instruction = '';
  if (modeIsActive && counter < 1) {
    instruction = 'Polygon: Step 1 - Click on the Map';
  } else if (modeIsActive && counter === 1) {
    instruction = 'Polygon: Step 2 - Click on another point';
  } else if (modeIsActive && counter > 1) {
    instruction = 'Polygon: Double-Click to Complete Polygon';
  }

  if (modeIsActive) {
    dispatch(actionAddAlert({
      id: 'draw',
      title: instruction,
      buttonLabel: 'CANCEL',
      onClick: cancelMode,
      onClose: cancelMode,
    }));
    trackEvent({
      category: DRAW,
      action: 'Polygon',
    });
  }

  return (
    <Tooltip title="Polygon" placement="top" classes={tooltipStyle}>
      <Button
        className={classes.button}
        variant={modeIsActive ? 'contained' : 'outlined'}
        color="primary"
        size="small"
        onClick={onChangeMode}
      >
          <FontAwesomeIcon icon={faDrawPolygon} className={classes.icon} />
      </Button>
    </Tooltip>
  );
}

export function FreehandPolygonButton({ drawMode, changeMode }) {
  const MODE = 'draw_freehand';
  const classes = useStylesDrawButton();
  const dispatch = useDispatch();
  const pencilStyle = {
    position: 'absolute',
    top: -8,
    left: 10,
    fontSize: '1.2rem',
    color: 'black'
  };
  const tooltipStyle = useStylesTooltip();
  const { mode } = drawMode;
  const modeIsActive = mode === MODE;
  const cancelMode = () => { changeMode('simple_select') }
  const onChangeMode = () => { changeMode(MODE) }  
  let instruction = 'Freehand: Move Cursor While Holding the Mouse Button Down. Release Button When Done';
  
  if (modeIsActive) {
    dispatch(actionAddAlert({
      id: 'draw',
      title: instruction,
      onClose: cancelMode,
    }));
    trackEvent({
      category: DRAW,
      action: 'Freehand Polygon',
    });
  }

  return (
    <Tooltip title="Freehand Polygon" placement="top" classes={tooltipStyle}>
      <Button
        className={classes.button}
        variant={modeIsActive ? 'contained' : 'outlined'}
        color="primary"
        size="small"
        onClick={onChangeMode}
      >
        <FontAwesomeIcon icon={faDrawPolygon} className={classes.icon} />
        <EditIcon style={pencilStyle} />
      </Button>
    </Tooltip>
  );
}

export function RoomSelectButton({ drawMode, changeMode }) {
  const MODE = 'room_picker';
  const classes = useStylesDrawButton();
  const dispatch = useDispatch();
  const tooltipStyle = useStylesTooltip();
  const roomStyle = { opacity: 0.75 };
  const pickerStyle = {
    position: 'absolute',
    color: 'black',
    top: 14,
    left: 11,
    fontSize: '1.3rem',
  };
  const { mode } = drawMode;
  const modeIsActive = mode === MODE;
  const onChangeMode = () => { changeMode(MODE, mode) }  
  const cancelMode = () => { changeMode('simple_select') }
  let instruction = 'Room Picker: Click on a Room';

  if (modeIsActive) {
    dispatch(actionAddAlert({
      id: 'draw',
      title: instruction,
      buttonLabel: 'CANCEL',
      onClick: cancelMode,
      onClose: cancelMode,
    }));
    trackEvent({
      category: DRAW,
      action: 'Room Picker',
    });
  }

  return (
    <Tooltip title="Room Picker" placement="top" classes={tooltipStyle}>
      <Button
        className={classes.button}
        variant={modeIsActive ? 'contained' : 'outlined'}
        color="primary"
        size="small"
        onClick={onChangeMode}
      >
        <DashboardIcon style={roomStyle} />
        <TouchAppIcon style={pickerStyle} />
      </Button>
    </Tooltip>
  );
}

export function GroupRoomSelectButton({ drawMode, changeMode }) {
  const MODE = 'group_room_picker';
  const classes = useStylesDrawButton();
  const dispatch = useDispatch();
  const tooltipStyle = useStylesTooltip();
  const roomStyle = { opacity: 0.75 };
  const pickerStyle = {
    position: 'absolute',
    color: 'black',
    top: -3,
    left: -3,
    fontSize: '2.4rem',
  };
  const { mode } = drawMode;
  const modeIsActive = mode === MODE;
  const onChangeMode = () => { changeMode(MODE, mode) }  
  const cancelMode = () => { changeMode('simple_select') }
  let instruction = 'Room Picker: Draw Rectangle to Select Multiple Rooms';

  if (modeIsActive) {
    dispatch(actionAddAlert({
      id: 'draw',
      title: instruction,
      buttonLabel: 'CANCEL',
      onClick: cancelMode,
      onClose: cancelMode,
    }));
    trackEvent({
      category: DRAW,
      action: 'Group Room Picker',
    });
  }

  return (
    <Tooltip title="Group Room Picker" placement="top" classes={tooltipStyle}>
      <Button
        className={classes.button}
        variant={modeIsActive ? 'contained' : 'outlined'}
        color="primary"
        size="small"
        onClick={onChangeMode}
      >
        <DashboardIcon style={roomStyle} />
        <CropFreeIcon style={pickerStyle} />
      </Button>
    </Tooltip>
  );
}

export function DeleteAllButton({ featureGroups, removeAll }) {
  const visible = featureGroups && featureGroups.length && featureGroups[0].features && featureGroups[0].features.length;
  const style = { margin: '2px 2px 2px 35px', height: 34 };
  const tooltipStyle = useStylesTooltip();

  return (
    <Tooltip title="Remove All Objects" placement="top" classes={tooltipStyle}>
      <span style={{visibility: visible ? 'visible' : 'hidden'}}>
        <Button
          variant="contained"
          color="secondary"
          size="small"
          style={style}
          onClick={removeAll}
        >
          Clear
        </Button>
      </span>
    </Tooltip>
  );
}

export function CancelModeButton({ drawMode, changeMode }) {
  const style = { margin: 2, height: 34 };
  const { mode } = drawMode;
  const isDisabled = mode === '' || mode === 'simple_select' ||
                     mode === 'delete' || mode === 'hide_feature_group' ||
                     mode === 'show_feature_group';
  const onClick = () => { changeMode('simple_select') }

  return (
    <Tooltip title="Cancel Mode" placement="top">
      <span style={{visibility: isDisabled ? 'hidden' : 'visible'}}>
        <Button
          variant="contained"
          color="primary"
          size="small"
          disabled={isDisabled}
          style={style}
          onClick={onClick}
        >
          Cancel
        </Button>
      </span>
    </Tooltip>
  );
}

export function MoreToolsButton({ moreToolsVisibile, moreToolsClick }) {
  const style = { padding: 0, minWidth: 0 };
  const title = moreToolsVisibile ? 'Hide Tools' : 'Show More Tools';
  const icon = moreToolsVisibile ? <ExpandLessIcon /> : <ExpandMoreIcon />;
  const onClick = () => { moreToolsClick() }
 
  return (
    <Tooltip title={title}>
      <Button style={style} onClick={onClick}>
        {icon}
      </Button>
    </Tooltip>
  )
}

const useStylesColorPicker = makeStyles(theme => ({
  root: {
    margin: '4px 20px 4px auto'
  },
  display: {
    width: 20,
    height: 20,
    borderRadius: '50%',
    '&:hover': {
      cursor: 'pointer',
    }
  },
  picker: {
    position: 'fixed',
    zIndex: theme.zIndex.tooltip,
    marginTop: 4,
    marginLeft: '-18.75%',
  },
}));

export function ColorPicker() {
  const classes = useStylesColorPicker();
  const dispatch = useDispatch();
  const defaultColor = useSelector(state => state.drawReducer.defaultColor);
  const bgColor = { backgroundColor: defaultColor };
  const [open, setOpen] = useState(false);

  const handleClick = () => { setOpen(true) }
  const handleClose = () => { setOpen(false) }
  const handleChange = (color) => {
    trackEvent({
      category: DRAW,
      action: 'Change Color',
      label: color.hex
    });
    dispatch(actionDrawDefaultColorUpdated(color.hex)) 
  }
  const handleDblClick = () => {
    dispatch(actionDrawResetDefaultColor());
    setOpen(false);
  }

  return (
    <ClickAwayListener onClickAway={handleClose}>
        <div className={classes.root}>
          <div
            style={bgColor}
            className={classes.display}
            onClick={handleClick}
            onDoubleClick={handleDblClick}>
          </div>
          <Dialog onClose={handleClose} open={open} className={classes.colorPicker}>
            <SwatchesPicker color={defaultColor} onChangeComplete={handleChange} />
          </Dialog>
        </div>
    </ClickAwayListener>
  )
}