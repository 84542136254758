import { createAction, handleActions } from 'redux-actions';

export const initialState = {
  auth: null,
  user: null,
  status: null, // authenticated, failed, sign-out, expired
  session: null,
  idToken: null,
  accessToken: null,
};

export const actionAuthUpdated = createAction('APP_USER_AUTH_UPDATED');
export const actionAuthSignedIn = createAction('APP_USER_SIGNED_IN');
export const actionAuthSigninFailed = createAction('APP_USER_SIGNIN_FAILED');
export const actionAuthSignOutRequested = createAction('APP_USER_SIGNOUT_REQUESTED');
export const actionAuthSessionExpired = createAction('APP_USER_EXPIRED_SESSION');
export const actionAuthReset = createAction('APP_USER_RESET');

export const actionAuthSignInUser = (session) => {
  return (dispatch, getState) => {
    // console.log(session);
    // session has 3 types of tokens: ID token, Access token, refresh token
    var idToken = session.getIdToken().getJwtToken();
    var accessToken = session.getAccessToken().getJwtToken();
    let user, groups;
    if (idToken) {
        // id token has key informations handling users
        // -- it shows who the user is (email)
        // -- it shows whether the user belongs to mgis-users group
        // If tokenobj["cognito:groups"] doesn't have 'mgis-users'
        // we should treat the user as non-user
        let payload = idToken.split('.')[1];
        let tokenobj = JSON.parse(atob(payload));
        user = tokenobj["email"];
        groups = tokenobj["cognito:groups"];
    }

    const isValid = !!accessToken && !!user && groups && groups.length &&
                    groups.indexOf('mgis-users') !== -1;
    if (isValid) {
      dispatch(actionAuthSignedIn({user, idToken, accessToken}));
    } else {
      dispatch(actionAuthSigninFailed());
    }
  }
}

export const actionAuthSignOutUser = () => {
  return (dispatch, getState) => {
    removeAuthFromLocalstorage();
    const auth = getState().authReducer.auth;
    auth.signOut();
    dispatch(actionAuthSignOutRequested());
  }
}

export const authReducer = handleActions({
  [actionAuthUpdated]: (state, action) => {
    return { ...state, auth: action.payload };
  },
  [actionAuthSignedIn]: (state, action) => {
    const { user, idToken, accessToken } = action.payload;
    return { ...state, status: 'authenticated', user, idToken, accessToken };
  },
  [actionAuthSigninFailed] : (state, action) => {
    return { ...state, status: 'failed', user: null };
  },
  [actionAuthSignOutRequested]: (state, action) => {
    return { ...state, status: 'sign-out' };
  },
  [actionAuthSessionExpired]: (state, action) => {
    return { ...state, status: 'expired' };
  },
  [actionAuthReset]: (state, action) => {
    return { ...state, ...initialState};
  }
}, initialState);

export function urlHasAccessToken() {
  return window.location.hash.indexOf('access_token=') !== -1;
}

export function localStorageHasToken() {
  if (!window.localStorage) {
    return false;
  }
  
  const storage = Object.keys(window.localStorage);
  for (let i = 0; i < storage.length; i++ ) {
    if (storage[i].indexOf('CognitoIdentityServiceProvider') !== -1) {
      return true;
    }
  }
  return false;
}

export const selectorAuthAccessToken = state => state.authReducer.accessToken;
export const selectorAuthIdToken = state => state.authReducer.idToken;

function removeAuthFromLocalstorage() {
  for (let key in window.localStorage) {
    if (key.indexOf('CognitoIdentityServiceProvider') !== -1) {
      window.localStorage.removeItem(key);
    }
  }
}

function getRedirectUrl() {
  if (process.env.NODE_ENV === 'development') {
    return 'http://localhost:3000/';
  }

  if (process.env.NODE_ENV !== 'production') {
    alert('No Redirect URI specified');
    return '';
  }

  return window.location.href.split('#')[0];
}

export function getAuthDataConfig() {
  return {
    ClientId : process.env.REACT_APP_AUTH_CLIENT_ID, 
    AppWebDomain : `${process.env.REACT_APP_AUTH_DOMAIN_PREFIX}.auth.us-east-1.amazoncognito.com`,
    TokenScopesArray : ['email', 'openid', 'profile'], 
    RedirectUriSignIn : getRedirectUrl(),
    RedirectUriSignOut : getRedirectUrl(), 
    IdentityProvider : 'umich.edu', 
    UserPoolId : process.env.USERPOOL_ID, 
    AdvancedSecurityDataCollectionFlag : false
  };
}


