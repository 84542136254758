import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import mapboxgl from "mapbox-gl";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import "mapbox-gl/dist/mapbox-gl.css";
import 'paper-css/paper.css';
import mapStyle from './mapStyle';
import { initialState, actionMapHandleScreenSize, isTouchDevice } from './MapRedux';
import {
  mapClickHandler,
  mapMoveendHandler,
  mapClickBuildingHandler,
  mapClickRoomHandler,
  mapDbclickBuildingHandler,
  mapDbclickRoomHandler,
  mapMouseoverBuildingHandler,
  mapMouseoverRoomHandler,
  mapMouseoutBuildingHandler,
  mapMouseoutRoomHandler,
  mapErrorHandler,
  mapDataSourceLoadedHandler,
  mapTouchHandler,
} from './mapEventHandlers';
import {
  mapDrawCreateHandler,
  mapDrawUpdateHandler,
  mapDrawModeChangeHandler,
} from './mapDrawEventHandlers';
import {
  handleMapViewChange,
  handleMapPopupDisplay,
  handleSourceLayersAdd,
  handleLayerChange,
  handleSelectFeatures,
  handleFloorChange,
  handlePrint,
  handleZoomFeatures,
  handleCursorChange,
  handleDrawModeChange,
  handleDrawFeaturesChange,
  handleActiveFeatureChange,
  handleSingleBuildingMode,
  handleIconAndLabelOffset,
  addAlternativeBasemap,
} from './mapEffectHandlers';
import { handleCustomLayers } from './mapSheetEffectHandlers';
import { addIconsToMap } from '../Spreadsheet/IconSelector';
import { 
  actionAddDefaultLayers,
  selectorLayerChangeRequest,
  selectorLayerIntialSourceLayers,
  selectorLayerSingleBuildingMode,
} from '../Layer';
import {
  selectorDataSelectFeatureRequest,
} from '../Data';
import { selectorAppBarFloor } from '../AppBar';
import { selectorAuthAccessToken, selectorAuthIdToken } from '../Auth';
import { selectorSheets } from '../Spreadsheet';
import { actionMapviewHistoryUpdated, actionUpdateMapviewHistory } from "../App";
// import pattern from '../../assets/patterns/pattern.png';

// fix mapbox gl js v2 transpiling issue (see: https://github.com/mapbox/mapbox-gl-js/issues/10173)
// eslint-disable-next-line import/no-webpack-loader-syntax
import MapboxWorker from 'worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker';
mapboxgl.workerClass = MapboxWorker;


const useStyles = makeStyles(theme => ({
  map: mapStyle,
  mapDimension: {
    width: '100vw',
    height: '100vh', 
  },
}));

export default function Map() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const mapContainer = useRef(null);
  
  const [map, setMap]  = useState(null);
  const [mapPopup, setMapPopup] = useState(null);

  const theme = useTheme();
  const xsDown = useMediaQuery(theme.breakpoints.down('xs'))
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))

  const mapPopupVisible = useSelector(state => state.mapReducer.popupVisible);
  const mapPopupRequest = useSelector(state => state.mapReducer.mapPopupRequest);
  const mapPadding = useSelector(state => state.mapReducer.padding);
  const mapviewChangeRequest = useSelector(state => state.mapReducer.mapviewChangeRequest);
  const print = useSelector(state => state.mapReducer.print);
  const cursor = useSelector(state => state.mapReducer.cursor);
  const zoomFeatures = useSelector(state => state.mapReducer.zoomFeatures);
  const drawMode = useSelector(state => state.mapReducer.drawMode);
  const drawFeatures = useSelector(state => state.mapReducer.drawFeatures);
  const activeFeature = useSelector(state => state.mapReducer.activeFeature);
  
  // external components
  const idToken = useSelector(selectorAuthIdToken);
  const accessToken = useSelector(selectorAuthAccessToken);
  const initialSourceLayers = useSelector(selectorLayerIntialSourceLayers);
  const layerChangeRequest = useSelector(selectorLayerChangeRequest);
  const singleBuildingMode = useSelector(selectorLayerSingleBuildingMode);
  const selectedFloor = useSelector(selectorAppBarFloor);
  const selectFeatureRequest = useSelector(selectorDataSelectFeatureRequest);
  const customSheets = useSelector(selectorSheets);
  const highlightRoomsWithPhoto = useSelector(state => state.photoReducer.highlightRoomsWithPhoto);
  const label = useSelector(state => state.layerReducer.label)
  const color = useSelector(state => state.layerReducer.color)
  const basemap = useSelector(state => state.layerReducer.basemapSource)

  // initialize
  useEffect(() => {
    if (!accessToken) {
      return;
    }

    mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;
    const { lng, lat, zoom } = initialState;
    const map = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v11",
      center: [lng, lat],
      zoom: zoom,
      preserveDrawingBuffer: true,
      bearingSnap: 0,
      // authorization header added when we request mgis tiles 
      transformRequest: (url, resourceType) => {
        if(resourceType === 'Tile' && url.startsWith('https://' + process.env.REACT_APP_DOMAIN)) {
          const headers = { 'Authorization': 'Bearer ' + accessToken };
          return { url, headers };
        }
      }
    });
    const mapPopup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false
    });

    map.on("load", () => {
      window.map = map; // debug
      setMap(map);
      setMapPopup(mapPopup);
      
      map.resize();
      map.on('click', mapClickHandler(map, dispatch));
      map.on('click', 'umich-building', mapClickBuildingHandler(map, dispatch));
      map.on('click', 'umich-room', mapClickRoomHandler(map, dispatch));
      map.on('touchstart', mapTouchHandler(map, dispatch));
      map.on('dblclick', 'umich-building', mapDbclickBuildingHandler(map, dispatch));
      map.on('dblclick', 'umich-room', mapDbclickRoomHandler(map, dispatch));
      map.on('mouseover', 'umich-building', mapMouseoverBuildingHandler(map, dispatch));
      map.on('mouseover', 'umich-room', mapMouseoverRoomHandler(map, dispatch));
      map.on('mouseout', 'umich-building', mapMouseoutBuildingHandler(map, dispatch));
      map.on('mouseout', 'umich-room', mapMouseoutRoomHandler(map, dispatch));
      map.on('moveend', mapMoveendHandler(map, dispatch));
      map.on('error', mapErrorHandler(map, dispatch));
      map.on('sourcedata', mapDataSourceLoadedHandler(dispatch));

      // draw events
      map.on('draw.create', mapDrawCreateHandler(map, dispatch));
      map.on('draw.update', mapDrawUpdateHandler(map, dispatch));
    //   map.on('draw.delete', function (e) { console.log('draw.delete', e.features); });
    //   map.on('draw.selectionchange', function (e) { console.log('draw.selectionchange', e.features); });
      map.on('draw.modechange', mapDrawModeChangeHandler(map, dispatch));

      dispatch(actionMapHandleScreenSize({xsDown, smDown}));
      dispatch(actionAddDefaultLayers(process.env.REACT_APP_DOMAIN));
      dispatch(actionUpdateMapviewHistory({ lng, lat, zoom, pitch: 0, bearing: 0 }));

      // add Material Icons
      addIconsToMap(map);
      addAlternativeBasemap(map);
    });
    return () => { map.remove(); }
  }, [accessToken]);
  
  // change mapview
  useEffect(() => {
    handleMapViewChange(map, mapviewChangeRequest, mapPadding); 
  }, [map, mapPadding, mapviewChangeRequest]);
  
  // mapPopupRequest
  useEffect(() => {
    handleMapPopupDisplay(map, mapPopup, mapPopupVisible, mapPopupRequest);
  }, [map, mapPopup, mapPopupVisible, mapPopupRequest]);

  // add source and layers
  useEffect(() => {
    handleSourceLayersAdd(map, initialSourceLayers);
  }, [map, initialSourceLayers]);

  // change layers
  useEffect(() => {
    handleLayerChange(map, layerChangeRequest, color);
  }, [map, layerChangeRequest, color]);

  // select/mark rooms @todo: this attr should belong to maps
  useEffect(() => {
    handleSelectFeatures(map, selectFeatureRequest);
  }, [map, selectFeatureRequest]);

  // floor change
  useEffect(() => {
    handleFloorChange(map, selectedFloor, dispatch, singleBuildingMode);
  }, [map, selectedFloor]);

  // print
  useEffect(() => {
    handlePrint(map, mapContainer, mapPopup, dispatch, print);
  }, [map, mapContainer, mapPopup, dispatch, print]);

  // spreadsheet
  useEffect(() => {
    handleCustomLayers(map, customSheets, selectedFloor, idToken, dispatch, singleBuildingMode);
  }, [map, customSheets, idToken, singleBuildingMode]);
  
  
  useEffect(handleZoomFeatures(map, zoomFeatures, mapPadding, idToken), [map, zoomFeatures, mapPadding, idToken]);
  useEffect(handleCursorChange(map, cursor), [map, cursor]); // cursor change 
  useEffect(handleDrawModeChange(map, dispatch, drawMode), [map, drawMode]); // draw
  useEffect(handleDrawFeaturesChange(map, drawFeatures), [map, drawFeatures]);
  useEffect(handleActiveFeatureChange(map, activeFeature), [map, activeFeature]);
  useEffect(handleSingleBuildingMode(map, singleBuildingMode), [map, singleBuildingMode]);
  useEffect(handleIconAndLabelOffset(map, label, highlightRoomsWithPhoto), [map, label, highlightRoomsWithPhoto]);
  
  return (
    <div ref={mapContainer} className={classes.map}/>
  );
};