import React, { useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import clsx from 'clsx';
import { actionChangeFilter } from './SearchRedux';

const useStyles = makeStyles(theme => ({
  root: {
    width: 500,
    maxWidth: '90vw',
    margin: 'auto',
    marginTop: 5,
    marginBottom: 5,
    overflow: 'auto',
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    '&::-webkit-scrollbar': {
      width: 0,
      height: 0,
    },
    [theme.breakpoints.down('xs')]: {
      margin: '10px 0',
      maxWidth: '85vw',
    },
  },
  container: {
    width: 700,
    '& button': {
      marginRight: 6,
      padding: '5px 9.5px',
    }
  },
  scrollBtn: {
    padding: 4,
    position: 'absolute',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    }
  },
  left: {
    left: 15,
  },
  right: {
    right: 15,
  },
  filterBtnActive: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    }
  }
}));

export default function Filters() {
  const classes = useStyles();
  const root = useRef(null);
  const filterBy = useSelector(state => state.searchReducer.filterBy);
  const dispatch = useDispatch();

  const scrollHandler = (direction) => () => {
    const nonValidElt = !root || !root.current ||
                        (root.current && root.current.scrollLeft === null);

    if (nonValidElt) {
      return;
    }

    if (direction === 'left') {
      root.current.scrollLeft -= 100;
    } else if (direction === 'right') {
      root.current.scrollLeft += 100;
    }
  }

  const clickHandler = (filterType) => () => {
    dispatch(actionChangeFilter(filterType));
  }

  return (
    <div className={classes.root} ref={root}>
      <IconButton 
        className={clsx(classes.scrollBtn, classes.left)}
        onClick={scrollHandler('left')}
      >
        <ArrowLeftIcon />
      </IconButton>
      <IconButton
        className={clsx(classes.scrollBtn, classes.right)}
        onClick={scrollHandler('right')}
      >
        <ArrowRightIcon />
      </IconButton>
      <div className={classes.container}>
        <Button
          variant="outlined"
          onClick={clickHandler('buildings')}
          className={clsx({[classes.filterBtnActive]: filterBy === 'buildings' })}
        >
          Buildings
        </Button>
        <Button
          variant="outlined"
          onClick={clickHandler('rooms')}
          className={clsx({[classes.filterBtnActive]: filterBy === 'rooms' })}
        >
          Rooms
        </Button>
        <Button
          variant="outlined"
          onClick={clickHandler('people')}
          className={clsx({[classes.filterBtnActive]: filterBy === 'people' })}        
        >
          People
        </Button>
        <Button
          variant="outlined"
          onClick={clickHandler('departments')}
          className={clsx({[classes.filterBtnActive]: filterBy === 'departments' })}      
        >
          Departments
        </Button>
        <Button
          variant="outlined"
          onClick={clickHandler('roomtypes')}
          className={clsx({[classes.filterBtnActive]: filterBy === 'roomtypes' })}          
        >
          Room Types
        </Button>
      </div>
    </div>
  );
}