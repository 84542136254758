import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles(theme => ({
  container: {
    visibility: 'hidden', // temp
    display: 'none', // temp
    // display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      marginTop: 10,
      justifyContent: 'flex-start',
    }
  },
  root: {
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    width: 500,
    borderBottom: '1px solid rgb(196, 196, 196)',
    borderRadius: 0,
    [theme.breakpoints.down('xs')]: {
      maxWidth: '85vw',
    }
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 6,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

export default function Input() {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.root}>
        <InputBase
          // autoFocus={true}
          className={classes.input}
          placeholder="Buildings, Rooms, Departments, etc"
        />
        <IconButton className={classes.iconButton} aria-label="search">
          <SearchIcon />
        </IconButton>
      </div>
    </div>
  );
}