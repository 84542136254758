import { rrnToBbox, brnToBbox } from '../Search';
import mapboxgl from 'mapbox-gl/dist/mapbox-gl';
import * as turf from '@turf/turf';
import parse from 'wellknown';
import { addMarkerStorage, removeMarkerStorage } from '../Shared/markerStore';

export function removeDuplicateFeatures(features=[], keyProperty) {
  let featureId = new Set();
  return features.reduce((featAcc, feature) => {
    const isValid = feature && feature.properties && feature.properties[keyProperty];
    const keyProp = isValid ? feature.properties[keyProperty] : '';
    if (!isValid || featureId.has(keyProp)) { return featAcc }
    featureId.add(keyProp);
    return [...featAcc, feature];
  }, []);
}

export function getUniqueFeatures(features, keyId) {
  let uniqueIds = new Set();
  return features.reduce((memo, feature) => {

    const id = feature.properties[keyId];
    if (uniqueIds.has(id)) {
      return memo;
    }

    if (id) {
      uniqueIds.add(id);
      memo.push({
        ...feature.properties,
        id,
        type: feature.sourceLayer
      });
    }
    return memo;
  }, []);
}

export function getMapView(map) {
  const { lng, lat } = map.getCenter();
  return {
    lng: lng,
    lat: lat,
    zoom: map.getZoom(),
    pitch: map.getPitch(),
    bearing: map.getBearing(),    
  };
}

export function updateGraphicLayer(map, data, layer, color) {
  const mapLayers = map.getStyle().layers;
  const { id, mode } = data;
  const visibility = mode === 'show' || mode === 'auto' ? 'visible' : 'none';

  mapLayers.forEach(mapLayer => {
    if (layer.isLayer(mapLayer)) {
      map.setLayoutProperty(mapLayer.id, 'visibility', visibility);
    }
  });

  if (id === 'basemap' && mode === "show") {
    const { basemapSource } = data;
    if (basemapSource === "map") {
      map.setLayoutProperty('satellite-raster', 'visibility', 'none');
      map.setLayoutProperty('satellite-background', 'visibility', 'none');
    }
  }

  if (id === 'floor' && (mode === 'auto' || mode === 'show')) {
    getNonBuildingLayers(mapLayers).forEach(mapLayer => {
      // check toggle value for umich-room-avail and umich-room-shared
      if (mapLayer.id === 'umich-room-avail') {
        map.setLayoutProperty(mapLayer.id, 'visibility', color.faavailable ? 'visible' : 'none');
      } else if (mapLayer.id === 'umich-room-shared') {
        map.setLayoutProperty(mapLayer.id, 'visibility', color.shared ? 'visible' : 'none')
      } else {
        map.setLayoutProperty(mapLayer.id, 'visibility', 'visible');
      }
    });
  }

  if (id === 'floor' && mode === 'show') {
    getNonBuildingLayers(mapLayers).forEach(mapLayer => {
      map.setLayerZoomRange(mapLayer.id, 0, 24);
    });
  } else if (id === 'floor' && mode === 'auto') {
    getNonBuildingLayers(mapLayers).forEach(mapLayer => {
      map.setLayerZoomRange(mapLayer.id, 17, 24);
    });
  }
}

function getNonBuildingLayers(mapLayers) {
  return mapLayers.filter(layer => {
    return (
        (
          layer.id.indexOf('umich-') !== -1 &&
          layer.id.indexOf('umich-building') === -1
        ) || 
        (layer.id.indexOf('sheet-') !== -1)
      );
  });
}

export function updateLabelLayer(map, data, layer) {
  if (!map || !layer) { return }
  
  if (layer.value) {
    updateLabelLayout(map, data, layer);
  } else if (layer.filter) {
    updateLabelFilter(map, data, layer);
  }
}


function updateLabelLayout(map, data, layer) {
  const labelLayerId = 'umich-room-label';
  const labelField = 'text-field';
  const textField = map.getLayoutProperty(labelLayerId, labelField);
  const expressionObj = expressionExtractor(textField)
  const expression = expressionConstructor(expressionObj, layer, data);
  map.setLayoutProperty(labelLayerId, labelField, expression);
}

function expressionExtractor(expression) {
  if (!expression) return;
  let obj = {};
  expression.forEach(x => {
    /*
      Expression Example
      ["concat", 
        [
          "case", 
          ["has", "rmnbr"], 
          ["concat", ["get", "rmnbr"], "\n"],
          ""
        ],
        [
          "case",
          ["has", "rmsqrft"],
          ["concat", ["get", "rmsqrft"], " sqft\n"], 
          ""
        ],
        Faculty Allocation Example WITHOUT other rooms
        [
          "case",
          [ "all",
            ["has", "falist"],
            ["any"
              ["==", ["get", "rmtyp"], "250"]
              ["==", ["get", "rmtyp"], "310"]
            ]
          ],
          ["concat", ["get", "falist"], "\n"], 
          ""]
        ],
        Faculty Allocation with other rooms
        [
          "case",
          ["all",
            ["has", "falist"]
            ["!==", ["get", "rmtyp"], "250"]
            ["!==", ["get", "rmtyp"], "310"]
          ],
          ["concat", ["get", "falist"], "\n"], 
          ""]
        ]
    */

    // skip concat string
    if (!Array.isArray(x)) return;
    // handle faculy allocation expressions
    if (x[1] && x[1][0] && x[1][0] === "all") {
      let rmtyp;
      // handle faculty allocation expression with no "other" rmtyp
      if (x[1].some(y => y[0] === "any")) {
        rmtyp = []
        // check any array, add entire list to rmtp array
        x[1].forEach(y => {
          if (y[0] === "any") {
            y.forEach(z => {
              if (Array.isArray(z)) {
                rmtyp.push(z[2])
              }
            })
          }
        })
      }
      // handle faculty allocation expression with "other" rmtyp
      else {
        rmtyp = ["others", "310", "250"]
        // check all
        x[1].forEach(y => {
          if (y[0] === "!=") {
            rmtyp = rmtyp.filter(z => z !== y[2])
          }
        });
      }
      obj.falist = {
        temp: x[2][2],
        rmtyp
      }
    }
    // handle all other expressions
    else {
      let value, temp;
      // iterate through expression
      x.forEach(y => {
        if (y[0] === "concat") {
          temp = y[2];
          value = y[1][1]
        };
      });
      // if value exists set the object
      if (value) {
        obj[value] = {
          temp,
        }
      }
    }
  });

  return obj;
}

function expressionConstructor(obj = {}, layer, data) {
  let returnExpression = ["concat"];
  const { id, rmtyp, value, concat } = layer;
  const { isVisible } = data;
  const val = Array.isArray(value) ? value : [value];
  const concatVal = Array.isArray(concat) ? concat : [concat];

  // update object with visible values & formatting
  // handle faculty allocation
  if (id.indexOf('facultyallocation') !== -1 ) {
    // handle faculty allocation parent control
    if (id === 'facultyallocation') {
      // if not visible, delete
      if (!isVisible) {
        delete obj.falist
      }
      // if visible, either don't change or create full
      else {
        if (obj.falist) return;
        else {
          obj.falist = {
            temp: concatVal[0] + '\n',
            rmtyp: ["250", "310", "others"]
          }
        }
      }
    }
    // handle child controls
    else {
      // add rmtyp to arry if visible
      if (isVisible) {
        // if value exists, add rmtyp to array
        if (obj.falist) {
          let arr = obj.falist.rmtyp || [];
          if (arr.indexOf(rmtyp) == -1) {
            arr.push(rmtyp);
          }
        } 
        // if value does not exist, create object with array
        else {
          obj.falist = {
            temp: concatVal[0] + '\n',
            rmtyp: [ rmtyp ]
          }
        }
      }
      // remove rmtyp to array if not visible
      else {
        // remove rmtyp from arr
        if (obj.falist) {
          let arr = obj.falist.rmtyp || [];
          if (arr.indexOf(rmtyp) !== -1) {
            let newArr = arr.filter(x => x !== rmtyp);
            // if array empty, delete value
            if (newArr.length === 0) delete obj.falist;
            else obj.falist.rmtyp = newArr;
          }
        }
      }
    }

  } else {
    // handle array of multiple values
    val.forEach((v, i) => {
      // Remove from expression object if not visible
      if (!isVisible) delete obj[v];
      // Add to expression object otherwise
      else {
        if (obj.hasOwnProperty(v)) {
          delete obj[v]
        } else {
          obj[v] = {
            temp: concatVal[i] + '\n',
          }
        }
      }
    });
  }

  // build mapbox expression from object for each case
  Object.keys(obj).forEach(key => {
    let caseArray;
    // handle faculty allocation
    if (key === 'falist') {
      // use "all" expression for faculty allocation with "others" in rmtyp array
      if (obj.falist.rmtyp && obj.falist.rmtyp.includes("others")) {
        let allArr = [
          "all",
            ["has", "falist"]
        ];
        let rmtyparr = ["250", "310"];
        rmtyparr.forEach(y => {
          if (obj.falist.rmtyp.includes(y)) return;
          let arr = ["!=", ["get", "rmtyp"], y];
          allArr.push(arr);
        });
        caseArray = [
          "case", 
          allArr,
          ["concat", ["get", "falist"], obj.falist.temp],
          ""
        ]
      } 
      // use "any" expression faculty allocation WITHOUT "others" in rmtyp array
      else {
        if (obj.falist.rmtyp && obj.falist.rmtyp.length > 0) {
          let anyArr = [
            "any",
          ];
          obj.falist.rmtyp.forEach(rmtyp => {
            let arr = ["==", ["get", "rmtyp"], rmtyp]
            anyArr.push(arr);
          });
          let allArr = [
            "all",
              ["has", "falist"],
              anyArr
          ]
          caseArray = [
            "case",
            allArr,
            ["concat", ["get", "falist"], obj.falist.temp],
            ""
          ]
        }
      }
    } 
    // build all other epxressions
    else {
      caseArray = [
        "case",
        ["has", key],
        ["concat", ["get", key], obj[key].temp],
        ""
      ];
    }

    // add case expression to concat expression
    returnExpression.push(caseArray);
  })
  return returnExpression;
}

function updateLabelFilter(map, data, layer) {
  const labelLayerId = 'umich-room-label';
  const instruction = layer.filter(data.isVisible);
  const currentFilter = map.getFilter(labelLayerId);
  let newFilter;

  if (instruction.type === 'add') {
    // remove duplicate filter
    newFilter = currentFilter.filter(filter => {
      return !Array.isArray(filter) || !isIdenticalFilter(filter, instruction.filter);
    });
    newFilter.push(instruction.filter);
  } else if (instruction.type === 'remove') {
    newFilter = currentFilter.filter(filter => {
      return !Array.isArray(filter) || !isIdenticalFilter(filter, instruction.filter);
    });
  }

  map.setFilter(labelLayerId, newFilter);
}

function isIdenticalFilter(filter1, filter2) {
  return filter1.length === 3 && filter2.length === 3 &&
         filter1[0] === filter2[0] &&
         filter1[1] === filter2[1] &&
         filter1[2] === filter2[2];
}

export function updateLabelLayerSettings(map, field, value) {
  if (!map) { return }

  const labelLayerId = 'umich-room-label';
  if (field === 'fontSize') {
    map.setLayoutProperty(labelLayerId, 'text-size', value);
  } else if (field === 'fontColor') {
    map.setPaintProperty(labelLayerId, 'text-color', value);
  }
}

export function updateColorLayer(map, targetColorDef, data) {
  map.setPaintProperty('umich-room', 'fill-color', targetColorDef);
  map.setLayoutProperty('umich-room-avail', 'visibility', data.faavailable ? 'visible' : 'none');
  map.setLayoutProperty('umich-room-shared', 'visibility', data.shared ? 'visible': 'none');
}

export function updateRoomSelectLayer(map, layerId, field, value) {
  const { attr, val } = value;
  if (field === 'paint') {
    map.setPaintProperty(layerId, attr, val);
  }
}

export function hideBuildingsExcept(map, bldrecnbr) {
  try {
    const layers = map.getStyle().layers;

    layers.forEach(layer => {
      // add bldrecnbr filter to custom layers
      if (layer.id.indexOf('sheet-') === 0) {
        const filter = map.getFilter(layer.id);
        if (layer.id.indexOf('sheet-point') ===0) {
          map.setFilter(layer.id,
            [
              ...filter,
              [
                'all',
                ['boolean', ['has', 'bldrecnbr']],
                ['boolean',
                  [
                    '==', ['get', 'bldrecnbr'], bldrecnbr
                  ]
                ]
              ]
            ]
          );
        } else {
          map.setFilter(layer.id, [...filter, ["in", "bldrecnbr", bldrecnbr]]);
        }
      }
    });
    
    // add bldrecnbr filter
    map.setFilter('umich-building', ["in", "bldrecnbr", bldrecnbr]);
    map.setFilter('umich-building-label', ["in", "bldrecnbr", bldrecnbr]);
    const floorFilter = map.getFilter('umich-floor');
    map.setFilter('umich-floor', ['all', floorFilter, ["in", "bldrecnbr", bldrecnbr]]);
    const roomFilter = map.getFilter('umich-room');
    map.setFilter('umich-room', ['all', roomFilter, ["in", "bldrecnbr", bldrecnbr]]);
    const roomLabelFilter = map.getFilter('umich-room-label');
    map.setFilter('umich-room-label', [...roomLabelFilter, ["in", "bldrecnbr", bldrecnbr]]);
    const roomSelect1Filter = map.getFilter('umich-room-select-1');
    map.setFilter('umich-room-select-1', [...roomSelect1Filter, ["in", "bldrecnbr", bldrecnbr]]);
    const roomSelect2Filter = map.getFilter('umich-room-select-2');
    map.setFilter('umich-room-select-2', [...roomSelect2Filter, ["in", "bldrecnbr", bldrecnbr]]);
    const roomSelect3Filter = map.getFilter('umich-room-select-3');
    map.setFilter('umich-room-select-3', [...roomSelect3Filter, ["in", "bldrecnbr", bldrecnbr]]);
    const roomSelect4Filter = map.getFilter('umich-room-select-4');
    map.setFilter('umich-room-select-4', [...roomSelect4Filter, ["in", "bldrecnbr", bldrecnbr]]);
    const roomSelect5Filter = map.getFilter('umich-room-select-5');
    map.setFilter('umich-room-select-5', [...roomSelect5Filter, ["in", "bldrecnbr", bldrecnbr]]);
    const roomSelectIntersectFilter = map.getFilter('umich-room-select-intersect');
    map.setFilter('umich-room-select-intersect', [...roomSelectIntersectFilter, ["in", "bldrecnbr", bldrecnbr]]);
    const roomDraw1Filter = map.getFilter('umich-room-draw-1');
    map.setFilter('umich-room-draw-1', [...roomDraw1Filter, ["in", "bldrecnbr", bldrecnbr]]);
    const refFilter = map.getFilter('umich-reference');
    map.setFilter('umich-reference', ['all', refFilter, ["in", "bldrecnbr", bldrecnbr]]);
  } catch(e) {
    console.error('hideBuildingsExcept(): ', e);
  }
}

export function revertHideBuildingsExcept(map) {
  try {
    const layers = map.getStyle().layers;

    layers.forEach(layer => {
      // remove bldrecnbr filter from custom layers
      if (layer.id.indexOf('sheet-') === 0) {
        const filter = map.getFilter(layer.id);
        if (layer.id.indexOf('sheet-point-') === 0 ) {
          // handle point filter expression structure
          const newFilter = filter.filter(i => 
            typeof i === 'string' ||
            !(Array.isArray(i) && i[1] && Array.isArray(i[1]) && i[1][1] && Array.isArray(i[1][1]) && i[1][1][1] && i[1][1][1] === 'bldrecnbr')
            );
          map.setFilter(layer.id, newFilter);
        } else {
          // handle
          const newFilter = filter.filter(i => typeof i === 'string' || i[1] !== 'bldrecnbr');
          map.setFilter(layer.id, newFilter);
        }
      }
    });

    // remove bldrecnbr filter
    map.setFilter('umich-building', null);
    map.setFilter('umich-building-label', null);
    const floorFilter = map.getFilter('umich-floor').find(item => Array.isArray(item) && item.length === 3 && item[1] === 'floor');
    map.setFilter('umich-floor', floorFilter);
    const roomFilter = map.getFilter('umich-room').find(item => Array.isArray(item) && item.length === 3 && item[1] === 'floor');
    map.setFilter('umich-room', roomFilter);
    const roomLabelFilter = map.getFilter('umich-room-label').filter(item => {
      return !(Array.isArray(item) && item.length === 3 && item[1] === 'bldrecnbr');
    });
    map.setFilter('umich-room-label', roomLabelFilter);
    const roomSelect1Filter = map.getFilter('umich-room-select-1').filter(item => {
      return !(Array.isArray(item) && item.length === 3 && item[1] === 'bldrecnbr');
    });
    map.setFilter('umich-room-select-1', roomSelect1Filter);
    
    const roomSelect2Filter = map.getFilter('umich-room-select-2').filter(item => {
      return !(Array.isArray(item) && item.length === 3 && item[1] === 'bldrecnbr');
    });
    map.setFilter('umich-room-select-2', roomSelect2Filter);

    const roomSelect3Filter = map.getFilter('umich-room-select-3').filter(item => {
      return !(Array.isArray(item) && item.length === 3 && item[1] === 'bldrecnbr');
    });
    map.setFilter('umich-room-select-3', roomSelect3Filter);

    const roomSelect4Filter = map.getFilter('umich-room-select-4').filter(item => {
      return !(Array.isArray(item) && item.length === 3 && item[1] === 'bldrecnbr');
    });
    map.setFilter('umich-room-select-4', roomSelect4Filter);

    const roomSelect5Filter = map.getFilter('umich-room-select-5').filter(item => {
      return !(Array.isArray(item) && item.length === 3 && item[1] === 'bldrecnbr');
    });
    map.setFilter('umich-room-select-5', roomSelect5Filter);

    const roomSelectIntersectFilter = map.getFilter('umich-room-select-intersect').filter(item => {
      return !(Array.isArray(item) && item.length === 3 && item[1] === 'bldrecnbr');
    });
    map.setFilter('umich-room-select-intersect', roomSelectIntersectFilter);
    
    const roomDraw1Filter = map.getFilter('umich-room-draw-1').filter(item => {
      return !(Array.isArray(item) && item.length === 3 && item[1] === 'bldrecnbr');
    });
    map.setFilter('umich-room-draw-1', roomDraw1Filter);

    const refFilter = map.getFilter('umich-reference').find(item => Array.isArray(item) && item.length === 3 && item[1] === 'floor');
    map.setFilter('umich-reference', refFilter);
  } catch(e) {
    console.error('revertHideBuildingsExcept(): ', e);
  }
}

export function cursorPointer(map) {
  map.getCanvas().style.cursor = 'pointer';
}

export function cursorCrosshair(map) {
  map.getCanvas().style.cursor = 'crosshair';
}

export function cursorReset(map) {
  map.getCanvas().style.cursor = '';
}

/**
 * Updates Mapbox filter for given RRNs and layers
 * 
 * @param {*} map - the MapBox map object
 * @param {[]} roomRecNums - Array of Room Record Numbers to mark
 * @param {string|[]} layerId - The id string of the layer, or if multiple layers, an array of strings
 * @param {boolean} [retry] 
 */
export function markRooms(map, roomRecNums, layerId, retry=false) {
  let newRmRecNumFilter = ["in", "rmrecnbr"].concat(roomRecNums);
  if (Array.isArray(layerId)) {
    try {
      layerId.forEach(layer => {
        setRoomFilter(map, layer, newRmRecNumFilter);
      });
    } catch (err) {
      if (!retry) { return };
      if (err.message === "Cannot read property 'filter' of undefined") {
        setTimeout(() => { markRooms(map, roomRecNums, layerId, true); }, 10000);
      }
    }
  } else {
    try {
      setRoomFilter(map, layerId, newRmRecNumFilter);
    } catch (err) {
      // only re-try once after waiting 10 secs
      if (!retry) { return };
      if (err.message === "Cannot read property 'filter' of undefined") {
        setTimeout(() => { markRooms(map, roomRecNums, layerId, true); }, 10000);
      }
    }
  }
}

function setRoomFilter(map, layerId, newRmRecNumFilter) {
  let currentFilter = map.getFilter(layerId);
  let newFilter = currentFilter.map(elt => {
    if (Array.isArray(elt) && elt.length >= 2 && elt[1] === 'rmrecnbr') {
      return newRmRecNumFilter;
    } 
    return elt;
  });
  map.setFilter(layerId, newFilter)
}

export function markRoomCustomColor(map, features=[], layerId) {
  if (!map) { return }

  const layerProp = features.reduce((data, feature) => {
    const { properties, source } = feature;
    const { rmrecnbr, color } = properties;
    if (!rmrecnbr || !source || source !== 'campus-room') { return data }
    data.filter = [...data.filter, rmrecnbr ];
    if (color) { data.paint = [...data.paint, ['==', ['get', 'rmrecnbr'], rmrecnbr], color] }
    return data;
  }, {filter:[], paint: []});

  // set filter
  let currentFilter = map.getFilter(layerId);
  let newFilter = currentFilter.map(elt => {
    const isRRNFilter = Array.isArray(elt) && elt.length >= 2 && elt[0] === 'in' &&  elt[1] === 'rmrecnbr';
    if (isRRNFilter) {
      return ["in", 'rmrecnbr', ...layerProp.filter];
    } 
    return elt;
  });
  map.setFilter(layerId, newFilter);

  // set paint
  const customPaint = [
    "case",
    ...layerProp.paint,
    "rgb(59, 178, 208)"
  ];
  const paint = layerProp.paint.length ? customPaint : "rgb(59, 178, 208)";
  map.setPaintProperty(layerId, "fill-color", paint);
  map.setPaintProperty(layerId, "fill-outline-color", paint);    
}

export function markActiveRoom(map, rmrecnbr) {
  if (!map) {
    console.error('Map Object not available');
    return;
  }
  
  let newFilter = rmrecnbr ? ["in", "rmrecnbr"].concat(rmrecnbr) : ["in", "rmrecnbr"];
  map.setFilter('umich-room-active', newFilter);
}

export function markActiveBuilding(map, bldrecnbr) {
  if (!map) {
    console.error('Map Object not available');
    return;
  }

  let newFilter = bldrecnbr ? ["in", "bldrecnbr", bldrecnbr] : ["in", "bldrecnbr"];
  map.setFilter('umich-building-active', newFilter);
}

export function markActiveFloor(map, bldrecnbr, floor) {
  if (!map) {
    console.error('Map Object not available');
    return;
  }

  let newFilter;
  if (floor) {
    newFilter = bldrecnbr ? ['all', ["==", "floor", floor], ["in", 'bldrecnbr', bldrecnbr]] : ['all', ["==", "floor", floor], ["in", 'bldrecnbr']];
  } else {
    newFilter = bldrecnbr ? ['all', ["==", "floor", ''], ["in", 'bldrecnbr', bldrecnbr]] : ['all', ["==", "floor", ''], ["in", 'bldrecnbr']];
  }

  map.setFilter('umich-floor-active', newFilter);
}

export async function zoomRrn(map, rrns, mapPadding, idToken) {
  try {
    const bbox = await rrnToBbox(rrns, idToken);
    zoomBbox(map, bbox, mapPadding);
  } catch (err) {
    console.error(`zoomRrn(): err-${err} rrns-${rrns}`);
  }
}

export async function zoomBrn(map, brn, mapPadding, idToken) {
  try {
    const bbox = await brnToBbox(brn, idToken);
    zoomBbox(map, bbox, mapPadding);
  } catch (err) {
    console.error(`zoomBrn(): err-${err} brn-${brn}`);
  }
}

export async function zoomBbox(map, targetBbox, mapPadding) {
  const bbox = toBbox(targetBbox);
  if (!isValidBbox(bbox)) {
    console.error(`zoomBbox(): Invalid bbox, bbox-${bbox}`);
    return;
  }

  if (mapPadding) {
    // in set timeout because sometime operation competes with map.doubleClickZoom
    setTimeout(() => { map.fitBounds(bbox, {padding: mapPadding}) }, 500);
  } else {
    setTimeout(() => { map.fitBounds(bbox) }, 500);
  }
}

export async function zoomFeature(map, feature, mapPadding) {
  const targetFeature = Array.isArray(feature) ? turf.featureCollection(feature) : feature;
  const bbox = toBbox(targetFeature);
  if (!isValidBbox(bbox)) {
    console.error(
      'zoomFeature(): Invalid bbox',
      '\nbbox', bbox,
      '\nfeature', feature
    );
    return;
  }
  zoomBbox(map, bbox, mapPadding);
}

//@todo: divide this function into several smaller specific functions
export function toBbox(targetPolygon) {
  if (isValidBbox(targetPolygon)) {
    return targetPolygon;
  }

  if (typeof targetPolygon === 'string') {
    const target = parse(targetPolygon);
    const [minLng, minLat, maxLng, maxLat] = turf.bbox(target);
    return [[minLng, minLat], [maxLng, maxLat]]; 
  }

  if (isValidFeature(targetPolygon)) {
    const [minLng, minLat, maxLng, maxLat] = turf.bbox(targetPolygon);
    return [[minLng, minLat], [maxLng, maxLat]]; 
  }

  return null;
}

// example of valid bbox - [[minLng, minLat], [maxLng, maxLat]]
function isValidBbox(bbox) {
  const validShape = Array.isArray(bbox) && bbox.length === 2 &&
                     bbox[0].length === 2 && bbox[1].length === 2;
  if (!validShape) { return false; }

  const [[minLng, minLat], [maxLng, maxLat]] = bbox;
  const validLatRange = minLat >= -90 && minLat <= 90 &&
                        maxLat >= -90 && maxLat <= 90 && 
                        typeof minLat === 'number' && typeof maxLat === 'number';
  const validLngRange = minLng >= -180 && minLng <= 180 &&
                        maxLng >= -180 && maxLng <= 180 &&
                        typeof minLng === 'number' && typeof maxLng === 'number';
  return validLatRange && validLngRange;
}

function isValidFeature(feature) {
  return (feature && feature.type && feature.type === 'Feature') ||
         (feature && feature.type && feature.type === 'FeatureCollection');        
}

export function mapViewToFloors(map) {
  if (map.getZoom() < 15) {
    return [];
  }

  const features = map.queryRenderedFeatures({layers:['umich-building']})
  const floors = features.reduce((acc, feature) => {
    const isValid = feature && feature.properties && feature.properties.floor_list;
    if (!isValid) {
      return acc;
    }
    const floorList = feature.properties.floor_list.split(',')
      .filter(floor => !!floor && !!floor.trim())
      .map(floor => floor.trim());
    return acc.concat(floorList);
  }, []);

  return [...new Set(floors)];
}

export function setMapFilter(map, layerId, filterType, filterValue) {
  
  try {
    let layerFilter = map.getFilter(layerId);
    const singleFilter = layerFilter && layerFilter.length && layerFilter[0] === '=='; // example: ["==", "floor", "02"]
    const multipleFilters = layerFilter && layerFilter.length && layerFilter[0] === 'all'; // example: ['all', ['==', 'floor', '01'], ["in", "rmrecnbr"]]
    const hasFilter = (filter, targetFilterType) => filter && Array.isArray(filter) && filter.length > 1 && filter[1] === targetFilterType;
    const hasFilterType = !!multipleFilters && layerFilter.some(elt => { return hasFilter(elt, filterType) });
  
    if (!layerFilter) { // no filter presant
      layerFilter = filterValue;
    } else if (singleFilter && layerFilter[1] === filterType) { 
      layerFilter = filterValue; // replace filter if target filter type is the same
    } else if (singleFilter && layerFilter[1] !== filterType) {
      layerFilter = ['all', layerFilter, filterValue]; // add additional condition to the current filter
    } else if (multipleFilters && !hasFilterType) { 
      layerFilter = [...layerFilter, filterValue]; // add aditional condition
    } else if (multipleFilters && hasFilterType) { 
      layerFilter = layerFilter.map(elt => {
        if (hasFilter(elt, filterType)) { // replace existing filter
          return filterValue;
        }
        return elt;
      });
    }
  
    map.setFilter(layerId, layerFilter);
  } catch(e) {
    console.log(e)
  }
}

export function setPointFloorFilter(map, layer, floor) {
  const oldFilter = map.getFilter(layer);
  const singleBuildingFilter = oldFilter[2]
  const newfloorFilter = [
    'any',
      ['boolean', 
        ['all',
          ['boolean', ['has', 'floor']],
          ['boolean', [ '==', ['get', 'floor'], floor ]],
        ]],
      ['boolean', ['!',[ 'has', 'floor' ]]]
    ];
  const combinedFilter = ['all', newfloorFilter]
  if (singleBuildingFilter) combinedFilter.push(singleBuildingFilter)
  map.setFilter(layer, combinedFilter);
}

export function resetPrintMode(styleElt, bodyElt, mapElt) {
  setStyleTagForPrintMode(styleElt, false);
  setBodyTagForPrintMode(bodyElt, false);
  setMapContainerForPrintMode(mapElt, false); 
}

export function setStyleTagForPrintMode(elt, params) {
  if (!params) {
    elt.innerHTML = '';
    return;
  }
  const { size, orientation } = params;
  let sizeText, orientationText;
  if (orientation === 'portrait') {
    sizeText = size;
    orientationText = '';
  }
  elt.innerHTML = `@page { size: ${sizeText} ${orientationText}; margin: 0mm; }`;
}

export function setBodyTagForPrintMode(elt, params) {
  const configs = ['letter', 'legal', 'tabloid', 'landscape', 'portrait'];
  if (!params) {
    configs.forEach(className => elt.classList.remove(className));    
    return;
  }
  const { size, orientation } = params;
  const classNames = orientation === 'portrait' ? [size] : [size, orientation];
  classNames.forEach(className => elt.classList.add(className));
}

export function setMapContainerForPrintMode(elt, params) {
  if (!params) {
    elt.classList.remove('sheet');
    return;
  }
  elt.classList.add('sheet');
}

export function getPrintStyleHTMLTag() {
  const ELT_ID = 'map-print-style';
  let styleTag = document.getElementById(ELT_ID);
  if (!styleTag) {
    styleTag = document.createElement("style");
    styleTag.id = ELT_ID;
    document.head.appendChild(styleTag);
  }
  return styleTag;
}

export function isEmptyObj(obj) {
  return Object.entries(obj).length === 0 && obj.constructor === Object;
}

export function createPopup(event, featureType) {
  if (!event || !event.features || !event.features.length) {
    return {};
  }

  const [feature] = event.features;
  // const coordinate = event.lngLat;
  const coordinate = toCenterFeature(feature);
  const content = featureType === 'building' ? buildingToPopup(feature) :
                                                roomToPopup(feature);
  return { coordinate, content };
}

function toCenterFeature(feature) {
  const center = turf.pointOnFeature(feature.geometry);
  const { coordinates } = center.geometry;
  return coordinates;  
}

function buildingToPopup(feature) {
  const { bldrecnbr, bld_descrshort } = feature.properties;
  return `<div class="secondary">${bldrecnbr}</div><div>${bld_descrshort}</div>`;
}

function roomToPopup(feature) {
  const { bld_descrshort, rmnbr } = feature.properties; 
  return `<div class="secondary">${bld_descrshort}</div><div>${rmnbr}</div>`;
}

export function addPropertiesToDrawFeatures(map, features) {
  const tempFeatures = addRoomLayerVisible(map, features);
  const rrnFeatures = addIntersectingRRN(map, tempFeatures);
  return addIntersectBuilding(map, rrnFeatures);
}

function addIntersectingRRN(map, features) {
  const newFeatures = features.map(feature => {
    let visibleRooms = map.queryRenderedFeatures({layers:['umich-room']});
    let container;

    try {
      if (feature.geometry.type === 'Point') {
        container = visibleRooms.filter(roomFeature => {
          try {
            return turf.booleanPointInPolygon(feature, roomFeature)
          } catch(e) {
            console.error(e);
            return false;
          }
        });
      } else if (feature.geometry.type === 'Polygon') {
        container = visibleRooms.filter(roomFeature => {
          try {
            return turf.booleanWithin(feature, roomFeature)
          } catch(e) {
            console.error(e)
            return false;
          }
        })
      } else {
        return feature;
      }
    } catch (error) {
      console.error(error);
      return feature;
    }

    let hasRRN = container.length > 0 && container[0].properties.rmrecnbr;
    let properties = hasRRN ? {...feature.properties, rmrecnbr: container[0].properties.rmrecnbr} : feature.properties;
    return {...feature, properties}
  });

  return newFeatures;
}

export function addRoomLayerVisible(map, features) {
  if (!map) { return features }
  const rooms = map.queryRenderedFeatures({layers:['umich-room']});
  if (!rooms.length) { return features }
  return features.map(feature => {
    const properties = {...feature.properties, roomvisible: 'visible' };
    return {...feature, properties };
  });
}

export function addIntersectBuilding(map, features) {
  const visibleFeatures = getVisibleFeatures(map);
  const intersects = getIntersectBuildings(visibleFeatures, features);
  const buildings = removeDuplicateBuildings(intersects);

  return features.map(feature => {
    const [building] = buildings || [];
    const { bldrecnbr, bldname } = building || {};
    const properties = {...feature.properties, bldname, bldrecnbr };
    return {...feature, properties };
  });
}

function getVisibleFeatures(map) {
  return map.queryRenderedFeatures({layers:['umich-building', 'umich-room']});
}

function getIntersectBuildings(visibleFeatures, drawnFeatures) {
  return visibleFeatures.reduce((accBuildings, visibleFeature) => {
    const intersection = drawnFeatures.reduce((acc, drawnFeature) => {
      const test = testIntersects(drawnFeature, visibleFeature);
      const isLine = isTypeLine(drawnFeature);
      if (!test && !isLine) { return acc }
      if (isLine && !retestLineIntersect(drawnFeature, visibleFeature)) { return acc }
      return [...acc, getBuildingProps(visibleFeature)];
    }, []);
    return [...accBuildings, ...intersection];
  }, []);
}

function getBuildingProps(feature={}) {
  const { bldrecnbr, bld_descrshort, bldname } = feature.properties || {};
  return { bldrecnbr, bldname: bldname || bld_descrshort };
}

function removeDuplicateBuildings(intersectBuildings=[]) {
  const buildings = {};
  return intersectBuildings.reduce((accBuildings, building) => {
    const { bldrecnbr, bldname } = building;
    if (buildings[bldrecnbr]) { return accBuildings; }
    buildings[bldrecnbr] = bldname;
    return [...accBuildings, { bldrecnbr, bldname }];
  }, []);
}

function testIntersects(feature1, feature2) {
  const tests = [
    {fn: turf.intersect, args: [feature1, feature2], target: true},
    {fn: turf.intersect, args: [feature2, feature1], target: true},
    {fn: turf.booleanContains, args: [feature1, feature2], target: true},
    {fn: turf.booleanContains, args: [feature2, feature1], target: true},
    {fn: turf.booleanWithin, args: [feature1, feature2], target: true},
    {fn: turf.booleanWithin, args: [feature2, feature1], target: true},
    {fn: turf.booleanPointInPolygon, args: [feature1, feature2], target: true},
    {fn: turf.booleanDisjoint, args: [feature1, feature2], target: false},
    {fn: turf.booleanPointInPolygon, args: [feature1, feature2], target: true},
  ];
  
  const results = tests.map(test => {
    const { fn, args, target } = test;
    try {
      const result = fn.apply(null, args);
      return !!result === target;
    } catch (err) {
      return false;
    }
  });
  
  return results.some(result => result === true);
}

function isTypeLine(feature) {
  return feature.geometry.type === 'LineString' ||
         feature.geometry.type === 'MultiLineString';
}

function retestLineIntersect(line, polygon) {
  let lineToPolgonIntersect = false;

  try {
    lineToPolgonIntersect = testIntersects(turf.lineToPolygon(line), polygon)
  } catch (err) {
    lineToPolgonIntersect = false;
  }

  let lineToPointsIntersect = false;
  let points = [];
  try {
    // convert line coordinates to point
    if (line.geometry.type === 'LineString') {
      points = line.geometry.coordinates[0].map(coord => turf.point(coord));
    } else if (line.geometry.type === 'MultiLineString') {
      points = line.geometry.coordinates[0].reduce((acc, line) => {
        const linePoints = line.reduce((accPoints, coord) => {
          return [...accPoints, turf.point(coord)];
        }, []);
        return [...acc, ...linePoints];
      }, []);
    }
    // test points in polygon
    let results = points.map(point => turf.booleanPointInPolygon(point, polygon));
    lineToPointsIntersect = results.some(result => result === true);
  } catch (err) {
    lineToPointsIntersect = false;
  }

  return lineToPolgonIntersect || lineToPointsIntersect;
}

export function addMarkersForPointFeatures(map, features) {
  features.forEach(feature => {
    if (feature.geometry.type === 'Point') {
      addMarker(map, feature);
    }
  });
}

export function addMarker(map, feature={}) {
  const { geometry, id } = feature;
  if (!map || !feature || !geometry || !id) { return }
  
  const { color } = feature.properties ? feature.properties : {};
  const marker = color ? new mapboxgl.Marker({ color }) : new mapboxgl.Marker();
  marker.properties = feature.properties;
  marker
    .setLngLat(geometry.coordinates)
    .addTo(map);

  addMarkerStorage({[id]: marker});
}

export function removeMarkersForPointFeatures(ids) {
  ids = typeof ids === 'string' ? [ids] : ids;
  ids.forEach(id => {
    const marker = removeMarkerStorage(id);
    if (marker && marker.remove) { marker.remove() }
  });
}

export function updateMarkersForPointFeatures(map, features) {
  features.forEach(feature => {
    const { id, geometry } = feature;
    const marker = removeMarkerStorage(id);
    if (geometry.type !== 'Point' || !marker) { return }
    marker
      .setLngLat(geometry.coordinates)
      .addTo(map);
    addMarkerStorage({[id]: marker});
  });
}

export function drawRectToRooms(map, feature) {
  const bbox = toBbox(feature);
  const projection = [map.project(bbox[0]), map.project(bbox[1])];
  return map.queryRenderedFeatures(projection, {layers:['umich-room']});
}

export function fivePctOfMapBound(map) {
  const {_sw, _ne} = map.getBounds();
  const lng1 = _sw.lng;
  const lng2 = _ne.lng;
  const lat1 = _sw.lat;
  const lat2 = _ne.lat;
  var line = turf.lineString([[lng1, lat1], [lng2, lat2]]);
  var length = turf.length(line);

  return length * 0.05;
}

export function getActiveFeature(features = []) {
  const [feature] = features;
  const properties = feature && feature.properties ? feature.properties : {};

  return {
    bldrecnbr: properties.bldrecnbr,
    floor: properties.floor,
    rmrecnbr: properties.rmrecnbr,
  };
}

export function featuresToFloors(features = []) {
  const floorList = features.reduce((acc, feature) => {
    const isValid = feature && feature.properties &&
                    feature.properties.bldrecnbr && feature.properties.floor_list;
    if (isValid) {
      const floors = feature.properties.floor_list.split(',').map(floor => floor.trim());
      acc = [...acc, ...floors];
    }
    return acc;
  }, []);
  
  return [...new Set(floorList)];
}

export const checkClick = (features) => {
  if (!features || !Array.isArray(features) || features.length === 0) {
    return {
      customClick: false,
      roomClick: false,
      buildingClick: false,
    };
  }

  const customClick = features
    .map(feature => feature.layer.id)
    .some(featureString => featureString.indexOf('sheet') !== -1);

  const roomClick = features
    .map(feature => feature.layer.id)
    .some(featureString => (
      featureString.indexOf('umich-room') !== -1 || 
      featureString.indexOf('umich-floor') !== -1
    ));

  const buildingClick = features
      .map(feature => feature.layer.id)
      .includes('umich-building');

  return {
    customClick,
    roomClick,
    buildingClick
  };
};