export const defaultState = {
  floor: '01',   
  roomColorDefault: {    
    "property": "rmtyp",    
    "type": "categorical",    
    "stops": [    
      ["020", "#FFF"],    
      ],    
    "default": "#FFF"    
  },
  roomColorAvail: [ 'case',
    ['>=', ['get', 'fa_avail'], 50],
    '#39FF14',
    "rgba(255, 255, 255, 0)"
  ],
  roomColorShared: 
  ['case',
    ['all',
      ['boolean', ['has', 'occdeptlist']],
      ['boolean', ['!', ['in', '100%', ['get', 'occdeptlist']]]],
    ],
    '#FE53BB',
    ['all',
      ['boolean', ['has', 'occdeptlist']],
      ['boolean', ['!', ['in', ['get', 'deptid'], ['get', 'occdeptlist']]]],
    ],
    '#08F7FE',
    "rgba(255,255,255,0)"
  ],
  roomColorRmtyp: {},   
  roomColorDept: {},   
  labelPrimary: '{rmnbr}\n',   
  labelSecondary: '{rmsqrft}SF',   
  labelPrimarySize: 8,   
  labelSecondarySize: 9,
  labelColor: "#222",
  roomSelectColor1: "#bbdefb", // light blue
  roomSelectColor2: "#d7ccc8", // light brown
  roomSelectColor3: "#c8e6c9", // light green
  roomSelectColor4: "#ffecb3", // light orange/yellow
  roomSelectColor5: "#e1bee7", // light purple
  roomSelectIntersectColor: '#b71c1c', // red
  roomDrawColor1: '#2196f3', // vivid blue
  // vectorTileUrl: process.env.REACT_APP_VECTOR_TILE_API,
  // vectorTileUrl: "https://" + window.config.url_tile + "/tile/{z}/{x}/{y}.pbf",  
  getVectorTileUrl: (vectorTileUrl, level) => {
    return `https://${vectorTileUrl}/tiles/${level}/{z}/{x}/{y}.pbf`
  }
} 

export const getDefaultLayers = tileUrl => { 

  return {
    sourceBuilding: {
        "id": "campus-building",
        "type": 'vector',   
        "tiles": [defaultState.getVectorTileUrl(tileUrl, 'building')],   
        "minzoom": 12,   
        "maxzoom": 18   
    },
    sourceFloor: {
        "id": "campus-floor", 
        "type": 'vector',   
        "tiles": [defaultState.getVectorTileUrl(tileUrl, 'floor')],   
        "minzoom": 17,   
        "maxzoom": 18   
    },
    sourceRoom: {   
        "id": "campus-room", 
        "type": 'vector',   
        "tiles": [defaultState.getVectorTileUrl(tileUrl, 'room')],
    },
    sourceReference: {   
        "id": "campus-reference", 
        "type": 'vector',   
        "tiles": [defaultState.getVectorTileUrl(tileUrl, 'reference')],   
        "minzoom": 18,   
        "maxzoom": 19   
    },
    sourceLabel: {
      "id": "campus-label",
      "type": 'vector',
      "tiles": [defaultState.getVectorTileUrl(tileUrl, 'label')]
    },
    building: {   
        'id': 'umich-building',   
        'type': 'fill',   
        'source': 'campus-building',   
        "source-layer": "building",   
        'paint': {   
            'fill-color': [
              'interpolate',
              ['exponential', 0.5],['zoom'],
              17.4, '#CA0',
              17.5,'#ffffed'
            ],
            'fill-outline-color':"rgba(0,0,0,0.2)",   
        },   
        // "minzoom": 12,   
        // "maxzoom": 18   
    },
    buildingSelect: {
      'id': 'umich-building-select',   
      'type': 'fill',   
      'source': 'campus-building',   
      "source-layer": "building",   
      "filter":["in", "bldrecnbr"],
      'paint': {   
        'fill-color': "rgba(59, 178, 208, 0.3)",   
        'fill-outline-color':"rgb(59, 178, 208)",   
      },   
      // "minzoom": 6,   
      // "maxzoom": 15   
    }, 
    buildingActive: {
      'id': 'umich-building-active',   
      'type': 'fill',   
      'source': 'campus-label',   
      "source-layer": "building",   
      "filter": ["in", "bldrecnbr"],
      'paint': {   
        'fill-color': "#e6c000",   
        // 'fill-outline-color':"rgba(0,0,0,0.2)",   
        'fill-outline-color': "rgba(240, 52, 52, 1)"
      },   
      "minzoom": 6,   
      "maxzoom": 17   
    }, 
  buildingLabel: {   
      'id': 'umich-building-label',   
      'type': 'symbol',   
      'source': 'campus-building',   
      "source-layer": "building",   
      "layout": {   
        "text-field": '{bld_descrshort}',   
        "text-font": ["Open Sans Bold", "Arial Unicode MS Bold"],   
        "text-size": 9,   
        "text-allow-overlap": true   
      },   
      "paint": {   
        "text-color": "#222"   
      },   
      "minzoom": 16
  },
  floor: {   
      'id': 'umich-floor',   
      'type': 'fill',   
      'source': 'campus-floor',   
      "source-layer": "floor",   
      "filter":["==", "floor", defaultState.floor],   
      'paint': {   
        'fill-color': "#000"
      },   
      "minzoom": 17
  },
  floorActive: {
    'id': 'umich-floor-active',   
    'type': 'line',   
    'source': 'campus-floor',   
    "source-layer": "floor",   
    "filter":['all', ["==", "floor", defaultState.floor], ["in", 'bldrecnbr']],   
    'paint': {   
      'line-color': "#e6c000",
      'line-width': 2
    },   
    "minzoom": 17
  },
  room: {   
      'id': 'umich-room',   
      'type': 'fill',   
      'source': 'campus-room',   
      "source-layer": "room",   
      "filter":["==", "floor", defaultState.floor],   
      'paint': {   
        'fill-color': defaultState.roomColorDefault,
        'fill-outline-color': "rgba(0, 0, 0, 0.3)"
        },
      "minzoom": 17
  },
  roomAvail: {   
    'id': 'umich-room-avail',   
    'type': 'line',   
    'source': 'campus-room',   
    "source-layer": "room",   
    "filter":["==", "floor", defaultState.floor], 
    "layout": {
      "visibility": "none"
    },
    'paint': {   
      'line-color': defaultState.roomColorAvail,
      'line-width': 3,
      // 'fill-outline-color': "rgba(0, 0, 0, 0.3)",
      // 'fill-pattern': defaultState.roomColorAvail,
      },
    "minzoom": 17
  },
  roomShared: {   
    'id': 'umich-room-shared',   
    'type': 'line',   
    'source': 'campus-room',   
    "source-layer": "room",
    "filter":["==", "floor", defaultState.floor],
    "layout": {
      "visibility": "none"
    },
    'paint': {   
      'line-color': defaultState.roomColorShared,
      'line-width': 3,
      // 'fill-outline-color': "rgba(0, 0, 0, 0.3)",
      },
    "minzoom": 17
  },
  roomActive: {   
    'id': 'umich-room-active',   
    'type': 'fill',   
    'source': 'campus-room',   
    "source-layer": "room",   
    "filter":['all', ["==", "floor", defaultState.floor], ["in", 'rmrecnbr']],   
    'paint': {   
      // 'fill-color': "rgba(0, 0, 0, 0.1)",   
      'fill-color': "rgba(240, 52, 52, 0.1)",   
      'fill-outline-color': "rgba(240, 52, 52, 1)"
    },   
    "minzoom": 17
  }, 
  roomSelect1: {   
    'id': 'umich-room-select-1',
    'type': 'fill',   
    'source': 'campus-room',   
    "source-layer": "room",   
    "filter":['all', ["==", "floor", defaultState.floor], ["in", 'rmrecnbr']],   
    'paint': {   
      'fill-color': defaultState.roomSelectColor1,   
      'fill-outline-color':"rgba(0, 0, 0, 0.25)", 
    },   
    "minzoom": 17
  },
  roomSelect2: {   
    'id': 'umich-room-select-2',   
    'type': 'fill',   
    'source': 'campus-room',   
    "source-layer": "room",   
    "filter":['all', ["==", "floor", defaultState.floor], ["in", 'rmrecnbr']],   
    'paint': {   
      'fill-color': defaultState.roomSelectColor2,   
      'fill-outline-color':"rgba(0, 0, 0, 0.25)", 
    },   
    "minzoom": 17
  },
  roomSelect3: {   
    'id': 'umich-room-select-3',   
    'type': 'fill',   
    'source': 'campus-room',   
    "source-layer": "room",   
    "filter":['all', ["==", "floor", defaultState.floor], ["in", 'rmrecnbr']],   
    'paint': {   
      'fill-color': defaultState.roomSelectColor3,   
      'fill-outline-color':"rgba(0, 0, 0, 0.25)", 
    },   
    "minzoom": 17
  },
  roomSelect4: {   
    'id': 'umich-room-select-4',   
    'type': 'fill',   
    'source': 'campus-room',   
    "source-layer": "room",   
    "filter":['all', ["==", "floor", defaultState.floor], ["in", 'rmrecnbr']],   
    'paint': {   
      'fill-color': defaultState.roomSelectColor4,   
      'fill-outline-color':"rgba(0, 0, 0, 0.25)", 
    },   
    "minzoom": 17
  },
  roomSelect5: {   
    'id': 'umich-room-select-5',   
    'type': 'fill',   
    'source': 'campus-room',   
    "source-layer": "room",   
    "filter":['all', ["==", "floor", defaultState.floor], ["in", 'rmrecnbr']],   
    'paint': {   
      'fill-color': defaultState.roomSelectColor5,   
      'fill-outline-color':"rgba(0, 0, 0, 0.25)", 
    },   
    "minzoom": 17
  },
  roomSelectIntersect: {   
    'id': 'umich-room-select-intersect',   
    'type': 'line',   
    'source': 'campus-room',   
    "source-layer": "room",   
    "filter":['all', ["==", "floor", defaultState.floor], ["in", 'rmrecnbr']],   
    'paint': {   
      'line-color': defaultState.roomSelectIntersectColor,
      'line-width': 3
    },   
    "minzoom": 17
  },
  roomSelectSymbol1: {
    'id': 'umich-room-select-symbol-1',
    'type': 'symbol',
    'source': 'campus-label',
    'source-layer': 'label',
    "filter":[
      'all', 
        ["==", "floor", defaultState.floor], 
        ["in", 'rmrecnbr']
      ],
    "minzoom": 17,
    "layout": {
      "icon-image": "camera",
      "icon-allow-overlap": true,
      "icon-anchor": "center",
      // "icon-allow-overlap": false,
      // "symbol-sort-key": 1,
      "icon-size": [
        'interpolate', 
        ['exponential', 3], 
        ['zoom'],
        // zoom is 17 or less (farther)
        17, .4, 
        // zoom is 22 or greater (nearer)
        22, 4
      ],
    }
  },
  roomDraw1: {   
    'id': 'umich-room-draw-1',   
    'type': 'fill',   
    'source': 'campus-room',   
    "source-layer": "room",   
    "filter":['all', ["==", "floor", defaultState.floor], ["in", 'rmrecnbr']],   
    'paint': {   
      'fill-color': defaultState.roomDrawColor1,   
      'fill-outline-color':"rgba(0, 0, 0, 0.25)", 
    },   
    "minzoom": 17
  },
  reference: {   
      'id': 'umich-reference',   
      'type': 'line',   
      'source': 'campus-reference',   
      "source-layer": "reference",   
      "filter":["==", "floor", defaultState.floor],   
      'paint': {   
        'line-color': "#444",   
        'line-width': 0.6   
      },   
      "minzoom": 20
  }, 
  roomLabel: {   
      'id': 'umich-room-label',   
      'type': 'symbol',   
      'source': 'campus-label',
      "source-layer": "label",
      "filter":['all', ["==", "floor", defaultState.floor], ["!=", "deptid", "000500"]],   
      "layout": {   
        // "text-field": defaultState.labelPrimary,   
        "text-field": "",   
        "text-font": ["Open Sans Bold", "Arial Unicode MS Bold"],   
        "text-size": defaultState.labelPrimarySize,   
        "text-allow-overlap": true, 
        "text-anchor": "center"
      },
      "paint": {   
        "text-color": defaultState.labelColor   
      },   
      "minzoom": 18.5
    }
  }
}; 


export const layerComponents = [
  // graphics
  {
    id: 'basemap',
    label: 'Basemap',
    type: 'graphics',
    isVisible: true,
    isLayer: function(layer) {
      return (
        // not umich
        layer.id.indexOf('umich') === -1 &&
        // not draw
        layer.id.indexOf('gl-draw-') === -1 &&
        // not spreadsheet
        layer.id.indexOf('sheet') === -1
      );
    }
  },
  {
    id: 'building',
    label: 'Building',
    type: 'graphics',
    isVisible: true,
    isLayer: function(layer) {
      return layer.id.indexOf('umich-building') !== -1;
    }
  },
  {
    id: 'floor',
    label: 'Floor Plan',
    type: 'graphics',
    isVisible: true,
    isLayer: function(layer) {
      return (
        layer.id.indexOf('umich-room') !== -1 ||
        layer.id.indexOf('umich-floor') !== -1 ||
        layer.id.indexOf('umich-reference') !== -1
      );
    },
  },
  // {
  //   id: 'reference',
  //   label: 'Reference',
  //   type: 'graphics',
  //   isVisible: true,
  //   isLayer: function(layer) {
  //     return layer.id === 'umich-reference';
  //   }
  // },
  // color
  {
    id: 'defaultcolor',
    label: 'White',
    type: 'color',
    isVisible: true,
    mapKey: 'default',
    tooltips: ['No room fill'],
    radio: true,
  },
  {
    id: 'roomtypecolor',
    label: 'Room Type',
    type: 'color',
    isVisible: false,
    mapKey: 'rmtyp',
    tooltips: ['Rooms color coded by room use'],
    radio: true,
  },
  {
    id: 'suverydepartmentcolor',
    label: 'Survey Department',
    type: 'color',
    isVisible: false,
    mapKey: 'surveydept',
    tooltips: ['Rooms color coded by department assignment', ],
    radio: true,
  },
  {
    id: 'shared',
    label: 'Shared Rooms',
    type: 'color',
    isVisible: false,
    mapKey: 'shared',
    tooltips: ['Red border: multiple departments sharing a room', 'Blue border: occupying department different from survey department']
  },
  {
    id: 'faavailable',
    label: 'FA Available (>50%)',
    type: 'color',
    isVisible: false,
    mapKey: 'faavailable',
    tooltips: ['Rooms highlighted with green border to display available space (50% or higher) in rooms']
  },
  // label
  {
    id: 'roomnumber',
    label: 'Room Number',
    type: 'label',
    template: '{rmnbr}\n',
    value: 'rmnbr',
    concat: '',
    isVisible: true,
    isParent: true,
    children: ['roomnumbernonassignable']
  },
  {
    id: 'roomnumbernonassignable',
    label: 'Non Assignable Rooms',
    type: 'label',
    isVisible: false,
    hasParent: true,
    filter: function(checked) {
      if (checked) {
        return {type: 'remove', filter: ["!=", "deptid", "000500"] };
      } else {
        return {type: 'add', filter: ["!=", "deptid", "000500"] };
      }
    }
  },
  {
    id: 'roomsqft',
    label: 'Room SQFT',
    type: 'label',
    value: 'rmsqrft',
    concat: ' sqft',
    isVisible: false,
  },
  {
    id: 'roomtype',
    label: 'Room Type/Subtype',
    type: 'label',
    value: ['rmtyp_descrshort', 'rmsubtyp_descrshort'],
    concat: ['', ''],
    isVisible: false,
  },
  {
    id: 'departmentocc',
    label: 'Occupying Department',
    type: 'label',
    value: 'occdeptlist',
    concat: '',
    isVisible: false,
  },
  {
    id: 'departmentsurvey',
    label: 'Survey Department',
    type: 'label',
    value: 'dept_descr',
    concat: '',
    isVisible: false,
  },
  {
    id: 'occupant',
    label: 'Occupants',
    type: 'label',
    value: 'occlist',
    concat: '',
    isVisible: false,
  },
  {
    id: 'facultyallocation',
    label: 'Faculty Allocation',
    type: 'label',
    value: 'falist',
    concat: '',
    isVisible: false,
    isParent: true,
    children: ['facultyallocationlabs', 'facultyallocationoffices', 'facultyallocationothers']
  },
  {
    id: 'facultyallocationlabs',
    label: 'Labs',
    type: 'label',
    isVisible: false,
    hasParent: true,
    value: 'falist',
    concat: '',
    rmtyp: '250'
  },
  {
    id: 'facultyallocationoffices',
    label: 'Offices',
    type: 'label',
    isVisible: false,
    hasParent: true,
    value: 'falist',
    concat: '',
    rmtyp: '310'
  },
  {
    id: 'facultyallocationothers',
    label: 'All others',
    type: 'label',
    isVisible: false,
    hasParent: true,
    value: 'falist',
    concat: '',
    rmtyp: 'others'
  },
  {
    id: 'grants',
    label: 'Grants',
    type: 'label',
    value: 'grantdtlist',
    concat: '',
    isVisible: false,
  },
  {
    id: 'workstations',
    label: 'Workstations',
    type: 'label',
    value: 'rmstationcnt',
    concat: ' WS',
    isVisible: false,
  },
];