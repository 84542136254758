import React, { useState } from 'react';
import { useDispatch } from 'react-redux'
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { actionDataSearchRooms } from './dataRedux';
import { actionMenuSearchTypeToggled, actionMenuSearchUpdateChanged } from '../AppBar';

const useStyles = makeStyles(theme => ({
  add: {
    color: 'rgb(224 224 224)',
    marginLeft: -4,
    '&:hover': {
      color: 'rgba(0, 0, 0, 0.54)',
    }
  },
  chip: {
    marginRight: 4,
    marginBottom: 2,
    '& .MuiAvatar-root': {
      width: 24,
      height: 24,
      fontSize: '0.7rem',
      marginLeft: 0,
      marginRight: -4,
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
    },
    '& .MuiChip-label': {
      paddingLeft: 8,
      paddingRight: 8,
    }
  },
  chipLabel: {
    fontSize: '0.65rem',
    letterSpacing: '0'
  }
}));

const FILTER_AVATAR_TEXT = {
  building: 'BLD',
  room: 'RM',
  person: 'P',
  department: 'DEP',
  occdepartment: 'ODEP',
  roomtype: 'TYP',
  roomsubtype: 'STYP',
};

export default function Filters({filters, id}) {
  const dispatch = useDispatch();

  const handleDelete = (filterId) => {
    const newFilters = filters.filter(filter => filter.id !== filterId);
    dispatch(actionDataSearchRooms(newFilters, id));
  }

  const handleChipClick = (filter) => {
    const searchType = filter.type === 'roomsubtype' ? 'roomtype' : filter.type;
    dispatch(actionMenuSearchUpdateChanged({ groupId: id, type: 'replace', targetFilterId: filter.id }));
    dispatch(actionMenuSearchTypeToggled(searchType));
  }

  return (
    <React.Fragment>
      {
        filters.map(filter => {
          return <Filter key={filter.id} filter={filter} handleDelete={handleDelete} handleChipClick={handleChipClick}/>
        })
      }
      <AddFilter id={id} />
    </React.Fragment>
  );
}

function Filter({ filter, handleDelete, handleChipClick }) {
  const classes = useStyles();
  const onDelete = () => { handleDelete(filter.id) };
  const onClick = () => { handleChipClick(filter) };

  return (
    <Chip
      size='small'
      className={classes.chip}
      onClick={onClick}
      onDelete={onDelete}
      avatar={
        <Tooltip title={toTooltip(filter)}>
          <Avatar data-testid="search-result-filter-type">
            {avatarText(filter)}
          </Avatar>
        </Tooltip>
      }
      label={
        <Typography variant="overline" className={classes.chipLabel} data-testid="search-result-filter-name">
          {toText(filter)}
        </Typography>
      }
    />
  );
}

function AddFilter({ id }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => { setAnchorEl(event.currentTarget) }
  const handleClose = () => { setAnchorEl(null) }

  const handleSelect = (searchType) => () => {
    dispatch(actionMenuSearchUpdateChanged({ groupId: id, type: 'add' }))
    dispatch(actionMenuSearchTypeToggled(searchType));
    handleClose();
  };

  return (
    <React.Fragment>
      <Tooltip title="Add Search Criteria">
        <IconButton size="small" className={classes.add} onClick={handleClick}>
          <AddCircleIcon />
        </IconButton>
      </Tooltip>
      <Menu
        id="add-search-criteria"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleSelect('building')}>
          <Typography variant="overline">Building</Typography>
        </MenuItem>
        <MenuItem onClick={handleSelect('room')}>
          <Typography variant="overline">Room</Typography>
        </MenuItem>
        <MenuItem onClick={handleSelect('person')}>
          <Typography variant="overline">People</Typography>
        </MenuItem>
        <MenuItem onClick={handleSelect('department')}>
          <Typography variant="overline">Department</Typography>
        </MenuItem>
        <MenuItem onClick={handleSelect('roomtype')}>
          <Typography variant="overline">Room Type</Typography>
        </MenuItem>
      </Menu>    
    </React.Fragment>
  );
}

export function toText(filter) {
  if (filter.label) {
    return filter.label;
  } else if (filter.type === 'building') {
    return filter.bld_descrshort || filter.bldrecnbr;
  } else if (filter.type === 'room') {
      return filter.rmnbr;
  } else if (filter.type === 'person') {
      return filter.person_uniqname.toLowerCase();
  } else if (filter.type === 'department') {
      return filter.dept_descr;
  } else if (filter.type === 'roomtype') {
      return filter.rmtyp;
  } else if (filter.type === 'roomsubtype') {
      return filter.rmtyp + '-' + filter.rmsubtyp;
  } else {
    return '';
  }
}

function avatarText(filter) { 
  return FILTER_AVATAR_TEXT[filter.type] ? FILTER_AVATAR_TEXT[filter.type] : ''; 
}

function toTooltip(filter) {
  if (filter.type === 'occdepartment') { return 'OCCUPYING DEPARTMENT' }
  return filter.type && filter.type.toUpperCase ? filter.type.toUpperCase() : ''
}