import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';

import HelpIcon from '@material-ui/icons/Help';
import GetAppIcon from '@material-ui/icons/GetApp';
import PublishIcon from '@material-ui/icons/Publish';

import { actionAppToggleComponent } from '../App';
import { actionSheetExpandToggled, actionSheetUploadToggled } from './spreadsheetRedux';
import Accordian from '../Shared/Accordion';
import SpreadsheetCard from './SpreadsheetCard';
import ExportDialog from './ExportDialog';

export default function Spreadsheet() {
  const component = 'spreadsheetVisible';
  const isVisible = useSelector(state => state.appReducer[component]);
  const { isExpanded, files } = useSelector(state => state.spreadsheetReducer);
  const dispatch = useDispatch();
  const [expandHelp, setExpandHelp] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  const visibleHandler = () => {
    dispatch(actionAppToggleComponent({ component }));
  };

  const expandHandler = () => {
    dispatch(actionSheetExpandToggled());
  };

  const handleExpandHelp = (event) => {
    setExpandHelp(!expandHelp);
    event.stopPropagation();
  };

  const handleUpload = (event) => {
    event.stopPropagation();
    dispatch(actionSheetUploadToggled());
  };

  return (
    <Accordian
      title='Spreadsheet'
      visible={isVisible}
      visibleHandler={visibleHandler}
      expand={isExpanded}
      expandHandler={expandHandler}
    >
      <>
        <Box>
          {files.map(({ id }) => {
            return (
              <SpreadsheetCard 
                key={id}
                sheetId={id}
              />
            );
          })}
        </Box>
        <Box 
          display="flex"
          justifyContent="space-evenly"
          alignItems="center"
          py={1}
          >
          <Button 
            variant="contained"
            color="primary"
            onClick={handleUpload}
            startIcon={<GetAppIcon />}
          >
            Import
          </Button>
          <Button
            variant="contained"
            startIcon={<PublishIcon />}
            onClick={() => {
              setDialogOpen(true);
            }}
            disabled={files.length === 0}
          >
            Export
          </Button>
          <ExportDialog 
            setDialogOpen={setDialogOpen}
            dialogOpen={dialogOpen}
          />
          <IconButton
            onClick={handleExpandHelp}
          >
            <HelpIcon />
          </IconButton>
        </Box>
        <Collapse
          in={expandHelp}
        >
          <Divider />
          <Box p={2}>
            This feature allows you to highlight multiple rooms by importing a spreadsheet file (<Link href="https://docs.google.com/spreadsheets/d/1dsiI3MwT97YeNnNtmqMPaJpUkVv6YbjH" target="_blank" rel="noopener">sample</Link>) containing a list of Room Record Numbers for rooms you wish to view. <Link href="https://docs.google.com/document/d/1Z1ryk9sZ8Sv2slBAkdXaCIJETdB5BxPT0gKi2OkozTc/" target="_blank" rel="noopener">Click here</Link> to learn more.
          </Box>
        </Collapse>
      </>
    </Accordian>
  );
};