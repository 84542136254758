import { createAction, handleActions } from 'redux-actions';
import {
  actionMapDrawModeUpdated,
  actionMapDrawFeaturesUpdated,
  actionMapZoomFeaturesUpdated,
  actionMapCursorUpdated,
} from '../Map';
import { actionDataSelectFeatureRequested } from '../Data';
import { actionDeleteAlert } from '../Alert';
import intersection from 'lodash/intersection';
import drawSettings from './settings';
import * as Draw from '@mapbox/mapbox-gl-draw';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import { flushSync } from 'react-dom'; // opt-out of automatic batching https://reactjs.org/blog/2022/03/08/react-18-upgrade-guide.html

export const initialState = {
  isExpanded: false,
  isDrawing: false, // suppress other click events (info window)
  clickCounter: { mode: '', counter: 0 }, // determine if end of drawing  
  /**
   * @featureGroups: [{group1}, {group2}, ...] 
   * @group: {
   *  groupId: 'xxxx', // id that represents a group of features
   *  name: 'Renovation 20XX', // used as 'layer name' or filename if downloaded, or stored in M+Box
   *  source: 'draw', // (search/draw/mbox/spreadsheet/mixed)
   *  features: [{feature1}, {feature2}, ...], // feature in geojson format, last added feature has index 0
   *  groupBy: 'building-floor', // (building-floor, feature),
   *  sortBy: 'building',
   *  sortDirection: 'ascending', // (ascending, descending)
   *  filterBy: { building, floor, roomtype, department },
   *  filterBySubstring: '',
   *  isLoading: false, // (true, false)
   *  isVisible: true, // (true, false)
   *  isEditable: true, // (true, false)
   * }
   */
  featureGroups: [],
  maxGroupSize: 3,
  defaultColor: 'rgb(59, 178, 208)', // light blue
};

export const actionDrawExpandToggled = createAction('DRAW_EXPAND_TOGGLED');
export const actionDrawIsDrawingUpdated = createAction('DRAW_IS_DRAWING_TOGGLED');
export const actionDrawClickCounterUpdated = createAction('DRAW_CLICK_COUNTER_UPDATED');
export const actionDrawFeatureGroupsUpdated = createAction('DRAW_FEATURE_GROUPS_UPDATED');
export const actionDrawDefaultColorUpdated = createAction('DRAW_DEFAULT_COLOR_UPDATED');

export const actionDrawAddGroup = (config) => {
  return (dispatch, getState) => {
    const { featureGroups, maxGroupSize } = getState().drawReducer;
    const newGroup = createDefaultGroup(config)
    let newFeatureGroups = [newGroup, ...featureGroups];
    newFeatureGroups = newFeatureGroups.length > maxGroupSize ?
                       newFeatureGroups.slice(0, maxGroupSize) : newFeatureGroups;
    dispatch(actionDrawUpdateFeatureGroups(newFeatureGroups));
  }
}

// @param operation - (add, update, delete, delete-all)
// @todo: refactor
export const actionDrawUpdateGroupFeature = (operation, target) => {
  return (dispatch, getState) => {
    const { featureGroups, defaultColor } = getState().drawReducer;
    const { drawMode, zoom } = getState().mapReducer;
    const { floor } = getState().appBarReducer;

    // assume first group being edited
    // @target: [feature1, feature2, ...]
    if (operation === 'add') {

      if (drawMode.mode === 'draw_zoom_box') {
        const [feature] = target;
        
        flushSync(() => {
          dispatch(actionMapZoomFeaturesUpdated({ data: feature }));
        });

        flushSync(() => {
          dispatch(actionMapDrawModeUpdated({ mode: 'delete', data: feature.id }));
        });

        return;
      }

      if (drawMode.mode === 'group_room_picker') {
        flushSync(() => {
          // send rectangle data to query rooms under the polygon
          dispatch(actionMapDrawModeUpdated({ mode: 'group_room_picker', data: target }));
        })

        flushSync(() => {
          // end mode
          dispatch(actionMapDrawModeUpdated({ mode: 'simple_select' }));
        })
        return;
      }

      const [firstGroup] = featureGroups;
      const groupId = firstGroup && firstGroup.groupId ? firstGroup.groupId : createId();
      const newProperties = { groupId, drawMode, floor, zoom, defaultColor };
      const newFeatures = updateFeatureProperties(target, newProperties);
      const features = firstGroup ? removeDuplicateFeatures(firstGroup.features, newFeatures) : newFeatures;
      dispatch(actionMapDrawFeaturesUpdated(newFeatures));

      if (firstGroup) {
        const newGroup = {...firstGroup, features };
        let newFeatureGroups = [...featureGroups];
        newFeatureGroups[0] = newGroup;
        dispatch(actionDrawUpdateFeatureGroups(newFeatureGroups));
      } else {
        dispatch(actionDrawAddGroup({ groupId, features }));
      }
      dispatch(actionDataSelectFeatureRequested({ type: 'room-color', data: features, layerId: 'umich-room-draw-1' }));
      return;
    }
    
    // @target: [feature1, feature2, ...]
    if (operation === 'update') {
      dispatch(actionMapDrawFeaturesUpdated(target));
      let newFeatureGroups = [...featureGroups];
      target.forEach(updatedFeature => {
        const groupIndex = newFeatureGroups.findIndex(group => group.groupId === updatedFeature.properties.groupId);
        if (groupIndex === -1) { return }
        const group = {...newFeatureGroups[groupIndex]};
        const index = group.features.findIndex(groupFeature => groupFeature.id === updatedFeature.id);
        if (index === -1) { return }
        group.features.splice(index, 1);
        group.features = [updatedFeature, ...group.features];
        newFeatureGroups[groupIndex] = group;
      });
      dispatch(actionDrawUpdateFeatureGroups(newFeatureGroups));
      const features = newFeatureGroups.reduce((acc, group) => [...acc, ...group.features], []);
      dispatch(actionDataSelectFeatureRequested({ type: 'room-color', data: features, layerId: 'umich-room-draw-1' }));
      return;
    }

    // @target: {groupId, id}
    if (operation === 'delete') {
      const { groupId, id } = target;
      let newFeatureGroups = [...featureGroups];
      const groupIndex = newFeatureGroups.findIndex(group => group.groupId === groupId);
      const group = newFeatureGroups[groupIndex];
      if (!group) { return }
      const newGroup = {...group, features: group.features.filter(feat => feat.id !== id )};
      newFeatureGroups[groupIndex] = newGroup;
      dispatch(actionDrawUpdateFeatureGroups(newFeatureGroups));
      dispatch(actionDataSelectFeatureRequested({ type: 'room-color', data: newGroup.features, layerId: 'umich-room-draw-1' }));
      return;
    }

    // assume first group
    if (operation === 'delete-all') {
      let newFeatureGroups = [...featureGroups];
      const [firstGroup] = newFeatureGroups;
      const newFirstGroup = {...firstGroup, features: []};
      newFeatureGroups[0] = newFirstGroup;
      dispatch(actionDrawUpdateFeatureGroups(newFeatureGroups));
      dispatch(actionDataSelectFeatureRequested({ type: 'room-color', data: [], layerId: 'umich-room-draw-1' }));
    }
  }
}

export const actionDrawRemoveGroup = (targetGroupId) => {
  return (dispatch, getState) => {
    const { featureGroups } = getState().drawReducer;
    const newFeatureGroups = featureGroups.filter(group => group.groupId !== targetGroupId);
    dispatch(actionDrawUpdateFeatureGroups(newFeatureGroups));
  }
}

export const actionDrawRoomSelect = (features) => {
  return (dispatch, getState) => {
    const { drawMode } = getState().mapReducer;
    const { mode } = drawMode;

    if (mode !== 'room_picker') { return }
    dispatch(actionDrawUpdateGroupFeature('add', features));
  }
}

// helps display instruction
export const actionDrawUpdateClickCounter = (changeMode) => {
  return (dispatch, getState) => {
    // handle when draw button clicked
    // on map click event won't be called if click a pane
    // the user has not clicked the map yet, hence counter starts at 0
    if (changeMode) {
      dispatch(actionDrawClickCounterUpdated({mode: changeMode, counter: 0}));
      return;
    }

    const { clickCounter } = getState().drawReducer;
    const { counter, mode } = clickCounter;
    const { drawMode } = getState().mapReducer;
    const modeChanged = drawMode.mode !== mode;
    let newClickCounter;

    if (modeChanged) {
      newClickCounter = { mode: drawMode.mode, counter: 1 };
      dispatch(actionDrawClickCounterUpdated(newClickCounter));
    } else {
      newClickCounter = {...clickCounter, counter: counter + 1 };
      dispatch(actionDrawClickCounterUpdated(newClickCounter));
    }

    if (hasStoppedDrawing(newClickCounter)) {
      dispatch(actionDrawIsDrawingUpdated(false));
    }
  }
}

export const actionDrawResetDefaultColor = () => {
  return dispatch => dispatch(actionDrawDefaultColorUpdated(initialState.defaultColor));
}

export const actionDrawResetMode = () => {
  return dispatch => {
    const mode = 'simple_select';
    dispatch(actionMapDrawModeUpdated({mode}));
    dispatch(actionDrawUpdateClickCounter(mode));
    dispatch(actionDrawIsDrawingUpdated(false));
    dispatch(actionMapCursorUpdated({drawMode: false}));
    dispatch(actionDeleteAlert('draw'));    
  }
}

export const actionDrawUpdateFeatureGroups = (newFeatureGroups) => {
  return dispatch => {
    const newGroups = newFeatureGroups.map(group => {
      return {...group, features: removeDuplicateRooms(group.features)};
    });

    dispatch(actionDrawFeatureGroupsUpdated(newGroups));
  }
}

export const actionDrawToggleVisiblePolygonsFromFloorChange = (map, floor) => {
  return (_, getState) => {
    const { featureGroups } = getState().drawReducer;

    featureGroups.forEach(group => {
      group.features.forEach(feature => {
        const isDrawnFeature = !feature.source;
        const featureIsVisible = feature.properties && (feature.properties.floor == floor || feature.properties.floor == "BLDG" || !feature.properties.floor);

        // only handle drawn polygons and ignore room polygons
        if (!isDrawnFeature) { return }

        // toggle drawn polygon visibility
        if (featureIsVisible) {
          map.draw.add(feature);
        } else {
          map.draw.delete(feature.id);
        }
      });
    });

  }
}

export const drawReducer = handleActions({
  [actionDrawExpandToggled]: (state, action) => {
    const isExpanded = action.payload === null || action.payload === undefined ? !state.isExpanded : action.payload;
    return {...state, isExpanded };
  },
  [actionDrawIsDrawingUpdated]: (state, action) => {
    return {...state, isDrawing: action.payload };
  },
  [actionDrawClickCounterUpdated]: (state, action) => {
    return {...state, clickCounter: action.payload };
  },
  [actionDrawFeatureGroupsUpdated]: (state, action) => {
    return {...state, featureGroups: action.payload };
  },
  [actionDrawDefaultColorUpdated]: (state, action) => {
    return {...state, defaultColor: action.payload }
  },
}, initialState);

function hasStoppedDrawing(clickCounter) {
  const { mode, counter } = clickCounter;
  return mode === 'simple_select' && counter === 2;
}

function createDefaultGroup(config={}) {
  const groupDefault = {
    groupId: createId(), 
    name: '', 
    source: 'draw',
    features: [],
    groupBy: 'building-floor',
    sortBy: 'building',
    sortDirection: 'ascending',
    filterBy: {},
    filterBySubstring: '',
    isLoading: false,
    isVisible: true,
    isEditable: true,
  };
  return {...groupDefault, ...config};
}

// ref: https://gist.github.com/gordonbrander/2230317
function createId() {
  // Math.random should be unique because of its seeding algorithm.
  // Convert it to base 36 (numbers + letters), and grab the first 9 characters
  // after the decimal.
  return '_' + Math.random().toString(36).substr(2, 9);
};

function updateFeatureProperties(features, newProperties) {
  return features.map(feature => {
    feature.properties = feature.properties || {}; 
    feature.geometry = feature.geometry || {}; 
    const { groupId, drawMode, floor, zoom, defaultColor } = newProperties;
    const { mode } = drawMode;
    const bldname = feature.properties.bldname || feature.properties.bld_descrshort;
    const additionalProperties = {
      groupId,
      drawmode: feature.properties.drawMode || mode,
      name: feature.properties.name ? feature.properties.name : makeName(feature, mode),
      bldname,
      floor: bldname ? getFloor(feature, floor) : '',
      zoom,
      color: defaultColor,
    };
    
    const id = feature.id || createId();
    const properties = {...feature.properties, ...additionalProperties };
    const geometry = {...feature.geometry, ...(feature._geometry || {})};

    return {...feature, id, geometry, properties};
  });
}

function getFloor(feature={}, floor) {
  const { properties } = {floor: '', roomvisible: '', ...feature};

  if (properties.floor) { return properties.floor }
  if (floor) { return floor }
  // floor not selected but at zoom level where room layer is visible
  if (properties.roomvisible) { return '01' }
  // BLDG: view level
  return 'BLDG';
}

function makeName(feature={}, mode='') {
  const { layer, properties } = feature;
  const validRoom = layer && layer.id && layer.id === 'umich-room' && properties.rmnbr;
  if (validRoom) {
    return `RM ${properties.rmnbr}`;
  } else {
    return drawModeToName(mode);
  } 
}

function drawModeToName(mode) {
  if (mode === 'draw_polygon') {
    return 'Polygon';
  } else if (mode === 'draw_rectangle') {
    return 'Rectangle';
  } else if (mode === 'draw_line_string') {
    return 'Line';
  } else if (mode === 'draw_point') {
    return 'Point';
  } else if (mode === 'draw_circle') {
    return 'Circle';
  } else if (mode === 'draw_freehand') {
    return 'Freehand';
  } else {
    return 'Object';
  }
}

function removeDuplicateFeatures(features, newFeatures) {
  const intersect = intersection(
    features.map(feat => feat.id),
    newFeatures.map(feat => feat.id)
  )
  .reduce((acc, item) => {
    acc[item] = true;
    return acc;
  }, {});

  return [...features, ...newFeatures].reduce((acc, feature) => {
    if (!intersect[feature.id]) { acc.push(feature); }
    return acc;
  }, []);
}

function removeDuplicateRooms(features) {
  const rrns = new Set();
  return features.reduce((newFeatures, feature) => {
    const hasRmrecnbr = feature && feature.properties && feature.properties.rmrecnbr;
    const uniqRoom = hasRmrecnbr && !rrns.has(feature.properties.rmrecnbr);
    const fromRoomPicker = feature && feature.source && feature.source === 'campus-room';

    if (hasRmrecnbr && uniqRoom && fromRoomPicker) { // allow unique rooms selected from room picker
      rrns.add(feature.properties.rmrecnbr);
      newFeatures.push(feature);
    } else if (!hasRmrecnbr) { // allow all non-room picked features 
      newFeatures.push(feature);
    } else if (hasRmrecnbr && !fromRoomPicker) { // allow non picked rooms that has rrn property
      newFeatures.push(feature);
    }

    return newFeatures;
  }, []); 
}

export function initDraw(map) {
  const draw = new Draw(drawSettings);
  map.addControl(draw);
  map.draw = draw;
}