import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';

const offsetTop = 55,
      offestMaxHeight = offsetTop + 1;

const useStyles = makeStyles(theme => ({
  accordionContainer: {
    position: 'fixed',
    top: offsetTop,
    left: 5,
    zIndex: 1,
    maxHeight: 'calc(100vh - ' + offestMaxHeight + 'px)',
    overflow: 'auto',
    [theme.breakpoints.down('xs')]: {
      position: 'fixed',
      bottom: 0,
      top: 'unset',
      left: '50%',
      transform: 'translate(-50%, 0)',
    },
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    }
  },
}));

export default function AccordionContainer({ children }) {
  const classes = useStyles();
  const accordionVisible = useSelector(state => state.appReducer.accordionVisible);

  return (
    <div
      className={classes.accordionContainer}
      style={{display: accordionVisible ? 'inherit' : 'none'}}>
      {children}
    </div>
  );
} 