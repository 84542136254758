import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import HomeIcon from '@material-ui/icons/Home';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Collapse from '@material-ui/core/Collapse';
import clsx from 'clsx';
import {
  actionMapMapviewChangeRequested,
} from '../Map';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { selectorAppIsSmallScreen, selectorAppIsTouchScreen, actionMapviewCurrentIndexUpdated, actionMapviewIgnoreUpdated } from '../App';
import Tooltip from '@material-ui/core/Tooltip';
import { actionMapResetMapCenter } from '../Map/MapRedux';
import { trackEvent, MAP_TOOLS } from '../../tracking';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 1,
  },
  widget: {
    width: 42,
    minWidth: 'unset',
    backgroundColor: '#ffffff',
    border: '1px solid #f0f0f0',
    [theme.breakpoints.down('xs')]: {
      width: 34,
      '& .MuiSvgIcon-root': {
        fontSize: '1.25rem',
      },
    },
  },
  btns: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    '&:hover': {
      '& button': {
        borderRadius: 0,
      },
    },
  },
  zoomIn: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    borderBottom: 'none',
  },
  zoomOut: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderTop: 'none',
  },
  expand: {
    paddingTop: 0,
    paddingBottom: 0,
    backgroundColor: 'rgba(213, 213, 213, 0.5)',
    borderRadius: 0,
    border: 'none',
    '&:hover': {
      backgroundColor: 'rgba(213, 213, 213, 0.95)',
    },
    '& .MuiSvgIcon-root': {
      fontSize: '1.25rem',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none !important',
    },
  },
}));

export default function Zoom() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isSmallScreen = useSelector(selectorAppIsSmallScreen);
  const isTouchScreen = useSelector(selectorAppIsTouchScreen);

  const [expandVisible, setExpandVisible] = useState(false);
  const [expand, setExpand] = useState(false);

  const expandClick = () => { setExpand(!expand) }
  const handlePopoverOpen = () => { setExpandVisible(true) }
  const handlePopoverClose = () => { setExpandVisible(false) }

  const mapViewHistory = useSelector(state => state.miscReducer.mapViewHistory);
  const mapViewCurrentIndex = useSelector(state => state.miscReducer.mapViewCurrentIndex);

  const clickHandler = (value) => () => {
    const targetIndex = mapViewCurrentIndex + value;
    const targetMapView = mapViewHistory[targetIndex];
    const { lng, lat, zoom, pitch, bearing } = targetMapView;
    trackEvent({
      category: MAP_TOOLS,
      action: 'Cycle Map View History',
      label: value > 0 ? 'Forward' : 'Backward' 
    })
    dispatch(actionMapMapviewChangeRequested({ center: [lng, lat], zoom, pitch, bearing }));
    dispatch(actionMapviewCurrentIndexUpdated(targetIndex));
    dispatch(actionMapviewIgnoreUpdated(true));
  }

  const resetHandler = () => {
    trackEvent({
      category: MAP_TOOLS,
      action: 'Reset Map View Center'
    });
    dispatch(actionMapResetMapCenter());
  }

  return (
    <React.Fragment>
      { 
        (!isSmallScreen || !isTouchScreen) &&
        <div className={classes.root}>
          <Paper
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
            className={classes.btns}
          >
            <Tooltip title="Reset Map View">
              <Button
                onClick={resetHandler}
                variant="contained"
                className={clsx(classes.widget, classes.home)} >
                <HomeIcon />
              </Button>
            </Tooltip>
            <Collapse in={expand}>
            <Paper className={classes.btns} >
                <Tooltip title={`Previous Map View ${mapViewCurrentIndex === 0 ? '(Disabled)' : ''}`}>
                  <span>
                    <Button
                      onClick={clickHandler(-1)}
                      variant="contained"
                      disabled={mapViewCurrentIndex === 0}
                      className={clsx(classes.widget, classes.zoomIn)} >
                      <ArrowBackIcon />
                    </Button>
                  </span>
                </Tooltip>
                <Tooltip title={`Next Map View ${mapViewCurrentIndex >= mapViewHistory.length - 1 ? '(Disabled)' : ''}`}>
                  <span>
                    <Button
                      onClick={clickHandler(1)}
                      variant="contained"
                      disabled={mapViewCurrentIndex >= mapViewHistory.length - 1}
                      className={clsx(classes.widget, classes.zoomOut)} >
                      <ArrowForwardIcon />
                    </Button>
                  </span>
                </Tooltip>
              </Paper>
            </Collapse>
            <Tooltip title="Previous and Next Map View">
              <Button
                style={{visibility: expandVisible ? 'visible' : 'hidden'}}
                variant="contained"
                className={clsx(classes.widget, classes.expand)}
                onClick={expandClick}>
                {expand ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </Button>
            </Tooltip>
          </Paper>
        </div>
      }
    </React.Fragment>
  );
}