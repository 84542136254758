import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import Collapse from '@material-ui/core/Collapse';
import Box from '@material-ui/core/Box';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';

import AddLocationIcon from '@material-ui/icons/AddLocation';
import BrushIcon from '@material-ui/icons/Brush';
import FormatColorFillIcon from '@material-ui/icons/FormatColorFill';
import FormatColorTextIcon from '@material-ui/icons/FormatColorText';
import FormatSizeIcon from '@material-ui/icons/FormatSize';
import HeightIcon from '@material-ui/icons/Height';
import LineWeightIcon from '@material-ui/icons/LineWeight';
import OpacityIcon from '@material-ui/icons/Opacity';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import ColorizeIcon from '@material-ui/icons/Colorize';

import { actionSheetFileUpdated, selectorSheet } from './spreadsheetRedux';
import { defaultSheetFile, returnSheetDefaults, } from './spreadsheetutils';
import {
  ColorConditional,
  TextConditional,
  IconConditional,
  PointConditional,
} from './ConditionalFormatting';
import { SettingSlider, SettingColorSelector, SettingDropSelector, CollapseHeading, Setting } from './SpreadsheetSettingSharedComponents';
import SharedIconSelector from './IconSelector';
import { trackEvent, SPREADSHEET } from '../../tracking';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: 0,
    minWidth: 80,
  },
  componentContainer: {
    paddingTop: 0,
    paddingLeft: 15,
    paddingRight: 15,
    paddingBottom: 0,
  } ,
  defaultComponents: {
    paddingTop: 0
  },
  settingController: {
    width: '55%',
  },
  widthIcon: {
    transform: 'rotate(90deg)'
  },
  sizeIcon: {
    transform: 'rotate(45deg)',
  },
  hr: {
    backgroundColor: 'lightgrey',
    height: 1,
    border: 0,
  },
  settingExpandHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 10,
    paddingLeft: 10,
    fontStyle: 'italic',
    '&:hover': {
      cursor: 'pointer',
    },
    '& :hover': {
      cursor: 'pointer',
    },
    '& button': {
      padding: 0,
      marginLeft: 4,
    },
  },
  switchChecked: {
    color: 'var(--fill-color)',
  },
  switchTrack: {
    backgroundColor: 'var(--fill-color)'
  },
}));

const trackSettingEvent = (key, value) => {
  trackEvent({
    category: SPREADSHEET,
    action: 'Change Setting',
    label: key,
    value
  })
}

const WidthIcon = () => {
  const classes = useStyles();
  return <HeightIcon className={classes.widthIcon} />;
};

const SizeIcon = () => {
  const classes = useStyles();
  return <HeightIcon className={classes.sizeIcon} />;
};

const ColorSelector = ({ sheetId, reduxKey, ...rest} ) => {
  const { [reduxKey]: value } = useSelector(selectorSheet(sheetId));
  const { [reduxKey]: defaultValue } = defaultSheetFile(sheetId);
  const dispatch = useDispatch();

  const handleNewColor = (newColor) => {
    if (!newColor) return;
    let { r, g, b } = newColor.rgb;
    let rgb = `rgb(${r},${g},${b})`;
    trackSettingEvent(reduxKey);
    dispatch(actionSheetFileUpdated({
      id: sheetId,
      [reduxKey]: rgb,
    }));
  };

  return(
    <SettingColorSelector
      fillColor={value || defaultValue}
      defaultColor={value || defaultValue}
      handlePickerClose={handleNewColor}
      {...rest}
    />
  );
};

const SheetSlider = ({ sheetId, reduxKey, displayValue = false, trackValue = true, vertical = false, ...rest }) => {
  const { [reduxKey] : reduxValue }  = useSelector(selectorSheet(sheetId));
  const { [reduxKey] : defaultValue } = defaultSheetFile(sheetId);
  const dispatch = useDispatch();
  const value = vertical ? -1 * reduxValue : reduxValue;
  const defaultVal = vertical ? -1 * defaultValue : defaultValue;

  const handleChange = (newValue) => {
    trackSettingEvent(reduxKey, newValue);
    dispatch(actionSheetFileUpdated({
      id: sheetId,
      [reduxKey]: vertical ? -1 * newValue : newValue,
    }));
  };

  const valueLabelDisplay = displayValue ? 'auto' : 'off';
  const track = trackValue && 'normal';
  
  return (
    <SettingSlider 
      value={value === null ? defaultVal : value}
      valueLabelDisplay={valueLabelDisplay}
      handleNewValue={handleChange}
      track={track}
      {...rest}
    />
  );
};

const TextSelector = ({ sheetId, ...rest }) => {
  const { text, json, defaultText } = useSelector(selectorSheet(sheetId));
  const dispatch = useDispatch()
  const textColumnHeaders = json[0].filter(header => header);
  const classes = useStyles();

  const textColumnHeaderObjects = textColumnHeaders.map((header, index) => {
    return {
      value: index,
      label: header
    }
  });

  const handleSelection = (text) => {
    trackSettingEvent('Label Column')
    dispatch(actionSheetFileUpdated({
      id: sheetId,
      text
    }));
  };

  let textValue = text === null ? defaultText : text;

  return (
    <SettingDropSelector 
      label="Attribute"
      formControllClass={classes.formControl}
      value={textValue}
      handleSelection={handleSelection}
      optionsArray={textColumnHeaderObjects}
      defaultOption={{
        value: '',
        label: 'No label'
      }}
      {...rest}
    />
  );
};

const IconSelector = ({sheetId, ...rest}) => {
  const { icon } = useSelector(selectorSheet(sheetId));
  const dispatch = useDispatch();
  let iconVal = icon === null ? '' : icon;

  const handleSelection = (icon) => {
    trackEvent({
      category: SPREADSHEET,
      action: 'Select Icon',
      label: icon,
    })
    trackSettingEvent("Icon");
    dispatch(actionSheetFileUpdated({
      id: sheetId,
      icon
    }));
  };

  return (
    <SharedIconSelector 
      value={iconVal}
      handleSelection={handleSelection}
      {...rest}
    />
  );
};

const PointIconSelector = ({sheetId, ...rest}) => {
  const { pointIcon } = useSelector(selectorSheet(sheetId));
  const { pointIcon: defaultPointIcon } = defaultSheetFile(sheetId);
  const dispatch = useDispatch();
  let iconVal = pointIcon === null ? defaultPointIcon : pointIcon;

  const handleSelection = (icon) => {
    trackEvent({
      category: SPREADSHEET,
      action: 'Select Point Icon',
      label: icon,
    })
    trackSettingEvent("Icon");
    dispatch(actionSheetFileUpdated({
      id: sheetId,
      pointIcon: icon
    }));
  };

  return (
    <SharedIconSelector 
      value={iconVal}
      handleSelection={handleSelection}
      {...rest}
    />
  );
};

const VisibilitySwitch = ({ sheetId, reduxKey }) => {  
  const value = useSelector(selectorSheet(sheetId))[reduxKey];
  const dispatch = useDispatch();
  const classes = useStyles();

  const handleSwitch = (event) => {
    trackEvent({
      category: SPREADSHEET,
      action: 'Toggle Setting Visibility',
      label: event.target.checked ? `${reduxKey} Visible` : `${reduxKey} Hidden`
    })
    dispatch(actionSheetFileUpdated({
      id: sheetId,
      [reduxKey]: event.target.checked,
    }));
  };

  return (
    <Setting
      active={true}
      label="Display"
      labelIcon={<VisibilityIcon />}
      controller={
        <FormGroup className={classes.settingController}>
        <FormControlLabel 
          control={
            <Switch
              classes={{
                checked: classes.switchChecked,
                track: classes.switchTrack
              }}
              checked={value}
              onChange={handleSwitch}
              color="default"
            />
          }
        />
      </FormGroup>
      }
    />
  );
};

const ResetButton = ({ sheetId, resetKeys, resetConditions, active }) => {
  const dispatch = useDispatch();
  const { conditions } = useSelector(selectorSheet(sheetId));

  const handleReset = () => {
    const resetObj = returnSheetDefaults(sheetId, ...resetKeys);
    const newConditions = conditions.filter(condition => !resetConditions.includes(condition.type));
    trackEvent({
      category: SPREADSHEET,
      action: `Reset Settings`,
      label: resetConditions[0]
    });
    dispatch(actionSheetFileUpdated({
      id: sheetId,
      ...resetObj,
      conditions: newConditions
    }))
  };

  return (
    <Setting
      active={active}
      label="Reset to defaults"
      labelIcon={<SettingsBackupRestoreIcon />}
      controller={
        <Button
          disabled={!active} 
          onClick={handleReset}
          startIcon={<SettingsBackupRestoreIcon />}
          variant="contained"
        >
          Reset
        </Button>
      }
    />
  );
};



const SettingTab = ({ defaultComponents, advancedComponents, conditionalComponent, type, sheetId }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const DEFAULT = type + 'DefaultExpanded';
  const ADVANCED = type + 'AdvancedExpanded';
  const CONDITIONAL = type + 'ConditionalExpanded';
  const { [DEFAULT]: defaultExpanded, [ADVANCED]: advancedExpanded, [CONDITIONAL]: conditionalExpanded } = useSelector(selectorSheet(sheetId));

  const handleExpand = (condition) => {
    let val;
    switch (condition) {
      case DEFAULT:
        val = !defaultExpanded;
        break;
      case ADVANCED:
        val = !advancedExpanded;
        break;
      case CONDITIONAL:
        val = !conditionalExpanded;
        break;
      default: 
        return;
    }
    dispatch(actionSheetFileUpdated({
      id: sheetId,
      [condition]: val
    }));
  };

  return (
    <>
      <CollapseHeading 
        headingClass={classes.settingExpandHeader}
        expanded={defaultExpanded}
        setExpanded={() => handleExpand(DEFAULT)}
        headingText='Default Settings'
      />
      <Collapse in={defaultExpanded}>
        <Box className={classes.componentContainer}>
          {defaultComponents}
        </Box>
      </Collapse>
      <hr className={classes.hr} />
      <CollapseHeading
        headingClass={classes.settingExpandHeader}
        expanded={advancedExpanded}
        setExpanded={() => handleExpand(ADVANCED)}
        headingText="Advanced Settings"
      />
      <Collapse in={advancedExpanded}>
        <Box className={classes.componentContainer}>
          {advancedComponents}
        </Box>
      </Collapse>
      <hr className={classes.hr} />
      <CollapseHeading
        headingClass={classes.settingExpandHeader}
        expanded={conditionalExpanded}
        setExpanded={() => handleExpand(CONDITIONAL)}
        headingText='Conditional Settings'
      />
      <Collapse in={conditionalExpanded}>
        <Box className={classes.componentContainer}>
          {conditionalComponent}
        </Box>
      </Collapse>
    </>
  );
};

export const ColorTab = ({ sheetId }) => {
  const { fillVisible: active } = useSelector(selectorSheet(sheetId))
  return (
    <SettingTab
      sheetId={sheetId}
      type="color"
      defaultComponents={[
        <VisibilitySwitch 
          sheetId={sheetId}
          reduxKey="fillVisible"
          key="fillVisible"
        />,
        <ColorSelector
          sheetId={sheetId}
          reduxKey="fillColor"
          label="Set Fill Color"
          key="fillColor"
          active={active}
          labelIcon={<FormatColorFillIcon />}
        />,
        <ResetButton
          sheetId={sheetId}
          resetKeys={[
            'fillVisible',
            'fillColor',
            'fillOpacity',
            'lineColor',
            'lineWidth',
          ]}
          resetConditions={[
            'color',
            'heatmapColor'
          ]}
          key="reset"
          active={active}
        />
      ]}
      advancedComponents={[
        <ColorSelector
          sheetId={sheetId}
          reduxKey="lineColor"
          key="lineColor"
          label="Set Line Color"
          active={active}
          labelIcon={<BrushIcon />}
        />,
        <SheetSlider
          sheetId={sheetId}
          reduxKey="fillOpacity"
          key="fillOpacity"
          min={0}
          max={1}
          step={0.1}
          label="Set Fill Opacity"
          active={active}
          labelIcon={<OpacityIcon />}
        />,
        <SheetSlider
          sheetId={sheetId}
          reduxKey="lineWidth"
          key="lineWidth"
          min={0}
          max={15}
          label="Set Border Width"
          active={active}
          labelIcon={<LineWeightIcon />}
        />
      ]}
      conditionalComponent={<ColorConditional 
        active={active}
        sheetId={sheetId}
      />}
    />
  );
};

export const TextTab = ({ sheetId }) => {
  const { textVisible: active } = useSelector(selectorSheet(sheetId))

  return (
    <SettingTab
      type="text"
      sheetId={sheetId}
      defaultComponents={[
        <VisibilitySwitch 
          sheetId={sheetId}
          reduxKey="textVisible"
          key="textVisible"
        />,
        <TextSelector
          sheetId={sheetId}
          key="text"
          active={active}
          labelIcon={<VpnKeyIcon />}
        />,
        <ResetButton
          sheetId={sheetId}
          resetKeys={[
            'textVisible',
            'text',
            'textX',
            'textY',
            'textSize',
            'textColor',
          ]}
          resetConditions={[
            'text',
          ]}
          key="reset"
          active={active}
        />
      ]}
      advancedComponents={[
        <SheetSlider 
          sheetId={sheetId}
          reduxKey="textX"
          key="textX"
          min={-5}
          max={5}
          step={0.25}
          trackValue={false}
          active={active}
          label="Horizontal"
          labelIcon={<WidthIcon />}
        />,
        <SheetSlider 
          sheetId={sheetId}
          reduxKey="textY"
          key="textY"
          min={-5}
          max={5}
          step={0.25}
          trackValue={false}
          vertical={true}
          label="Vertical"
          active={active}
          labelIcon={<HeightIcon />}
        />,
        <SheetSlider 
          sheetId={sheetId}
          reduxKey="textSize"
          key="textSize"
          min={0}
          max={32}
          label="Text Size"
          active={active}
          labelIcon={<FormatSizeIcon />}
        />,
        <ColorSelector 
          sheetId={sheetId}
          reduxKey="textColor"
          key="textColor"
          label="Set Text Color"
          active={active}
          labelIcon={<FormatColorTextIcon />}
        />
      ]}
      conditionalComponent={
        <TextConditional 
          sheetId={sheetId}
          active={active}
        />
      }
    />
  );
};

export const IconTab = ({ sheetId }) => {
  const { iconVisible: active } = useSelector(selectorSheet(sheetId))

  return (
    <SettingTab
      type="icon"
      sheetId={sheetId}
      defaultComponents={[
        <VisibilitySwitch 
          sheetId={sheetId}
          reduxKey="iconVisible"
          key="iconVisible"
        />,
        <IconSelector 
          sheetId={sheetId}
          key="icon"
          active={active}
          labelIcon={<AddLocationIcon />}
        />,
        <ResetButton 
          sheetId={sheetId}
          resetKeys={[
            "iconVisible",
            "icon",
            "iconSize",
            "iconX",
            "iconY",
            "iconColor"
          ]}
          resetConditions={[
            'icon'
          ]}
          key="reset"
          active={active}
        />
      ]}
      advancedComponents={[
        <SheetSlider 
          sheetId={sheetId}
          reduxKey="iconX"
          key="iconX"
          min={-5}
          max={5}
          step={0.25}
          trackValue={false}
          label="Horizontal"
          active={active}
          labelIcon={<WidthIcon />}
        />,
        <SheetSlider 
          sheetId={sheetId}
          reduxKey="iconY"
          key="iconY"
          min={-5}
          max={5}
          step={0.25}
          trackValue={false}
          vertical={true}
          label="Vertical"
          active={active}
          labelIcon={<HeightIcon />}
        />,
        <SheetSlider 
          sheetId={sheetId}
          reduxKey="iconSize"
          key="iconSize"
          min={0}
          max={3}
          step={0.1}
          label="Icon Size"
          active={active}
          labelIcon={<SizeIcon />}
        />,
        <ColorSelector
          sheetId={sheetId}
          active={active}
          reduxKey="iconColor"
          key="iconColor"
          label="Set Icon Color"
          labelIcon={<ColorizeIcon />}
        />
      ]}
      conditionalComponent={
        <IconConditional 
          sheetId={sheetId}
          active={active}
        />
      }
    />
  );
};

export const PointTab = ({ sheetId }) => {
  const { pointVisible: active } = useSelector(selectorSheet(sheetId));

  return (
    <SettingTab
      type="point"
      sheetId={sheetId}
      defaultComponents={[
        <VisibilitySwitch
          sheetId={sheetId}
          reduxKey="pointVisible"
          key="pointVisible"
        />,
        <PointIconSelector 
          sheetId={sheetId}
          key="pointIcon"
          active={active}
          labelIcon={<AddLocationIcon />}
        />,
        <ResetButton
          sheetId={sheetId}
          resetKeys={[
            'pointVisible',
            'pointIcon',
            'pointColor',
            'pointOpacity',
            'pointSize'
          ]}
          resetConditions={[
            'point',
            'heatmapPoint'
          ]}
          key="reset"
          active={active}
        />
      ]}
      advancedComponents={[
        <ColorSelector
          sheetId={sheetId}
          reduxKey="pointColor"
          label="Set Point Color"
          key="pointColor"
          active={active}
          labelIcon={<FormatColorFillIcon />}
        />,
        <SheetSlider
          sheetId={sheetId}
          reduxKey='pointSize'
          key='pointSize'
          min={0}
          max={10}
          label="Icon Size"
          active={active}
          labelIcon={<SizeIcon />}
        />,
        <SheetSlider
          sheetId={sheetId}
          reduxKey='pointOpacity'
          key='pointOpacity'
          min={0}
          max={1}
          step={.1}
          label='Icon Opacity'
          active={active}
          labelIcon={<OpacityIcon />}
        />
      ]}
      conditionalComponent={
        <PointConditional
          sheetId={sheetId}
          active={active}
        />
      }
    />
  )
}