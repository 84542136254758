import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import { actionSheetFileDropped, selectorSheets } from './spreadsheetRedux';
import { 
  wbFromFile,
  getNextSheetId,
  defaultSheetFile,
  reduxSheetFile,
  jsonFromWb,
  guessRrnHeaderFromJson,
  guessLngLatHeaderFromJson,
  guessSheetDataType,
} from './spreadsheetutils'; 
import { SPREADSHEET, trackEvent } from '../../tracking';

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: 10,
    pointerEvents: 'none',
    backgroundColor: 'rgba(255,255,255,0.75)',
    color: 'rgba(0,0,0,0.75)',
  },
}));

export default function DragAndDrop({children}) {
  const [dragState, setDragState] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const files = useSelector(selectorSheets);
  const nextId = getNextSheetId(files);

  const handleWorkbook = async (file, id) => {
    const fileName = file.name;
    const workbook = await wbFromFile(file);
    const json = jsonFromWb(workbook);
    const rmrecnbr = guessRrnHeaderFromJson(json);
    const lnglat = guessLngLatHeaderFromJson(json);
    const type = guessSheetDataType(json, rmrecnbr, lnglat);
    const status = type !== undefined ? 'centerMap' : 'complete';

    const fileObject = {
      ...defaultSheetFile(id),
      ...{
          id,
          fileName,
          rmrecnbr,
          json,
          lnglat,
          defaultText: rmrecnbr,
          type,
          status
        },
      ...reduxSheetFile(id)
    };

    dispatch(actionSheetFileDropped(fileObject));
    trackEvent({
      category: SPREADSHEET,
      action: 'Import',
      label: 'Drag & Drop',
    });
  };

  const handleFiles = dropEvent => {
    if (dropEvent.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      for (let i = 0; i < dropEvent.dataTransfer.items.length; i++) {
        // If dropped items aren't files or accepted file types, reject them
        if ( dropEvent.dataTransfer.items[i].kind === 'file' ) {
          let id = nextId + i;
          let file = dropEvent.dataTransfer.items[i].getAsFile();
          handleWorkbook(file, id);
        }
      }
    } else {
      // Use DataTransfer interface to access the file(s)
      for (let i = 0; i < dropEvent.dataTransfer.files.length; i++) {
        let id = nextId + i; 
        let file = dropEvent.dataTransfer.files[i];
        handleWorkbook(file, id);
      }
    }
  }

  const handleDragEnter = e => {
    setDragState(true);
    e.preventDefault();
    e.stopPropagation();
  }

  const handleDragLeave = e => {
    setDragState(false);
    e.preventDefault();
    e.stopPropagation();
  }

  const handleDragOver = e => {
    e.preventDefault();
    e.stopPropagation();
  }

  const handleDrop = e => {
    handleFiles(e);
    setDragState(false);
    e.preventDefault();
    e.stopPropagation();
  }

  return (
    <div
      onDragEnter={e => handleDragEnter(e)}
      onDragLeave={e => handleDragLeave(e)}
      onDragOver={e => handleDragOver(e)}
      onDrop={e => handleDrop(e)}
    >
      <Backdrop
        className={classes.backdrop}
        open={dragState}
      >
        <SaveAltIcon fontSize="large"/>
        Drop File
      </Backdrop>
      {children}
    </div>
  );
};