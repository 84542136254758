import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { actionLayerTabChanged } from './LayerRedux';
import { layerComponents } from './LayerData';
import GraphicsTabPanel from './TabPanelGraphics';
import LabelTabPanel from './TabPanelLabel';
import ColorTabPanel from './TabPanelColor';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={2}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
  },
  tabs: {
    '& button.MuiTab-root': {
      minWidth: 'auto !important'
    }
  },
}));

export default function LayerTabs() {
  const classes = useStyles();
  const theme = useTheme();
  const tabIndex = useSelector(state=>state.layerReducer.tabIndex);
  const dispatch = useDispatch();

  const handleChange = (event, newValue) => {
    dispatch(actionLayerTabChanged(newValue));
  };

  const handleChangeIndex = index => {
    dispatch(actionLayerTabChanged(index));
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={tabIndex}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="map layer tabs"
          className={classes.tabs}
        >
          <Tab label="Graphic" {...a11yProps(0)} data-testid="layer-tab-graphic"/>
          <Tab label="Label" {...a11yProps(1)} data-testid="layer-tab-label"/>
          <Tab label="Color" {...a11yProps(2)} data-testid="layer-tab-color"/>
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={tabIndex}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={tabIndex} index={0} dir={theme.direction}>
          <GraphicsTabPanel layers={layerComponents} />
        </TabPanel>
        <TabPanel value={tabIndex} index={1} dir={theme.direction}>
          <LabelTabPanel layers={layerComponents} />
        </TabPanel>
        <TabPanel value={tabIndex} index={2} dir={theme.direction}>
          <ColorTabPanel layers={layerComponents} />
        </TabPanel>
      </SwipeableViews>
    </div>
  );
}