import Search from './Search';
import BuildingMenu from './BuildingMenu';
import RoomMenu from './RoomMenu';
import PeopleMenu from './PeopleMenu';
import DepartmentMenu from './DepartmentMenu';
import RoomtypeMenu from './RoomtypeMenu';

export default Search;
export { BuildingMenu, RoomMenu, PeopleMenu, DepartmentMenu, RoomtypeMenu };
export * from './SearchRedux';
export * from './searchApi';