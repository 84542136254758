import React from "react";
import { useSelector } from "react-redux";
import DataCard from "./RoomSelector/RoomSelector";
import RestroomLayerInfo from "./RestroomLayerInfo";


export default function RestroomLayers({ data }) {

  const selectedRoom = useSelector(state => state.customLayerReducer.selectedRoom);
  const {rmrecnbr} = selectedRoom || {};
  const roomTemp = rmrecnbr && data && data.rooms?.length ? data.rooms.filter((room) => room.rmrecnbr === rmrecnbr) : null;
  const room = roomTemp && roomTemp.length ? roomTemp[0] : {};

  return (
    <>
      { data && <DataCard data={data} headerType="filter" />}
      <RestroomLayerInfo room={room} />
    </>
    
  );
}