// api docs: https://docs.google.com/document/d/12WWHq56jS3f23FJ0M1x0HxJ0NuQykNyCy9MkVH5uooI/edit#heading=h.ftowpn6z8nyp
import axios from 'axios';
import parse from 'wellknown';
import * as turf from '@turf/turf';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL; 

export async function searchQuery(queryParam, idToken, formatter) {
  const url = API_BASE_URL + queryParam;
  const headers = { "Authorization": `Bearer ${idToken}` };
  const opt = { headers };

  let details = {url, response: null};
  let response = null;
  let data = null;
  let error = null;

  try {
    response = await axios.get(url, opt);
    const isValid = response.status === 200 && response.data &&
                    response.data.result;
    details.response = response;
    if (!isValid) {
      throw new Error('Invalid Response');
    }
    data = formatter ? formatter(response.data.result) : response.data.result;
  } catch (e) {
    console.error('Error query url', details.url, '\n' , e.message, '\n', response)
    error = e;
  }
  return {data, error, details};
}

// @todo: check valid rrn
export async function queryRoom(rrn, idToken) {
  const query = `/rooms/${rrn}`;
  const response = await searchQuery(query, idToken);
  if (response.error || !response.data || !response.data.length) {
    return null
  }
  return response.data[0];
}

export async function queryBuilding(brn, idToken) {
  const query = `/buildings/${brn}`;
  const response = await searchQuery(query, idToken);
  if (response.error || !response.data || !response.data.length) {
    return null;
  }
  return response.data[0];
}

export async function rrnToBbox(rmrecnbr, idToken) {
  const SLICE_LENGTH = 500;
  const rrns = Array.isArray(rmrecnbr) ? rmrecnbr : [rmrecnbr];
  if (rrns.length === 0) return [];
  let queryRRNS = [];
  if (rrns.length > SLICE_LENGTH) {
    let sliceVal = 0;
    while (sliceVal < rrns.length) {
      queryRRNS.push(rrns.slice(sliceVal, sliceVal + SLICE_LENGTH));
      sliceVal += SLICE_LENGTH;
    }
  } else {
    queryRRNS = [rrns];
  }

  const results = await Promise.all(queryRRNS.map(async rrns => {
    const query = '/rooms/bbox/' + rrns.join(',');
    return await searchQuery(query, idToken);
  }));

  const isValid = results.some(resp => {
    return (
      resp.error === null &&
      resp.data &&
      resp.data.length &&
      resp.data[0].bbox
    );
  });

  if (!isValid) {
    console.error(`rrnToBbox()`);
    results.filter(res => res.error).forEach(res => {
      console.error(res.error);
    });
    return [];
  }

  const minMaxArray = results.map(resp => turf.bbox(parse(resp.data[0].bbox)));
  const minLng = Math.min(...minMaxArray.map(each => each[0]));
  const minLat = Math.min(...minMaxArray.map(each => each[1]));
  const maxLng = Math.max(...minMaxArray.map(each => each[2]));
  const maxLat = Math.max(...minMaxArray.map(each => each[3]));
  return [[minLng, minLat], [maxLng, maxLat]];
}

export async function brnToBbox(brn, idToken) {
  const response = await searchQuery(`/buildings/${brn}`, idToken);
  const isValid = response && response.data && response.data.length;
  if (!isValid) {
    console.error(`brnToBbox() -brn:${brn} -response:${response}`);
    return null;
  }

  const [minLng, minLat, maxLng, maxLat] = turf.bbox(parse(response.data[0].bbox));
  return [[minLng, minLat], [maxLng, maxLat]];
}