import React, { useState } from 'react';
import { useDispatch } from 'react-redux'
import FilterListIcon from '@material-ui/icons/FilterList';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { actionMenuSearchTypeToggled, actionMenuSearchUpdateChanged } from '../AppBar';

const useStyles = makeStyles(theme => ({
  add: {
    border: '1px solid rgba(0, 0, 0, 0.1)',
    color: 'rgba(0, 0, 0, 0.54)',
    marginLeft: -2,
    marginRight: 6,
    '&:hover': {
      color: 'rgba(0, 0, 0, 1)',
    }
  },
  chip: {
    marginRight: 4,
    marginBottom: 2,
    '& .MuiAvatar-root': {
      width: 24,
      height: 24,
      fontSize: '0.7rem',
      marginLeft: 0,
      marginRight: -4,
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
    },
    '& .MuiChip-label': {
      paddingLeft: 8,
      paddingRight: 8,
    }
  },
  chipLabel: {
    fontSize: '0.65rem',
    letterSpacing: '0'
  }
}));

// const FILTER_AVATAR_TEXT = {
//   building: 'BLD',
//   room: 'RM',
//   person: 'P',
//   department: 'DEP',
//   occdepartment: 'ODEP',
//   roomtype: 'TYP',
//   roomsubtype: 'STYP',
// };

export default function FilterDown({filters=[], id, rooms, addFilter, removeFilter}) {
  const dispatch = useDispatch();

  // const handleDelete = (filterId) => {
  //   const newFilters = filters.filter(filter => filter.id !== filterId);
  //   dispatch(actionDataSearchRooms(newFilters, id));
  // }

  const handleChipClick = (filter) => {
    const searchType = filter.type === 'roomsubtype' ? 'roomtype' : filter.type;
    dispatch(actionMenuSearchUpdateChanged({ groupId: id, type: 'replace', targetFilterId: filter.id }));
    dispatch(actionMenuSearchTypeToggled(searchType));
  }

  return (
    <React.Fragment>
      {
        filters.map(filter => {
          return <Filter key={`${filter.attribute}-${filter.value}`} filter={filter} handleDelete={removeFilter} handleChipClick={handleChipClick}/>
        })
      }
      <AddFilter id={id} rooms={rooms} addFilter={addFilter} />
    </React.Fragment>
  );
}

function Filter({ filter, handleDelete, handleChipClick }) {
  const classes = useStyles();
  const onDelete = () => { handleDelete(filter.attribute, filter.value) };
  const onClick = () => { handleChipClick(filter) };

  return (
    <Chip
      size='small'
      className={classes.chip}
      onClick={onClick}
      onDelete={onDelete}
      label={
        <Typography variant="button" className={classes.chipLabel} data-testid="search-result-filter-name">
          {filter.attribute.replace('_', ' ')}: {filter.value}
        </Typography>
      }
    />
  );
}

function AddFilter({ id, rooms=[], addFilter }) {
  const classes = useStyles();
  // const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => { setAnchorEl(event.currentTarget) }
  const handleClose = () => { setAnchorEl(null) }

  const handleSelect = (filterType) => (event) => {
    // dispatch(actionMenuSearchUpdateChanged({ groupId: id, type: 'add' }))
    // dispatch(actionMenuSearchTypeToggled(searchType));
    // handleClose();
    setFilterType(filterType);
    const newOptions = [...new Set(rooms.map(room => room[filterType]))];
    newOptions.sort();
    setOptions(newOptions)
    setMenuAchorElt(event.currentTarget)
  };


  const [filterType, setFilterType] = useState('');
  const [options, setOptions] = useState([]);
  const [menuAchorElt, setMenuAchorElt] = useState(null);

  const closeSecondMenu = () => {setMenuAchorElt(null);}
  const selectFilter = option => () => {
    addFilter(filterType, option);
    closeSecondMenu();
    handleClose();
  }

  return (
    <React.Fragment>
      <Tooltip title="Filter">
        <IconButton size="small" className={classes.add} onClick={handleClick}>
          <FilterListIcon />
        </IconButton>
      </Tooltip>
      <Menu
        id="add-search-criteria"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleSelect('bld_descrshort')}>
          <Typography variant="overline">Building</Typography>
        </MenuItem>
        <MenuItem onClick={handleSelect('accessible_room')}>
          <Typography variant="overline">accessible room</Typography>
        </MenuItem>
        <MenuItem onClick={handleSelect('changing_table')}>
          <Typography variant="overline">changing table</Typography>
        </MenuItem>
        <MenuItem onClick={handleSelect('countertops')}>
          <Typography variant="overline">countertops</Typography>
        </MenuItem>
        <MenuItem onClick={handleSelect('gender_designation')}>
          <Typography variant="overline">gender designation</Typography>
        </MenuItem>
        <MenuItem onClick={handleSelect('institutional_intent')}>
          <Typography variant="overline">institutional intent</Typography>
        </MenuItem>
        <MenuItem onClick={handleSelect('menstrual_products')}>
          <Typography variant="overline">menstrual products</Typography>
        </MenuItem>
        <MenuItem onClick={handleSelect('user_type')}>
          <Typography variant="overline">user type</Typography>
        </MenuItem>
        <MenuItem onClick={handleSelect('num_lockers')}>
          <Typography variant="overline">num lockers</Typography>
        </MenuItem>
        <MenuItem onClick={handleSelect('num_showers')}>
          <Typography variant="overline">num showers</Typography>
        </MenuItem>
        <MenuItem onClick={handleSelect('num_sinks')}>
          <Typography variant="overline">num sinks</Typography>
        </MenuItem>
        <MenuItem onClick={handleSelect('num_toilets')}>
          <Typography variant="overline">num toilets</Typography>
        </MenuItem>
        <MenuItem onClick={handleSelect('num_urinals')}>
          <Typography variant="overline">num urinals</Typography>
        </MenuItem>
      </Menu>
      <Menu
        id="restroom-filter-options"
        anchorEl={menuAchorElt}
        keepMounted
        open={Boolean(menuAchorElt)}
        onClose={closeSecondMenu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        getContentAnchorEl={null}
        PaperProps={{
          style: {
            maxHeight: 350,
            width: 250,
          },
        }}
      >
        {options.map(option => {
          return (
            <MenuItem onClick={selectFilter(option)} key={`${filterType}-${option}`}>
              <Typography variant="overline">{option}</Typography>
            </MenuItem>
          )
        })}
      </Menu>
    </React.Fragment>
  );
}

export function toText(filter) {
  if (filter.label) {
    return filter.label;
  } else if (filter.type === 'building') {
    return filter.bld_descrshort || filter.bldrecnbr;
  } else if (filter.type === 'room') {
      return filter.rmnbr;
  } else if (filter.type === 'person') {
      return filter.person_uniqname.toLowerCase();
  } else if (filter.type === 'department') {
      return filter.dept_descr;
  } else if (filter.type === 'roomtype') {
      return filter.rmtyp;
  } else if (filter.type === 'roomsubtype') {
      return filter.rmtyp + '-' + filter.rmsubtyp;
  } else {
    return '';
  }
}

// function avatarText(filter) { 
//   return FILTER_AVATAR_TEXT[filter.type] ? FILTER_AVATAR_TEXT[filter.type] : ''; 
// }

// function toTooltip(filter) {
//   if (filter.type === 'occdepartment') { return 'OCCUPYING DEPARTMENT' }
//   return filter.type && filter.type.toUpperCase ? filter.type.toUpperCase() : ''
// }