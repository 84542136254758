import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import {
  actionDataCardExpandToggled,
  actionDataUpdateMaxSize,
  actionDataHideAllLayers,
} from './dataRedux';
import DataTable from './DataTable';
import { actionAppToggleComponent } from '../App';
import Accordion from '../Shared/Accordion';
import { toText } from './Filters';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

export default function SearchResultWrapper() {
  const component = 'resultDataVisibile';
  const theme = useTheme();
  const xsDown = useMediaQuery(theme.breakpoints.down('xs'));
  const dispatch = useDispatch();

  useEffect(() => {
    if (xsDown) { dispatch(actionDataUpdateMaxSize(1)) }
  }, [dispatch, xsDown]);

  const visible = useSelector(state => state.appReducer[component]);
  const visibleHandler = () => {
    dispatch(actionDataHideAllLayers()); // hide all highlighted rooms
    setTimeout(() => { // make sure the above operation has been completed, otherwise this component will reappear 
      dispatch(actionAppToggleComponent({ component }));
    }, 100);
  }
  
  const expand = useSelector(state => state.dataCardReducer.isExpanded);
  const expandHandler = () => { dispatch(actionDataCardExpandToggled()) }

  const accordion = {
    visible,
    visibleHandler,
    expand,
    expandHandler
  };

  const dataGroups = useSelector(state => state.dataCardReducer.dataGroups);
  useEffect(() => {
    if (!visible && dataGroups.length) {
      dispatch(actionAppToggleComponent({ component, visible: true }));
    }
  }, [dispatch, dataGroups]);

  return <SearchResult accordion={accordion} dataGroups={dataGroups} />
}

export function SearchResult({ accordion, dataGroups }) {
  const {
    visible,
    visibleHandler,
    expand,
    expandHandler
  } = accordion;

  return (
    <Accordion
      title='SEARCH'
      subtitle={toSubtitle(dataGroups)}
      visible={visible}
      visibleHandler={visibleHandler}
      expand={expand}
      expandHandler={expandHandler}
    >
      <DataTable dataGroups={dataGroups} />
    </Accordion>
  )
}

export function toSubtitle(dataGroups) {
  const nonValid = !dataGroups || !dataGroups.length ||
                  !dataGroups[dataGroups.length - 1].filters ||
                  dataGroups[dataGroups.length - 1].filters.length !== 1;
  
  if (Array.isArray(dataGroups)) {
    if (nonValid) { return '' }
    const [filter] = dataGroups[dataGroups.length - 1].filters; 
    return toText(filter);
  }

  if (dataGroups && dataGroups.filters && dataGroups.filters.length) {
    const [filter] = dataGroups.filters; 
    return toText(filter);
  }

  return '';
}