import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
// import Button from '@material-ui/core/Button';
// import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';
import { actionDataSearchRooms } from '../Data';
import BuildingMenu from './BuildingMenu';
import RoomMenu from './RoomMenu';
import PeopleMenu from './PeopleMenu';
import DepartmentMenu from './DepartmentMenu';
import RoomtypeMenu from './RoomtypeMenu';
import {
  actionSearchVisibleToggled,
  actionSearchUpdateRoomResult,
  actionSearchDisplayDataCard,
} from './SearchRedux';
import { selectorAppIsSmallScreen } from '../App';


const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center'
  },
}));

function Building({ data, filterClickHandler }) {
  return (
    <React.Fragment>
      <ListItem button onClick={filterClickHandler(data)}>
        <ListItemText
          primary={data.bld_descrshort + ' (' + data.bldrecnbr + ')'}
          secondary={data.bld_descr}/>
      </ListItem>
      <Divider />
    </React.Fragment>
  );
}

function Room({ data, filterClickHandler }) {
  return (
    <React.Fragment>
      <ListItem button onClick={filterClickHandler(data)}>
        <ListItemText
          primary={data.rmnbr + ' (' + data.rmrecnbr + ')'}
          secondary={data.bld_descrshort}/>
      </ListItem>
      <Divider />
    </React.Fragment>
  );  
}

function Person({ data, filterClickHandler }) {
  return (
    <React.Fragment>
      <ListItem button onClick={filterClickHandler(data)}>
        <ListItemText
          primary={data.person_name + ' (' + data.person_uniqname.toLowerCase() + ')'}
          secondary={data.rmrole}/>
      </ListItem>
      <Divider />
    </React.Fragment>
  );  
}

function Department({ data, filterClickHandler }) {
  return (
    <React.Fragment>
      <ListItem button onClick={filterClickHandler(data)}>
        <ListItemText
          primary={data.dept_descr + ' (' + data.deptid + ')'}
          secondary={data.dept_grp_descr}/>
      </ListItem>
      <Divider />
    </React.Fragment>
  );  
}

function RoomType({ data, filterClickHandler }) {
  return (
    <React.Fragment>
      <ListItem button onClick={filterClickHandler(data)}>
        <ListItemText
          primary={data.rmtyp_descr50 + ' (' + data.rmtyp + ')'}
          secondary={data.rmtyp_descrshort}/>
      </ListItem>
      <Divider />
    </React.Fragment>
  );
}

function RoomTypeSubtype({ data, filterClickHandler }) {
  const [open, setOpen] = useState(false);
  const handleClick = (e) => {
    e.stopPropagation();
    setOpen(!open);
  }

  return (
    <React.Fragment>
      <ListItem button onClick={filterClickHandler(data)}>
        <ListItemText
          primary={data.rmtyp_descr50 + ' (' + data.rmtyp + ')'}
          secondary={data.rmtyp_descrshort}
        />
        <IconButton onClick={handleClick}>
          {open ? <ExpandLess /> : <ExpandMore/>}
        </IconButton>
      </ListItem>
      <Divider />
      <Collapse in={open}>
        <List component="div" disablePadding>
          {data.subtypes.map(subtype=><Subtype data={subtype} key={subtype.id} filterClickHandler={filterClickHandler}/>)}
        </List>
      </Collapse>
    </React.Fragment>
  );
}

function Subtype({ data, filterClickHandler }) {
  const classes = useStyles();
  return (
    <React.Fragment>
      <ListItem button className={classes.nested} onClick={filterClickHandler(data)}>
        <ListItemText
          primary={data.rmsubtyp_descr50 + ' (' + data.rmtyp + '-' + data.rmsubtyp + ')'}
          secondary={data.rmsubtyp_descrshort}/>
      </ListItem>
      <Divider />
    </React.Fragment>
  );
}

export function ResultTable({ filterType, filterResult, filterClickHandler }) {
  const classes = useStyles();

  const renderEntries = (entry) => {
    if (filterType === 'building') {
      return <Building key={entry.id} data={entry} filterClickHandler={filterClickHandler} />;
    } else if (filterType === 'room') {
      return <Room key={entry.id} data={entry} filterClickHandler={filterClickHandler} />;
    } else if (filterType === 'person') {
      return <Person key={entry.id} data={entry} filterClickHandler={filterClickHandler} />;
    } else if (filterType === 'department') {
      return <Department key={entry.id} data={entry} filterClickHandler={filterClickHandler}  />;
    } else if (filterType === 'roomtype') {
      if (entry.subtypes && entry.subtypes.length) {
        return <RoomTypeSubtype key={entry.id} data={entry} filterClickHandler={filterClickHandler} />
      } else {
        return <RoomType key={entry.id} data={entry} filterClickHandler={filterClickHandler} />;
      }
    } else {
      return '';
    }
  }

  return (
    <div className={classes.filterTable}>
      <List dense={true}>
        {filterResult.map(entry=>renderEntries(entry))}
      </List>
    </div>
  );
}

export default function DataTable() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isVisible = useSelector(state => state.searchReducer.isVisible);
  const filterBy = useSelector(state => state.searchReducer.filterBy);
  const isSmallScreen = useSelector(selectorAppIsSmallScreen);
  // const OFFSET = isSmallScreen ? 0 : 90;
  const OFFSET = isSmallScreen ? 0 : 55;
  // menu visibility
  const isBuildingMenuVisible = filterBy === 'buildings' && isVisible;
  const isRoomMenuVisible = filterBy === 'rooms' && isVisible;
  const isPeopleMenuVisible = filterBy === 'people' && isVisible;
  const isDepartmentMenuVisible = filterBy === 'departments' && isVisible;
  const isRoomtypeMenuVisible = filterBy === 'roomtypes' && isVisible;
  // // display result if more than one filter applied
  // const roomResult = useSelector(state=>state.searchReducer.roomResult);
  // const filters = useSelector(state=>state.searchReducer.filters);
  // const roomResultId = useSelector(state=>state.searchReducer.roomResultId);
  // const roomResultName = useSelector(state=>state.searchReducer.roomResultName);
  // const displayDataCard = useSelector(state=>state.searchReducer.displayDataCard);
  
  const filterClickHandler = (data) => {
    console.log('data!', data)
    // dispatch(actionSearchUpdateRoomResult(data));
    // dispatch(actionSearchTransferToDataCard(true));

    if (!Array.isArray(data)) { // single filter
      dispatch(actionDataSearchRooms(data));
      dispatch(actionSearchVisibleToggled());
    } else { // multiple filters, display result on search pane
      dispatch(actionSearchUpdateRoomResult(data));
      dispatch(actionSearchDisplayDataCard(true));
      // @todo: display data on search pane
    }

  }

  const onCloseHandler = (filterType) => () => {
    // dispatch(actionChangeFilter(filterType));
    dispatch(actionSearchVisibleToggled());
  }

  // useEffect(() => {
  //   if (displayDataCard && roomResult && roomResult.length) {
  //     dispatch(actionDataAddDataGroup({
  //       id: roomResultId,
  //       name: roomResultName,
  //       rooms: roomResult,
  //       filters: filters,
  //     }));
  //     dispatch(actionSearchVisibleToggled());
  //     dispatch(actionSearchTransferToDataCard(false));    
  //   }
  // }, [roomResult]);

  return (
    <div className={classes.root}>
      <BuildingMenu
        offset={OFFSET}
        visible={isBuildingMenuVisible}
        selectHandler={filterClickHandler}
        onCloseHandler={onCloseHandler('buildings')} />
      <RoomMenu
        offset={OFFSET}
        visible={isRoomMenuVisible}
        selectHandler={filterClickHandler}
        onCloseHandler={onCloseHandler('rooms')} />
      <PeopleMenu
        offset={OFFSET}
        visible={isPeopleMenuVisible}
        selectHandler={filterClickHandler}
        onCloseHandler={onCloseHandler('people')} />
      <DepartmentMenu
        offset={OFFSET}
        visible={isDepartmentMenuVisible}
        selectHandler={filterClickHandler}
        onCloseHandler={onCloseHandler('departments')} />
      <RoomtypeMenu
        offset={OFFSET}
        visible={isRoomtypeMenuVisible}
        selectHandler={filterClickHandler}
        onCloseHandler={onCloseHandler('roomtypes')} />
    </div>
  );
}