import { createAction, handleActions } from 'redux-actions';

export const initialState = {
  menuVisible: false,
  searchType: '',
  /**
   * Update current search (dataGroupId in dataCardReducer)
   * { groupId: null, type: null, target: null }
   * updateSearch: {
   *  groupId: data group id,
   *  type: add|replace (add new criteria, replace current filter criteria),
   *  targetFilterId: filter id to be replaced if type === replace 
   * }
   */
  updateSearch: null,
  floor: '',
  floors: ['02', '01', '0G', '0B'],
  floorsInView: [],
  floorsInCurrentSearch: [],
  floorsInActiveBuilding: [],
};

export const actionMenuToggled = createAction('APPBAR_MENU_TOGGLED');
export const actionMenuSearchTypeToggled = createAction('APPBAR_SEARCH_TYPE_TOGGLED');
export const actionMenuSearchUpdateChanged = createAction('APPBAR_SEARCH_UPDATE_CHANGED');
export const actionAppBarFloorUpdated = createAction('APPBAR_FLOOR_UPDATED');
export const actionAppBarFloorsInViewUpdated = createAction('APPBAR_FLOORS_IN_VIEW_UPDATED');
export const actionAppBarFloorsInSearchUpdated = createAction('APPBAR_FLOORS_IN_SEARCH_UPDATED');
export const actionAppBarFloorsInActiveBuildingUpdated = createAction('APPBAR_FLOORS_IN_ACTIVE_BLDG_UPDATED');

export const appBarReducer = handleActions({
  [actionMenuToggled]: (state, action) => {
    return { ...state, menuVisible: !state.menuVisible };
  },
  [actionMenuSearchTypeToggled]: (state, action) => {
    const searchType = action.payload !== state.searchType ? action.payload : '';
    return { ...state, searchType };
  },
  [actionAppBarFloorUpdated]: (state, action) => {
    return { ...state, floor: action.payload };
  },
  [actionAppBarFloorsInViewUpdated]: (state, action) => {
    return { ...state, floorsInView: action.payload };
  },
  [actionAppBarFloorsInSearchUpdated]: (state, action) => {
    return { ...state, floorsInCurrentSearch: action.payload };
  },
  [actionMenuSearchUpdateChanged]: (state, action) => {
    return { ...state, updateSearch: action.payload };
  },
  [actionAppBarFloorsInActiveBuildingUpdated]: (state, action) => {
    return { ...state, floorsInActiveBuilding: action.payload };
  },
}, initialState);

// selector
export const selectorAppBarFloor = state => state.appBarReducer.floor;