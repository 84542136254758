import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'fixed',
    top: 5,
    right: 5,
    maxHeight: '99vh',
    overflow: 'auto',
    opacity: 0.975,
    [theme.breakpoints.down('xs')]: {
      position: 'fixed',
      right: 2,
      top: 60,
      opacity: 0.9,
    },
  },
}));

export default function Widgets({ children }) {
  const classes = useStyles();
  const widgetVisible = useSelector(state => state.appReducer.widgetVisible);
  return (
    <div style={{display: widgetVisible ? 'inherit' : 'none'}}>
      <div className={classes.root} >
        {children}
      </div>
    </div>
  );
} 