export default {
  position: 'absolute',
  top: 0,
  right: 0,
  left: 0,
  bottom: 0,
  '& .mapboxgl-control-container': {
    display: 'none',
  },
  '& .mapboxgl-popup' : {
    minWidth: '80px !important',
    pointerEvents: 'none',
  },
  '& .mapboxgl-popup-anchor-top .mapboxgl-popup-tip': {
    borderBottomColor: 'rgba(0, 0, 0, 0.75)',
  },
  '& .mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip': {
    borderBottomColor: 'rgba(0, 0, 0, 0.75)',
  },
  '& .mapboxgl-popup-anchor-right .mapboxgl-popup-tip': {
    borderLeftColor: 'rgba(0, 0, 0, 0.75)',
  },
  '& .mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip': {
    borderTopColor: 'rgba(0, 0, 0, 0.75)',
  },
  '& .mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip': {
    borderTopColor: 'rgba(0, 0, 0, 0.75)',
  },
  '& .mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip': {
    borderTopColor: 'rgba(0, 0, 0, 0.75)',
  },
  '& .mapboxgl-popup-anchor-left .mapboxgl-popup-tip': {
    borderRightColor: 'rgba(0, 0, 0, 0.75)',
  },
  '& .mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip': {
    borderBottomColor: 'rgba(0, 0, 0, 0.75)',
  },
  '& .mapboxgl-popup .mapboxgl-popup-content': {
    background: 'rgba(0, 0, 0, 0.75)',
    color: 'white',
    padding: 8,
    textAlign: 'center',
    pointerEvents: 'none',
  },
  '& .mapboxgl-popup .mapboxgl-popup-content .secondary': {
    fontSize: '0.6rem',
    marginBottom: -5,
  },
}