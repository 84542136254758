import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import IconButton from '@material-ui/core/IconButton';

import AddIcon from '@material-ui/icons/Add';
import Paper from '@material-ui/core/Paper';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import CategoryIcon from '@material-ui/icons/Category';
import FormatColorTextIcon from '@material-ui/icons/FormatColorText';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import HeightIcon from '@material-ui/icons/Height';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import FormatColorFillIcon from '@material-ui/icons/FormatColorFill';
import BrushIcon from '@material-ui/icons/Brush';
import OpacityIcon from '@material-ui/icons/Opacity';
import DeleteIcon from '@material-ui/icons/Delete';
import GradientIcon from '@material-ui/icons/Gradient';
import ColorizeIcon from '@material-ui/icons/Colorize';
import Tooltip from '@material-ui/core/Tooltip';

import { SettingSlider, Setting, SettingDropSelector, SettingColorSelector } from './SpreadsheetSettingSharedComponents';
import { 
  selectorSheet, 
  selectorSheetCondition, 
  actionSheetConditionAdded, 
  actionSheetConditionUpdated, 
  actionSheetConditionRemoved, 
  actionSheetFileUpdated, 
  actionSheetHeatmapCreated,
  actionSheetHeatmapRemoved,
  actionSheetHeatmapColorUpdated
} from '.';
import { defaultSheetFile, heatMapObjfromJson } from './spreadsheetutils';
import SharedIconSelector from './IconSelector';
import { SPREADSHEET, trackEvent } from '../../tracking';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: 0,
    minWidth: 80,
  },
  button: {
    margin: 10
  },
  widthIcon: {
    transform: 'rotate(90deg)'
  },
  sizeIcon: {
    transform: 'rotate(45deg)',
  },
  condition: {
    minWidth: 275,
    marginBottom: 8,
    '&:hover': {
      backgroundColor: 'rgba(217, 217, 217, 0.2)',
    },
    width: '100%',
    padding: 10,
},
  conditionHeader: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  radioChecked: {
    color: 'var(--fill-color)',
  }
}));

const SizeIcon = () => {
  const classes = useStyles();
  return <HeightIcon className={classes.sizeIcon} />;
};

const TextSelector = ({ sheetId, active, labelIcon, column, handleColSelect }) => {
  const { json } = useSelector(selectorSheet(sheetId));
  const classes = useStyles();
  const textColumnHeaders = json[0].filter(header => header).map((header, index) => {
    return {
      value: index,
      label: header
    }
  });
  const handleChange = (text) => {
    handleColSelect(text)
  };

  return (
    <SettingDropSelector 
      active={active}
      label="Attribute"
      labelIcon={labelIcon}
      formControlClass={classes.formControl}
      value={column}
      handleSelection={handleChange}
      optionsArray={textColumnHeaders}
      defaultOption={{
        value: '',
        label: 'Select Column',
        disabled: true,
      }}
    />
  );
}

const OpacitySlider = ({ sheetId, conditionId, ...rest }) => {
  const { fillOpacity: reduxFillOpacity } = useSelector(selectorSheet(sheetId));
  const { fillOpacity: defaultFillOpacity } = defaultSheetFile(sheetId);
  const { fillOpacity: conditionFillOpacity} = useSelector(selectorSheetCondition(sheetId, conditionId));
  const dispatch = useDispatch();
  
  const handleNewOpacity = (value) => {
    dispatch(actionSheetConditionUpdated({
      id: sheetId,
      updatedCondition: {
        fillOpacity: value,
        id: conditionId
      }
    }));
    trackEvent({
      category: SPREADSHEET,
      action: 'Change Conditional Formatting',
      label: 'Opacity'
    });
  }

  const value = (
    typeof conditionFillOpacity === 'number' ? conditionFillOpacity :  
    typeof reduxFillOpacity === 'number' ? reduxFillOpacity : 
    typeof defaultFillOpacity === 'number' ? defaultFillOpacity : 
    0.5 );

  return (
    <SettingSlider
      value={value}
      min={0}
      max={1}
      step={0.1}
      valueLabelDisplay="off"
      handleNewValue={handleNewOpacity}
      label='Transparency'
      labelIcon={<OpacityIcon />}
      {...rest}
    />
  );
};

const TextSizeSlider = ({ sheetId, conditionId, ...rest }) => {
  const { textSize: reduxTextSize } = useSelector(selectorSheet(sheetId));
  const { textSize: defaultTextSize } = defaultSheetFile(sheetId);
  const { textSize: conditionTextSize } = useSelector(selectorSheetCondition(sheetId, conditionId));
  const dispatch = useDispatch();
  
  const handleNewTextSize = (value) => {
    dispatch(actionSheetConditionUpdated({
      id: sheetId,
      updatedCondition: {
        id: conditionId,
        textSize: value
      }
    }));
    trackEvent({
      category: SPREADSHEET,
      action: 'Change Conditional Formatting',
      label: 'Text Size'
    });
  };
  
  const value = conditionTextSize || reduxTextSize || defaultTextSize;
  
  return (
    <SettingSlider 
      value={value}
      min={0}
      max={32}
      valueLabelDisplay='off'
      handleNewValue={handleNewTextSize}
      label="Size"
      labelIcon={<SizeIcon />}
    />
  );
}

const IconSizeSlider = ({ sheetId, conditionId, ...rest }) => {
  const { iconSize: defaultIconSize } = defaultSheetFile(sheetId);
  const { iconSize: reduxIconSize } = useSelector(selectorSheet(sheetId));
  const { iconSize: conditionIconSize } = useSelector(selectorSheetCondition(sheetId, conditionId));
  const dispatch = useDispatch();
  
  const handleNewIconSize = (value) => {
    dispatch(actionSheetConditionUpdated({
      id: sheetId,
      updatedCondition: {
        id: conditionId,
        iconSize: value
      }
    }));
    trackEvent({
      category: SPREADSHEET,
      action: 'Change Conditional Formatting',
      label: 'Icon Size'
    });
  };
  
  const value = (
    typeof conditionIconSize === 'number' ? conditionIconSize :  
    typeof reduxIconSize === 'number' ? reduxIconSize : 
    typeof defaultIconSize === 'number' ? defaultIconSize : 
    10 );
  
  return (
    <SettingSlider 
      value={value}
      min={0}
      max={3}
      step={0.1}
      valueLabelDisplay='off'
      handleNewValue={handleNewIconSize}
      label="Size"
      labelIcon={<SizeIcon />}
    />
  );
}
const PointSizeSlider = ({ sheetId, conditionId, ...rest }) => {
  const { pointSize: defaultPointSize } = defaultSheetFile(sheetId);
  const { pointSize: reduxPointSize } = useSelector(selectorSheet(sheetId));
  const { pointSize: conditionPointSize } = useSelector(selectorSheetCondition(sheetId, conditionId));
  const dispatch = useDispatch();
  
  const handleNewIconSize = (value) => {
    dispatch(actionSheetConditionUpdated({
      id: sheetId,
      updatedCondition: {
        id: conditionId,
        pointSize: value
      }
    }));
    trackEvent({
      category: SPREADSHEET,
      action: 'Change Conditional Formatting',
      label: 'Point Size'
    });
  };
  
  const value = (
    typeof conditionPointSize === 'number' ? conditionPointSize :  
    typeof reduxPointSize === 'number' ? reduxPointSize : 
    typeof defaultPointSize === 'number' ? defaultPointSize : 
    10 );
  
  return (
    <SettingSlider 
      value={value}
      min={0}
      max={3}
      step={0.1}
      valueLabelDisplay='off'
      handleNewValue={handleNewIconSize}
      label="Size"
      labelIcon={<SizeIcon />}
      {...rest}
    />
  );
}

const IconSelector = ({ sheetId, conditionId, ...rest }) => {
  const { icon: defaultIcon } = useSelector(selectorSheet(sheetId));
  const { icon: conditionIcon } = useSelector(selectorSheetCondition(sheetId, conditionId));
  const dispatch = useDispatch();

  const icon = conditionIcon || defaultIcon || '';

  const handleNewIcon = (newIcon) => {
    dispatch(actionSheetConditionUpdated({
      id: sheetId,
      updatedCondition: {
        id: conditionId,
        icon: newIcon,
      }
    }));
    trackEvent({
      category: SPREADSHEET,
      action: 'Change Conditional Formatting',
      label: 'Icon'
    });
  };

  return <SharedIconSelector 
    value={icon}
    handleSelection={handleNewIcon}
    {...rest}
  />

};

const PointIconSelector = ({ sheetId, conditionId, ...rest }) => {
  const { pointIcon: defaultPointIcon } = defaultSheetFile(sheetId);
  const { pointIcon: reduxPointIcon } = useSelector(selectorSheet(sheetId));
  const { pointIcon: conditionPointIcon } = useSelector(selectorSheetCondition(sheetId, conditionId));
  const dispatch = useDispatch();

  const icon = conditionPointIcon || reduxPointIcon || defaultPointIcon;

  const handleNewIcon = (newIcon) => {
    dispatch(actionSheetConditionUpdated({
      id: sheetId,
      updatedCondition: {
        id: conditionId,
        pointIcon: newIcon,
      }
    }));
    trackEvent({
      category: SPREADSHEET,
      action: 'Change Conditional Formatting',
      label: 'Point Icon'
    });
  };

  return <SharedIconSelector 
    value={icon}
    handleSelection={handleNewIcon}
    {...rest}
  />

};

const HeatMapSwitch = (props) => {
  const { sheetId, conditionId, catObj, ...rest} = props;
  const { type, hslObj } = useSelector(selectorSheetCondition(sheetId, conditionId));
  const dispatch = useDispatch();

  const newHslObj = hslObj || { h: 0, s: 1, l: .5};

  const handleHeatMapSwitch = (event) => {
    if (type === 'color' || type === 'point') {
      let heatMapObj = heatMapObjfromJson(catObj, newHslObj);
      dispatch(actionSheetHeatmapCreated({
        conditionId,
        sheetId,
        heatMapObj,
        catObj,
        hslObj: newHslObj,
      }));
      trackEvent({
        category: SPREADSHEET,
        action: 'Change Conditional Formatting',
        label: 'Heatmap'
      });
    } else {
      dispatch(actionSheetHeatmapRemoved({conditionId, sheetId}));
    }
  }

  return (
    <Setting 
      label="Heatmap"
      {...rest}
      labelIcon={<GradientIcon />}
      controller={
        <Switch
          checked={type.indexOf('heatmap') === 0}
          onChange={handleHeatMapSwitch}
          name="checkedA"
          inputProps={{ 'aria-label': 'secondary checkbox' }}
        />
      }
    />
  );
};

const FillColorSelector = ({ sheetId, conditionId, ...rest }) => {
  const { fillColor: reduxFillColor } = useSelector(selectorSheet(sheetId));
  const { fillColor: defaultFillColor } = defaultSheetFile(sheetId);
  const { fillColor: conditionFillColor, type, catObj, hslObj} = useSelector(selectorSheetCondition(sheetId, conditionId));
  const dispatch = useDispatch();

  let hslColor = null;
  if (hslObj) {
    let {h, s, l} = hslObj
    hslColor = `hsl(${h}, ${s * 100}%, ${l * 100}%)`;
  }

  const color = (type === 'color' ? conditionFillColor : hslColor) || reduxFillColor || defaultFillColor

  const handleNewColor = (newColor) => {
    if (!newColor) return;
    let { r, g, b } = newColor.rgb;
    let rgb = `rgb(${r},${g},${b})`;

    let updatedCondition = {
      id: conditionId,
      fillColor: rgb,
      hslObj: newColor.hsl
    };
    // update color reference
    dispatch(actionSheetConditionUpdated({
      id: sheetId,
      updatedCondition
    }));
    // if heatmap toggles, update all rooms
    if (type === 'heatmapColor') {
      const heatMapObj = heatMapObjfromJson(catObj, newColor.hsl);
      dispatch(actionSheetHeatmapColorUpdated({
        conditionId,
        sheetId,
        heatMapObj,
        newColor
      }));
    }
    trackEvent({
      category: SPREADSHEET,
      action: 'Change Conditional Formatting',
      label: 'Fill Color'
    });
  };

  return (
    <SettingColorSelector 
      fillColor={color}
      defaultColor={color}
      handlePickerClose={handleNewColor}
      label="Fill Color"
      labelIcon={<FormatColorFillIcon />}
      {...rest}
    />
  );
}

const LineColorSelector = ({ sheetId, conditionId, ...rest }) => {
  const { lineColor: conditionLineColor} = useSelector(selectorSheetCondition(sheetId, conditionId));
  const { lineColor: reduxLineColor } = useSelector(selectorSheet(sheetId));
  const { lineColor: defaultLineColor } = defaultSheetFile(sheetId);
  const dispatch = useDispatch();
  
  const handleNewColor = (newColor) => {
    if (!newColor) return;
    dispatch(actionSheetConditionUpdated({
      id: sheetId,
      updatedCondition: {
        id: conditionId,
        lineColor: newColor.hex
      }
    }));
    trackEvent({
      category: SPREADSHEET,
      action: 'Change Conditional Formatting',
      label: 'Line Color'
    });
  }
  
  const color = conditionLineColor || reduxLineColor || defaultLineColor;

  return (
    <SettingColorSelector 
      fillColor={color}
      defaultColor={color}
      handlePickerClose={handleNewColor}
      label="Border"
      labelIcon={<BrushIcon />}
      {...rest}
    />
  );
}

const TextColorSelector = ({ sheetId, conditionId, ...rest }) => {
  const { textColor: reduxTextColor } = useSelector(selectorSheet(sheetId));
  const { textColor: conditionTextColor } = useSelector(selectorSheetCondition(sheetId, conditionId));
  const { textColor: defaultTextColor } = defaultSheetFile(sheetId);
  const dispatch = useDispatch();
    
  const handleNewColor = (newColor) => {
    if (!newColor) return;
    dispatch(actionSheetConditionUpdated({
      id: sheetId,
      updatedCondition: {
        id: conditionId,
        textColor: newColor.hex
      }
    }));
    trackEvent({
      category: SPREADSHEET,
      action: 'Change Conditional Formatting',
      label: 'Text Color'
    });
  };
  
  const color = conditionTextColor || reduxTextColor || defaultTextColor;

  return (
    <SettingColorSelector 
      fillColor={color}
      defaultColor={color}
      labelIcon={<FormatColorTextIcon />}
      handlePickerClose={handleNewColor}
      label="Text Color"
      {...rest}
    />
  );
}

const IconColorSelector = ({ sheetId, conditionId, ...rest}) => {
  const { iconColor: defaultIconColor } = useSelector(selectorSheet(sheetId));
  const { iconColor: conditionIconColor } = useSelector(selectorSheetCondition(sheetId, conditionId));
  const dispatch = useDispatch();

  const handleNewColor = (newColor) => {
    if (!newColor) return;
    dispatch(actionSheetConditionUpdated({
      id: sheetId,
      updatedCondition: {
        id: conditionId,
        iconColor: newColor.hex
      }
    }));
    trackEvent({
      category: SPREADSHEET,
      action: 'Change Conditional Formatting',
      label: 'Icon Color'
    });
  }

  const color = conditionIconColor || defaultIconColor;

  return (
    <SettingColorSelector
      fillColor={color}
      defaultColor={color}
      labelIcon={<ColorizeIcon />}
      handlePickerClose={handleNewColor}
      label="Icon Color"
      {...rest}
    />
  );
}

const PointColorSelector = ({ sheetId, conditionId, ...rest}) => {
  const { pointColor: defaultPointColor } = defaultSheetFile(sheetId);
  const { pointColor: reduxPointColor } = useSelector(selectorSheet(sheetId));
  const { pointColor: conditionPointColor, type, catObj, hslObj } = useSelector(selectorSheetCondition(sheetId, conditionId));
  const dispatch = useDispatch();

  let hslColor = null;
  if (hslObj) {
    let {h, s, l} = hslObj
    hslColor = `hsl(${h}, ${s * 100}%, ${l * 100}%)`;
  }

  const handleNewColor = (newColor) => {
    if (!newColor) return;
    dispatch(actionSheetConditionUpdated({
      id: sheetId,
      updatedCondition: {
        id: conditionId,
        pointColor: newColor.hex,
        hslObj: newColor.hsl
      }
    }));
    if (type === 'heatmapPoint') {
      const heatMapObj = heatMapObjfromJson(catObj, newColor.hsl);
      dispatch(actionSheetHeatmapColorUpdated({
        conditionId,
        sheetId,
        heatMapObj,
        newColor,
      }));
    }
    trackEvent({
      category: SPREADSHEET,
      action: 'Change Conditional Formatting',
      label: 'Point Color'
    });
  }

  const color = (type === 'point' ? conditionPointColor : hslColor) || reduxPointColor || defaultPointColor;

  return (
    <SettingColorSelector
      fillColor={color}
      defaultColor={color}
      labelIcon={<ColorizeIcon />}
      handlePickerClose={handleNewColor}
      label="Point Color"
      {...rest}
    />
  );
}

const TextInput = ({ sheetId, conditionId, ...rest }) => {
  const { text: conditionText } = useSelector(selectorSheetCondition(sheetId, conditionId));
  const dispatch = useDispatch();

  const handleTextChange = (event) => {
    dispatch(actionSheetConditionUpdated({
      id: sheetId,
      updatedCondition: {
        id: conditionId,
        text: event.target.value
      }
    }));
    trackEvent({
      category: SPREADSHEET,
      action: 'Change Conditional Formatting',
      label: 'Label'
    });
  };
  
  const text = conditionText || '';

  return (
    <Setting 
      label="Text"
      labelIcon={<TextFieldsIcon />}
      {...rest}
      controller={
        <TextField 
          id={`cond${conditionId}-text`} 
          size="small"
          variant="standard"
          placeholder="Enter text"
          value={text}
          onChange={handleTextChange}
        />
      }
    />
  );
}


const TypeSelector = (props) => {
  const classes = useStyles();

  const handleChange = (event) => {
    props.handleTypeSet(event.target.value);
  }


  return (
    <Setting 
      active={props.active}
      label="Field Value Type"
      labelIcon={<CategoryIcon />}
      controller={
        <FormControl component="fieldset" disabled={!props.active}>
          <RadioGroup row aria-label="field value type" name="field-value" value={props.type} onChange={handleChange}>
            <FormControlLabel value="category" control={<Radio classes={{checked: classes.radioChecked}} color="default"/>} label="Category" />
            <FormControlLabel value="number" control={<Radio classes={{checked: classes.radioChecked}} color="default"/>} label="Number" />
            <FormControlLabel value="date" control={<Radio classes={{checked: classes.radioChecked}} color="default"/>} label="Date" />
          </RadioGroup>
        </FormControl>
      }
    />
  );
};

const ConditionHeader = (props) => {
  const classes = useStyles();

  return (
    <Box className={classes.conditionHeader}>
      <Typography>{props.text}</Typography>
      <Tooltip title="Remove Condition">
        <IconButton 
          aria-label="remove condition"
          onClick={props.handleIconClick}
        >
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    </Box>
  );
}

const ColorCondition = ({ sheetId, active, conditionId, column, type: dataType }) => {
  const { type: mapType } = useSelector(selectorSheetCondition(sheetId, conditionId));
  const { conditions } = useSelector(selectorSheet(sheetId));
  const dispatch = useDispatch();
  const classes = useStyles();

  const handleConditionRemoval = () => {
    dispatch(actionSheetConditionRemoved({
      id: sheetId,
      conditionId,
    }));
    if (mapType.indexOf('heatmap') === 0)  {
      conditions.filter(condition => condition.heatMapConditionRef === conditionId).forEach(condition => {
        dispatch(actionSheetConditionRemoved({
          id: sheetId,
          conditionId: condition.id
        }));
      });
    }
  };
  
  const isHeatmap = mapType.indexOf('heatmap') === 0;

  return (
    <Paper
      className={classes.condition}
    >
      <ConditionHeader 
        handleIconClick={handleConditionRemoval}
        text="Color Conditional"
      />
      <RRNSelector 
        dataType={dataType}
        sheetId={sheetId}
        conditionId={conditionId}
        active={active}
        column={column}
        showHeatmap={true}
      />
      <FillColorSelector
        sheetId={sheetId}
        active={active}
        conditionId={conditionId}
      />
      <OpacitySlider 
        sheetId={sheetId}
        active={active && !isHeatmap}
        conditionId={conditionId}
      />
      <LineColorSelector 
        sheetId={sheetId}
        active={active && !isHeatmap}
        conditionId={conditionId}
      />
    </Paper>
  );
}

const TextCondition = (props) => {
  const {sheetId, active, conditionId, column, type: dataType} = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleConditionRemoval = () => {
    dispatch(actionSheetConditionRemoved({
      id: sheetId,
      conditionId,
    }));
  };

  return (
    <Paper
      className={classes.condition}
    >
      <ConditionHeader 
        handleIconClick={handleConditionRemoval}
        text="Text Conditional"
      />
      <RRNSelector 
        dataType={dataType}
        sheetId={sheetId}
        conditionId={conditionId}
        active={active}
        column={column}
      />
      <TextColorSelector 
        sheetId={sheetId}
        conditionId={conditionId}
        active={active}
      />
      <TextSizeSlider 
        sheetId={sheetId}
        conditionId={conditionId}
        active={active}
      />
      <TextInput 
        sheetId={sheetId}
        conditionId={conditionId}
        active={active}
      />
    </Paper>
  );
}

const IconCondition = (props) => {
  const {sheetId, active, conditionId, column, type: dataType} = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleConditionRemoval = () => {
    dispatch(actionSheetConditionRemoved({
      id: sheetId,
      conditionId,
    }))
  };

  return (
    <Paper
      className={classes.condition}
    >
      <ConditionHeader 
        handleIconClick={handleConditionRemoval}
        text="Icon Conditional"
      />
      <RRNSelector 
        dataType={dataType}
        sheetId={sheetId}
        conditionId={conditionId}
        active={active}
        column={column}
      />
      <IconSelector 
        sheetId={sheetId}
        conditionId={conditionId}
        active={active}
      />
      <IconSizeSlider 
        sheetId={sheetId}
        conditionId={conditionId}
        active={active}
      />
      <IconColorSelector 
        sheetId={sheetId}
        conditionId={conditionId}
        active={active}
      />
    </Paper>
  );
}

const PointCondition = (props) => {
  const {sheetId, active, conditionId, column, type: dataType} = props;
  const { type: mapType } = useSelector(selectorSheetCondition(sheetId, conditionId));
  const { conditions } = useSelector(selectorSheet(sheetId));
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleConditionRemoval = () => {
    dispatch(actionSheetConditionRemoved({
      id: sheetId,
      conditionId,
    }));
    if (mapType.indexOf('heatmap') === 0)  {
      conditions.filter(condition => condition.heatMapConditionRef === conditionId).forEach(condition => {
        dispatch(actionSheetConditionRemoved({
          id: sheetId,
          conditionId: condition.id
        }));
      });
    }
  };

  const isHeatmap = mapType === 'heatmapPoint';

  return (
    <Paper
      className={classes.condition}
    >
      <ConditionHeader 
        handleIconClick={handleConditionRemoval}
        text="Point Conditional"
      />
      <RRNSelector 
        dataType={dataType}
        sheetId={sheetId}
        conditionId={conditionId}
        active={active}
        column={column}
        showHeatmap={true}
      />
      <PointIconSelector 
        sheetId={sheetId}
        conditionId={conditionId}
        active={active && !isHeatmap}
      />
      <PointSizeSlider 
        sheetId={sheetId}
        conditionId={conditionId}
        active={active && !isHeatmap}
      />
      <PointColorSelector 
        sheetId={sheetId}
        conditionId={conditionId}
        active={active}
      />
    </Paper>
  );
}

const RRNSelector = ({dataType, ...rest}) => {
  switch(dataType) {
    case 'category':
      return <CategoryRRNSelect {...rest} />;
    case 'number':
      return <NumberRRNSelect {...rest} />;
    case 'date':
      return <DateRRNSelect {...rest} />;
    default:
      return null;
  }
};

const catObjFromJson = (json, rmrecnbrCol, valueCol) => {
  /* 
  pair rmrecnbrs with label values, omitting header values 
  [{ rmrecnbr: '1234567', label: 'example'}, ....]
  */
  const rmrecnbrPairs = json.map(row => {
    return {
      rmrecnbr: row[rmrecnbrCol], 
      label: row[valueCol]
    }
  }).filter((x, i) => i !== 0);
  /*
  create obj to group related label values
  property names are labels, property values are arrays of rmrecnbrs (as strings)
  {
    'example': ['1234567', '7654321'],
    'example2': ['']
  }
  */
  const catObj = {}
  rmrecnbrPairs.forEach(pair => {
    if (!pair.rmrecnbr) return;
    if (catObj[pair.label]) {
      catObj[pair.label] = [...catObj[pair.label], pair.rmrecnbr];
    } else {
      catObj[pair.label] = [pair.rmrecnbr]
    }
  });
  return catObj;
};

const pointCatObjFromJson = (json, valueCol, sheetId) => {
  function isPointValue(row, lngCol, latCol, typeCol) {
    if (typeCol && row[typeCol] === 'Point') return true;
    if (row[lngCol] && row[latCol]) return true;
    return false;
  }

  // TODO export find index as util ?
  const lngCol = json[0].findIndex(x => ['lng', 'longitude', 'long'].includes(String(x).toLowerCase()));
  const latCol = json[0].findIndex(x => ['lat', 'latitude', 'lat'].includes(String(x).toLowerCase()));
  const typeCol = json[0].indexOf('type');

  /* 
  pair rmrecnbrs with label values, omitting header values 
  [{ pointId: 'point-1-1', label: 'example'}, ....]
  */
  // TODO filter blank labels
  const pointIdPairs = json.map((row, i) => {
    if (isPointValue(row, lngCol, latCol, typeCol)) {
      return {
        pointId: `point-${sheetId}-${i}`,
        label: row[valueCol]
      }
    }
  }).filter((x, i) => (i !== 0 && x));
  /*
  create obj to group related label values
  property names are labels, property values are arrays of rmrecnbrs (as strings)
  {
    'example': ['point-0-1', 'point-0-2'],
    'example2': ['point-0-3']
  }
  */
  const catObj = {}
  pointIdPairs.forEach(pair => {
    if (!pair.pointId) return;
    if (catObj[pair.label]) {
      catObj[pair.label] = [...catObj[pair.label], pair.pointId];
    } else {
      catObj[pair.label] = [pair.pointId]
    }
  });
  return catObj;
};

const NumberRRNSelect = ({ sheetId, column, conditionId, active, showHeatmap = false }) => {
  const { json, rmrecnbr: rmrecnbrCol, conditions } = useSelector(selectorSheet(sheetId))
  const { type } = useSelector(selectorSheetCondition(sheetId, conditionId));
  const [min, setMin] = useState('');
  const [max, setMax] = useState('');
  const dispatch = useDispatch();

  const firstHeatMap = (conditions.filter(condition => ['heatmapColor', 'heatmapPoint', 'color', 'point'].includes(condition.type)).length > 0 ?
    conditions.find(condition => ['heatmapColor', 'heatmapPoint', 'color', 'point'].includes(condition.type)).id : null);
  
  const catObj = type === 'point' || type === 'heatmapPoint' ? pointCatObjFromJson(json, column, sheetId) : catObjFromJson(json, rmrecnbrCol, column);
  const numArray = Object.keys(catObj).map(num => parseFloat(num)).filter(num => !isNaN(num));
  const mathMin = Math.min(...numArray);
  const mathMax = Math.max(...numArray);
  const defaultMin = numArray.length > 0 ? mathMin.toString() : '';
  const defaultMax = numArray.length > 0 ? mathMax.toString() : '';

  const handleMax = (e) => {
    setMax(e.target.value);
  }

  const handleMin = (e) => {
    setMin(e.target.value);
  }

  const calcRange = (min, max) => {
    const rmrecnbrArray = [];
    // use catObj to build combine arrays of rmrecnbrs whose values fall in the selected range
    for (const num in catObj) {
      if (parseInt(num) <= parseInt(max) && parseInt(num) >= parseInt(min)) {
        rmrecnbrArray.push(...catObj[num]);
      }
    }
    dispatch(actionSheetConditionUpdated({
      id: sheetId,
      updatedCondition: {
        id: conditionId,
        rmrecnbr: rmrecnbrArray,
      }
    }));
  };

  useEffect(() => {
    calcRange(min, max);
  }, [min, max]);

  const activeVal = active && type.indexOf('heatmap') !== 0; 

  return (
    <>
      {
        showHeatmap && conditionId === firstHeatMap ?
        <HeatMapSwitch
          sheetId={sheetId}
          conditionId={conditionId}
          active={active}
          catObj={catObj}
          /> :
        ''
      }
      <Setting 
        label="Lower Boundary"
        active={activeVal}
        labelIcon={<ArrowDownwardIcon />}
        controller={
          <TextField 
            inputProps={{
              min: defaultMin,
              max: defaultMax
            }}
            disabled={!activeVal}
            size="small"
            fullWidth
            type="number"
            id={`cond-${conditionId}-min`}
            variant="standard"
            placeholder={defaultMin}
            value={min}
            onChange={handleMin} />
        }
      />
      <Setting 
        label="Upper Boundary"
        active={activeVal}
        labelIcon={<ArrowUpwardIcon />}
        controller={
          <TextField
            inputProps={{
              min: defaultMin,
              max: defaultMax
            }}
            disabled={!activeVal}
            size="small" 
            fullWidth 
            type="number" 
            id={`cond-${conditionId}-max`} 
            variant="standard" 
            placeholder={defaultMax} 
            value={max} 
            onChange={handleMax}/>
        }
      />
    </>
  );
}

// TODO create date utils file
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

const DateRRNSelect = ({ sheetId, column, conditionId, active }) => {
  const { json, rmrecnbr: rmrecnbrCol, conditions } = useSelector(selectorSheet(sheetId))
  const { type } = useSelector(selectorSheetCondition(sheetId, conditionId));

  // TODO @point-2 handle heatmap with types and conditions values (see NumberRRNSelect)
  // const firstHeatMap = (conditions.filter(condition => ['heatmap', 'color'].includes(condition.type)).length > 0 ?
  // conditions.find(condition => ['heatmap', 'color'].includes(condition.type)).id : null);
  const [min, setMin] = useState('');
  const [max, setMax] = useState('');
  const dispatch = useDispatch();
  // Must be 'YYYY-MM-DD' format for native datepicker
  const DATE_FORMAT = 'YYYY-MM-DD';
  
  /* 
    * catObj format
    {
      * Sheetjs date import format as key
      'Mon Jan 1 2023 00:00:00 GMT-0500 (Eastern Standard Time)':
      * Array of rmrecnbrs for as value
      [111111, 111112],
      'Tue Jan 1 2023 00:00:00 GMT-0500 (Eastern Standard Time)':
      [111113],
      ...
    }
  */
  // TODO refactor catObj as single function
  const catObj = type === 'point' ? pointCatObjFromJson(json, column, sheetId) : catObjFromJson(json, rmrecnbrCol, column);
  // convert date keys to dayjs date
  const dateArray = Object.keys(catObj).map(date => dayjs(date));
  // sort in chronological order
  const sortedDateArray = [...dateArray].sort((a,b) => {
    if (a.isBefore(b, 'day')) return -1;
    if (b.isBefore(a, 'day')) return 1;
    return 0;
  });

  // format min and max dayjs dates as 'YYYY-MM-DD' for inputs
  const defaultMin = sortedDateArray.length > 0 && sortedDateArray[0].format(DATE_FORMAT);
  const defaultMax = sortedDateArray.length > 0 && sortedDateArray[sortedDateArray.length - 1].format(DATE_FORMAT);
  
  const handleMax = (e) => {
    setMax(e.target.value);
  }

  const handleMin = (e) => {
    setMin(e.target.value);
  }

  const calcRange = (min, max) => {
    const rmrecnbrArray = [];
    // create dayjs dates from input min max for comparisons
    const dayMin = dayjs(min, DATE_FORMAT);
    const dayMax = dayjs(max, DATE_FORMAT);
    // use catObj to combine arrays of rmrecnbrs whose values fall in the selected range
    for (const date in catObj) {
      const d = dayjs(date);
      if (min && max ) {
        if (
          d.isSameOrBefore(dayMax, 'day') && 
          d.isSameOrAfter(dayMin, 'day')
        ) {
          rmrecnbrArray.push(...catObj[date])
        }
      }
      else {
        if (d.isSameOrBefore(dayMax, 'day')) {
          rmrecnbrArray.push(...catObj[date])
        }
        if (d.isSameOrAfter(dayMin, 'day')) {
          rmrecnbrArray.push(...catObj[date])
        }
      }
    }

    dispatch(actionSheetConditionUpdated({
      id: sheetId,
      updatedCondition: {
        id: conditionId,
        rmrecnbr: rmrecnbrArray,
      }
    }));
  };

  useEffect(() => {
    calcRange(min, max);
  }, [min, max])

  return (
    <>
      <Setting 
        label="After"
        active={active}
        labelIcon={<ArrowForwardIcon />}
        controller={
          <TextField 
            inputProps={{
              min: defaultMin,
              max: defaultMax
            }}
            disabled={!active}
            size="small"
            fullWidth
            type="date"
            id={`cond-${conditionId}-datemin`}
            variant="standard"
            placeholder={defaultMin}
            value={min}
            onChange={handleMin} />
        }
      />
      <Setting 
        label="Before"
        active={active}
        labelIcon={<ArrowBackIcon />}
        controller={
          <TextField
            inputProps={{
              min: defaultMin,
              max: defaultMax
            }}
            disabled={!active} 
            size="small" 
            fullWidth 
            type="date" 
            id={`cond-${conditionId}-datemax`} 
            variant="standard" 
            placeholder={defaultMax} 
            value={max} 
            onChange={handleMax}/>
        }
      />
    </>
  );
};

const CategoryRRNSelect = ({ sheetId, handleRmrecnbrSelect, column, conditionId, ...rest }) => {
  const { json, rmrecnbr: rmrecnbrCol } = useSelector(selectorSheet(sheetId));
  const { rmrecnbr: rmrecnbrArray } = useSelector(selectorSheetCondition(sheetId, conditionId));
  const classes = useStyles();
  const dispatch = useDispatch();
  const { type } = useSelector(selectorSheetCondition(sheetId, conditionId));
  const catObj = type === 'point'? pointCatObjFromJson(json, column, sheetId) : catObjFromJson(json, rmrecnbrCol, column);

  const value = Object.keys(catObj).find(key => _.isEqual(catObj[key], rmrecnbrArray)) || '';
  const optionsArray = Object.keys(catObj).map((key) => {
    return {
      value: key,
      label: key,
    }
  });

  const handleChange = (key) => {
    dispatch(actionSheetConditionUpdated({
      id: sheetId,
      updatedCondition: {
        id: conditionId,
        rmrecnbr: catObj[key],
      },
    }));
  };


  return (
    <SettingDropSelector 
      labelIcon={<VpnKeyIcon />}
      label="Field Value"
      formControlClass={classes.formControl}
      handleSelection={handleChange}
      value={value}
      optionsArray={optionsArray}
      defaultOption={{
        value: '',
        label: 'Select',
        disabled: true,
      }}
      {...rest}
    />
  );
};

const conditionIsBlank = (conditions, mapType) => {
  if (Array.isArray(mapType)) {
    conditions = conditions.filter(condition => mapType.includes(condition.type));
  } else {
    conditions = conditions.filter(condition => condition.type === mapType);
  }
  if (conditions.length === 0) return false;
  const condition = conditions[conditions.length -1 ];
  // only one heatmap condition allowed
  if (condition.isHeatMapCondition) return true;
  return (condition.rmrecnbr.length === 0 && !condition.fillColor && !condition.fillOpacity && !condition.lineColor);
}

const Conditional = (props) => {
  const { sheetId, active, mapType, attrKey, dataKey, conditionArray } = props;
  const { conditions, [attrKey]: conditionalAttr, [dataKey]: conditionalDatatype } = useSelector(selectorSheet(sheetId));
  const dispatch = useDispatch();

  const handleColSelect = (col) => {
    dispatch(actionSheetFileUpdated({
      id: sheetId,
      [attrKey]: col,
    }));
    if (conditions.length > 0) {
      clearConditions(conditionArray)
    }
  }

  const handleTypeSet = (type) => {
    dispatch(actionSheetFileUpdated({
      id: sheetId,
      [dataKey]: type
    }));
    if (conditions.length > 0) {
      clearConditions(conditionArray)
    }
  }

  const clearConditions = (typesArray) => {
    console.log('clear conditions');
    console.log(typesArray);

    conditions
    .filter(condition => typesArray.includes(condition.type))
    .forEach(condition => {
      dispatch(actionSheetConditionRemoved({
        id: sheetId,
        conditionId: condition.id
      }))
    });
  }

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Typography>Step 1. Choose attribute</Typography>
      <TextSelector 
        sheetId={sheetId}
        active={active}
        handleColSelect={handleColSelect}
        column={conditionalAttr}
        labelIcon={<VpnKeyIcon />}
      />
      <TypeSelector 
        handleTypeSet={handleTypeSet}
        type={conditionalDatatype}
        active={active}
      />
      <Conditions 
        sheetId={sheetId}
        active={active}
        conditionArray={conditionArray}
        attrKey={attrKey}
        mapType={mapType}
        dataKey={dataKey}
      />
    </Box>
  );
}

const Conditions = ({ sheetId, active, conditionArray, attrKey, dataKey, mapType }) => {
  const { conditions, [attrKey]: conditionalAttr, [dataKey]: conditionalDatatype } = useSelector(selectorSheet(sheetId));
  const classes = useStyles();
  const dispatch = useDispatch();
  const canAddCondition = conditionIsBlank(conditions, conditionArray);
  const validAttr = Number.isInteger(conditionalAttr);

  const handleButtonClick = () => {
    let nextId = conditions.length > 0 ? Math.max(...conditions.map(condition => parseInt(condition.id))) + 1 : 0;
    let newCondition = {
      id: nextId,
      rmrecnbr: [],
      type: mapType,
    }
    dispatch(actionSheetConditionAdded({
      id: sheetId,
      newCondition
    }));
    trackEvent({
      category: SPREADSHEET,
      action: 'Create Formatting Condition',
      label: mapType
    })
  }
  
  if (!conditionalDatatype || !validAttr) {
    return '';
  }

  return (
    <>
      <Typography>Step 2. Add condition</Typography>
      {conditions.filter(condition => conditionArray.includes(condition.type) && !condition.isHeatMapCondition).map(condition => {
        switch (mapType) {
          case 'text':
            return (
              <TextCondition
                type={conditionalDatatype}
                column={conditionalAttr}
                key={condition.id}
                conditionId={condition.id}
                sheetId={sheetId}
                active={active}
              />
            );
          case 'icon':
            return (
              <IconCondition
                type={conditionalDatatype}
                column={conditionalAttr}
                key={condition.id}
                conditionId={condition.id}
                sheetId={sheetId}
                active={active}
              />
            );
          case 'color':
            return (
              <ColorCondition
                type={conditionalDatatype}
                column={conditionalAttr}
                key={condition.id}
                conditionId={condition.id}
                sheetId={sheetId}
                active={active}
              />
            );
          case 'point':
            return (
              <PointCondition
                type={conditionalDatatype}
                column={conditionalAttr}
                key={condition.id}
                conditionId={condition.id}
                sheetId={sheetId}
                active={active}
              />
            );
          default:
            return '';
        }
      })}
      <Button
        disabled={canAddCondition}
        className={classes.button}
        startIcon={<AddIcon />}
        onClick={handleButtonClick}
        variant="contained"
      >Condition</Button>
    </>
  );
}

export const ColorConditional = (props) => {
  const {sheetId, active} = props;

  return (
    <Conditional 
      sheetId={sheetId}
      active={active}
      mapType='color'
      attrKey='colorConditionalAttr'
      dataKey='colorConditionalDatatype'
      conditionArray={['color', 'heatmapColor']}
    />
  );
}

export const TextConditional = (props) => {
  const { sheetId, active } = props;
  
  return (
    <Conditional
      sheetId={sheetId}
      active={active}
      mapType="text"
      attrKey="textConditionalAttr"
      dataKey="textConditionalDatatype"
      conditionArray={['text']}
    />
  );
};

export const IconConditional = (props) => {
  const {sheetId, active} = props;
  return (
    <Conditional 
      sheetId={sheetId}
      active={active}
      mapType="icon"
      attrKey="iconConditionalAttr"
      dataKey="iconConditionalDatatype"
      conditionArray={['icon']}
    />
  );
}

export const PointConditional = (props) => {
  const {sheetId, active} = props;
  return (
    <Conditional 
      sheetId={sheetId}
      active={active}
      mapType="point"
      attrKey="pointConditionalAttr"
      dataKey="pointConditionalDatatype"
      conditionArray={['point', 'heatmapPoint']}
    />
  );
}
