import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import DeleteIcon from '@material-ui/icons/Delete';
import GridOnIcon from '@material-ui/icons/GridOn';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Tooltip from '@material-ui/core/Tooltip';
import DataSelector from './DataSelector';

import { actionSheetFileRemoved, selectorSheet } from '.';
import { defaultSheetFile } from './spreadsheetutils';

export default function UploadListItem({ sheetId }) {
  const [collapseOpen, setCollapseOpen] = useState(false);
  const dispatch = useDispatch();
  const { id, fileName, rmrecnbr, fillColor, lineColor, textColor, status, lnglat } = useSelector(selectorSheet(sheetId));
  const { fillColor: defaultFill, lineColor: defaultLine, textColor: defaultText } = defaultSheetFile(sheetId);
  const validRRN = rmrecnbr !== null;
  const validLngLat = lnglat !== null && Array.isArray(lnglat) && lnglat[0] !== null && lnglat[1] !== null;
  const validHeader = validRRN || validLngLat

  const style = {
    backgroundColor: fillColor || defaultFill,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: lineColor || defaultLine,
    color: textColor || defaultText,
  };

  const toggleCollapse = () => {
    setCollapseOpen(!collapseOpen);
  };

  const toggleRemoval = (id) => {
    dispatch(actionSheetFileRemoved({id}));
  };

  return (
    <>
      <ListItem key={id} >
        <ListItemAvatar>
          <Avatar style={style} >
            <GridOnIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={fileName}
        />
        {
          status !== 'preupload' &&
          <Button
            variant={ validHeader ? 'outlined' : 'contained' }
            color="primary"
            onClick={toggleCollapse}
          >
            { validHeader ? 'Header Selected' : 'Select Column Headers'}
          </Button>
        }
        <Tooltip title="Remove File">
          <IconButton
            arial-label="remove file"
            onClick={() => toggleRemoval(id)}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </ListItem>
      <Collapse in={collapseOpen} >
        <DataSelector
          id={id}
          toggleCollapse={toggleCollapse}
        />
      </Collapse>
    </>
  );
};