import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { actionLayerUpdate } from './LayerRedux';
import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { trackEvent, LAYERS } from '../../tracking';

import Collapse from '@material-ui/core/Collapse';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import IconButton from '@material-ui/core/IconButton';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InfoIcon from '@material-ui/icons/Info';

const useStyles = makeStyles(theme => ({
  root: {
    padding: 0,
    '& .MuiRadio-root': {
      padding: 0,
    },
    '& .MuiTypography-overline': {
      lineHeight: 1,
    },
  },
  label: {
    display: 'flex',
    alignItems: 'center'
  },
  radioContainer: {
    textAlign: 'center',
  },
  satelliteInfo: {
    margin: 5
  },
  iconButton: {
    marginInlineStart: 10
  }
}));

export default function GraphicLayers({ layers }) {
  return (
    <Grid container>
      <Grid item xs={6}></Grid>
      <Grid
        container
        item
        xs={6}
        style={{textAlign: 'center'}}
      >
        <Grid item xs={4}>
          <Typography variant="overline" style={{fontWeight: 'bold'}}>
            Auto
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="overline" style={{fontWeight: 'bold'}}>
            Show
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="overline" style={{fontWeight: 'bold'}}>
            Hide
          </Typography>
        </Grid>
      </Grid>
      {
        layers
          .filter(layer=>layer.type === 'graphics')
          .map(layer=><GraphicLayer layer={layer} key={layer.id}/>)
      }
    </Grid>
  );
}

function GraphicLayer({layer}) {
  const {id, label} = layer;
  const selectedValue = useSelector(state=>state.layerReducer.graphics[id]);
  const basemapSource = useSelector(state => state.layerReducer.basemapSource);
  const [expanded, setExpanded] = useState(false);
  const dispatch = useDispatch();
  const classes = useStyles();

  const handleChange = event => {
      const mode = event.target.value;
      const data = { id, mode, basemapSource };
      trackEvent({
        category: LAYERS,
        action: `Change Graphic ${id}`,
        label: mode,
      });
      dispatch(actionLayerUpdate({type: 'graphic', data}));
  }

  return (
    <>
    <Grid container item xs={12} className={classes.root} >
      <Grid item xs={6} className={classes.label}>
          <Typography variant="overline" data-testid={`layer-graphic-label-${id}`}>
            {label}
          </Typography>
          { layer.id === "basemap" ? <IconButton size="small" onClick={() => setExpanded(!expanded)}><KeyboardArrowDownIcon /></IconButton> : "" }
      </Grid>
      <Grid container item xs={6} className={classes.radioContainer}>
          <Grid item xs={4}>
            <Radio
              disabled={id === 'basemap'}
              color="primary"
              checked={selectedValue === 'auto'}
              onChange={handleChange}
              value="auto"
              name={'toggle-graphic-' + id}
              />      
          </Grid>
          <Grid item xs={4}>
            <Radio
              color="primary"
              checked={selectedValue === 'show'}
              onChange={handleChange}
              value="show"
              name={'toggle-graphic-' + id}
              />                        
          </Grid>
          <Grid item xs={4}>
            <Radio
              color="primary"
              checked={selectedValue === 'hide'}
              onChange={handleChange}
              value="hide"
              name={'toggle-graphic-' + id}
              />                        
          </Grid>
      </Grid>
    </Grid>
    {
      layer.id === 'basemap' ? <BasemapSubSelector 
        expanded={expanded}
      /> : ''
    }
    </>
  );
}

function BasemapSubSelector({expanded}) {
  const classes = useStyles();
  const basemapVisibility = useSelector(state=>state.layerReducer.graphics.basemap);
  const basemapValue = useSelector(state=>state.layerReducer.basemapSource);
  const dispatch = useDispatch();
  const [dialogOpen, setDialogOpen] = useState(false)

  const handleChange = (event) => {
    const bmap = event.target.value;
    const data = { id: "basemap", mode: basemapVisibility, basemapSource: bmap };
    trackEvent({
      category: LAYERS,
      action: `Change Basemap Source`,
      label: bmap,
    });
    dispatch(actionLayerUpdate({type: 'graphic', data}));
  }

  return (
    <Collapse in={expanded}>
      <Grid container className={classes.root} >
        <RadioGroup row value={basemapValue} onChange={handleChange} >
          <FormControlLabel
            value="map"
            label={
              <Typography variant="overline" >
                Map
              </Typography>
            }
            labelPlacement="start"
            control={<Radio color="primary" />}
          />
          <FormControlLabel
            value="satellite"
            label={
              <Typography variant="overline" >
                Satellite
              </Typography>
            }
            labelPlacement="start"
            control={<Radio color="primary" />}
          />
          <IconButton className={classes.iconButton} onClick={() => setDialogOpen(!dialogOpen)} size="small" ><InfoIcon /></IconButton>
        </RadioGroup>
      </Grid>
      <Collapse in={dialogOpen}>
        <Typography variant="body2" className={classes.satelliteInfo}>
          Satellite imagery is sourced by Mapbox, varies by location, is updated on an irregular schedule, and cannot be guaranteed to be up-to-date.
        </Typography>
      </Collapse>
    </Collapse>
  );
}