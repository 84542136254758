import axios from 'axios';
import Papaparse from 'papaparse';

const DOMAIN = !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? "https://mgis-dev.med.umich.edu" : "";
const BASE_URL = `${DOMAIN}/api/photolist?`;
const BULKDATA_URL = `${process.env.REACT_APP_API_BASE_URL}/bulkdata?`;

export const getPhotosByBuilding = async (buildingRecordNumber, idToken) => {
  const requestUrl = `${BASE_URL}bldrecnbr=${buildingRecordNumber}&url=false`;
  const options = { headers: { "Authorization": `Bearer ${idToken}` } };

  try {
    const response = await axios.get(requestUrl, options);
    return response.data;
  } catch (error) {
    return error;
  }
}

export const getPhotosByRoom = async (roomRecordNumber, buildingRecordNumber, idToken) => {
  const requestUrl = `${BASE_URL}bldrecnbr=${buildingRecordNumber}&rmrecnbr=${roomRecordNumber}`;
  const options = { headers: { "Authorization": `Bearer ${idToken}` } };

  try {
    const response = await axios.get(requestUrl, options);
    return response.data[roomRecordNumber];
  } catch (error) {
    return error;
  }
}

export const getRoomData = async (buildingRecordNumber, idToken) => {
  const requestUrl = `${BULKDATA_URL}table=room&bldrecnbr=${buildingRecordNumber}`;
  const options = { headers: { "Authorization": `Bearer ${idToken}` } };

  try {
    const response = await axios.get(requestUrl, options);
    const file = await axios.get(response.data);
    return Papaparse.parse(file.data);
  } catch (error) {
    return error;
  }
} 