import { createAction, handleActions } from 'redux-actions';

export const initialState = {
  lng: -83.722124,
  lat: 42.289594,
  zoom: 14,
  pitch: 0,
  bearing: 0,
  padding: { top: 0, bottom: 0, left: 0, right: 0},
  popupVisible: false,
  sourceDataLoaded: false,
  print: {}, // {status: preview/print/cancel, size: letter/legal, orientation: landscape/portrait}
  mapviewChangeRequest: {},
  mapPopupRequest: {},
  zoomFeatures: { data: null, type: null }, // @data:[rrn1,..]/rrn/brn/[feature1,..]/feature @type:rrns/brn/bbox
  cursor: { featureHover: false, drawMode: false },
  drawMode: { mode: '', data: null }, // refer handleDrawModeChange()
  drawFeatures: [], //[feature1,...]
  activeFeature: { bldrecnbr: null, floor: null, rmrecnbr: null },
};

// actions
export const actionMapPaddingUpdated = createAction('MAP_PADDING_UPDATED');
export const actionMapViewChanged = createAction('MAP_VIEW_CHANGED');
export const actionMapPrintUpdated = createAction('MAP_PRINT_UPDATED');
export const actionMapMapviewChangeRequested = createAction('MAP_MAPVIEW_CHANGE_REQUESTED');
export const actionMapPopupRequested = createAction('MAP_POPUP_REQUESTED');
export const actionMapPopupVisibilityUpdated = createAction('MAP_POPUP_VISIBILITY_UPDATED');
export const actionMapSourceDataLoaded = createAction('MAP_SOURCE_DATA_LOADED');
export const actionMapZoomFeaturesUpdated = createAction('MAP_ZOOM_FEATURES_UPDATED');
export const actionMapCursorUpdated = createAction('MAP_CURSOR_UPDATED');
export const actionMapDrawModeUpdated = createAction('MAP_DRAW_MODE_UPDATED');
export const actionMapDrawFeaturesUpdated = createAction('MAP_DRAW_FEATURES_UPDATED');
export const actionMapActiveFeatureUpdated = createAction('MAP_ACTIVE_FEATURE_UPDATED');

export const actionMapResetMapCenter = () => {
  return dispatch => {
    const { lng, lat, zoom } = initialState;
    dispatch(actionMapMapviewChangeRequested({
      center: [lng, lat],
      zoom,
      speed: 0.8,
    }));
  }
}

export const actionMapHandleScreenSize = (screenSize) => {
  return (dispatch) => {
    const { xsDown, smDown } = screenSize;
    // const popupVisible = !isTouchDevice() && !xsDown;
    let padding;

    if (xsDown) {
      padding = {top: 30, bottom: 30, left: 0, right: 0 };
    } else if (smDown) {
      padding = {top: 50, bottom: 50, left: 40, right: 40 };
    } else {
      padding = {top: 50, bottom: 50, left: 455, right: 50 };
    }

    // dispatch(actionMapPopupVisibilityUpdated(popupVisible));
    dispatch(actionMapPaddingUpdated(padding));
  }
}

export const actionMapUpdateZoomFeatures = (data) => {
  return (dispatch, getState) => {
    if (getState().drawReducer.isDrawing) { return }
    dispatch(actionMapZoomFeaturesUpdated(data));
  }
}

export const actionMapUpdateActiveFeature = (data) => {
  return (dispatch, getState) => {
    if (getState().drawReducer.isDrawing) { return }
    dispatch(actionMapActiveFeatureUpdated(data));
  }
}

// reducer
export const mapReducer = handleActions({
    [actionMapPaddingUpdated]: (state, action) => {
      return {...state, padding: action.payload };
    },
    [actionMapViewChanged]: (state, action) => {
      const {lng, lat, zoom, pitch, bearing } = {...state, ...action.payload};
      return {...state, lng, lat, zoom, pitch, bearing };
    },
    [actionMapMapviewChangeRequested]: (state, action) => {
      return {...state, mapviewChangeRequest: action.payload}
    },
    [actionMapPrintUpdated]: (state, action) => {
      return {...state, print: action.payload };
    },
    [actionMapPopupRequested]: (state, action) => {
      return {...state, mapPopupRequest: action.payload };
    },
    [actionMapPopupVisibilityUpdated]: (state, action) => {
      return {...state, popupVisible: action.payload };
    },
    [actionMapSourceDataLoaded]: (state, action) => {
      return {...state, sourceDataLoaded: action.payload };
    },
    [actionMapCursorUpdated]: (state, action) => {
      const cursor = {...state.cursor, ...action.payload };
      return {...state, cursor };
    },
    [actionMapZoomFeaturesUpdated]: (state, action) => {
      return {...state, zoomFeatures: action.payload };
    },
    [actionMapDrawModeUpdated]: (state, action) => {
      return {...state, drawMode: action.payload };
    },
    [actionMapDrawFeaturesUpdated]: (state, action) => {
      return {...state, drawFeatures: action.payload };
    },
    [actionMapActiveFeatureUpdated]: (state, action) => {
      return {...state, activeFeature: action.payload };
    },
  }, initialState);

// selector
export const selectorMapZoom = state => state.mapReducer.zoom;
export const selectorMapBearing = state => state.mapReducer.bearing;
export const selectorMapPitch = state => state.mapReducer.pitch;

// @ref: https://stackoverflow.com/questions/4817029/whats-the-best-way-to-detect-a-touch-screen-device-using-javascript
export function isTouchDevice() {
    var prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
    var mq = function (query) {
        return window.matchMedia(query).matches;
    }

    if (('ontouchstart' in window) || (window.DocumentTouch && document instanceof window.DocumentTouch)) {
        return true;
    }

    // include the 'heartz' as a way to have a non matching MQ to help terminate the join
    // https://git.io/vznFH
    var query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
    return mq(query);
}
