

export function clearCache() {
    const warning = "This action will not only clear your browser's cache but also log you out from the website. It will terminate your current session, and any unsaved data or settings will be lost. Do you want to continue?"
    if (window.confirm(warning)) {
        // remove cookies
        const cookies = document.cookie.split(';');
        cookies.forEach((cookie) => {
            const cookieName = cookie.trim().split('=')[0];
            document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
        });
    
        localStorage.clear();
        sessionStorage.clear();
    }
}