import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { 
  actionLayerPopulateColorDef,
  actionLayerCompactToggled,
} from './LayerRedux';
import { actionAppToggleComponent } from '../App';
import Tabs from './LayerTabs';
import Accordion from '../Shared/Accordion';

export default function Layers() {
  const component = 'layersVisible';
  const dispatch = useDispatch();
  dispatch(actionLayerPopulateColorDef());

  const visible = useSelector(state => state.appReducer[component]);
  const visibleHandler = () => { dispatch(actionAppToggleComponent({component}))}

  const expand = useSelector(state => state.layerReducer.isExpanded);
  const expandHandler = () => { dispatch(actionLayerCompactToggled()) }

  return (
    <Accordion
      title='Layers'
      visible={visible}
      visibleHandler={visibleHandler}
      expand={expand}
      expandHandler={expandHandler}
    >
      <Tabs />
    </Accordion>
  );
}