import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import {
  PolygonButton,
  RectangleButton,
  LineButton,
  MarkerButton,
  CircleButton,
  FreehandPolygonButton,
  RoomSelectButton,
  GroupRoomSelectButton,
  MoreToolsButton,
  DeleteAllButton,
  // CancelModeButton,
  ColorPicker,
} from './DrawButtons';
import {
  actionMapDrawModeUpdated,
  actionMapCursorUpdated,
} from '../Map';
import {
  actionDrawIsDrawingUpdated,
  actionDrawUpdateClickCounter,
  actionDrawUpdateGroupFeature,
} from './drawRedux';
import { actionDeleteAlert } from '../Alert';
import { trackEvent, DRAW } from '../../tracking';

export default function DrawTools({ featureGroups }) {
  const dispatch = useDispatch();
  const drawMode = useSelector(state => state.mapReducer.drawMode);
  const clickCounter = useSelector(state => state.drawReducer.clickCounter);

  const [moreToolsVisibile, setMoreToolsVisibile] = useState(false);
  const moreToolsClick = () => { setMoreToolsVisibile(!moreToolsVisibile) }

  const changeMode = (mode) => {
    if (mode === drawMode.mode) { mode = 'simple_select' } // user toggle of selected mode
    const isDrawing = !mode || mode === 'simple_select' || mode === 'direct_select';
    const crosshairCursor = mode && mode !== 'simple_select' && mode !== 'direct_select' && mode !== 'room_picker';
    const drawing = isDrawing ? false : true;

    dispatch(actionMapDrawModeUpdated({mode}));
    dispatch(actionDrawUpdateClickCounter(mode));
    dispatch(actionDrawIsDrawingUpdated(drawing));
    // programatically changing mode does't trigger draw.modechange event
    dispatch(actionMapCursorUpdated({drawMode: crosshairCursor}));
    // remove instruction
    if (!isDrawing) { dispatch(actionDeleteAlert('draw')) }
  }

  const removeAll = () => {
    const [firstGroup] = featureGroups;
    if (!firstGroup) { return }
    const targetIds = firstGroup.features.map(feature => feature.id);
    trackEvent({
      category: DRAW,
      action: 'Remove All',
    })
    dispatch(actionDrawUpdateGroupFeature('delete-all'))
    dispatch(actionMapDrawModeUpdated({mode: 'delete', data: targetIds}));
  }

  useEffect(() => {
    if (!drawMode.mode || drawMode.mode === 'simple_select' || drawMode.mode === 'direct_select') {
      // remove instruction
      dispatch(actionDeleteAlert('draw'));
    }
  }, [dispatch, drawMode])

  return (
    <React.Fragment>
      <PrimaryTools>
        <RectangleButton drawMode={drawMode} clickCounter={clickCounter} changeMode={changeMode} />
        <LineButton drawMode={drawMode} clickCounter={clickCounter} changeMode={changeMode} />
        <MarkerButton drawMode={drawMode} changeMode={changeMode} />
        <RoomSelectButton drawMode={drawMode} changeMode={changeMode} />
        <MoreToolsButton moreToolsVisibile={moreToolsVisibile} moreToolsClick={moreToolsClick}/>
        <DeleteAllButton featureGroups={featureGroups} removeAll={removeAll} />
        {/* <CancelModeButton drawMode={drawMode} changeMode={changeMode} /> */}
        <ColorPicker />
      </PrimaryTools>
      <MoreTools moreToolsVisibile={moreToolsVisibile}>
        <PolygonButton drawMode={drawMode} clickCounter={clickCounter} changeMode={changeMode} />
        <FreehandPolygonButton drawMode={drawMode} changeMode={changeMode} />
        <CircleButton drawMode={drawMode} changeMode={changeMode} />
        <GroupRoomSelectButton drawMode={drawMode} changeMode={changeMode} />
      </MoreTools>
    </React.Fragment>
  )
}

// containers
function PrimaryTools({ children }) {
  const zeroPadding = {
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
  };

  return (
    <CardActions disableSpacing style={zeroPadding}>
      {children}
    </CardActions>
  )
}

function MoreTools({ moreToolsVisibile, children }) {
  const zeroPadding = {
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
  };

  return (
    <Collapse in={moreToolsVisibile} timeout="auto">
      <CardActions disableSpacing style={zeroPadding}>
        {children}
      </CardActions>
    </Collapse>
  );
}