import {
  isEmptyObj,
  getMapView,
  updateGraphicLayer,
  updateLabelLayer,
  updateColorLayer,
  updateLabelLayerSettings,
  markRooms,
  zoomRrn,
  zoomBrn,
  zoomBbox,
  zoomFeature,
  setMapFilter,
  setPointFloorFilter,
  getPrintStyleHTMLTag,
  resetPrintMode,
  setStyleTagForPrintMode,
  setBodyTagForPrintMode,
  setMapContainerForPrintMode,
  cursorPointer,
  cursorCrosshair,
  cursorReset,
  removeMarkersForPointFeatures,
  drawRectToRooms,
  fivePctOfMapBound,
  markRoomCustomColor,
  addMarker,
  markActiveRoom,
  markActiveBuilding,
  markActiveFloor,
  hideBuildingsExcept,
  revertHideBuildingsExcept,
  updateRoomSelectLayer,
} from './maputils';
import { getAllMarkers } from '../Shared/markerStore';
import { actionMapPopupVisibilityUpdated, actionMapPrintUpdated } from './MapRedux';
import { actionAppHideAll, actionAppVisibilityRestored } from '../App';
import { actionDrawUpdateGroupFeature, actionDrawToggleVisiblePolygonsFromFloorChange, initDraw } from '../Draw';
import { actionDataSelectFeatureRequested } from '../Data';
import { actionPrintSizeUpdated } from '../Print';


export const handleMapViewChange = (map, mapviewChangeRequest, mapPadding) => {
  if (!map || isEmptyObj(mapviewChangeRequest)) { return; }
  const defaults = { duration: 250, speed: 1.2 };
  const combined = {...defaults, ...getMapView(map), ...mapviewChangeRequest };
  // const { zoom, bearing, pitch, center, duration, speed, bbox } = combined;
  const { zoom, bearing, pitch, center, duration, speed } = combined;
  
  if (mapviewChangeRequest.center) {
    map.flyTo({ zoom, pitch, bearing, center, speed, padding: mapPadding, essential: true });
  } 
  // else if (mapviewChangeRequest.bbox.length > 0) {
  //   map.fitBounds(bbox, { padding: mapPadding });
  // } 
  else {
    map.easeTo({ zoom, pitch, bearing, center, duration, padding: mapPadding, essential: true });
  } 
}

export const handleMapPopupDisplay = (map, mapPopup, mapPopupVisible, mapPopupRequest) => {
  const { coordinate, content } = mapPopupRequest;
  const hide = !map || !mapPopup || !mapPopupVisible ||
        isEmptyObj(mapPopupRequest) || !coordinate || !content;
  if (hide) {
    if (mapPopup) {mapPopup.remove();}
    return;
  }

  mapPopup 
    .setLngLat(coordinate)
    .setHTML(content)
    .addTo(map);
}

export const handleSourceLayersAdd = (map, initialSourceLayers) => {
  if (!map || !initialSourceLayers.sources || !initialSourceLayers.layers) { return; }
  const { sources, layers } = initialSourceLayers;
  sources.forEach(source => {
    if (source.name === 'campus-room') {
      source.data.promoteId = 'rmrecnbr';
    }
  });
  sources.forEach(source => map.addSource(source.name, source.data));
  layers.forEach(layer => map.addLayer(layer));  
  // draw must be added after default layers, otherwise it'll be under building polygons
  initDraw(map)
}

export function handleLayerChange(map, layerChangeRequest, color) {
  if (!map || isEmptyObj(layerChangeRequest)) { return; }
  const { type, id, data, layer, targetColorDef, field, value, labels } = layerChangeRequest;

  if (Array.isArray(layerChangeRequest)) {
    handleLayerChangeHelper(map, layerChangeRequest, color)
  }

  if (type === 'graphic') {
    updateGraphicLayer(map, data, layer, color);
  } else if (type === 'label' && labels?.length) {
    labels.forEach(({ data, layer}) => updateLabelLayer(map, data, layer));
  } else if (type === 'label') {
    updateLabelLayer(map, data, layer);
  } else if (type === 'color') {
    updateColorLayer(map, targetColorDef, data);
  } else if (type === 'label-settings') {
    updateLabelLayerSettings(map, field, value);
  } else if (type === 'room-select') {
    updateRoomSelectLayer(map, id, field, value);
  }
}

function handleLayerChangeHelper(map, layerChangeRequests, color) {
  layerChangeRequests.forEach(layerChangeRequest => {
    handleLayerChange(map, layerChangeRequest, color)
  });
}

export function handleSelectFeatures(map, features) {
  if (!map || isEmptyObj(features)) { return; }

  const { type, data, layerId } = features;
  if (type === 'room') {
    markRooms(map, data, layerId);
  } else if (type === 'room-color') {
    markRoomCustomColor(map, data, layerId);
  }
}

// zoom to features
// @param zoomFeatures {data, type} - data:[rrn1,...]/rrn/brn/[feature1,...]/feature type:rrn/brn/bbox/null 
// @param mapPadding {top, bottom, left, right}
export const handleZoomFeatures = (map, zoomFeatures, mapPadding, idToken) => () => {
  const { data, type } = zoomFeatures || {};
  const isValid = map && map.fitBounds && data;
  if (!isValid) { return }

  // @todo: if drawing, dont zoom
  if (type === 'rrn') {
    zoomRrn(map, data, mapPadding, idToken);
  } else if (type === 'brn') {
    zoomBrn(map, data, mapPadding, idToken);
  } else if (type === 'bbox') {
    zoomBbox(map, data, mapPadding);
  } else {
    zoomFeature(map, data, mapPadding);
  }
} 

export function handleFloorChange(map, floor, dispatch, singleBuildingMode) {
  if (!map || !floor) { return; }
  setMapFilter(map, 'umich-floor', 'floor', ['==', 'floor', floor]);
  setMapFilter(map, 'umich-floor-active', 'floor', ['==', 'floor', floor]);
  setMapFilter(map, 'umich-room', 'floor', ['==', 'floor', floor]);
  setMapFilter(map, 'umich-room-avail', 'floor', ['==', 'floor', floor]);
  setMapFilter(map, 'umich-room-shared', 'floor', ['==', 'floor', floor]);
  setMapFilter(map, 'umich-room-select-1', 'floor', ['==', 'floor', floor]);
  setMapFilter(map, 'umich-room-select-2', 'floor', ['==', 'floor', floor]);
  setMapFilter(map, 'umich-room-select-3', 'floor', ['==', 'floor', floor]);
  setMapFilter(map, 'umich-room-select-4', 'floor', ['==', 'floor', floor]);
  setMapFilter(map, 'umich-room-select-5', 'floor', ['==', 'floor', floor]);
  setMapFilter(map, 'umich-room-select-intersect', 'floor', ['==', 'floor', floor]);
  setMapFilter(map, 'umich-room-select-symbol-1', 'floor', ['==', 'floor', floor]);
  setMapFilter(map, 'umich-room-draw-1', 'floor', ['==', 'floor', floor]);
  setMapFilter(map, 'umich-reference', 'floor', ['==', 'floor', floor]);
  setMapFilter(map, 'umich-room-label', 'floor', ['==', 'floor', floor]);
  setMapFilter(map, 'umich-room-active', 'rmrecnbr', ['in', 'rmrecnbr']);

  const customLayers = map.getStyle().layers.map(layer => layer.id).filter(id => id.indexOf('sheet') === 0);
  customLayers.forEach(layer => {
    if (layer.indexOf('sheet-point') === 0) {
      setPointFloorFilter(map, layer, floor);
    } else {
      setMapFilter(map, layer, 'floor', ['==', 'floor', floor]);
    }
  });

  // toggle markers
  const markers = getAllMarkers();
  for (let markerId in markers) {
    const marker = markers[markerId];
    const hide = marker && marker.properties && marker.properties.floor && marker.properties.floor !== floor;
    const singleBuildingHide = (
      marker &&
      marker.properties &&
      singleBuildingMode && 
      singleBuildingMode.bldrecnbr &&
      ( !marker.properties.bldrecnbr || marker.properties.bldrecnbr !== singleBuildingMode.bldrecnbr )
    );
    if (hide || singleBuildingHide) {
      marker.remove();
    } else {
      marker.addTo(map); 
    }    
  }

  // toggle drawn polygons visibility
  dispatch(actionDrawToggleVisiblePolygonsFromFloorChange(map, floor));
}

export function handlePrint(map, mapContainer, mapPopup, dispatch, print) {
  if (!map || !mapContainer || !mapContainer.current || isEmptyObj(print)) {
    return;
  }
  const { status } = print;
  const styleTag = getPrintStyleHTMLTag();

  if (status === 'print') {
    dispatch(actionMapPopupVisibilityUpdated(false));
    dispatch(actionAppHideAll());
    map.resize();
    setTimeout(() => { // initiate print
      mapPopup.remove();
      map.resize();    
      window.print();
    }, 100);
    setTimeout(() => { // print finished
      dispatch(actionAppVisibilityRestored());
      dispatch(actionPrintSizeUpdated(''));
      dispatch(actionMapPrintUpdated({status: 'cancel', size: '', orientation: 'landscape'}));
    }, 1000);
    return;
  }
  
  resetPrintMode(styleTag, document.body, mapContainer.current);
  map.resize();

  if (status === 'cancel') {
    // dispatch(actionMapPopupVisibilityUpdated(true));
    return;
  } else if (status === 'preview') {
    dispatch(actionMapPopupVisibilityUpdated(false));
    setStyleTagForPrintMode(styleTag, print);
    setBodyTagForPrintMode(document.body, print);
    setMapContainerForPrintMode(mapContainer.current, print);    
    map.resize();
    return;
  }
}

export const handleCursorChange = (map, cursor) => () => {
  if (!map || !cursor) { return }

  if (cursor.drawMode) {
    cursorCrosshair(map);
    return;
  }

  if (cursor.featureHover) {
    cursorPointer(map);
    return;
  }

  cursorReset(map);
}

export const handleDrawModeChange = (map, dispatch, drawMode) => () => {
  if (!map || !map.draw || !drawMode) { return }

  const { mode, data } = drawMode;
  const validModes = new Set([
    "draw_line_string",
    "draw_polygon",
    "draw_point",
    "simple_select",
    "draw_rectangle",
    "draw_freehand",
  ]);

  // direct_select throws err: Error: You must provide a featureId to enter direct_select mode
  if (mode === 'direct_select') { return }

  if (validModes.has(mode)) {
    map.draw.changeMode(mode);
    return;
  }

  if (mode === 'draw_circle') {
    const initialRadiusInKm = fivePctOfMapBound(map);
    map.draw.changeMode(mode, { initialRadiusInKm });
    return;
  }

  if (mode === 'draw_zoom_box') {
    map.draw.changeMode('draw_rectangle');
    return;
  }

  if (mode === 'delete') {
    if (!data) { return }
    if (typeof data === 'string') {
      removeMarkersForPointFeatures(data);
      map.draw.delete(data);
    }
    if (Array.isArray(data)) {
      removeMarkersForPointFeatures(data);
      data.forEach(id => map.draw.delete(id))
    }
    return;
  }

  // @todo: hide by groupId
  // @data: {featureGroup}
  if (mode === 'hide_feature_group') {
    const { features } = data;
    const featureIds = features.map(feat => feat.id);
    // hide draw features
    map.getStyle().layers.forEach(layer => {
      if (layer.id.indexOf('draw') !== -1) {
        map.setLayoutProperty(layer.id, 'visibility', 'none');
      }
    });
    // hide markers
    const markers = getAllMarkers();
    for (let markerId in markers) {
      if (featureIds.includes(markerId)) {
        markers[markerId].remove();
      }
    }
    // hide selected rooms
    dispatch(actionDataSelectFeatureRequested({ type: 'room-color', data: [], layerId: 'umich-room-draw-1' }));

    return;
  }

  // @todo: show by groupId
  // @data: {featureGroup}
  if (mode === 'show_feature_group') {
    const { features } = data;
    const featureIds = features.map(feat => feat.id);
    // show draw features
    map.getStyle().layers.forEach(layer => {
      if (layer.id.indexOf('draw') !== -1) {
        map.setLayoutProperty(layer.id, 'visibility', 'visible');
      }
    });
    // show markers
    const markers = getAllMarkers();
    for (let markerId in markers) {
      if (featureIds.includes(markerId)) {
        markers[markerId].addTo(map);
      }
    }
    // show selected rooms
    dispatch(actionDataSelectFeatureRequested({ type: 'room-color', data: features, layerId: 'umich-room-draw-1' }));
    return;
  }

  if (mode === 'room_picker') {
    return;
  }

  if (mode === 'group_room_picker') {
    const isStepTwo = data && data.length;
    if (!isStepTwo) {
      // step 1: draw rectangle over map
      map.draw.changeMode('draw_rectangle');
    } else {
      // step 2: query rooms below rectangle, store rooms
      // drawRedux will send back here with rectangle feature via var data
      const [feature] = data;
      const rooms = drawRectToRooms(map, feature) || []
      const visitedRooms = {};
      const uniqRooms = rooms.reduce((agg, room) => {
        let rmrecnbr = room && room.properties && room.properties.rmrecnbr;
        if (rmrecnbr && !visitedRooms[rmrecnbr]) {
          agg.push(room);
          visitedRooms[rmrecnbr] = true;
        }
        return agg;
      }, []);
      const features = uniqRooms.map(feature => {
        const properties = {...feature.properties, drawmode: 'group_room_picker'};
        const geometry = {...feature._geometry, ...feature.geometry}
        return {...feature, geometry, properties };
      });
      map.draw.changeMode('simple_select');
      dispatch(actionDrawUpdateGroupFeature('add', features));
      map.draw.delete(feature.id);
    }
  }
}

export const handleDrawFeaturesChange = (map, drawFeatures) => () => {
  if (!map || !map.draw || !drawFeatures) { return }

  // update draw feature propertiesi
  drawFeatures.forEach(feature => {
    const drawFeature = map.draw.get(feature.id);
    if (!drawFeature) { return }
    
    for (let property in feature.properties) {
      // only update properties that changed
      if (drawFeature.properties[property] === feature.properties[property]) { continue }
      map.draw.setFeatureProperty(feature.id, property, feature.properties[property]);

      // update marker
      if (property === 'color' && feature.geometry.type === 'Point') {
        removeMarkersForPointFeatures(feature.id);
        addMarker(map, feature);
      }
    }
  });

  // refresh draw layer to reflect current changes in color
  const collections = map.draw.getAll();
  map.draw.set(collections);

}

export const handleActiveFeatureChange = (map, activeFeature) => () => {
  if (!map || !map.draw || !activeFeature) { return }

  const { bldrecnbr, floor, rmrecnbr } = activeFeature;
  markActiveBuilding(map, bldrecnbr);
  markActiveFloor(map, bldrecnbr, floor);
  markActiveRoom(map, rmrecnbr);
}

export const handleSingleBuildingMode = (map, singleBuildingMode) => () => {
  if (!map || !singleBuildingMode) { return }

  if (singleBuildingMode && singleBuildingMode.bldrecnbr) { // single mode on
    hideBuildingsExcept(map, singleBuildingMode.bldrecnbr);
  } else if (singleBuildingMode && !singleBuildingMode.bldrecnbr) { // single mode off
    revertHideBuildingsExcept(map);
  }
}

export const handleIconAndLabelOffset = (map, label, highlightRoomsWithPhoto) => () => {
  if (!map || !map.getLayer('umich-room-label') || !map.getLayer('umich-room-select-symbol-1')) return;
  const labelBool = Object.values(label).some(e => e);
  if (highlightRoomsWithPhoto && labelBool) {
    map.setLayoutProperty('umich-room-label', 'text-anchor', 'bottom');
    map.setLayoutProperty('umich-room-select-symbol-1', 'icon-anchor', 'top');
  } else {
    map.setLayoutProperty('umich-room-label', 'text-anchor', 'center');
    map.setLayoutProperty('umich-room-select-symbol-1', 'icon-anchor', 'center');
  }
}

export const addAlternativeBasemap = (map) => {
  map.addLayer({
    id: 'satellite-background',
    type: 'background',
    layout: {
      'visibility': 'none'
    },
    paint: {
      'background-color': '#D3D3D3',
    },
  }, 'umich-building')
  map.addLayer({
    id: 'satellite-raster',
    source: {
        "type": "raster",                
       "tiles": ["https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/{z}/{x}/{y}?access_token=" + process.env.REACT_APP_MAPBOX_TOKEN],
       "tileSize": 512
    },
    type: "raster",
    paint: {
      'raster-opacity': 0.25
    },
    layout: {
      "visibility": "none"
    }
  }, 'umich-building');
}