import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import { actionDeleteAlert } from './AlertRedux';

const useStyles = makeStyles(theme => ({
  root: {
    zIndex: theme.zIndex.tooltip,
    position: 'fixed',
    left: '50%',
    transform: 'translateX(-50%)'
  },
  alert: {
    margin: 2,
    minWidth: 300,
  },
  actionButton: {
    color: 'white',
    border: '1px solid white',
    marginRight: 6,
  },
  close: {
    color: 'white',
    '& .MuiSvgIcon-root': {
      fontSize: '1.25rem',
    }
  }
}));

export default function AlertManager() {
  const classes = useStyles();
  const alerts = useSelector(state => state.alertReducer.alerts);

  return (
    <div className={classes.root}>
      {/* <Alert variant="filled" severity="success">
        Zoom Box — draw map
      </Alert> */}
      {alerts.map(alert => <AlertItem alert={alert} key={alert.id} />)}
    </div>
  );
}

function AlertItem({ alert = {} }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    id,
    title,
    severity,
    buttonLabel,
    onClick,
    onClose,
  } = alert;

  const onCloseHandler = () => {
    onClose && onClose();
    dispatch(actionDeleteAlert(id));
  }

  return (
    <Alert
      className={classes.alert}
      variant="filled"
      severity={severity || 'info'} 
      action={
        <span>
          {
            !!buttonLabel && !!onClick &&
            <Button size="small" className={classes.actionButton} onClick={onClick}>
              {buttonLabel}
            </Button>
          }
          {
            onClose !== false &&
            <IconButton size="small" className={classes.close} onClick={onCloseHandler}>
              <CloseIcon />
            </IconButton>
          }
        </span>
        }
    >
      {title}
    </Alert>
  );
}