import { createAction, handleActions } from 'redux-actions';
import { defaultState, getDefaultLayers, layerComponents } from './LayerData';
import axios from 'axios';
import Papaparse from 'papaparse';

export const initialState = {
  isExpanded: false,
  tabIndex: 1,
  floor: '01',
  graphics: graphicsInitialState(layerComponents),
  label: labelInitialState(layerComponents),
  expandLabel: initialExpandLabel(layerComponents),
  basemapSource: 'map',
  labelSettings: {
    fontSize: defaultState.labelPrimarySize,
    fontColor: defaultState.labelColor
  },
  color: {
    color: 'defaultcolor',
    shared: false,
    faavailable: false,
  },
  colorDef: {}, // color definition from cvs files in /public folder
  initialSourceAndLayers: {},
  layerChangeRequest: {}, // {id, type, element}
  singleBuildingMode: null, // { bldrecnbr }
};

export const actionLayerCompactToggled = createAction('LAYER_EXPANDED_TOGGLED');
export const actionDefaultLayersAdded = createAction('LAYERS_DEFAULT_ADDED');
export const actionLayerTabChanged = createAction('LAYER_TAB_CHANGED');
export const actionLayerChangeRequested = createAction('LAYER_CHANGE_REQUESTED');
export const actionLayerColorDefPopulated = createAction('LAYER_COLOR_DEF_POPULATED');
// user input
export const actionLayerGraphicsChanged = createAction('LAYER_GRAPHICS_CHANGED');
export const actionLayerLabelChanged = createAction('LAYER_LABEL_CHANGED');
export const actionLayerColorChanged = createAction('LAYER_COLOR_CHANGED');
export const actionLayerExpandLabelUpdated = createAction('LAYER_LABEL_EXPAND_UPDATED');
export const actionLayerLabelSettingsUpdated = createAction('LAYER_LABEL_SETTINGS_UPDATED');
export const actionLayerSingleBuildingModeUpdated = createAction('LAYER_SINGLE_BUILDING_MODE_UPDATED');

export const actionLayerPopulateColorDef = () => {
  return async (dispatch) => {
    const colorDef = await getColorDef();
    dispatch(actionLayerColorDefPopulated(colorDef));
  }
}

export const actionAddDefaultLayers = (tileUrl) => {
  return (dispatch) => {
    const sourceAndLayers = getSourceAndLayers(tileUrl);
    dispatch(actionDefaultLayersAdded(sourceAndLayers));
    dispatch(actionLayerLabelsUpdate());
  };
}

export const actionLayerLabelsUpdate = () => {
  return (dispatch, getState) => {
    // get saved settings from local storage
    const { label, labelSettings } = getState().layerReducer;
    
    // restore labels from local storage
    const labels = [];
    for (let attribute in label) {
      const data = {id: attribute, isVisible: label[attribute]};
      const layer = getLayerComponent(attribute);
      labels.push({ data, layer });
      dispatch(actionLayerLabelChanged(data)); // sync ui
    }
    
    // restore labels settings from local storage
    const { fontSize } = labelSettings;
    dispatch(actionLayerLabelSettingsUpdated({ fontSize })); // sync ui

    dispatch(actionLayerChangeRequested([
      {type: 'label', labels},
      {type: 'label-settings', field: 'fontSize', value: fontSize}
    ]));
  }
}

export const actionLayerUpdate = (update) => {
  return (dispatch, getState) => {
    const { colorDef } = getState().layerReducer;
    const { type, data } = update;
    const layer = data && data.id && getLayerComponent(data.id);
    if (type === 'graphic') {
      dispatch(actionLayerChangeRequested({ type, data, layer }));
      dispatch(actionLayerGraphicsChanged(data));
    } else if (type === 'label') {
      dispatch(actionLayerChangeRequested({ type, data, layer }));
      dispatch(actionLayerLabelChanged(data));
    } else if (type === 'color') {
      const targetColorDef = colorDef[layer.mapKey];
      dispatch(actionLayerChangeRequested({ type, data, layer, targetColorDef }));
      dispatch(actionLayerColorChanged({ color: data.id, faavailable: data.faavailable, shared: data.shared}));
    } else if (type === 'label-settings') {
      dispatch(actionLayerChangeRequested({ type, field: update.field, value: update.value }));
      dispatch(actionLayerLabelSettingsUpdated({ [update.field]: update.value }));
    } else if (type === 'room-select') {
      dispatch(actionLayerChangeRequested({ type, id: update.id, field: update.field, value: update.value }));
    }
  }
}

export const layerReducer = handleActions({
  [actionDefaultLayersAdded]: (state, action) => {
    return { ...state, initialSourceAndLayers: action.payload };
  },
  [actionLayerCompactToggled]: (state, action) => {
    const isExpanded = action.payload === null || action.payload === undefined ? !state.isExpanded : action.payload;
    return { ...state, isExpanded };
  },
  [actionLayerTabChanged]: (state, action) => {
    return { ...state, tabIndex: action.payload };
  },
  [actionLayerGraphicsChanged]: (state, action) => {
    const { id, mode, basemapSource } = action.payload;
    return { ...state, 
      graphics: {
        ...state.graphics,
        [id]: mode, 
      },
      basemapSource
    };
  },
  [actionLayerLabelChanged]: (state, action) => {
    const { id, isVisible } = action.payload;

    if (id.indexOf('facultyallocation') !== -1) {
      const {
        facultyallocation,
        facultyallocationoffices,
        facultyallocationlabs,
        facultyallocationothers
      } = facultyallocationLabelLogic(state, id, isVisible);
      return {
        ...state,
        label: {
          ...state.label,
          facultyallocation,
          facultyallocationoffices,
          facultyallocationlabs,
          facultyallocationothers,
        }
      }
    }

    return { ...state, label: {
      ...state.label,
      [id]: isVisible,
    }}
  },
  [actionLayerColorChanged]: (state, action) => {
    return { ...state, color: action.payload };
  },
  [actionLayerChangeRequested]: (state, action) => {
    return { ...state, layerChangeRequest: action.payload };
  },
  [actionLayerColorDefPopulated]: (state, action) => {
    return { ...state, colorDef: action.payload };
  },
  [actionLayerExpandLabelUpdated]: (state, action) => {
    const expand = !state.expandLabel[action.payload];
    const expandLabel = {...state.expandLabel, [action.payload]: expand };
    return { ...state, expandLabel };
  },
  [actionLayerLabelSettingsUpdated]: (state, action) => {
    const labelSettings = {...state.labelSettings, ...action.payload };
    return { ...state, labelSettings };
  },
  [actionLayerSingleBuildingModeUpdated]: (state, action) => {
    const singleBuildingMode = { bldrecnbr: action.payload };
    return { ...state, singleBuildingMode };
  },
}, initialState);

export const selectorLayerIntialSourceLayers = state => state.layerReducer.initialSourceAndLayers;
export const selectorLayerChangeRequest = state => state.layerReducer.layerChangeRequest;
export const selectorLayerSingleBuildingMode = state => state.layerReducer.singleBuildingMode;

// utils

/**
 * @return { 
  *  layerId1: 'auto',
  *  layerId2: 'hide' 
  * } 
  */
 function graphicsInitialState(layers) {
   return layers
     .filter(layer=>layer.type==='graphics')
     .reduce((accumulator, layer) => {
       const { id } = layer;
       if (id === 'basemap') {
        accumulator[id] = 'show';
       } else {
        accumulator[id] = 'auto';
       }
       return accumulator;
     }, {});
}

/**
 * @return { 
  *  layerId1: true,
  *  layerId2: false 
  * } 
  */
function labelInitialState(layers) {
  return layers
    .filter(layer => layer.type==='label')
    .reduce((accumulator, layer) => {
      const { id, isVisible } = layer;
      accumulator[id] = isVisible;
      return accumulator;
    }, {});
}

function initialExpandLabel(layers) {
  return layers
    .filter(layer => layer.type==='label' && layer.isParent)
    .reduce((accumulator, layer) => {
      const { id } = layer;
      accumulator[id] = false;
      return accumulator;
    }, {});
}

export function getLayerComponent(layerId) {
  return layerComponents.find(component => component.id === layerId);
}

async function queryFile(file, callback) {
  try {
    const response = await axios.get(file);
    return Papaparse.parse(response.data);
  } catch (error) {
    console.error(error);
  }
}

function papaParseToColorMapper(parsed) {
  const data = parsed.data
    .filter((row, index) => index !== 0 && row.length === 5)
    .map(row => [row[0], 'rgb(' + row[2] + ',' + row[3] + ',' + row[4] + ')']);
  return data;
}

async function getColorDef() {
  const rmtypeQuery = await queryFile('rmtyp_def.csv');
  const roomTypeDef = {    
    "property": "rmtyp",    
    "type": "categorical",    
    "stops": papaParseToColorMapper(rmtypeQuery),
    "default": "#FFF"    
  };

  const surveyDeptQuery = await queryFile('surveydept_def.csv');
  const surveydeptDef = {    
    "property": "deptid",    
    "type": "categorical",    
    "stops": papaParseToColorMapper(surveyDeptQuery),
    "default": "#FFF"    
  };

  return {
    rmtyp: roomTypeDef,
    surveydept: surveydeptDef,
    default: defaultState.roomColorDefault,
    faavailable: defaultState.roomColorAvail,
    shared: defaultState.roomColorShared,    
  };
}

function getSourceAndLayers(tileUrl) {
  const defaultLayers = getDefaultLayers(tileUrl);

  return {
    sources: [{
        name: 'campus-building',
        data: defaultLayers.sourceBuilding
      },{
        name: 'campus-floor',
        data: defaultLayers.sourceFloor
      },{
        name: 'campus-room',
        data: defaultLayers.sourceRoom
      },{
        name: 'campus-reference',
        data: defaultLayers.sourceReference
      },{
        name: 'campus-label',
        data: defaultLayers.sourceLabel
      }
    ],
    layers: [
      defaultLayers.building,
      defaultLayers.buildingSelect,
      defaultLayers.buildingActive,
      defaultLayers.buildingLabel,
      defaultLayers.floor,
      defaultLayers.floorActive,
      defaultLayers.room,
      defaultLayers.roomAvail,
      defaultLayers.roomShared,
      defaultLayers.roomSelect1,
      defaultLayers.roomSelect2,
      defaultLayers.roomSelect3,
      defaultLayers.roomSelect4,
      defaultLayers.roomSelect5,
      defaultLayers.roomSelectIntersect,
      defaultLayers.roomSelectSymbol1,
      defaultLayers.roomDraw1,
      defaultLayers.roomActive,
      defaultLayers.roomLabel,
      defaultLayers.reference,        
    ]
  };
}

function facultyallocationLabelLogic(state, id, isVisible) {
  const { 
    facultyallocationlabs: prevLabs, 
    facultyallocationoffices: prevOffices, 
    facultyallocationothers: prevOthers 
  } = state.label;
  let facultyallocation, facultyallocationoffices, facultyallocationlabs, facultyallocationothers;

  if (id === 'facultyallocation') {
    if (isVisible) {
      facultyallocation = true;
      facultyallocationoffices = true;
      facultyallocationlabs = true;
      facultyallocationothers = true;
    } else {
      facultyallocation = false;
      facultyallocationoffices = false;
      facultyallocationlabs = false;
      facultyallocationothers = false;
    }
  }

  if (id === 'facultyallocationlabs') {
    facultyallocationothers = prevOthers;
    facultyallocationoffices = prevOffices;

    if (isVisible) {
      facultyallocation = prevOffices && prevOthers || "indeterminate";
      facultyallocationlabs = true;
    } else {
      facultyallocation = (prevOffices || prevOthers) ? "indeterminate" : false;
      facultyallocationlabs = false;
    }
  }

  if (id === 'facultyallocationoffices') {
    facultyallocationlabs = prevLabs;
    facultyallocationothers = prevOthers;
    
    if (isVisible) {
      facultyallocation = prevLabs && prevOthers || "indeterminate";
      facultyallocationoffices = true;
    } else {
      facultyallocation = (prevLabs || prevOthers) ? "indeterminate" : false;
      facultyallocationoffices = false;
    }
  }

  if (id === 'facultyallocationothers') {
    facultyallocationoffices = prevOffices;
    facultyallocationlabs = prevLabs;

    if (isVisible) {
      facultyallocation = prevLabs && prevOffices || 'indeterminate';
      facultyallocationothers = true;
    }
    else {
      facultyallocation = (prevLabs || prevOffices) ? 'indeterminate' : false;
      facultyallocationothers = false;
    }
  }

  return {
    facultyallocation,
    facultyallocationoffices,
    facultyallocationlabs,
    facultyallocationothers
  }
}