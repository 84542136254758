import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { isEmpty, min } from 'lodash';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Popover from '@material-ui/core/Popover';
import ListItem from '@material-ui/core/ListItem';
import { actionAppDateUpdated } from '../App/AppRedux';

const LAST_UPDATE_URL = `${process.env.REACT_APP_API_BASE_URL}/lastupdated`;
const USERLIST_URL = `${process.env.REACT_APP_API_BASE_URL}/userlist`;

const useStyles = makeStyles(theme => ({
  date: {
    fontSize: 12,
    fontStyle: 'italic'
  },
  type: {
    fontSize: 12,
  },
  container: {
    marginTop: 'auto',
    width: '20vw',
    minWidth: '260px',
  },
  popover: {
    width: 'calc(20vw - 32px)',
    minWidth: 'calc(260px - 32px)',
    padding: '10px',
  }
}))

export default function MenuStatus() {
  const dispatch = useDispatch();
  const status = useSelector(state => state.appReducer.lastUpdated);
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();
  const dataFetched = !isEmpty(status) && formatDate(status.data_fetched_at);
  const tileGenerated = !isEmpty(status) && formatDate(status.tile_generated_at);
  const listRetrieved = !isEmpty(status) && formatDate(status.list_retrieved_at);
  const dateStr = getEarliestDate(status);

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  useEffect(() => {
    if (isEmpty(status)) {
      setStatus(dispatch, actionAppDateUpdated);
    }
  }, [status]);

  return (
    <div className={classes.container}>
      { isEmpty(status) ? '' : (
        <>
          <ListItem button onClick={handleClick}>
            <Grid container  >
              <Grid container direction="row" justifyContent="space-between">
                <Grid item>
                  <Typography variant="body2" className={classes.type} >Last Updated:</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2" className={classes.date} >{dateStr}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </ListItem>
          <Popover
            id={id}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            classes={{paper: classes.popover,}}
          >          
            <Grid container>
              <Grid container direction="row" justifyContent="space-between">
                <Grid item>
                  <Typography variant="body2" className={classes.type} >Data Fetched:</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2" className={classes.date} >{dataFetched}</Typography>
                </Grid>
              </Grid>
              <Grid container direction="row" justifyContent="space-between">
                <Grid item>
                  <Typography variant="body2" className={classes.type} >Tiles Generated:</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2" className={classes.date} >{tileGenerated}</Typography>
                </Grid>
              </Grid>
              <Grid container direction="row" justifyContent="space-between">
                <Grid item>
                  <Typography variant="body2" className={classes.type} >User List Retrieved:</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2" className={classes.date} >{listRetrieved}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Popover>
        </>
        )}
    </div>
  );
}

function setStatus(dispatch, actionAppDateUpdated) {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const tileAndDataPromise = axios.get(LAST_UPDATE_URL, { cancelToken: source.token });
  const userListPromise = axios.get(USERLIST_URL, { cancelToken: source.token });

  Promise.all([tileAndDataPromise, userListPromise])
    .then(responses => {
      const [tileAndDataRes, userListRes] = responses;
      if (
        tileAndDataRes && tileAndDataRes.data && tileAndDataRes.data.data_fresh && tileAndDataRes.data.data_fetched_at &&
        tileAndDataRes && tileAndDataRes.data && tileAndDataRes.data.tile_fresh && tileAndDataRes.data.tile_generated_at &&
        userListRes && userListRes.data && userListRes.data.list_fresh && userListRes.data.list_retrieved_at
        ) {
          dispatch(actionAppDateUpdated({
            data_fetched_at: tileAndDataRes.data.data_fetched_at,
            tile_generated_at: tileAndDataRes.data.tile_generated_at,
            list_retrieved_at: userListRes.data.list_retrieved_at
          }));
      }
    })
    .catch(function(err) {
      return;
    });
}

function formatDate(utcString) {
  const date = new Date(utcString);
  return `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`;
}

function getEarliestDate(status) {
  const earliestDate = !isEmpty(status) && min([new Date(status.data_fetched_at), new Date(status.tile_generated_at), new Date(status.list_retrieved_at)]);
  return !isEmpty(status) && `${earliestDate.getMonth() + 1}-${earliestDate.getDate()}-${earliestDate.getFullYear()}`
}