import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import BusinessIcon from '@material-ui/icons/Business';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import Chip from '@material-ui/core/Chip';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import FormHelperText from '@material-ui/core/FormHelperText';
import { actionSheetFileUpdated, selectorSheets } from './spreadsheetRedux';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
    maxWidth: 120,
    width: 120,
  },
  chip: {
    marginTop: 5,
    padding: 5
  }
}));


export default function TablePreview({ id, toggleCollapse}) {
  const [point, setPoint ] = useState(true);
  const [room, setRoom] = useState(true)
  const [rmrecnbr, setRmrecnbr] = useState('');
  const [lat, setLat] = useState('');
  const [lng, setLng] = useState('')
  const classes = useStyles();

  const dispatch = useDispatch();
  const { json } = useSelector(selectorSheets).find(sheet => sheet.id === id);
  const [header] = json;

  const handleSubmit = () => {
    const lngVal = point ? lng : null;
    const latVal = point ? lat : null;
    const rrnVal = room ? rmrecnbr : null;
    const type = room && point ? 'dual' : (point ? 'point' : 'room');

    dispatch(actionSheetFileUpdated({
      id,
      rmrecnbr: rrnVal,
      lnglat: [lngVal, latVal],
      type
    }));
    toggleCollapse();
  };

  return (
    <Box display="flex" justifyContent="space-between">
      <Box display="flex" flexDirection="column" justifyContent="space-evenly">
        <Typography>Data Types</Typography>
        <TypeChip
          label='Room'
          value={room}
          clickFunction={setRoom}
          icon={<BusinessIcon />}
        />
        <TypeChip
          label='Point'
          value={point}
          clickFunction={setPoint}
          icon={<LocationOnIcon />}
        />
      </Box>
      <Box display='flex' justifyContent={'space-evenly'} flexGrow={1}>
      <Box display="flex" flexDirection="column" justifyContent='space-evenly' className={classes.formControl} >
        <InputLabel
          disabled={!room}
          id='rmrecnbr-label'
        >
          Rmrecnbr
        </InputLabel>
        <Select
          labelId='rmrecnbr-label'
          id='rmrecnbr'
          value={rmrecnbr}
          onChange={(e) => setRmrecnbr(e.target.value)}
          disabled={!room}
          // variant="outlined"
        >
          {header.map((item, i) => (
            <MenuItem key={i} value={i}>{item}</MenuItem>
            ))}
        </Select>
        {!room ? <FormHelperText disabled >Select room type to activate</FormHelperText> : <FormHelperText>Select header column</FormHelperText>}
      </Box>
      <Box className={classes.formControl} display="flex" flexDirection="column" justifyContent='space-evenly'>
        <InputLabel
          disabled={!point}
          id='lng-label'
        >Longitude</InputLabel>
        <Select
          labelId='lng-label'
          id='lng'
          value={lng}
          onChange={(e) => setLng(e.target.value)}
          disabled={!point}
          // variant="outlined"
        >
          {header.map((item, i) => (
            <MenuItem key={i} value={i}>{item}</MenuItem>
            ))}
        </Select>
        {!point ? <FormHelperText disabled >Select point type to activate</FormHelperText> : <FormHelperText>Select header column</FormHelperText>}
      </Box>
      <Box className={classes.formControl} display="flex" flexDirection="column" justifyContent='space-evenly'>
        <InputLabel
          disabled={!point}
          id='lat-label'
        >Latitude</InputLabel>
        <Select
          labelId='lat-label'
          id='lat'
          value={lat}
          onChange={(e) =>setLat(e.target.value)}
          disabled={!point}
          // variant="outlined"
        >
          {header.map((item, i) => (
            <MenuItem key={i} value={i}>{item}</MenuItem>
            ))}
        </Select>
        {!point ? <FormHelperText disabled >Select Point Type to Activate</FormHelperText> : <FormHelperText>Select header column</FormHelperText>}
      </Box>
      </Box>
      <Box display="flex" flexDirection="column" justifyContent="center" >
        <Button
          variant="contained"
          color="secondary"
          onClick={handleSubmit}
        >Submit</Button>
      </Box>
    </Box>
  );
};

function TypeChip({label, value, clickFunction, icon}) {
  const classes = useStyles();

  return (
    <Chip
      className={classes.chip}
      label={label}
      color="primary"
      size="small"
      variant={value ? 'default' : 'outlined'}
      icon={icon}
      deleteIcon={value ? <CheckCircleIcon /> : <RadioButtonUncheckedIcon />}
      onClick={() => clickFunction(!value)}
      onDelete={() => clickFunction(!value)}
    />
  );

}