import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Paper from '@material-ui/core/Paper';

import LocationOnIcon from '@material-ui/icons/LocationOn';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import ColorLensIcon from '@material-ui/icons/ColorLens';

import { ColorTab, TextTab, IconTab, PointTab } from './SpreadsheetCardSettingsTabs';
import { CollapseHeading } from './SpreadsheetSettingSharedComponents';
import { actionSheetFileUpdated, selectorSheet } from './spreadsheetRedux';

const useStyles = makeStyles(theme => ({
  sectionTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 10,
    fontWeight: 500,
    '&:hover': {
      cursor: 'pointer',
    },
    '& :hover': {
      cursor: 'pointer',
    },
    '& button': {
      padding: 0,
      marginLeft: 4,
    },
  },
  tab: {
    minWidth: '20%',
    flexGrow: 1,
  },
  indicator: {
    height: 5,
    backgroundColor: 'var(--fill-color)',
  },
  tabPanel: {
    paddingTop: 0,
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 10
  }
}));

const TabPanel = ({ children, value, section, ...other }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== section}
      id={`wrapped-tabpanel-${section}`}
      aria-labelledby={`wrapped-tab-${section}`}
      {...other}
    >
      {value === section && (
        <Box pt={2}>{children}</Box>
      )}
    </div>
  );
}

export default function SpreadsheetCardSettings({ sheetId }) {
  const classes = useStyles();
  const { settingsExpanded, settingsTab, type } = useSelector(selectorSheet(sheetId));
  const dispatch = useDispatch();

  const handleTabChange = (_event, newValue) => {
    dispatch(actionSheetFileUpdated({
      id: sheetId,
      settingsTab: newValue
    }));
  };

  const handleSettingsExpand = () => {
    dispatch(actionSheetFileUpdated({
      id: sheetId,
      settingsExpanded: !settingsExpanded
    }))
  }

  useEffect(() => {
    if (type === 'point') {
      dispatch(actionSheetFileUpdated({
        id: sheetId,
        settingsTab: 'point'
      }));
    }
  }, [type])

  return (
    <>
      <CollapseHeading
        headingClass={classes.sectionTitle}
        expanded={settingsExpanded}
        setExpanded={handleSettingsExpand}
        headingText="Settings"
      />
      <Collapse in={settingsExpanded}>
        <Paper>
        <Tabs
          classes={{
            root: classes.tabs,
            indicator: classes.indicator
          }}
          value={settingsTab}
          onChange={handleTabChange}
        >   
          <Tab 
            className={classes.tab}
            icon={<TextFieldsIcon />}
            value="text"
            label="Text"
            disabled={type === 'point'}
          />
          <Tab
            className={classes.tab}
            icon={<ColorLensIcon />}
            value="color"
            label="Color"
            disabled={type === 'point'}
          />
          <Tab
            className={classes.tab}
            icon={<LocationOnIcon />}
            value="icon"
            label="Room Icon"
            disabled={type === 'point'}
          />
          <Tab
            className={classes.tab}
            icon={<LocationOnIcon />}
            value="point"
            label="Point"
            disabled={type === 'room'}
          />
        </Tabs>
        <TabPanel className={classes.tabPanel} value={settingsTab} section="text">
          <TextTab sheetId={sheetId} />
        </TabPanel>
        <TabPanel className={classes.tabPanel} value={settingsTab} section="color">
          <ColorTab sheetId={sheetId} />
        </TabPanel>
        <TabPanel className={classes.tabPanel} value={settingsTab} section="icon">
          <IconTab sheetId={sheetId} />
        </TabPanel>
        <TabPanel className={classes.tabPanel} value={settingsTab} section="point">
          <PointTab sheetId={sheetId} />
        </TabPanel>
        </Paper>
      </Collapse>
    </>
  );
};