import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { actionAppToggleComponent } from '../App';
import {
  actionLegendExpandToggled,
} from './legendRedux';
import Accordion from '../Shared/Accordion';

export default function Legend() {
  const component = 'legendVisible';
  const isVisible = useSelector(state=>state.appReducer[component]);
  const isExpanded = useSelector(state=>state.legendReducer.isExpanded);
  const dispatch = useDispatch();

  const visibleHandler = () => {
    dispatch(actionAppToggleComponent({ component }));
  }

  const expandHandler = () => {
    dispatch(actionLegendExpandToggled());
  }
  
  return (
    <Accordion
      title='Legend'
      visible={isVisible}
      visibleHandler={visibleHandler}
      expand={isExpanded}
      expandHandler={expandHandler}
    >
      <div> Work in progress </div>
    </Accordion>
  );
}