import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import CropRotateIcon from '@material-ui/icons/CropRotate';
import clsx from 'clsx';
import Grid from './Grid';
import { actionMapMapviewChangeRequested } from '../Map/MapRedux';
import { actionAddAlert, actionDeleteAlert } from '../Alert';
import Tooltip from '@material-ui/core/Tooltip';
import { trackEvent, MAP_TOOLS } from '../../tracking';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: 2,
    display: 'flex',
    flexDirection: 'column',
    '&:hover': {
      '& button': {
        borderRadius: 0,    
      }
    },
  },
  widget: {
    width: 42,
    minWidth: 'unset',
    backgroundColor: '#ffffff',
    border: '1px solid #f0f0f0',
    fontSize: '0.7rem',
    [theme.breakpoints.down('xs')]: {
      width: 34,
      '& .MuiSvgIcon-root': {
        fontSize: '1.25rem',
      },
    },
  },
  sharpBtmEdges: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,  
  },
  active: {
    backgroundColor: '#4555b5',
    color: 'white',
  },
}));

export default function Rotate() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [expand, setExpand] = useState(false);
  const [gridVisible, setGridVisible] = useState(false);

  const handlePopoverOpen = () => { setExpand(true) }
  const handlePopoverClose = () => { setExpand(false) }
  const toggleGridVisisble = () => { setGridVisible(!gridVisible) }

  const resetRotationAndPitch = () => {
    dispatch(actionMapMapviewChangeRequested({
      bearing: 0,
      pitch: 0,
    }));
  }

  useEffect(() => {
    const toggleGridVisisble = () => { setGridVisible(!gridVisible) }

    const resetRotationAndPitch = () => {
      dispatch(actionMapMapviewChangeRequested({
        bearing: 0,
        pitch: 0,
      }));
    } 

    if (gridVisible) {
      dispatch(actionAddAlert({
        id: 'rotation',
        title: 'Rotate Map - Adjust Sliders to Rotate and Tilt',
        buttonLabel: 'RESET',
        onClick: resetRotationAndPitch,
        onClose: toggleGridVisisble,
      }));
      trackEvent({
        category: MAP_TOOLS,
        action: 'Toggle Rotate Grid'
      });
    } else {
      dispatch(actionDeleteAlert('rotation'));
    }
  }, [dispatch, gridVisible])



  return (
    <React.Fragment>
      <div
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        className={classes.root}>
        <Tooltip title="Rotate Map (CTRL + Drag Mouse)">
          <Button
            variant="contained"
            className={clsx(
              classes.widget,
              classes.rotateBtn,
              {
                [classes.active]: gridVisible,
                [classes.sharpBtmEdges]: expand
              }
            )}
            onClick={toggleGridVisisble}
            onDoubleClick={resetRotationAndPitch}
          >
            <CropRotateIcon />
          </Button>
        </Tooltip>
      </div>
      <Grid visible={gridVisible} />
    </React.Fragment>

  );
}
