import React, { useState, useEffect, useRef } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from '@material-ui/core/styles';
// import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
// import DialogActions from '@material-ui/core/DialogActions';

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    pointerEvents: 'none',
    '& .MuiBackdrop-root': {
      backgroundColor: 'transparent',
    },
    '& .MuiDialog-container': {
      justifyContent: 'flex-start',
      alignItems: 'flex-end',
    },
  },
  closeIconBtn: {
    position: 'absolute !important',
    top: 0,
    right: 0,
    [theme.breakpoints.down('xs')]: {
      top: 5,    
      right: 5,
      '& .MuiSvgIcon-root': {
        fontSize: '1.7rem',
      }
    }
  },
  btnContainer: {
    justifyContent: 'space-around',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'space-evenly',
      padding: 0,
    }
  },
  paper: {
    pointerEvents: 'auto',
    margin: 0,
    maxHeight: '100vh',
    width: '45vw',
    minWidth: 335,
    maxWidth: 600,
    [theme.breakpoints.down('md')]: {
      width: '80vw',
    },
    [theme.breakpoints.down('sm')]: {
      width: '90vw',
    },
    [theme.breakpoints.down('xs')]: {
      width: '95vw',
    },
    '& .MuiDialogContent-root': {
      padding: 10,
      display: 'flex',
      justifyContent: 'center',
      [theme.breakpoints.down('xs')]: {
        padding: 2,
      }
    }
  }
}));

export default function Menu({ children, visible, selectHandler, onCloseHandler, menuType, offset }) {
  const descriptionElementRef = useRef(null);
  const classes = useStyles();

  const [open, setOpen] = useState(visible);
  const handleClose = () => {
    setOpen(false);
    if (onCloseHandler) {
      onCloseHandler(menuType || '');
    }
  };

  const [heightOffset, setHeightOffset] = useState((offset || 0) + 'px');
  useEffect(() => {
    setHeightOffset((offset || 0) + 'px');
  }, [offset]);

  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  useEffect(() => {
    setOpen(visible);
  }, [visible]);

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll='paper'
        // disableBackdropClick={true}
        classes={{
          root: classes.dialogRoot,
          paper: classes.paper,
        }}
        PaperProps={{
          style: {
            height: `calc(100% - ${heightOffset})`,
          },
        }}
      >
        <DialogContent dividers={true}>
            {React.cloneElement(children, { open, selectHandler })}
            <IconButton className={classes.closeIconBtn} onClick={handleClose} >
              <CloseIcon />
            </IconButton>
        </DialogContent>
        {/* <DialogActions className={classes.btnContainer}>
          <Button variant="contained" onClick={handleClose} color="secondary">
            Close
          </Button>
          <Button variant="contained" onClick={handleClose} color="primary">
            Search
          </Button>          
        </DialogActions> */}
      </Dialog>
    </React.Fragment>
  );
}