import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import GetAppIcon from '@material-ui/icons/GetApp';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Typography from '@material-ui/core/Typography';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import LaunchIcon from '@material-ui/icons/Launch';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { actionLayerSingleBuildingModeUpdated } from '../Layer';
import { actionAddAlert, actionDeleteAlert } from '../Alert';
import DownloadDialog from './DownloadDialog';
import PDFDownloadDialog from './PDFDownloadDialog';
import { actionPhotoPaneToggled, actionPhotoGetTargetRoom } from '../Photo';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { INFO, trackEvent } from '../../tracking';

const useActionBtnStyles = makeStyles(theme => ({
  expand: {
    position: 'relative',
    top: 8,
    color: 'rgba(0,0,0,0.6)',
    '&:hover': {
      cursor: 'pointer',
    }
  },
  iconButton: {
    margin: 2,
  },
  faIconButton: {
    margin: 2,
    '& svg': {
      marginRight: 8,
    },
  },
  moreBtn: {
    position: 'absolute',
    top: 8,
    right: 0
  }
}));

export default function RoomActions({ room }) {
  const classes = useActionBtnStyles();
  const dispatch = useDispatch();

  const {
    rmrecnbr, rmnbr,
    bld_descrshort, bldrecnbr,
    floor,
  } = room;

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => { setAnchorEl(event.currentTarget) }
  const handleClose = () => { setAnchorEl(null) }

  const [downloadVisible, setDownloadVisible] = useState(false);
  const handleDownloadClick = () => {
    setDownloadVisible(true);
    handleClose();
  };
  const handleDownloadClose = () => { setDownloadVisible(false) };

  const quitMode = () => {
    dispatch(actionLayerSingleBuildingModeUpdated(null));
    dispatch(actionDeleteAlert('sbv'));  
  }
  const singleBuildingMode = useSelector(state => state.layerReducer.singleBuildingMode);
  const checked = !!singleBuildingMode && !!singleBuildingMode.bldrecnbr;

  const singleBuildingModeClickHandler = () => {
    if (checked) {
      dispatch(actionLayerSingleBuildingModeUpdated(null));
      dispatch(actionDeleteAlert('sbv'));
    } else {
      dispatch(actionLayerSingleBuildingModeUpdated(bldrecnbr));
      dispatch(actionAddAlert({
        id: 'sbv',
        title: 'Single Building View',
        buttonLabel: 'QUIT',
        onClick: quitMode,
        onClose: false
      }));
    }
  }

  const togglePhoto = () => {
    dispatch(actionPhotoPaneToggled(true));
    dispatch(actionPhotoGetTargetRoom(room));
    handleClose();
    trackEvent({
      category: INFO,
      action: 'Photo',
    });
  };

  const [roomUrlTooltip, setRoomUrlTooltip] = useState('');
  const onCopyHandler = () => {
    setRoomUrlTooltip('COPIED');
    setTimeout(() => { setRoomUrlTooltip('') }, 1000);
  }

  const [pdfDownloadVisible, setPdfDownloadVisible] = useState(false);
  const handlePdfDownloadClick = () => {
    setPdfDownloadVisible(true);
    handleClose();
  };
  const handlePdfDownloadClose = () => { setPdfDownloadVisible(false) };

  const mpathwayLink = `https://finprod.dsc.umich.edu/psp/finprod/EMPLOYEE/ERP/c/M_SM_SPACE.M_SM_SVY.GBL?M_SM_BLDRECNBR=${bldrecnbr}&M_SM_FLOOR=${floor}&M_SM_RMNBR=${rmnbr}&SETID=UMICH&PAGE=M_SM_SVY`;
  const handleEventTracking = () => {
    trackEvent({
      category: INFO,
      action: 'M-Pathways'
    });
  }

  return (
    <React.Fragment>
      <Tooltip title="More Options">
        <IconButton size="small" className={classes.moreBtn} onClick={handleClick}>
          <MoreVertIcon />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleDownloadClick}>
          <ListItemIcon><GetAppIcon fontSize="small" /></ListItemIcon>
          <Typography variant="overline">Data</Typography>    
        </MenuItem>
        <MenuItem onClick={handlePdfDownloadClick}>
          <ListItemIcon><PictureAsPdfIcon fontSize="small" /></ListItemIcon>
          <Typography variant="overline">Floor Plan</Typography>    
        </MenuItem>
        <MenuItem onClick={togglePhoto}>
          <ListItemIcon><PhotoCameraIcon fontSize="small" /></ListItemIcon>
          <Typography variant="overline">Photo</Typography>    
        </MenuItem>
        <Tooltip title={roomUrlTooltip}>
          <CopyToClipboard text={`${window.location.href}#feature=search&rrn=${rmrecnbr}`} onCopy={onCopyHandler}>
            <MenuItem>
                <ListItemIcon><FileCopyIcon fontSize="small" /></ListItemIcon>
                <Typography variant="overline">Room URL</Typography>    
            </MenuItem>
          </CopyToClipboard>
        </Tooltip>
        <MenuItem onClick={handleClose}>
          <ListItemIcon><LaunchIcon fontSize="small" /></ListItemIcon>
          <Typography variant="overline">
            <a onClick={handleEventTracking} href={mpathwayLink} target="_blank" rel="noopener noreferrer">M-Pathways</a>
          </Typography>    
        </MenuItem>
        <MenuItem onClick={singleBuildingModeClickHandler}>
          <ListItemIcon>
            {
              checked ?
                <CheckBoxIcon fontSize="small" /> :
                <CheckBoxOutlineBlankIcon fontSize="small" />
            }
          </ListItemIcon>
          <Typography variant="overline">Single Building</Typography>   
        </MenuItem>
      </Menu>
      <DownloadDialog
        open={downloadVisible}
        onClose={handleDownloadClose}
        bldrecnbr={bldrecnbr}
        name={bld_descrshort}
        floor={floor}
        />
      <PDFDownloadDialog
        open={pdfDownloadVisible}
        onClose={handlePdfDownloadClose}
        bldrecnbr={bldrecnbr}
        name={bld_descrshort}
        floor={floor}
        />
    </React.Fragment>
  );
}