import React, { useState, useEffect } from 'react';
import { useDispatch } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import _ from 'lodash';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import { actionMapZoomFeaturesUpdated, actionMapUpdateActiveFeature } from '../Map';
import { actionAppBarFloorUpdated } from '../AppBar';
import { floorSorter } from '../AppBar/Floor';

const useStyles = makeStyles(theme => ({
  root: {
    maxHeight: 115,
    padding: '8px 0',
    borderTop: '1px solid rgba(0,0,0,0.1)',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      webkitAppearance: 'none',
      width: 7,
      backgroundColor: 'rgba(0, 0, 0, 0.1)',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 2,
      backgroundColor: 'rgba(0, 0, 0, .5)',
      webkitBoxShadow: '0 0 1px rgba(255, 255, 255, .5)',
    }
  },
  gridContent: {
    alignSelf: 'center',
    '& .MuiTypography-overline': {
      lineHeight: '24px',
      height: 24,
      fontSize: '0.7rem',
    },
    '& .MuiButton-root': {
      fontSize: '0.7rem',
    }
  },
  accordionBtn: {
    padding: '0 4px',
    minWidth: 0,
    '&:hover': {
      textDecoration: 'underline',
    }
  },
  compact: {
    padding: 8,
  },
  cardActionRoot: {
    padding: 0,
  },
  row: {
    lineHeight: 1,
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
      color: 'black',
    }
  },
}));

export default function BuildingFloorsDisplay({ id, rooms, sortBy, sortDirection }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const rows = toRows(id, rooms);
  const buildings = sortRows(rows, sortBy, sortDirection);
  const [firsBuilding] = buildings;
  const firstFloor = firsBuilding && firsBuilding.floors && firsBuilding.floors.length &&
                     firsBuilding.floors.indexOf('01') === -1 ? firsBuilding.floors[0] : '01';

  const selectFloor = (floor, rrns, bldrecnbr) => {
    dispatch(actionMapZoomFeaturesUpdated({ type: 'rrn', data: rrns }));
    dispatch(actionAppBarFloorUpdated(floor));
    dispatch(actionMapUpdateActiveFeature({ bldrecnbr, floor }));
  }

  const [selected, setSelected] = useState(firstFloor ? `${firsBuilding.id}-${firstFloor}` : null);
  const selectHandler = (id, floor, rooms) => {
    const rrns = toRrns(rooms, floor);
    const bldrecnbr = toBldrecnbr(rooms);
    setSelected(id);
    selectFloor(floor, rrns, bldrecnbr);
  }

  useEffect(() => {
    if (firstFloor) {
      const rrns = toRrns(firsBuilding.rooms, firstFloor);
      const bldrecnbr = toBldrecnbr(firsBuilding.rooms);
      setTimeout(() => {
        selectFloor(firstFloor, rrns, bldrecnbr);
      }, 1000);
    }
  }, []);

  return (
    <div className={classes.root}>
      {
        buildings.map(building => {
          return <Building data={building} key={building.id} selected={selected} selectHandler={selectHandler}/>
        })
      }
    </div>
  );
}

function Building({ data, selected, selectHandler }) {
  const classes = useStyles();

  const handleFloorClick = (floor, id) => () => {
    selectHandler(`${id}-${floor}`, floor, data.rooms);
  }

  return (
    <CardActions disableSpacing classes={{root: classes.cardActionRoot}}>
      <Grid container align="center" justifyContent="center" className={classes.row}>
        <Grid item xs={4} className={classes.gridContent} >
          <Typography variant="overline">
            {data.building}
          </Typography>       
        </Grid>
        <Grid item xs={8}  className={classes.gridContent} >
          <Floors
            building={data.building}
            floors={data.floors}
            id={data.id}
            handleFloorClick={handleFloorClick}
            selected={selected}
          />
        </Grid>
      </Grid>
    </CardActions>
  ); 
}

function Floors({building, id, floors, handleFloorClick, selected}) {
  const classes = useStyles();

  return (
    <React.Fragment>
      {
        floors.sort(floorSorter).reverse().map(floor => {
          const isSelected = `${id}-${floor}` === selected;
          return  (
            <Tooltip title={`Go To ${building} FLOOR ${floor}`} key={floor}>
              <Button
                variant={!isSelected ? 'text' : 'outlined'}
                size="small"
                color="primary"
                className={classes.accordionBtn}
                onClick={handleFloorClick(floor, id)}
                key={floor}>
                {floor}
              </Button> 
            </Tooltip>
          )
        })
      }
    </React.Fragment>
  );
}

// output [{id, buildingName, floors, rooms}, ...]
function toRows(groupId, rooms) {
  const byBuildings = _.groupBy(rooms, 'bld_descrshort');
  let data = [];
  for (let building in byBuildings) {
    const id = groupId + '-' + building;
    const brn = byBuildings[building].length ? byBuildings[building][0]['bldrecnbr'] : '';
    const rooms = _.sortBy(byBuildings[building], ['floor', 'rmnbr'])
    const floors = _.chain(rooms).map('floor').uniq().value().sort();
    data.push({ id, building, brn, rooms, floors });
  }
  return _.sortBy(data, 'building');
}

function sortRows(rows, sortBy, sortDirection) {
  if (sortBy === 'building' && sortDirection === 'ascending') {
    return _.sortBy(rows, 'building');
  } else if (sortBy === 'building' && sortDirection === 'descending') {
    return _.sortBy(rows, 'building').reverse();
  }

  return rows;
}

function toRrns(rooms, floor) {
  return rooms
    .filter(room => room.floor === floor)
    .map(room => room.rmrecnbr);
}

function toBldrecnbr(rooms) {
  return rooms && rooms.length && rooms[0].bldrecnbr;
}