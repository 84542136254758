import { useState, useEffect } from 'react';
import { intersection, reduce, isEmpty } from 'lodash'

export default function useUrlHashRouter(identifier = {}) {
  const [params, setParams] = useState(null);
  const isRelevantHash = getRelevantParam(extractHashQueryParam(window.location.hash), identifier);
  const isRelevant = !isEmpty(isRelevantHash);

  const clearParam = !isRelevant ? null : (redirect) => {
    if (!params) { return; }

    // const newHash = reduce(params, (hashString, value, key) => {
    //   const param = `${key}=${value}`;
    //   return hashString.replace(param, '');
    // }, window.location.hash);
    // const hash = newHash.replace(/&/g, '');
    // window.history.replaceState(null, null, document.location.pathname + hash);

    window.setTimeout(() => {
      if (redirect) {
        window.location.hash = redirect;
      } else {
        const newUrl = document.location.href.replace(document.location.hash, '');
        window.history.replaceState(null, null, newUrl);
      }
    }, 500);
    setParams(null);
  }
  
  useEffect(() => {
    const getHashData = (hash) => {
      const hashParams = extractHashQueryParam(hash);
      // const hashData = getRelevantParam(hashParams, identifier);
      // setParams(hashData);
      setParams(isRelevant ? hashParams : null);
    }

    getHashData(window.location.hash);
    const getUrlHash = event => {
      getHashData(event.target.location.hash);
    };

    window.addEventListener('hashchange', getUrlHash);
    return () => {
      window.removeEventListener('hashchange', getUrlHash);
    }
  }, []);

  return [params, clearParam];
}

// @todo: decodeUri
function extractHashQueryParam(hashUrl) {
  if (!hashUrl || hashUrl === '#') { return null; }
  const parts = hashUrl.replace('#', '').split('&');
  return parts.reduce((data, part) => {
    const [key, value] = part.split('=');
    return {...data, ...{[key]: value}}
  }, {});
}

// @param hashParams {param1:value1, ..., paramNth:valueNth}
// @param identifier { feature, signature } mark relavant parameters in hash
function getRelevantParam(hashParams, identifier) {
  const { feature, signature } = identifier;
  const isValidIdentifier = feature || signature;
  if (!hashParams || !isValidIdentifier) { return null; }

  // url: http://abc.com/#feature=photo&photo_rrn=2124691
  // identifier: {feature: 'photo'}
  // output: {feature: "photo", photo_rrn: "2046660"}
  if (feature) {
    const featureSet = typeof feature === 'string' ? [feature] : feature;
    return reduce(hashParams, (hashData, value, param) => {
      if (param === null || param === undefined || !param.split) {
        return hashData;
      }
      const paramParts = param.split('_');
      if (param === 'feature' && featureSet.includes(value)) {
        hashData[param] = value;
      } else if (paramParts.length > 1 && featureSet.includes(paramParts[0])) {
        hashData[param] = value;
      }
      return hashData;
    }, {});
  }
  
  // url: http://abc.com/#access_token=BQ&state=VE4P&token_type=Bearer&expires_in=3600
  // identifier: {signature: ["access_token", "id_token", "state", "token_type", "expires_in"]}
  // output: {"access_token", "id_token", "state", "token_type", "expires_in"}
  if (signature) {
    const hashParamKeys = Object.keys(hashParams);
    const intersect = intersection(signature, hashParamKeys);
    // hash must contains at least all signature elts
    if (intersect.length !== signature.length) { return null; }

    return reduce(hashParams, (hashData, value, param) => {
      if (signature.includes(param)) {
        hashData[param] = value;
      }
      return hashData;
    }, {});
  }

  return null;
}