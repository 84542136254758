import XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';

// @param {Obj} data - { name, features: [feature1, feature2, ...], data: [[col1, col2,..],[],...] }
// @param {raw} boolean - if true include all attributes as excel header, only include official room attributes if false 
export function xlsx({ name, features, directPropPath=true, data, raw=false }) {
  const downloadData = features && features.length ? featuresTo2DArray(features, directPropPath, raw) : data;
  const MAX_ROWS = 1500; // max download size without zip
  const filename = getFileName(name);
  const zipFilename = 'MGIS-Data.zip';
  const isBelowSizeLimit = downloadData <= MAX_ROWS;

  const workbook = XLSX.utils.book_new();
  workbook.SheetNames.push("Sheet 1");
  const worksheetData = downloadData;
  const worksheet = XLSX.utils.aoa_to_sheet(worksheetData); // aoa === arrays of arrays
  workbook.Sheets["Sheet 1"] = worksheet;
  const workbookOut = XLSX.write(workbook, {bookType:'xlsx',  type: 'binary'});

  if (isBelowSizeLimit) {
    saveAs(new Blob([s2ab(workbookOut)], { type: "application/octet-stream" }), filename);
  } else {
    const zip = new JSZip();
    zip.file(filename, workbookOut, { binary: true });
    zip.generateAsync({ type: "blob" }).then(content => saveAs(content, zipFilename))
  }
}

// @param data - arrays of arrays 
export function csv({ name, data }) {
  // @ref: https://github.com/mholt/PapaParse/issues/175
  var csvData = new Blob([data], {type: 'text/csv;charset=utf-8;'});
  var filename = `${name || 'MGIS-Data'}.csv`;
  //IE11 & Edge
  if (navigator.msSaveBlob) {
      navigator.msSaveBlob(csvData, filename);
  } else {
      //In FF link must be added to DOM to be clicked
      var link = document.createElement('a');
      link.href = window.URL.createObjectURL(csvData);
      link.setAttribute('download', filename);
      document.body.appendChild(link);    
      link.click();
      document.body.removeChild(link);    
  }
}

// content type for excel file is octet stream
// convert the binary data into octet
function s2ab(s) { 
  var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
  var view = new Uint8Array(buf);  //create uint8array as viewer
  for (var i=0; i<s.length; i++) view[i] = s.charCodeAt(i) & 0xFF; //convert to octet
  return buf;    
}

function getFileName(name) {
  return name ? `${name}.xlsx` : 'MGIS-Data.xlsx';
}

function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
}

function getColor(colorStr) {
  if (!colorStr) {
    return ''
  } else if (colorStr.startsWith('#')) {
    const rgbObj = hexToRgb(colorStr);
    return rgbObj ? `rgb(${rgbObj.r}, ${rgbObj.g}, ${rgbObj.b})` : '';
  } else {
    return colorStr;
  }
}

const HEADER = [
  'rmrecnbr',
  'bldrecnbr',
  'bld_descrshort',
  'bld_descr50',
  'floor',
  'rmnbr',
  'rmsqrft',
  'rmstationcnt',
  'deptid',
  'rmtyp',
  'rmsubtyp',
  'rmtyp_descrshort',
  'rmsubtyp_descrshort',
  'dept_descr',
  'falist',
  'occlist',
  'occdeptlist',
  'grantdtlist',
  'rmfnclist',
  'fa_avail',
  'fa_reserve',
  'fa_na',
  'refreshed_at',
];

function featuresTo2DArray(features, directPropPath, raw=false) {
  if (!features || !features.length) { return [] }

  let SPREADSHEET_HEADER;
  let HEADER_PROPERTIES_MAP;

  if (isRoomFeaturesOnly(features) && !raw) {
    SPREADSHEET_HEADER = [...HEADER, 'MGIS-Color'];
    HEADER_PROPERTIES_MAP = [...HEADER, 'color'];
  } else if (raw) {
    const rawHeader = getRawHeader(features, HEADER);
    SPREADSHEET_HEADER = rawHeader;
    HEADER_PROPERTIES_MAP = rawHeader;
  } else {
    SPREADSHEET_HEADER = ['type', ...HEADER, 'longitude', 'latitude', 'geometry', 'id', 'MGIS-Color'];
    HEADER_PROPERTIES_MAP = ['name', ...HEADER, 'longitude', 'latitude', 'geometry', 'id', 'color'];
  }

  return features.reduce((arr, feature) => {
    const properties = !directPropPath ? feature.properties : feature;
    const geometry = feature.geometry || feature._geometry;

    if (isRoomType(feature)) {
      properties.name = 'Room';
    } else if (isPointType(feature)) {
      properties.longitude = feature.geometry.coordinates[0];
      properties.latitude = feature.geometry.coordinates[1];
    }

    if (!properties.bld_descrshort && properties.bldname) {
      properties.bld_descrshort = properties.bldname;
    }

    properties.id = feature.id;
    properties.geometry = JSON.stringify(geometry);
    properties.color = properties.color ? getColor(properties.color) : '';
    const row = HEADER_PROPERTIES_MAP.map(prop => properties[prop]);

    return [...arr, row];
  }, [SPREADSHEET_HEADER]);
}

/**
 * 
 * @param { [{feat1}, {feat2}, ...]} features 
 * @param { [attr1, attr2, ...] } defaultHeader 
 * @returns header of excel spreadsheet
 * 
 *  get attributes from each row of features, remove duplicate and sort,
 *  then sort array based on defaultHeader 
 */
function getRawHeader(features, defaultHeader) {
  const attrs = features.reduce((aggr, row) => {
    aggr.push(...Object.keys(row));
    return aggr;
  }, []);

  const uniq = [...new Set(attrs)]
  uniq.sort();

  const header = [];
  
  defaultHeader.forEach(attr => {
    if (uniq.indexOf(attr) !== -1) {
      header.push(attr);
    }
  });

  uniq.forEach(attr => {
    if (defaultHeader.indexOf(attr) === -1) {
      header.push(attr);
    }
  });

  return header;
}

function isRoomType(feature = {}) {
  const properties = feature.properties ? feature.properties : feature;

  const hasRRN = !!properties.rmrecnbr
  const validCampusData = (!feature.layer) ||
                          (feature.layer && feature.layer['source-layer'] === 'room');
  const validDrawMode = (!properties.drawmode) ||
                        (properties.drawmode && properties.drawmode === "room_picker") ||
                        (properties.drawmode && properties.drawmode === "simple_select")
  return hasRRN && validCampusData && validDrawMode;
}

function isPointType(feature) {
  return feature && feature.geometry && feature.geometry.type &&
         feature.geometry.type === 'Point' && feature.geometry.coordinates &&
         feature.geometry.coordinates.length === 2;
}

function isRoomFeaturesOnly(features) {
  return features.every(isRoomType);
}